<script>
export default {
  name: 'PFAddressesComponent',
  props: {
    addresses: {
      type: Array,
      default: (() => []),
    },
  },
  data: () => ({
    showMaxDefault: 3,
    showMore: true,
  }),
  computed: {
    getItems() {
      if (this.showMore && this.addresses && this.addresses.length > this.showMaxDefault) {
        return this.addresses.slice(0, this.showMaxDefault);
      }
      return this.addresses;
    },
  },
  methods: {
    moreOfficesCount() {
      if (this.addresses && this.addresses.length > 0) {
        return Math.abs(this.addresses.length - this.showMaxDefault);
      }
      return 0;
    },
    adjustCityName(city) {
      return city ? `${city}, ` : city;
    },
  },
};
</script>

<template>
<div class="ds-flexCol-12 ds-m-t-m"
  :class="showMore ? 'ds-m-b-xl' : 'ds-m-b-m'">
  <div
    v-show="addresses && addresses.length > 0"
    class="addresses-container ds-flexRow ds-p-none ds-m-none service-row-max-width"
    :class="addresses && addresses.length < 3 ? 'center-items' : ''">
    <div
      v-for="address in getItems"
      :key="address.id"
      class="ds-flexCol ds-flexCol-sm-6 ds-flexCol-md-4 ds-p-b-l">
      <div class="address-container" :class="addresses && addresses.length < 3 ? 'center-content' : ''">
        <div class="address__line-1" v-for="addressLine in address.addressLines" :key="addressLine.id">
          <span>{{ addressLine }}</span>
        </div>
        <div class="address__line-2">
          {{ address.suite }}
        </div>
        <div v-show="address.phone !== ''" class="address__phone">
          {{ address.phone }}
        </div>
        <div v-show="address.email !== ''" class="address__email">
          <a @click="$eventBus.$emit('PFCustomEvent',
             {eventName: 'NavigateExternal', custom2: 'ContactPartnerEmail'})"
             :href="'mailto:'+address.email">{{ address.email }}</a>
        </div>
      </div>
    </div>
  </div>
  <button
    type="button"
    v-show="addresses && addresses.length > 3 && showMore"
    class="ds-button ds-button--secondary addresses-container--more-location-button"
    @click="showMore = !showMore">
    {{ moreOfficesCount() }} {{ $tc('local.office.more.button.text', moreOfficesCount()) }}
  </button>
</div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.addresses-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  .ds-flexCol {
    min-width: 180px;
  }

  .address-container {
    font-size: 14px;
    width: fit-content;
    &:extend(.font-normal);
    line-height: 21px;
    .address--office-name {
      color: @color-black;
      font-size: 16px;
      line-height: 24px;
      &:extend(.font-expanded);
    }
    .address--location-name {
      color: @color-black;
      font-size: 16px;
      line-height: 24px;
      &:extend(.font-expanded);
    }

    a {
      color: @color-link;

      &:hover {
        color: @color-link__hover;
      }
    }
  }
  .center-content {
    margin-left: auto;
    margin-right: auto;
  }
}
.addresses-container--more-location-button {
  &:extend(.ds-button-secondary);
  width: auto;
  max-width: unset;
  display: block;
  margin: 0 auto;
}
.center-items {
  justify-content: center;
}
</style>
