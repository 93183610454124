<script>
import { mapState } from 'vuex';

export default {
  name: 'PESiteLeavePopupComponent',
  props: {
    toggleShowLeaveDialog: {
      type: Function,
      default: () => {},
    },
    showLeaveDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      pfProfileData: (state) => state.profileData,
      loading: (state) => state.loading,
      saveSuccess: (state) => state.saveSuccess,
      publishSuccess: (state) => state.publishSuccess,
    }),
  },
  methods: {
    relocateToHomePage() {
      window.location.href = '/';
      this.$store.commit('userProfile/setCancelSessionExtendingClicked', false);
    },
    discardChanges() {
      this.$store.commit('partnerProfile/updateChangedData', false);
      this.relocateToHomePage();
    },
    async saveProfileData() {
      this.$store.commit('partnerProfile/updateRedirectAfterSave', true);
      if (this.pfProfileData.id && this.pfProfileData.status !== 'PUBLISHED') {
        await this.$store.dispatch('partnerProfile/patchProfileData');
      } else {
        await this.$store.dispatch('partnerProfile/postProfileData');
      }
      this.toggleShowLeaveDialog(false);
      this.$store.commit('partnerProfile/updateRedirectAfterSave', false);
      if (this.saveSuccess || this.publishSuccess) {
        this.relocateToHomePage();
      }
      document.body.style.overflow = 'scroll';
      document.body.style.paddingRight = '0px';
    },
    backToEditor() {
      this.toggleShowLeaveDialog(false);
      document.body.style.overflow = 'scroll';
      document.body.style.paddingRight = '0px';
    },
  },
};
</script>

<template>
    <div class="ds-modal ds-modal--active" v-if="showLeaveDialog">
      <div class="ds-modal__box leave-dialog-modal">
        <div class="ds-modal__box__header ds-p-y-none">
          <i class="ds-icon--exclamation-circle ds-p-r-xs" />
          <div class="ds-modal__box__header__heading">
            <div class="ds-upload__block-reminder-upload dialog-warning-message ds-message__content ds-p-none">
              {{ $t('unsaved.changes') }}
            </div>
          </div>
        </div>
        <div class="ds-modal__box__content">
          <div class="dialog-text ds-m-y-s">
            <div v-if="loading" class="save-in-progress">
              <i class="ds-loading-indicator ds-m-r-s"></i>
              {{ $t("leave.dialog.save.in.progress") }}
            </div>
            <div v-else>{{ $t("leave.dialog.text") }}</div>
          </div>
        </div>
        <div v-if="!loading" class="ds-modal__box__action-bar ds-p-y-none">
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary save-button ds-m-x-none"
            type="button"
            @click="saveProfileData"
            id="leave-dialog-button__done"
          >
            {{ $t("button.save.changes") }}
          </button>
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--secondary discard-button ds-m-l-m"
            type="button"
            @click="discardChanges"
            id="leave-dialog-button__discard"
          >
            {{ $t("button.discard") }}
          </button>
        </div>
      </div>
    </div>

</template>

<style lang="less" scoped>
.ds-modal--active {
    z-index: 200;
}
.leave-dialog-modal {
  max-width: 480px;
  position: relative;
  height: fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 120px);
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  .dialog-warning-message {
    &:extend(.font-expanded);
    font-weight: normal;
    line-height: 27px;
  }
  .dialog-text {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
    padding-left: 44px;
  }
  .ds-button {
    &:extend(.font-normal);
    font-size: 16px;
    max-width: none;
    width: fit-content !important;
  }
  .save-in-progress {
    margin-left: auto;
    margin-right: auto;
    margin-top: 34px;
    margin-bottom: 49px;
    width: fit-content;
    color: @color-gray-1;
    display: flex;
    font-size: 16px;
    line-height: 31px;
    .ds-loading-indicator::before {
      font-size: 31px;
      line-height: 31px;
    }
  }
  .cancel-button {
    color: @color-link;
    border: none;
    background-color: transparent !important;
  }
  .cancel-button:hover {
    color: @color-link__hover;
  }
  .ds-icon--exclamation-circle::before {
    color: @warning-orange;
    font-size: 35px;
    line-height: 35px;
    position: relative;
    left: -10px;
  }
  .ds-modal__box__header__heading {
    font-size: 1.31rem;
  }
  .ds-modal__box__content {
    margin-top: -5px;
    margin-bottom: 5px;
  }
  .ds-modal__box__action-bar {
    margin-top: 18px;
    padding-right: 1rem;
  }
  .ds-message__content {
    color: @color-black;
  }
}

</style>
