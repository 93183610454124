<script>

import PFProfileSliderDataResourceComponent from '@/components/common/PFProfileSliderDataResourceComponent';

export default {
  name: 'PFSliderComponent',
  props: {
    content: { type: Array, default: () => [] },
    componentId: { type: String },
    showAllToggle: { type: Boolean, default: false },
    countryName: { type: String, default: '' },
    countryText: { type: String, default: '' },
  },
  data: () => ({
    currentIndex: 0,
  }),
  methods: {
    nextItem() {
      if (this.currentIndex !== this.content.length - 1) {
        this.currentIndex++;
      }
    },
    prevItem() {
      if (this.currentIndex !== 0) {
        this.currentIndex--;
      }
    },
    emitToggleCardHeight() {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('toggleCardHeight');
    },
  },
  components: {
    PFProfileSliderDataResourceComponent,
  },
};
</script>

<template>
  <div class="ds-content-slider">
    <div class="ds-content-slider__content">
      <i v-if="content.length > 1"
         class="ds-icon--arrow-left ds-content-slider__content__button ds-content-slider__content__button--previous"
         :class="{
            'pf-icon--arrow-disabled' : currentIndex <= 0,
            'pf-icon--arrow-active' : currentIndex > 0
         }"
         @click="prevItem"/>
      <div class="ds-content-slider__content__slides">
        <component v-bind:is="componentId"
                   :showAllToggle="showAllToggle"
                   :countryName="countryName"
                   :countryText="countryText"
                   v-on:toggleCardHeight="emitToggleCardHeight"
                   :data="content[currentIndex]"
                   class="ds-content-slider__content__slides__slide ds-content-slider__content__slides__slide--active"/>
      </div>
      <i v-if="content.length > 1"
         class="ds-icon--arrow-right ds-content-slider__content__button ds-content-slider__content__button--next"
         :class="{
            'pf-icon--arrow-disabled' : currentIndex === this.content.length - 1,
            'pf-icon--arrow-active' : currentIndex !== this.content.length - 1
         }"
         @click="nextItem"/>
    </div>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.ds-content-slider__content {
  grid-template-columns: auto auto auto !important;
  .ds-icon--arrow-right, .ds-icon--arrow-left {
    color:@color-gray-3 !important;
  }
}
.pf-icon--arrow-active:hover {
  color:@color-gray-2 !important;
  opacity: 1;
}
.pf-icon--arrow-disabled {
  color:@color-gray-5 !important
}
.pf-icon--arrow-disabled:hover {
  cursor: not-allowed;
  opacity: 1;
}

</style>
