<script>

export default {
  name: 'PFCloudImplementationAccordionComponent',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    country: {
      type: String,
      default: '',
    },
  },
  computed: {
    cloudImplementations() {
      if (!this.isShown) {
        return [];
      }
      if (this.country) {
        return this.$store.getters['partnerProfile/getLocalProfileByCountry'](this.country).cloudImpls;
      }
      return this.$store.state.partnerProfile.profileData.cloudImpls;
    },

  },
};

</script>

<template>
  <div class="ds-flexCol-12 ds-accordion ds-p-none">
    <div
      v-for="implementation in cloudImplementations"
      :key="implementation.solutionName + country"
      class="ds-accordion__item cloud-implementation-item">
      <div class="ds-accordion__item-header--icon">
        <label :for="implementation.solutionName + country">
          <i class="ds-icon--big empty-icon"/>
        </label>
      </div>
      <div class="ds-accordion__item-content ds-flexCol-11 ds-p-none ds-p-r-s">
        <div class=" cloud-modal-header ds-p-none ds-m-t-s ds-accordion__item-content--header
          ds-p-l-xxs font-normal">
          <label :for="implementation.solutionName + country">
            <div class="cloud-number ds-m-r-m">{{ implementation.count }}</div>
            {{ implementation.solutionName }}
          </label>
        </div>
      </div>
    </div>

  </div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";
.cloud-modal-header {
  font-size: 21px;
  line-height: 1.52;
  margin-left: 0;
}

.cloud-implementation-item {
  height: 54px;
}

.ds-accordion__item-content--header:hover{
  color: @black-color;
}

.empty-icon {
  height: 1.5rem;
}

.empty-icon::before {
  content: "";
  width: 0;
}

.cloud-number {
  display: inline-block;
  width: 49px;
  text-align: right;
}

.display {
  display: block !important;
}

.hidden {
  display: none !important;
}

.ds-accordion__item-header--icon {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
