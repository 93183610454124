<script>
export default {
  name: 'PFSocialMediaComponent',
  data: () => ({
    socialLinks: [
      {
        tooltip: 'Facebook',
        target: '_blank',
        networkType: 'facebook',
        path: 'https://www.facebook.com/SAP',
      },
      {
        tooltip: 'Youtube',
        target: '_blank',
        networkType: 'youtube',
        path: 'https://www.youtube.com/user/SAP',
      },
      {
        tooltip: 'Instagram',
        target: '_blank',
        networkType: 'instagram',
        path: 'https://instagram.com/sap/',
      },
      {
        tooltip: 'Linkedin',
        target: '_blank',
        networkType: 'linkedin',
        path: 'https://www.linkedin.com/company/sap/',
      },
    ],
  }),
};
</script>

<template>
<div class="social-media-footer">
  <h4 class="social-media-footer__headline">{{$t('find.us.on')}}</h4>
  <div>
  <ul class="social-media-footer__list">
    <li v-for="socialLink in socialLinks" class="social-media-footer__item" v-bind:key="socialLink.tooltip">
      <a
        class="social-media-footer__link"
        :title="socialLink.tooltip"
        :target="socialLink.target"
        :data-share-channel="socialLink.networkType"
        :href="socialLink.path">
        <span :class="'social-media-footer__icon social-media-footer__icon_' +  socialLink.networkType"></span>
      </a>
    </li>
  </ul>
  </div>
</div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.social-media-footer {
  display: block;
  float: right;
  margin: 0.75rem 0 0.75rem auto;
  height: fit-content;
}

.social-media-footer__item {
  float: left;
  margin-left: 10px;
  width: 26px;
  height: 26px;
  border-radius: 2px;
  background-color: @color-white;
}

.social-media-footer__headline {
    float: left;
    margin: 0;
    margin-left: 9px;
    padding-top: 4px;
    font-size: 16px;
    &:extend(.font-bold-expanded);
    line-height: 1.5;
    letter-spacing: normal;
    color: @color-white;
}

.social-media-footer__link {
    margin: 0;
    padding: 0;
    line-height: normal;
    outline: none;

    &:hover {
        background-color: transparent;
        cursor: pointer;
    }
}

.social-media-footer__list {
    position: relative;
    float: left;
    margin: 0;
    padding-left: 0;
    list-style: none;
    line-height: normal;
}

.social-media-footer__link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    &:hover,
    &:focus {
        background-color: transparent;
    }
}

.social-media-footer__icon {
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
}

.social-media-footer__icon_facebook    { background-image: url('../../assets/icons/icon-2018-facebook.svg'); }
.social-media-footer__icon_linkedin   { background-image: url('../../assets/icons/icon-2018-linkedin.svg'); }
.social-media-footer__icon_instagram     { background-image: url('../../assets/icons/icon-2018-instagram.svg'); }
.social-media-footer__icon_youtube     { background-image: url('../../assets/icons/icon-2018-youtube.svg'); }

</style>
