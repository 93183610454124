<script>
import { mapActions, mapState } from 'vuex';

const MAX_LENGTH_VALUE_PROP = 400;
const MAX_LENGTH_VALUE_PROP_SUMMARY = 70;
const MAX_CHAR_DEFAULT_SHOW = 250;
const LINE_HEIGHT_PROP_TEXT = 24;
const MAX_LINES_PROP_TEXT = 4;

export default {
  name: 'PFPartnerValuePropComponent',
  data: () => ({
    isValidValueProp: true,
    isFocusedValueProp: false,
    isValidValuePropSummary: true,
    isFocusedValuePropSummary: false,
    valuePropSummaryMaxCharCount: MAX_LENGTH_VALUE_PROP_SUMMARY,
    valuePropMaxCharCount: MAX_LENGTH_VALUE_PROP,
    valuePropMandatoryErr: {},
    valuePropSummaryMandatoryErr: {},
    mountedValueProp: 'mountedValueProp',
    mountedValuePropSummary: 'mountedValuePropSummary',
    showAllToggle: false,
    initialDisplayCount: MAX_CHAR_DEFAULT_SHOW,
    isPropTextOverflow: false,
    showLess: false,
  }),
  props: {
    editMode: {
      type: Boolean,
    },
  },
  mounted() {
    this.valuePropMandatoryErr = {
      id: 'valuePrepositionErr',
      field: this.$t('field.name.value.proposition'),
      message: this.$t('mandatory.field.error.message'),
    };
    this.valuePropSummaryMandatoryErr = {
      id: 'valuePrepositionSummaryErr',
      field: this.$t('field.name.value.proposition.summary'),
      message: this.$t('mandatory.field.error.message'),
    };
    if (this.mountedComponent.includes(this.mountedValueProp)) {
      this.isValidValuePropSummary = this.valuePropSummary && this.valuePropSummary !== '';
      this.isValidValueProp = this.valueProp && this.valueProp !== '';
    }
    this.$eventBus.$on('*', (type) => {
      if (['footerSaveClicked', 'footerPublishClicked', 'editorToggleButtonClicked'].includes(type)) {
        this.isValidValuePropSummary = this.valuePropSummary && this.valuePropSummary !== '';
        this.isValidValueProp = this.valueProp && this.valueProp !== '';
      }
    });
    this.$eventBus.$on('valuePrepositionSummaryErr', () => {
      this.isValidValuePropSummary = false;
    });
    this.$eventBus.$on('valuePrepositionErr', () => {
      this.isValidValueProp = false;
    });
  },
  computed: {
    ...mapState('partnerProfile', {
      mountedComponent: (state) => state.mountedComponent,
    }),
    valuePropSummary: {
      get() {
        return this.$store.state.partnerProfile.profileData
          .valuePrepositionSummary;
      },
      set(newProposition) {
        this.$store.dispatch(
          'partnerProfile/updateValuePropositionSummary',
          newProposition,
        );
      },
    },
    valueProp: {
      get() {
        return this.$store.state.partnerProfile.profileData.valuePreposition;
      },
      set(newProposition) {
        this.$store.dispatch(
          'partnerProfile/updateValueProposition',
          newProposition,
        );
      },
    },
    getValuePropText() {
      if (this.valueProp) {
        return this.$sanitize(this.valueProp);
      }
      return '';
    },
    showMoreText() {
      return (this.valueProp && this.valueProp.length > this.initialDisplayCount);
    },
    valuePropCharCount() {
      if (this.valueProp) {
        return MAX_LENGTH_VALUE_PROP - this.valueProp.length;
      }
      return MAX_LENGTH_VALUE_PROP;
    },
    valuePropSummaryCharCount() {
      if (this.valuePropSummary) {
        return MAX_LENGTH_VALUE_PROP_SUMMARY - this.valuePropSummary.length;
      }
      return MAX_LENGTH_VALUE_PROP_SUMMARY;
    },
    valuePropStyle() {
      if (this.isPropTextOverflow && !this.showLess) {
        return {
          'max-height': `${LINE_HEIGHT_PROP_TEXT * MAX_LINES_PROP_TEXT}px`,
          overflow: 'hidden',
        };
      }
      return {};
    },
    moreLessButtonStyle() {
      if (!this.showLess) {
        return {
          position: 'absolute',
          bottom: '0',
          right: '0',
        };
      }
      return { position: 'relative' };
    },
  },
  methods: {
    ...mapActions('partnerProfile', {
      updateValuePropSummary: 'updateValuePropositionSummary',
      updateValueProp: 'updateValueProposition',
    }),
    handleValuePropError(val) {
      val
        ? this.$store.dispatch('partnerProfile/removeErrorField', this.valuePropMandatoryErr.id)
        : this.$store.dispatch('partnerProfile/addErrorField', this.valuePropMandatoryErr);
    },
    handleValuePropSummaryError(val) {
      val
        ? this.$store.dispatch('partnerProfile/removeErrorField', this.valuePropSummaryMandatoryErr.id)
        : this.$store.dispatch('partnerProfile/addErrorField', this.valuePropSummaryMandatoryErr);
    },
    onResize() {
      this.isPropTextOverflow = false;
      this.numOfLines = Math.floor(this.$refs.propText.scrollHeight / LINE_HEIGHT_PROP_TEXT);
      this.isPropTextOverflow = (this.numOfLines > MAX_LINES_PROP_TEXT);
    },
  },
  watch: {
    valuePropSummary(val) {
      if (this.mountedComponent.includes(this.mountedValuePropSummary)) {
        this.isValidValuePropSummary = val && val !== '';
      } else {
        this.$store.dispatch(
          'partnerProfile/addMountedComponent',
          this.mountedValuePropSummary,
        );
      }
    },
    valueProp(val) {
      if (this.mountedComponent.includes(this.mountedValueProp)) {
        this.isValidValueProp = val && val !== '';
      } else {
        this.$store.dispatch(
          'partnerProfile/addMountedComponent',
          this.mountedValueProp,
        );
      }
    },
    isValidValuePropSummary(val) {
      this.handleValuePropSummaryError(val);
    },
    isValidValueProp(val) {
      this.handleValuePropError(val);
    },
  },
};
</script>

<template>
  <div class="value-prop-container">
    <div v-show="editMode">
      <div class="ds-textarea value-prop-textarea">
        <label class="ds-textField ds-m-b-xxs">
          <textarea
            id="value-proposition-summary__text"
            :placeholder="$t('helptext.global.valueprop.summary.edit')"
            class="ds-textField__input ds-textField__input--textarea
            value-proposition-summary__textField value-prop-text-area"
            :class="!isValidValuePropSummary && !isFocusedValuePropSummary ? 'error-value-prop-border' : ''"
            :maxlength="valuePropSummaryMaxCharCount"
            @focus="isFocusedValuePropSummary = true"
            @blur="isFocusedValuePropSummary = false"
            v-model="valuePropSummary"
          >
          </textarea>
          <!-- <span class="ds-textField__placeholder"></span> -->
        </label>
        <div class="ds-flexRow ds-m-b-xs">
          <div class="ds-flexCol-6 textarea-validation-message">
            <div
              class="ds-textField__message ds-m-t-none"
              v-show="!isValidValuePropSummary && !isFocusedValuePropSummary"
            >
              <i class="ds-icon--close-outline ds-p-r-xs"></i>
              {{ $t("mandatory.field.error.message") }}
            </div>
          </div>
          <div class="ds-flexCol-6">
            <span class="char-count">
              {{ valuePropSummaryCharCount }}
              {{ $tc("editor.global.characters.left", valuePropSummaryCharCount) }}
            </span>
          </div>
        </div>
      </div>
      <div class="ds-textarea value-prop-textarea">
        <label class="ds-textField ds-m-b-xxs">
          <textarea
            id="value-proposition__text"
            :placeholder="$t('helptext.global.valueprop.edit')"
            class="ds-textField__input ds-textField__input--textarea value-prop-text-area"
            :class="!isValidValueProp && !isFocusedValueProp ? 'error-value-prop-border' : ''"
            :maxlength="valuePropMaxCharCount"
            @focus="isFocusedValueProp = true"
            @blur="isFocusedValueProp = false"
            v-model="valueProp"
          >
          </textarea>
          <!-- <span class="ds-textField__placeholder"></span> -->
        </label>
        <div class="ds-flexRow">
          <div class="ds-flexCol-6 textarea-validation-message">
            <div
              class="ds-textField__message ds-m-t-none"
              v-show="!isValidValueProp && !isFocusedValueProp"
            >
              <i class="ds-icon--close-outline ds-p-r-xs"></i>
              {{ $t("mandatory.field.error.message") }}
            </div>
          </div>
          <div class="ds-flexCol-6">
            <span class="char-count"
              >{{ valuePropCharCount }}
              {{ $tc("editor.global.characters.left", valuePropCharCount) }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <div v-show="!editMode">
      <p class="ds-p-y-xs ds-m-y-none value-proposition-summary">
        {{ valuePropSummary }}
      </p>
      <div class="ds-m-y-xs value-proposition" ref="propText" :style="valuePropStyle">
        <resize-observer @notify="this.onResize"></resize-observer>
        <span v-html="getValuePropText"></span>
        &nbsp;
        <span class="show-more-link" v-show="isPropTextOverflow" :style="moreLessButtonStyle">
          <span class="fade-overlay" v-show="isPropTextOverflow && !showLess"></span>
          <a
            href=""
            @click.prevent="showLess = !showLess">
             {{ showLess ? $t('show.less.text') : $t('title.brand.names.more') }}
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.value-prop-container {
  .value-proposition {
    // max-width: 590px;
    white-space: pre-wrap;
    position: relative;
    font-size: 16px;
    line-height: 24px;
    &:extend(.font-normal);
    min-width: 15.75rem;
  }
  .show-more-link{
    display: inline-block;
    background-color: @color-white;
    color: @color-link;
    cursor: pointer;
  }
  .show-more-link:hover {
    color: @color-link__hover;
  }
  .value-proposition-summary {
    font-size: 38px;
    line-height: 46px;
    // max-width: 590px;
    min-height: 100px;
    &:extend(.font-expanded);
  }

  .value-proposition-summary__textField {
    font-size: 38px;
    line-height: 46px;
  }

  .value-proposition-summary__textField::placeholder {
    font-size: 16px;
    line-height: 24px;
  }

  .error-value-prop-border {
    border-color: @color-field-error;
  }

  .value-prop-text-area {
    resize: vertical;
  }
}
.value-prop-textarea {
  & > label textarea.ds-textField__input--textarea {
    min-height: 108px;
  }
}

.textarea-validation-message {
  & > .ds-textField__message {
    font-size: 14px;
    color: @color-field-error;
    position: relative;
    margin-top: 0;
    top: auto;
  }
  .ds-icon--close-outline {
    line-height: 17px;
  }
}

.char-count {
  &:extend(.font-normal);
  font-size: 12px;
  display: block;
  text-align: right;
  line-height: 18px;
  color: @color-glance-consultants;
}
</style>
