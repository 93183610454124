<script>
import PFContactEmailComponent from '@/components/common/PFContactEmailComponent';

export default {
  name: 'PFPartnerAnchorLinksComponent',
  props: {
    location: { type: String, default: '#partnerDetail#' },
    editMode: { type: Boolean, default: false },
    defaultHeader: { type: Boolean, default: true },
  },
  components: {
    PFContactEmailComponent,
  },
  methods: {
    scrollTo(positionId) {
      const sectionEle = document.getElementById(
        `partner-detail-section-${positionId}`,
      );
      const partnerDetailHeaderOffsetHeight = document.getElementById(
        'partner-detail-header',
      ).offsetHeight;
      const editHeaderContainerEl = document.getElementsByClassName(
        'edit-header-container',
      )[0];
      const { top } = sectionEle.getBoundingClientRect();
      let result = window.scrollY === 0
        ? top
            - (editHeaderContainerEl ? editHeaderContainerEl.offsetHeight : 0)
        : top + window.scrollY;
      result -= partnerDetailHeaderOffsetHeight;
      if (editHeaderContainerEl) {
        result -= editHeaderContainerEl.offsetHeight;
      }
      window.scrollTo({ left: 0, top: result, behavior: 'smooth' });
    },
    highlightScrolledLink() {
      const pdHeaderEle = document.getElementById('partner-detail-header');
      const pdHeaderOffset = pdHeaderEle.offsetHeight;
      const bottomPosition = this.getBottomPos(pdHeaderEle, pdHeaderOffset);
      if (window.scrollY >= 0 && window.scrollY < bottomPosition) {
        this.showHighlight('glance');
      } else if (this.isInSection('service', pdHeaderOffset)) {
        this.showHighlight('service');
      } else if (this.isInSection('locations', pdHeaderOffset)) {
        this.showHighlight('locations');
      } else if (this.isInSection('applications', pdHeaderOffset)) {
        this.showHighlight('applications');
      } else if (this.isInSection('resources', pdHeaderOffset)) {
        this.showHighlight('resources');
      }
    },
    getBottomPos(pdHeaderEle, pdHeaderOffset) {
      const deteilSection = 'partner-detail-section-glance';
      const glanceSectionEle = document.getElementById(deteilSection);

      const editHeaderCont = 'edit-header-container';
      const editHeaderEl = document.getElementsByClassName(editHeaderCont)[0];

      const headerCont = 'header-container';
      const stickyHeaderEl = document.getElementsByClassName(headerCont)[0];

      const stickyEleOffset = stickyHeaderEl ? stickyHeaderEl.offsetHeight : 0;
      const editEleOffset = editHeaderEl ? editHeaderEl.offsetHeight : 0;
      const pdHeaderEleTop = pdHeaderEle.getBoundingClientRect().top;
      const glanceEleOffsetTop = glanceSectionEle.offsetTop;
      const glanceEleOffsetHeight = glanceSectionEle.offsetHeight;
      return (
        glanceEleOffsetTop
        + glanceEleOffsetHeight
        - pdHeaderEleTop
        - stickyEleOffset
        - editEleOffset
        - pdHeaderOffset
      );
    },

    isInSection(sectionName, offset) {
      const sectionEle = document.getElementById(
        `partner-detail-section-${sectionName}`,
      );
      if (sectionEle != null) {
        return (
          window.scrollY + offset >= sectionEle.offsetTop - offset
          && window.scrollY + offset < sectionEle.offsetTop + sectionEle.offsetHeight - offset
        );
      }
      return false;
    },
    showHighlight(anchorName) {
      const anchorNames = [
        'glance',
        'service',
        'locations',
        'applications',
        'resources',
      ];
      anchorNames.forEach((name) => {
        if (anchorName === name) {
          const element = document.getElementById(`${name}-anchor-highlight`);
          if (element) {
            element.classList.add('highlight');
          }
        } else {
          const element = document.getElementById(`${name}-anchor-highlight`);
          if (element) {
            element.classList.remove('highlight');
          }
        }
      });
    },
  },
  created() {
    window.addEventListener('scroll', this.highlightScrolledLink);
  },
  unmounted() {
    window.removeEventListener('scroll', this.highlightScrolledLink);
  },
  mounted() {
    this.highlightScrolledLink();
  },
  computed: {
    resources: {
      get() {
        return this.$store.state.partnerProfile.profileData.resources;
      },
    },
  },
};
</script>

<template>
      <div class="anchor-link-container">
        <a
          class="ds-p-none ds-m-r-m anchor-link-text-container"
          v-on:click="scrollTo('glance')"
          v-on:scroll="highlightScrolledLink()"
        >
          <div id="anchor-link-glance" class="anchor-link-text">{{$t('section.title.glance')}}</div>
          <div id="glance-anchor-highlight" />
        </a>
        <a class="ds-p-none ds-m-r-m anchor-link-text-container"
          v-on:click="scrollTo('service')" v-on:scroll="highlightScrolledLink()">
          <div id="anchor-link-service" class="anchor-link-text">{{$t('section.title.services')}}</div>
          <div id="service-anchor-highlight" />
        </a>
        <a
          :class="['ds-p-none anchor-link-text-container',
          editMode || (resources && resources.length > 0) ? 'ds-m-r-m' : '']"
          v-on:click="scrollTo('locations')"
          v-on:scroll="highlightScrolledLink()"
        >
          <div id="anchor-link-locations" class="anchor-link-text">{{$t('section.title.locations')}}</div>
          <div id="locations-anchor-highlight" />
        </a>
        <a v-if="editMode || (resources && resources.length > 0)"
          class="ds-p-none anchor-link-text-container"
          v-on:click="scrollTo('resources')"
          v-on:scroll="highlightScrolledLink()"
        >
          <div id="anchor-link-resources" class="anchor-link-text">{{$t('section.title.resources')}}</div>
          <div id="resources-anchor-highlight" />
        </a>
        <PFContactEmailComponent v-show="!editMode && !defaultHeader"
          :headerButton="true"
          class="ds-p-none anchor-link-text-container header-contact-button ds-m-l-m"/>
      </div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";
.anchor-link-container {
  display: flex;
  height: 100%;
}
.header-contact-button {
  height: 42px !important;
  width: 160px;
  top: calc(50% - 42px/2);
}
.anchor-link-text-container {
  height: 100%;
  position: relative;
}
.anchor-link-text {
  &:extend(.font-normal);
  text-align: center;
  height: 24px;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  position: relative;
  top: calc(50% - 24px/2);
}
a,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none;
  color: @color-black;
  cursor: pointer;
}
.highlight {
  position: absolute;
  width: 100%;
  bottom: 0px;
  height: 4px;
  background-color: @color-sap-orange;
}
</style>
