<script>
import { mapState } from 'vuex';
import PESiteLeavePopupComponent from '@/components/editor/popup/PESiteLeavePopupComponent';
import PESessionExpirationPopupComponent from '@/components/editor/popup/PESessionExpirationPopupComponent';

export default {
  name: 'PFSiteLogoComponent',
  props: {
    isTitleVisible: { type: Boolean, default: () => true },
    title: { type: String },
    editorHeader: { type: Boolean, default: () => false },
    blackHeaderLogo: { type: Boolean, default: () => false },
  },
  components: {
    PESiteLeavePopupComponent,
    PESessionExpirationPopupComponent,
  },
  data: () => ({
    showLeaveDialog: false,
  }),
  computed: {
    ...mapState('partnerProfile', {
      changedData: (state) => state.changedData,
    }),
    ...mapState('userProfile', {
      cancelSessionExtendingClicked: (state) => state.cancelSessionExtendingClicked,
    }),
  },
  methods: {
    goToHomePage() {
      if (this.editorHeader && this.changedData && !this.cancelSessionExtendingClicked) {
        this.showLeaveDialog = true;
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      } else {
        this.relocateToHomePage();
      }
    },
    relocateToHomePage() {
      window.location.href = '/';
    },
    toggleShowLeaveDialog(val) {
      this.showLeaveDialog = val;
    },
  },
};
</script>

<template>
  <div class="sap-header-logo-container">
    <a class="ds-sap-logo ds-m-none" style="text-decoration: none" href="/" @click.prevent="goToHomePage">
      <div class="ds-sap-logo__link">
        <img
          class="ds-sap-logo__link__image"
          src="@/assets/SAP_Logo.png"
        />
        <div
          v-if="isTitleVisible"
          :class="[
            blackHeaderLogo ? 'pf-color' : 'pe-color',
            'ds-sap-logo__link__text ds-m-l-m',
          ]"
        >
          {{ title ? title : $t('header.logo.label.title') }}
          <span
            v-if="editorHeader"
            :class="blackHeaderLogo ? 'pf-color-editor' : 'pe-color-editor'"
          >
            Editor</span
          >
        </div>
      </div>
    </a>
    <PESiteLeavePopupComponent
      v-if="editorHeader"
      :showLeaveDialog="showLeaveDialog"
      :toggleShowLeaveDialog="toggleShowLeaveDialog"
    />
    <PESessionExpirationPopupComponent
      v-if="editorHeader"
      :toggleShowLeaveDialog="toggleShowLeaveDialog"
    />
  </div>
</template>

<style lang="less" scoped>
@import '../../assets/css/common.less';
.sap-header-logo-container {
  height: 100%;
.ds-sap-logo {
  top: calc(50% - 32px/2);
  cursor: pointer;
  position: relative;
  .ds-sap-logo__link__image {
    height: 32px;
  }

  .ds-sap-logo__link__text {
    &:extend(.font-bold);
    height: 32px;
    font-size: 16px;
    font-stretch: normal;
    line-height: 32px;
    letter-spacing: normal;
  }
  .pf-color.ds-sap-logo__link__text {
    width: max-content;
  }
  .pe-color.ds-sap-logo__link__text {
    width: 281px;
  }

  .pe-color {
    color: @color-gray-1;
  }

  .pe-color-editor {
    color: @color-link;
    font-size: 16px;
  }

  .pf-color {
    color: @color-white;
  }

  .pf-color-editor {
    color: @light-blue;
    font-size: 16px;
  }
}
}
</style>
