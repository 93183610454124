<script>
export default {
  name: 'PFValidatedComboBoxComponent',
  props: {
    localizedPlaceholder: {
      type: String,
      default: '',
    },
    localizedErrorMessage: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    validAction: {
      type: Function,
      default: () => true,
    },
    changeAction: {
      type: Function,
      default: () => true,
    },
    options: { type: Object },
  },
  data() {
    return {
      title: '',
      selectedIndex: -1,
      arrayLength: Object.keys(this.options).length - 1,
    };
  },
  computed: {
    elementOffset() {
      if (this.selectedIndex === -1) {
        return 0;
      }
      const str = this.selectedIndex.toString();
      const ind = `#option-${str}`;
      const elem = this.$el.querySelector(ind);
      return elem.offsetTop - 12;
    },
  },
  methods: {
    homeValueIndex() {
      let i;
      if (this.value != null && this.value.length > 0) {
        for (i = 0; i < this.arrayLength; i++) {
          if (Object.values(this.options)[i] === this.options[this.value]) {
            this.selectedIndex = i;
            return;
          }
        }
      }
      this.selectedIndex = -1;
    },
    up() {
      if (this.selectedIndex >= 0) {
        this.selectedIndex--;
      }
      if (this.selectedIndex === -1) {
        this.selectedIndex = this.arrayLength;
      }
      this.changeValue();
    },
    down() {
      if (this.selectedIndex < this.arrayLength) {
        this.selectedIndex++;
      } else {
        this.selectedIndex = 0;
      }
      this.changeValue();
    },
    changeValue() {
      this.title = this.getCurrentOption();
      this.changeAction(this.getCurrentOption());
      this.$el.querySelector('#option-list').scrollTo(0, this.elementOffset);
    },
    getCurrentOption() {
      return Object.keys(this.options)[this.selectedIndex];
    },
    clickAction(type, index) {
      this.title = type;
      this.changeAction(type);
      this.selectedIndex = index;
    },
    selectItem() {
      this.$el.querySelector('#select-box').blur();
    },
  },
  beforeMount() {
    this.homeValueIndex();
  },
};
</script>

<template>
<div class="ds-select-box" :class="!validAction(name) ? 'ds-select-box--error' : ''">
  <label class="ds-textField ds-select-box__wrapper">
    <input class="ds-textField__input ds-select-box__wrapper__input" id="select-box"
    :class="{ 'ds-textField__input--active' : value != null && value.length > 0, }"
           :readonly="true"
           type="text"
           v-on:blur.prevent
           :value="this.value === null ? options[title] : options[value]"
           @keydown.down.prevent="down"
           @keydown.up.prevent="up"
           @keydown.enter="selectItem"
    />
    <span class="ds-textField__placeholder ds-select-box__wrapper__placeholder pf-combobox-placeholder">
        {{ $t(localizedPlaceholder) }}
    </span>
    <i class="ds-select-box__wrapper__icon--down ds-icon--arrow-down ds-icon--big"></i>
    <i class="ds-select-box__wrapper__icon--up ds-icon--arrow-up ds-icon--big"></i>
    <div class="ds-select-box__wrapper__dropdown">
      <div class="ds-dropdown__inner ds-dropdown__inner--scrollable" id="option-list" >
        <div  class="ds-dropdown__inner__option" v-for="(type,index) in Object.keys(options)" :key="type"
          v-bind:id="'option-' + index"
          :class="[value === type ? 'ds-dropdown_inner__option--selected' : '',
          value === type ? 'selected-option'  : '' ]"
          v-on:mousedown="clickAction(type,index)">
          {{ options[type] }}
        </div>
      </div>
    </div>
  </label>
  <div v-if="!validAction(name)" class="ds-textField__message pf-validation-error-message">
      <i class="ds-icon--close-outline ds-m-r-xs"></i>
      <span>{{ $t(localizedErrorMessage) }}</span>
  </div>
</div>
</template>

<style scoped lang="less">
@import "../../assets/css/common.less";

.selected-option {
  background-color: @color-slight-gray;
}

.pf-combobox-placeholder {
  color: @color-gray-2;
  line-height: 1.21;
}

.ds-select-box--error {
  margin-bottom: 32px !important;
}

.ds-textField__input--active:not(:focus) ~ .ds-textField__placeholder {
  color: @color-gray-2 !important;
}

// .ds-combo-box__placeholder__text {
//   color: @color-link !important;
// }

.ds-combo-box__selected__option {
  color: @black-color !important;
}

.pf-validation-error-textField:focus {
  border-color: @color-field-error;
}

.pf-validation-error-textField {
  margin-bottom: 37px !important;
  border-color: @color-field-error;
}

.pf-validation-error-textField-placeholder, .pf-validation-error-message {
  color: @color-field-error !important;
}

.ds-textField__input {
  cursor: pointer;
  opacity: 1 !important;
  position: static !important;
  font-size: inherit;
}

.ds-textField__input::placeholder {
  font-size: inherit;
}

.pf-validation-error-message {
  font-size: 14px;
  line-height: 21px;
  left: 0;
  .ds-icon--close-outline {
    font-size: 14px;
    line-height: 21px;
  }
}

</style>
