<script>
export default {
  name: 'PFValidatedTextAreaComponent',
  props: {
    localizedPlaceholder: { type: String, default: '' },
    localizedErrorMessage: { type: String, default: '' },
    value: { type: String, default: '' },
    name: { type: String, default: '' },
    validAction: { type: Function, default: () => true },
    changeAction: { type: Function, default: () => true },
    maxLength: { type: Number },
  },
  methods: {
    isNotEmpty(str) {
      return str !== null && str.length > 0;
    },
  },
};
</script>

<template>
  <label class="ds-textField ds-m-none"
         :class="validAction(name) ? '' : 'ds-textField--error'">
      <textarea class="ds-textField__input ds-textField__input--textarea pf-resource-textarea"
                :class="{
                  'pf-validation-error-textField':!validAction(name),
                  'ds-textField__input--active' : isNotEmpty(value)
                }"
                :spellcheck="true"
                :placeholder="$t(localizedPlaceholder)"
                :maxlength="maxLength"
                @input="changeAction($event, name)"
                :value="value"
                @keydown.enter.prevent
      />
    <span
      class="ds-textField__placeholder pf-textarea-placeholder"
      :class="!validAction(name) ? 'pf-validation-error-textField-placeholder':''"
      v-if="isNotEmpty(value)">
        Description
      </span>
    <div class="ds-textField__message pf-validation-error-message" v-if="!validAction(name)">
      <i class="ds-icon--close-outline ds-m-r-xs"></i>
      {{ $t(localizedErrorMessage) }}
    </div>
    <div class="char-left ds-m-t-xxs" :class="validAction(name) ? 'position-no-error' : 'position-error'">
      {{ maxLength - (value === null ? 0 : value.length) }}
      {{ $tc('editor.global.characters.left', maxLength - (value === null ? 0 : value.length)) }}
    </div>
  </label>
</template>

<style scoped lang="less">
@import "../../assets/css/common.less";

.pf-textarea-placeholder {
  margin-left: 2px;
  color: @color-icon__hover-before;
}
.pf-resource-textarea {
  resize: none;
  height: 90px;
}

.pf-validation-error-textField:focus {
  border-color: @color-field-error;
}

.pf-validation-error-textField {
  border-color: @color-field-error;
}

.pf-validation-error-message {
  color: @color-field-error !important;
  font-size: 14px;
  .ds-icon--close-outline {
    line-height: 17px;
    padding-left: 1px;
  }
}

.ds-textField__input {
  opacity: 1 !important;
  position: static !important;
}

.char-left {
  text-align: end;
  color: @color-glance-consultants;
  line-height: 1.5;
  font-size: 12px;
  height: 18px;
  position: absolute;
  right: 0;
}

.position-no-error {
  top: 100%;
}

.position-error {
  bottom: 0px;
}
</style>
