<script>
/* eslint-disable vue/custom-event-name-casing */
export default {
  name: 'PEOfficeGoogleAddressComponent',
  data: () => ({
    selectedItem: null,
    selected: -1,
    visible: false,
    addressChanged: false,
    highlight: true,
    keyCodes: {
      END: 35,
      HOME: 36,
      SHIFT: 16,
      COMMAND: 91,
      OPTION: 18,
      CONTROL: 17,
      DOWN: 40,
      ENTER: 13,
      ESCAPE: 27,
      LEFT: 37,
      RIGHT: 39,
      TAB: 9,
      UP: 38,
    },
    addressTimer: null,
    isAddressValid: true,
    onFocus: false,
  }),
  props: {
    placeholderMessage: {
      type: String,
      default: '',
    },
    initialValue: {
      type: String,
      default: '',
    },
    countryCode: {
      type: String,
      default: '',
    },
    isValidData: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.initialValue) {
      this.query = this.initialValue;
      this.isAddressValid = true;
    }
  },
  computed: {
    searchResults: {
      get() {
        this.changeHighlight(true);
        return this.$store.state.locationAddressAutoComplete.searchResults;
      },
    },
    lastUsedQuery: {
      get() {
        return this.$store.state.locationAddressAutoComplete.lastUsedQuery;
      },
    },
    query: {
      get() {
        return this.$store.state.locationAddressAutoComplete.query;
      },
      set(value) {
        this.changeHighlight(false);
        this.addressChanged = true;
        this.changeIsValidData(false);
        this.$store.dispatch('locationAddressAutoComplete/setQueryValue', value);
      },
    },
  },
  watch: {
    initialValue(val) {
      this.query = val;
    },
    isValidData() {
      if (!this.visible) { this.changeValidationWithDelay(); }
    },
  },
  methods: {
    changeIsValidData(val) {
      this.$emit('validOfficeData', val);
    },
    changeHighlight(value) {
      this.highlight = value;
    },
    changeValidationWithDelay() {
      const valid = this.isValidData || (this.initialValue && this.initialValue === this.query);
      if (this.addressChanged) {
        if (this.addressTimer) {
          clearTimeout(this.addressTimer);
        }
        // if address is valid, then we remove error message immediately, without delay
        if (valid) {
          this.isAddressValid = true;
        } else {
          this.addressTimer = setTimeout(
            () => {
              this.isAddressValid = valid;
            },
            1000,
          );
        }
      }
    },
    highlightSearchTerm(item) {
      if (this.highlight) {
        const searchValueRegExp = new RegExp(
          this.query.replace(
            /[*%.\\[\](){}+?^$|]/g,
            '',
          ),
          'ig',
        );

        return item.address.replace(searchValueRegExp, (str) => `<b>${str}</b>`);
      }
      return item.address;
    },

    up() {
      if (this.selected === 0) {
        // if first element was selected, then remove selection and restore initial value
        this.selected--;
        this.$store.dispatch('locationAddressAutoComplete/setQueryValue', this.lastUsedQuery);
        return;
      }
      if (this.selected === -1) {
        // select last element
        this.selected = this.searchResults.length - 1;
      } else {
        this.selected--;
      }
      this.$store.dispatch('locationAddressAutoComplete/setQueryValue', this.searchResults[this.selected].address);
    },

    down() {
      if (this.selected === this.searchResults.length - 1) {
        this.selected = -1;
        this.$store.dispatch('locationAddressAutoComplete/setQueryValue', this.lastUsedQuery);
        return;
      }

      this.selected++;
      this.$store.dispatch('locationAddressAutoComplete/setQueryValue', this.searchResults[this.selected].address);
    },

    escape() {
      this.visible = false;
      this.selected = 0;
    },
    tab() {
      // if suggestion list is empty - then do nothing
      if (this.searchResults.length === 0) {
        return;
      }
      // otherwise we paste selected or the first element
      const index = Math.max(0, this.selected);
      this.$store.dispatch('locationAddressAutoComplete/keyPressed', this.searchResults[index].address);
    },
    selectItem() {
      if (!this.searchResults.length) {
        return;
      }
      this.changeIsValidData(true);
      this.selectedItem = this.searchResults[this.selected];
      this.$store.dispatch('locationAddressAutoComplete/setQueryValue', this.selectedItem.address);
      this.visible = false;
      this.selected = -1;
      this.finishEditing();
    },
    itemClicked(index) {
      this.selected = index;
      this.selectItem();
    },
    inputKeyup(event) {
      const isSpecialKey = Object.values(this.keyCodes)
        .findIndex((value) => value === event.keyCode) !== -1;
      if (isSpecialKey) {
        return;
      }

      this.visible = true;
      this.$store.dispatch('locationAddressAutoComplete/keyPressed', { value: event.target.value, country: this.countryCode });
    },
    focusLost() {
      this.selected = -1;
      this.visible = false;
      this.changeValidationWithDelay();
    },
    showSuggestions(event) {
      this.visible = true;
      this.selected = -1;
      if (this.searchResults.length === 0) {
        this.$store.dispatch('locationAddressAutoComplete/keyPressed', { value: event.target.value, country: this.countryCode });
      }
    },
    finishEditing() {
      this.$emit('officeFinishEditing', JSON.parse(JSON.stringify(this.selectedItem)), this.initialValue !== this.query);
    },
  },

};
</script>

<template>
  <div v-click-outside="focusLost" class="office-edit-address ds-m-l-s ds-m-r-s">
    <label
            class="ds-m-none ds-textField ds-textField--fixed location-name-input-field"
          >
    <input v-model="query"
           :class="[
             'office-address__address',
             !isAddressValid ? 'office-address-error-textField' : '']"
           :placeholder="onFocus ? '' : placeholderMessage"
           class="ds-textField__input"
           type="text"
           autocomplete="off"
           @click="showSuggestions"
           @keyup="inputKeyup"
           @keydown.esc="escape"
           @keydown.up.prevent="up"
           @keydown.tab.prevent="tab"
           @keydown.down.prevent="down"
           @keydown.enter="selectItem"
           @focus="onFocus=true"
           @blur="onFocus=false"
           id="office-address__text">
     <span v-if="onFocus || query" :class="!isAddressValid ? 'address-error-color' : ''"
          class="ds-textField__placeholder">
          {{ $t('address.edit.address') }}
     </span>
     <span class="ds-textField__message office-error-message" v-show="!isAddressValid ">
            <i class="ds-icon--close-outline ds-m-r-xs"></i>
            {{ this.query ? $t("address.edit.error") : $t("mandatory.field.error.message")}}
      </span>
    </label>
    <div v-if="searchResults.length > 0 && visible" class="pf-address-autocomplete-menu-wrapper">
      <div ref="optionsList" class="office-autocomplete-options">
        <ul class="pf-address-autocomplete-menu-wrapper_auto-suggest pf-address-search-auto-suggest_suggested">
          <li class="pf-address-autocomplete-menu__title">
          </li>
          <li v-for="(item, index) in searchResults" :key="item.placeId"
              :class="{'pf-address-autocomplete-active-background' : (selected === index)}"
              class="pf-address-autocomplete-menu-item"
              @click.prevent="itemClicked(index)">
            <span v-html="highlightSearchTerm(item)"></span>
          </li>
          <li class="pf-address-autocomplete-menu__separator"/>
        </ul>
      </div>
    </div>
  </div>

</template>

<style lang="less" scoped>
@import "styles/css/addressautocomplete";
.office-edit-address {
  position: relative;
  .office-error-message {
    color: @color-field-error;
  }
  .pf-address-autocomplete-menu-wrapper {
    position: absolute !important;
    top: 42px !important;
  }
  .office-address-error-textField {
    border-color: @color-field-error !important;
  }

  .address-error-color {
    color: @color-field-error !important;
  }

  .pf-address-autocomplete-active-background {
    background-color: #eaeaea;
    cursor: pointer;
  }

  .autocomplete-options {
    width: 387px;
  }

  .hq-address__address-edit {
    width: 387px;
    display: inline !important;
  }

  .hq-address__address {
    width: 319px;
    display: inline !important;
  }

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
</style>
