<script>
import { mapState } from 'vuex';
import PFPopUpComponent from '@/components/common/PFPopUpComponent';

export default {
  name: 'PEGuidanceTextComponent',
  data: () => ({
    isInformedChecked: false,
  }),
  components: {
    PFPopUpComponent,
  },
  computed: {
    ...mapState('userProfile', {
      isInformed: (state) => state.user.isInformed,
      isGuidanceCloseButtonClicked: (state) => state.isGuidanceCloseButtonClicked,
      showGuidancePopup: (state) => state.showGuidancePopup,
    }),
    showPopup() {
      return this.showGuidancePopup && !this.isInformed && !this.isGuidanceCloseButtonClicked;
    },
  },
  methods: {
    close() {
      if (this.isInformedChecked) {
        this.$store.dispatch('userProfile/sendDoNotShowGuidanceRequest', this.isInformedChecked);
      }
      this.$store.commit('userProfile/setIsGuidanceCloseButtonClicked', true);
    },
    changeIsInformedChecked(val) {
      this.isInformedChecked = val;
    },
  },
};
</script>

<template>
  <PFPopUpComponent
      class="pe-guidance-text-popup"
      :isShown="showPopup"
      :text="$t('guidance.text.body.a')"
      :textSecond="$t('guidance.text.body.b')"
      :submitText="$t('button.close')"
      :headerText="$t('guidance.text.header')"
      :submitAction="close"
      :submitButtonClass="'ds-button ds-button--primary'"
      :cancelAction="close"
      :headerIconClass="'ds-icon--info'"
      :checkBoxLabel="$t('do.not.show.again')"
      :checkBoxParentValue="isInformed"
      :checkBoxToggleAction="changeIsInformedChecked"/>
</template>
