<script>
/* eslint-disable no-undef */
/* eslint-disable no-prototype-builtins */
import { mapState } from 'vuex';
import PFContentAreaComponent from '@/components/common/PFContentAreaComponent';
import PFLoadingIndicatorComponent from '@/components/common/PFLoadingIndicatorComponent';

const IE_BROWSER_PAGE = 'browserNotSupportedPage';

export default {
  name: 'PFProfilePage',
  components: {
    PFContentAreaComponent,
    PFLoadingIndicatorComponent,
  },
  computed: {
    ...mapState('partnerProfile', {
      partnerProfileData: (state) => state.profileData,
      partnerProfileName: (state) => state.profileData.name,
      loading: (state) => state.loading,
    }),
    ...mapState('solutionL2Store', {
      buildInitSolutionFlag: (state) => state.buildInitSolutionFlag,
    }),
  },
  beforeCreate() {
    if (/Trident\/|MSIE /i.test(navigator.userAgent)) {
      this.$router.replace({
        name: IE_BROWSER_PAGE,
      }).catch(() => {});
    } else {
      this.$store.dispatch('partnerProfile/findByProfileId', {
        profileId: this.$route.params.profileId,
      });
      this.$store.dispatch('consultant/reset');
    }
  },
  mounted() {
    if (typeof QSI !== 'undefined') {
      QSI.API.unload();
      QSI.isDebug = false;
      QSI.API.load();
      QSI.API.run();
    }
  },
  watch: {
    $route(to, from) {
      if (to.name === 'profilePage' && from.name === 'profilePage') {
        this.$router.go();
      }
    },
    loading(val, oldVal) {
      if (oldVal && !val && !this.buildInitSolutionFlag) {
        this.$store.dispatch('partnerProfile/createL2sEntries');
      }
    },
    buildInitSolutionFlag(val, oldVal) {
      if (oldVal && !val && !this.loading) {
        this.$store.dispatch('partnerProfile/createL2sEntries');
      }
    },
  },
};
</script>

<template>
<main class="ds-flexRow ds-m-x-none profile-page-container">
  <PFLoadingIndicatorComponent :active="loading" :fullScreen="true"/>
  <section class="ds-flexCol-12 ds-p-x-none">
    <PFContentAreaComponent :partnerProfileData="partnerProfileData"
      @vue:updated="$eventBus.$emit('PFCustomEvent', {eventName: $options.name})"/>
  </section>
</main>
</template>

<style lang="less" scoped>
  .profile-page-container {
    width: 100%;
  }
</style>
