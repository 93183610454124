<script>
import { mapState } from 'vuex';
import PFForbiddenPage from '@/pages/PFForbiddenPage';

const urlLinks = require('@/plugins/linkUrl');

export default {
  components: {
    PFForbiddenPage,
  },
  name: 'PFNotFoundPage',
  props: {
    mode: {
      type: String,
      default: '404',
    },
    isFromPfEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    faqLink: urlLinks.FOOTER.FAQ_PE,
  }),
  methods: {
    sendEmail() {
      const subject = encodeURIComponent(this.$tc('help.mail.subject'));
      const emailBody = encodeURIComponent(this.$tc('help.mail.body'));
      const email = encodeURIComponent(this.$tc('help.mail.contact.email'));
      this.openInNewTab(`mailto:${email}?subject=${subject}&display-name=SAP&body=${emailBody}`);
    },
    sendEmailBackendError() {
      const subject = encodeURIComponent(this.$tc('help.mail.body.backend.error.subject'));
      const profileId = this.partnerProfile.profileId ? this.partnerProfile.profileId : 'N/A';
      const userId = this.profileAuthorizations?.userName ? this.profileAuthorizations.userName : 'N/A';
      const pageTitle = this.$route.name;
      const url = window.location.href;
      this.$sendEmail.constructor.sendEmailBackendError(subject, profileId, userId, pageTitle, url);
    },
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      partnerProfile: (state) => state.profileData,
    }),
    ...mapState('userProfile', {
      profileAuthorizations: (state) => state.user.profileAuthorizations,
    }),
  },
};
</script>

<template>
  <div class="not-found-page-container">
    <div v-if="mode === '404' || mode === '400'">
      <div class="not-found-image-container">
        <img alt="" class="not-found-img" src="../assets/281951_Binoculars_R_blue.png"/>
      </div>
    </div>
    <div v-if="mode === '401' || mode === '403'" class="not-found-image-container ds-m-b-m">
      <img alt="" class="not-authorized-img" src="../assets/287360_L2_team_R_blue.png"/>
    </div>
    <div v-if="mode === '500' || mode === '503' || mode === 'networkError'" class="system-error-img-container ds-m-b-m">
      <img alt="" class="system-error-img" src="../assets/282678_BrokenChain_R_orange.png"/>
    </div>
    <div v-if="mode === 'systemMaintenance'" class="not-found-image-container">
      <img alt="" class="system-maintenance-img" src="../assets/283287_Low-utilization_R_blue.png"/>
    </div>
    <div v-if="mode === 'browserNotSupported'" class="browser-not-supported-img-container ds-m-b-m">
      <img alt="" class="browser-not-supported" src="../assets/282748_SecurityIncidentLaptop_R_orange.png"/>
    </div>

    <div v-if="mode === '404' || mode === '400'" class="not-found-header ds-m-b-s"> {{ $t('not.found.header') }}</div>
    <div v-if="mode === '401'" class="not-found-header ds-m-b-s"> {{ $t('not.authorized.header') }}</div>
    <div v-if="mode === '403'">
      <PFForbiddenPage
        :isFromPfEditor="isFromPfEditor"
      />
    </div>
    <div v-if="mode === '500'|| mode === '503'" class="not-found-header ds-m-b-s"> {{ $t('system.error.header') }}</div>
    <div v-if="mode === 'browserNotSupported'"
      class="not-found-header ds-m-b-s"> {{ $t('browser.not.supported.header') }}</div>
    <div v-if="mode === 'networkError'" class="not-found-header ds-m-b-s"> {{ $t('system.network.error.header') }}</div>
    <div v-if="mode === 'systemMaintenance'" class="not-found-header ds-m-b-s">
      {{ $t('system.maintenance.error.header') }}</div>
    <div v-if="mode === '404' || mode === '400'" class="not-found-text ds-m-b-s">{{ $t('not.found.text') }}</div>
    <div v-if="mode === '401'" class="not-found-text ds-m-b-s" v-html="$t('not.authorized.text')"></div>
    <div v-if="mode === '500'" class="not-found-text ds-m-b-s">
      <span>{{ $t('editor.footer.message.contact.admin') }}</span>
      <span>{{ $t('editor.footer.message.contact.please') }}</span>
      <a href=""  @click.prevent="sendEmailBackendError()">
                {{ $t('editor.footer.message.contact.us') }}
      </a>
      <span>{{ $t('editor.footer.message.contact.b') }}</span>
    </div>
    <div v-if="mode === '503'" class="not-found-text ds-m-b-s">
      {{$t('system.error.text')}}
      <a href=""  @click.prevent="sendEmailBackendError()">
                {{ $t('editor.footer.message.contact.us') }}
      </a>
      <span>{{ $t('editor.footer.message.contact.b') }}</span>
    </div>
    <div v-if="mode === 'networkError'" class="not-found-text ds-m-b-s"
      v-html="$t('editor.footer.message.network.error')"></div>
     <div v-if="mode === 'systemMaintenance'" class="not-found-text ds-m-b-s"
      v-html="$t('system.maintenance.error.text')"></div>
    <div v-if="mode === 'browserNotSupported'"
      class="not-found-text ds-m-b-s" v-html="$t('browser.not.supported.text')"></div>
    <div class="not-found-link-container" v-if="mode !== 'browserNotSupported' && mode !== 'networkError'">
      <div v-if="mode !== '500' && mode !== '503'" class="ds-m-b-s link">
        <a
          href="/">
          {{ $t('title.homepage') }}
        </a>
      </div>
      <div class="ds-m-b-s link" v-if="mode !== '500'  && mode !== '503'">
        <a
          href=""
          @click.prevent="sendEmail()">
          {{ $t('title.contact.us') }}
        </a>
      </div>
      <div v-if="mode !== '500' && mode !== '503'" class="ds-m-b-s link">
        <a
          href=""
          @click.prevent="openInNewTab(faqLink)">
          {{ $t('title.help') }}
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../assets/css/common.less";

.not-found-page-container {
  width: 100%;
  height: 70vh;
  min-height: 570px !important;
  display: -moz-box;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  -moz-box-align: center;
  -webkit-box-align: center;
  -moz-box-pack: start;
  -webkit-box-pack: start;
  text-align: center;
}

.not-found-image-container {
  margin-top: 45px;
}

.not-found-img {
  width: 223px;
  height: 223px;
}
.system-maintenance-img {
  width: 244px;
  height: 244px;
}
.browser-not-supported-img-container {
  margin-top: 44px;
}
.browser-not-supported {
  width: 223px;
  height: 223px;
}

.not-authorized-image-container {
  margin-top: 64px;
}

.not-authorized-img {
  width: 185px;
  height: 185px;
}

.system-error-img-container {
  margin-top: 84px;
}

.system-error-img {
  width: 145px;
  height: 145px;
}

.not-found-header {
  &:extend(.font-expanded);
  font-size: 38px;
  line-height: 46px;
  color: @color-black;
}

.not-found-text {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
  color: @color-gray-1;
  text-align: center;
}

.not-found-link-container {
  text-align: center;
  line-height: 24px;
  .link {
    &:extend(.font-normal);
  }
}

</style>
