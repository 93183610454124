import { createApp } from 'vue';
import vClickOutside from 'click-outside-vue3';
import Vue3Resize from 'vue3-resize';
import Vue3Sanitize from 'vue-3-sanitize';
import axios from './plugins/axios';
import FileValidator from './plugins/validator';
import SendEmail from './plugins/sendEmail';
import store from './store/index';
import Router from './router/routes';
import i18n from './localization/i18n';
import PartnerFinderApp from './app/PartnerFinderApp';
import EventBus from './plugins/eventBus';
import './assets/ds-filter.css';
import './assets/ds-upload-file.css';
import './assets/ds-tooltip.less';
import './assets/css/fonts.css';
import './assets/css/smallScreen.less';
import 'vue3-resize/dist/vue3-resize.css';

/* App instance */

const app = createApp({
  ...PartnerFinderApp,
});
app.use(i18n);
app.use(store);
app.use(Router);
app.use(Vue3Resize);

const overridenOptions = {
  allowedTags: ['br'],
};
app.use(Vue3Sanitize, overridenOptions);

/* Setup our Vue instance to use http library, i18n and routing */
app.config.warnHandler = (msg, instance, trace) => ![
  'Missing required prop:',
].some((warning) => msg.includes(warning)) && console.warn('[Vue warn]: '.concat(msg).concat(trace));
app.config.globalProperties.$http = axios;
app.config.globalProperties.$fileValidator = new FileValidator(app.config.globalProperties.$http);
app.config.globalProperties.$sendEmail = new SendEmail();
app.config.globalProperties.$showConsultantEnv = 'none';
app.config.globalProperties.$hideCompetencyEnv = false;
app.config.globalProperties.$hideQPPSEnv = false;
app.config.globalProperties.$showVPUCFeature = true;
app.config.globalProperties.$hideIECompetencyEnv = false;

// Vue.config.productionTip = false; //removed in Vue3
// app.config.globalProperties.$eventBus = new Vue(); // cannot use in Vue3, need to change to use mitt package
app.config.globalProperties.$eventBus = EventBus;
app.directive('clickOutside', vClickOutside.directive);

store.$app = app;
app.mount('#app');
