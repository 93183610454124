<script>
import { mapState } from 'vuex';
import PFCardItemComponent from './PFCardItemComponent';
import PFPaginationComponent from '../partnerNavigatorPage/PFPaginationComponent';

export default {
  name: 'PFCardItemContainer',
  components: {
    PFCardItemComponent,
    PFPaginationComponent,
  },
  computed: {
    ...mapState('profileSearch', {
      data: (state) => state.searchResults,
      loading: (state) => state.loading,
    }),
  },
};
</script>

<template>
  <div class="ds-card-container">
    <div class="ds-card ds-grid">
      <div class="ds-flexRow search-result-row">
        <div v-for="(res,idx) in data" class="ds-card__tile ds-flexCol-xs ds-flexCol-sm-6 ds-flexCol-lg-4 ds-p-none" :key="res.profileId">
          <PFCardItemComponent :data="res" :idx="idx"/>
        </div>
        <div v-if="data.length === 0 && !loading" class="search-page-no-result-img">
          <div class="not-found-image-container">
            <img alt="" class="not-found-img" src="../../assets/281951_Binoculars_R_blue.png"/>
          </div>
          <div class="no-search-result-title">{{$t('no.search.result.title')}}</div>
          <div class="no-search-result-text">{{$t('no.search.result.text')}}</div>
        </div>
      </div>
    </div>
    <div>
      <PFPaginationComponent />
    </div>
  </div>
</template>

<style lang="less">
.search-page-no-result-img{
  width: 100%;
  text-align: center;
.not-found-image-container {
  margin-top: 45px;
  margin-left: auto;
  margin-right: auto;
}

.not-found-img {
  width: 223px;
  height: 223px;
}
.no-search-result-title {
  &:extend(.font-expanded);
  font-size: 38px;
  line-height: 46px;
  color: #000000;
}
.no-search-result-text {
    &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
  color: #3C3C3C;
}
}
.ds-card-container {
  margin: 0 70px 84px 70px;
  .search-result-row {
    width: 100%;
  }

  .ds-card {
    max-width: 1280px;
    padding-right: 0;
    justify-content: center;
  }

  .ds-card {
    margin-bottom: 52px;

    .ds-card__tile {
      max-width: 100%;
      padding-bottom: 0 !important;
    }
  }
}
</style>
