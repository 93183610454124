<script>
import PFValidatedInputComponent from '@/components/common/PFValidatedInputComponent';
import PFValidatedTextAreaComponent from '@/components/common/PFValidatedTextAreaComponent';
import PFValidatedComboBoxComponent from '@/components/common/PFValidatedComboBoxComponent';

export default {
  name: 'PEProfileResourceComponent',
  components: {
    PFValidatedComboBoxComponent,
    PFValidatedTextAreaComponent,
    PFValidatedInputComponent,
  },
  props: {
    isShown: { type: Boolean, default: false },
    cancelAction: { type: Function, default: () => false },
    saveAction: { type: Function, default: () => false },
    countryProfileId: { type: Number, default: 0 },
    resource: { type: Object },
  },
  data: () => ({
    resourceModel: {
      id: null,
      link: null,
      name: null,
      description: null,
      type: null,
    },
    resourceTypes: {
      SUCCESS_STORY: 'Success Story',
      OWN_IP: 'Own IP',
      SAP_REFERENCE: 'SAP Reference',
      WHITE_PAPER: 'White Paper',
      BROCHURE: 'Brochure',
      FACT_SHEET: 'Factsheet',
      BLOG: 'Blog',
      EVENT: 'Event',
      OTHER: 'Other',
    },
  }),
  methods: {
    validateUrl(url) {
      if ((typeof url === 'string' && url.length === 0) || url === null) {
        return false;
      }
      const pattern = new RegExp(
        '^(https?:\\/\\/)?'
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
        + '((\\d{1,3}\\.){3}\\d{1,3}))'
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~&+@]*)*'
        + '(\\?[;&a-z\\d%_.~+=-]*)?'
        + '(\\#[-a-z\\d_]*)?$',
        'i',
      );
      return pattern.test(url);
    },
    isValid(field) {
      if (this.resourceModel[field] === null) {
        return true;
      }

      if (field === 'link') {
        return this.validateUrl(this.resourceModel.link);
      }
      return this.resourceModel[field] && this.resourceModel[field].length > 0;
    },
    onChange(event, field) {
      this.resourceModel[field] = event.target.value;
    },
    onTypeChange(type) {
      this.resourceModel.type = type;
    },
    hasValue(field) {
      return (
        this.resourceModel[field] !== null
        && this.resourceModel[field].length > 0
      );
    },
    clearResourceModel() {
      this.resourceModel = {
        id: null,
        link: null,
        name: null,
        description: null,
        type: null,
      };
    },
    validModel(prefill) {
      if (prefill && this.resourceModel.link === null) {
        this.resourceModel.link = '';
      }
      if (prefill && this.resourceModel.name === null) {
        this.resourceModel.name = '';
      }
      if (prefill && this.resourceModel.description === null) {
        this.resourceModel.description = '';
      }
      if (prefill && this.resourceModel.type === null) {
        this.resourceModel.type = '';
      }
      return (
        this.isValid('link')
        && this.isValid('name')
        && this.isValid('description')
        && this.isValid('type')
      );
    },
    saveResource() {
      if (this.validModel(true)) {
        this.saveAction(
          {
            id: this.resourceModel.id,
            link: this.resourceModel.link,
            name: this.resourceModel.name,
            description: this.resourceModel.description,
            type: this.resourceModel.type,
          },
          this.resource,
        );
        this.clearResourceModel();
        this.cancelAction();
      }
    },
    closeModal() {
      this.clearResourceModel();
      this.cancelAction();
    },
  },
  watch: {
    isShown() {
      if (this.isShown) {
        window.setTimeout(() => this.$refs.resourceModal.focus(), 0);
      }
      if (this.isShown && this.resource) {
        this.resourceModel.id = this.resource.id;
        this.resourceModel.link = this.resource.link;
        this.resourceModel.name = this.resource.name;
        this.resourceModel.description = this.resource.description;
        this.resourceModel.type = this.resource.type;
      }
    },
  },
};
</script>
<template>
  <div
    class="ds-modal ds-modal--active profile-resource-modal"
    v-if="isShown"
    tabindex="0"
    ref="resourceModal"
    @keyup.esc="closeModal"
  >
  <div class="modal-container">
    <div class="modal-container-inner-resource">
    <div id="resourceModal" class="ds-modal__box">
      <div class="ds-modal__box__header ds-p-y-none">
        <div class="ds-heading--m da-modal__box_header__heading">
          {{ resource != null ? "Edit" : "Add" }} Resource
        </div>
      </div>
      <div class="ds-modal__box__content">
        <PFValidatedInputComponent
          class="ds-m-none ds-m-t-m"
          :localized-placeholder="'editor.resource.link.placeholder.text'"
          :localized-error-message="'editor.resource.url.error.message'"
          :value="resourceModel.link"
          :name="'link'"
          :valid-action="isValid"
          :change-action="onChange"
          :max-length="1024"
        />
        <PFValidatedInputComponent
          class="pf-resource-name ds-m-none ds-m-t-s"
          :localized-placeholder="'editor.resource.name.placeholder.text'"
          :localized-error-message="'editor.resource.field.error.message'"
          :value="resourceModel.name"
          :name="'name'"
          :valid-action="isValid"
          :change-action="onChange"
          :max-length="50"
        />
        <PFValidatedTextAreaComponent
          class="ds-m-none ds-m-t-s"
          :localized-error-message="'editor.resource.field.error.message'"
          :localized-placeholder="'editor.resource.description.placeholder.text'"
          :value="resourceModel.description"
          :name="'description'"
          :valid-action="isValid"
          :change-action="onChange"
          :max-length="155"
        />

        <PFValidatedComboBoxComponent
          class="pf-resource-type"
          :localized-error-message="'editor.resource.field.error.message'"
          :localized-placeholder="'editor.resource.type.placeholder.text'"
          :value="resourceModel.type"
          :options="resourceTypes"
          :name="'type'"
          :valid-action="isValid"
          :change-action="onTypeChange"
        />
      </div>
      <div class="ds-modal__box__action-bar ds-p-y-none">
        <button
          class="ds-button ds-button--primary ds-m-none"
          :class="{ 'ds-button--disabled': !validModel(false) }"
          @click="saveResource"
        >
          Done
        </button>
        <button
          class="ds-button ds-button--secondary ds-m-none ds-m-l-m"
          @click="closeModal"
        >
          Cancel
        </button>
      </div>
    </div>
    </div>
    </div>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.pf-resource-name {
  font-size: 21px;
}
.modal-container-inner-resource {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 208px);
}

.pf-resource-type {
  margin-top: 12px;
  width: 306px;
  left: 0;
  margin-bottom: 19px;
}

.profile-resource-modal {
  z-index: 105;
}

.profile-resource-modal .ds-modal__box__content {
  padding: 0 !important;
  overflow: initial;
  text-align: initial;
  overflow-y: inherit !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ds-modal__box__action-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 19px;

  .ds-button {
    width: auto;
  }
}
.ds-textField__placeholder {
  height: 20px;
}

#resourceModal {
  width: 632px;
  height: auto;
  min-height: unset;
  margin: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}

.da-modal__box_header__heading {
  &:extend(.font-normal);
}

.ds-modal__box__header__icon {
  width: 23px;
  height: 23px;
  float: right;
  margin-right: 33px;
  color: @color-glance-consultants !important;
  position: absolute;
  top: 20px;
  right: 0;
}

.ds-modal__box__header__icon::before {
  font-size: 23px;
}
</style>
