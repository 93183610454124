<script>
const MAX_LENGTH_BRAND_NAME = 90;
const MAX_LENGTH_SHORT_DESCRIPTION = 300;
export default {
  name: 'PEBrandNameAddComponent',
  data: () => ({
    emptyDescription: false,
    errorBrandName: false,
    emptyBrandName: false,
    updatedBrandNameIdx: '',
    updatedBrandName: '',
    updatedShortDescription: '',
    addMode: true,
    brandNameTimer: null,
    shortDescriptionTimer: null,
    brandNameMaxLength: MAX_LENGTH_BRAND_NAME,
    shortDescriptionMaxLength: MAX_LENGTH_SHORT_DESCRIPTION,
    tooltipOpen: false,
    brandNameCheckBox: false,
    modalWidth: 625,
    maxBrandHeaderChar: 50,
  }),
  props: {
    brandNameProp: { type: Object, default: () => ({ idx: null, brandName: {} }) },
    deleteAction: { type: Function, default: () => false },
    addAction: { type: Function, default: () => false },
    submitAction: { type: Function, default: () => false },
    cancelAction: { type: Function, default: () => false },
  },
  methods: {
    updateBrandName(event) {
      this.updatedBrandName = event.target.value;
      if (this.brandNameTimer) {
        clearTimeout(this.brandNameTimer);
      }
      this.brandNameTimer = setTimeout(() => this.checkUpdatedBrandName(), 2000);
    },
    updateShortDescription(event) {
      this.updatedShortDescription = event.target.value;
      if (this.shortDescriptionTimer) {
        clearTimeout(this.shortDescriptionTimer);
      }
      this.shortDescriptionTimer = setTimeout(() => this.checkUpdatedShortDescription(), 2000);
    },
    submitUpdatedBrandName() {
      this.checkUpdatedBrandName();
      this.checkUpdatedShortDescription();
      if (!this.disableSave) {
        const updatedData = this.buildUpdatedData();
        this.addMode ? this.addAction(updatedData)
          : this.submitAction(updatedData, this.updatedBrandNameIdx);
      }
    },
    checkUpdatedBrandName() {
      this.emptyBrandName = this.updatedBrandName.length <= 0;
      const re = /^[A-Za-z0-9]/;
      this.errorBrandName = this.updatedBrandName.length > 0 && !re.test(this.updatedBrandName);
    },
    checkUpdatedShortDescription() {
      this.emptyDescription = this.updatedShortDescription.length <= 0;
    },
    buildUpdatedData() {
      const updatedData = {
        brandName: this.updatedBrandName,
        shortDescription: this.updatedShortDescription,
        isFavoriteName: this.brandNameCheckBox,
      };
      if (this.brandNameProp.brandName && this.brandNameProp.brandName.id) {
        updatedData.id = this.brandNameProp.brandName.id;
      }
      return updatedData;
    },
    onResize() {
      this.modalWidth = this.$refs.modalBrandHeaderTitle.clientWidth;
      this.maxBrandHeaderChar = Math.round(this.modalWidth / 19);
    },
    getTruncatedText(title) {
      let maxChar = this.maxBrandHeaderChar;
      maxChar = maxChar < 5 ? 5 : maxChar;
      if (title.length <= maxChar) {
        return title;
      }
      return `${title.substring(0, maxChar - 2)}...`;
    },
  },
  computed: {
    disableSave() {
      return this.emptyBrandName || this.errorBrandName || this.emptyDescription;
    },
    shortDescriptionLeft() {
      return this.shortDescriptionMaxLength - this.updatedShortDescription.length;
    },
  },
  mounted() {
    this.updatedBrandNameIdx = this.brandNameProp.idx;
    this.updatedBrandName = this.brandNameProp.brandName ? this.brandNameProp.brandName.brandName : '';
    this.updatedShortDescription = this.brandNameProp.brandName ? this.brandNameProp.brandName.shortDescription : '';
    this.addMode = this.updatedBrandNameIdx === null;
    this.brandNameCheckBox = this.brandNameProp.brandName ? this.brandNameProp.brandName.isFavoriteName : false;
  },
};
</script>

<template>
  <div class="brand-name-edit-container ds-m-t-l">
    <div class="ds-modal ds-modal--active"
         ref="brandNameEditModal" tabindex="0" @keyup.esc="cancelAction">
      <div class="modal-container">
      <div class="modal-container-inner-upload">
      <div class="ds-modal__box brand-name-edit-modal">
        <div class="ds-modal__box__header ds-p-y-none">
          <div class="ds-modal__box__header__heading" ref="modalBrandHeaderTitle">
            <resize-observer @notify="this.onResize"></resize-observer>
            {{
              addMode
                ? $t("editor.brand.add.name")
                : ($t("button.edit") + ' "' + getTruncatedText(brandNameProp.brandName.brandName) + '"')
            }}
          </div>
        </div>
        <div class="ds-modal__box__content">
          <label
            class="ds-m-none ds-textField ds-textField--fixed brand-name-input-field"
            :class="errorBrandName || emptyBrandName ? 'ds-textField--error' : ''"
          >
            <input
              class="ds-textField__input ds-m-none ds-m-t-s"
              type="text"
              :placeholder="$t('editor.brand.name')"
              :value="updatedBrandName"
              @input="updateBrandName"
              @blur="checkUpdatedBrandName"
              :maxlength="brandNameMaxLength"
              id="brand-name-edit-text"
            />
            <span class="ds-textField__placeholder" v-if="updatedBrandName">
              {{ $t("editor.brand.name") }}
            </span>
            <span class="ds-textField__message" v-show="errorBrandName || emptyBrandName">
              <i class="ds-icon--close-outline ds-m-r-xs"></i>
              {{
                emptyBrandName
                  ? $t("mandatory.field.error.message")
                  : $t("editor.brand.name.edit.error")
              }}
            </span>
          </label>
          <label
            class="ds-textField ds-textField--fixed ds-m-none ds-m-t-s short-description-input-field"
            :class="emptyDescription ? 'ds-textField--error' : ''">
            <textarea
              class="ds-textField__input ds-textField__input--textarea ds-m-none"
              type="text"
              :spellcheck="true"
              :placeholder="$t('editor.brand.name.short.description')"
              :maxlength="shortDescriptionMaxLength"
              :value="updatedShortDescription"
              @blur="checkUpdatedShortDescription"
              @input="updateShortDescription"/>
            <span class="ds-textField__placeholder" v-if="updatedShortDescription">
              {{ $t("editor.brand.name.short.description") }}
            </span>
            <span class="ds-textField__message" v-show="emptyDescription">
              <i class="ds-icon--close-outline ds-m-r-xs"></i>
              {{ $t("mandatory.field.error.message") }}
            </span>
          </label>
          <div class="char-left ds-m-y-xxs">
            {{ shortDescriptionLeft }} {{ $tc("editor.global.characters.left", shortDescriptionLeft) }}
          </div>
          <div class="brand-name-check-box-container ds-m-b-s">
            <label class="ds-checkbox"
                for="set-brand-name-checkbox">
                <input
                  class="ds-checkbox__control"
                  type="checkbox"
                  id="set-brand-name-checkbox"
                  v-model="brandNameCheckBox"
                />
                <span class="ds-checkbox__box">
                  <i class="ds-icon ds-icon--checked"></i>
                </span>
                <span class="ds-checkbox__label brand-name-hq-checkbox-label">
                  {{ $t('editor.brand.favorite.name') }}
                </span>
            </label>
            <div class="hq-help-container">
              <i class="ds-icon ds-icon--info brand-name-select-info-icon ds-m-l-xxs"
              :class="tooltipOpen ? 'brand-name-select-info-icon-hover' : ''"
              @click="tooltipOpen = true"
              v-click-outside="()=>{tooltipOpen = false}"></i>
              <div class="ds-tooltip-icon brand-name-check-tooltip-icon">
                <div :class="[tooltipOpen ? 'display-block' : 'display-none',
                'tooltiptext','ds-tooltip-icon__tooltip--HQ']">
                    <span>{{$t('editor.brand.favorite.name.help')}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="edit-brand-name-text ds-m-b-xs">
          {{ $t("editor.brand.name.edit.text") }}
        </div>
        </div>
        <div class="ds-modal__box__action-bar ds-p-y-none">
          <span v-if="!addMode"
            class="brand-name-edit-button-delete ds-m-r-s"
            id="brand-name-edit-button__delete"
            @click="deleteAction">
              {{ $t("button.delete") }}</span>
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary save-button ds-m-x-none"
            type="button"
            @click="submitUpdatedBrandName"
            :disabled="disableSave"
            id="brand-name-edit-button__done"
          >
            {{ addMode ? $t("button.add") : $t("button.save")}}
          </button>
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--secondary ds-m-l-m"
            type="button"
            @click="cancelAction"
            id="brand-name-edit-button__cancel"
          >
            {{ $t("button.cancel") }}
          </button>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.brand-name-edit-container {
  .ds-modal__box__header__heading {
    &:extend(.font-expanded);
    font-size: 27px;
    line-height: 35px;
    cursor: default;
  }

  .ds-textField__message {
    &:extend(.font-normal);
    font-size: 14px;
    color: @color-field-error;
    .ds-icon--close-outline {
      line-height: 17px;
    }
  }

  .ds-modal--active {
    z-index: 200;
  }
  .brand-name-edit-modal {
    max-width: 564px;
    height: fit-content;
    min-height: 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  }

  .edit-brand-name-text {
    &:extend(.font-normal);
    font-size: 14px;
    line-height: 21px;
    color: @color-gray-3;
    cursor: default;
  }

  .brand-name-input-field {
    width: 100%;
    .ds-textField__input {
      max-width: none;
    }
  }
  .short-description-input-field {
    width: 100%;
    height: 84px;
    .ds-textField__input--textarea {
      height: 100%;
      max-width: none;
    }
  }
  .ds-button {
    &:extend(.font-normal);
    font-size: 16px;
  }
  .save-button {
    width: fit-content;
  }
  .modal-container-inner-upload {
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    top: calc(50% - 145px);
  }
  .brand-name-edit-button-delete{
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 40px;
    color: @color-red-delete-button;
    cursor: pointer;
  }
  .brand-name-edit-button-delete:hover{
    color: @color-editor-footer-error;
  }
  .char-left {
    &:extend(.font-normal);
    height: 18px;
    font-size: 12px;
    line-height: 1.5;
    text-align: right;
    color: @color-glance-consultants;
  }
  .brand-name-check-box-container {
    display: flex;
  }
  .brand-name-hq-checkbox-label {
    line-height: 24px;
  }
  .brand-name-select-info-icon {
    line-height: 24px;
    display: inline-block;
    cursor: pointer;
    color: @color-gray-4;
  }
  .brand-name-select-info-icon::before {
    font-size: 18px;
  }
  .brand-name-select-info-icon-hover {
    color: @color-gray-2;
  }
  .brand-name-check-tooltip-icon {
    margin-top: 6px;
    margin-left: 4px;
  }
  #set-brand-name-checkbox {
    width: 0px;
  }
}
</style>
