<script>
import { mapState } from 'vuex';
import PEFileUploadBoxComponent from './PEFileUploadBoxComponent';

export default {
  name: 'PEUploadFilePopupComponent',
  props: {
    closeAction: { type: Function, default: () => true },
    uploadAndValidateFunction: { type: Function, default: () => true },
    pushToStoreAction: { type: String, default: '' },
    profileId: { type: String, default: null },
    imgSrc: { type: String, default: null },
    justUploadLogo: { type: Boolean, default: false },
    toggleJustUploadLogo: { type: Function },
  },
  components: {
    PEFileUploadBoxComponent,
  },
  data: () => ({
    iconUrl: null,
    fileName: '',
    errorMessages: [],
    loading: false,
  }),
  computed: {
    ...mapState('userProfile', {
      sessionExpired: (state) => (PROCESS_NODE_ENV !== 'local'
        ? state.sessionExpired : false),
    }),
  },
  methods: {
    handleFilesUpload(file) {
      if (this.sessionExpired) {
        return;
      }
      this.fileName = file.name;
      this.errorMessages = [];
      this.loading = true;
      this.uploadAndValidateFunction(file, this.profileId)
        .then((data) => {
          this.loading = false;
          this.iconUrl = data;
          this.toggleJustUploadLogo();
          this.setFocus();
        })
        .catch((err) => {
          this.loading = false;
          this.iconUrl = null;
          this.errorMessages = this.$fileValidator.constructor.handleResponseError(err);
        });
    },
    isNotValidFile() {
      return !this.iconUrl || this.errorMessages.length > 0;
    },
    isValidFile() {
      return !this.isNotValidFile();
    },
    pushToStor() {
      this.$store.dispatch(this.pushToStoreAction, this.iconUrl);
      this.closeAction();
    },
    setFocus() {
      window.setTimeout(() => this.$refs.uploadFileModal.focus(), 0);
    },
  },
  mounted() {
    this.iconUrl = this.imgSrc;
    this.setFocus();
  },
};
</script>

<template>
  <div class="ds-modal pf-popup-file-upload-model ds-modal--active"
       ref="uploadFileModal" tabindex="0" @keyup.esc="closeAction">
    <div class="modal-container">
    <div class="modal-container-inner-upload">
    <div class="ds-modal__box pf-popup-file-upload-model_box">
      <div class="ds-modal__box__header">
        <div class="ds-modal__box__header__heading ds-p-b-s">{{$t('editor.logo.upload.popup.form.title')}}</div>
      </div>
      <PEFileUploadBoxComponent
        :iconUrl="iconUrl"
        :loading="loading"
        :fileName="fileName.length === 0 ? iconUrl : fileName"
        :errorMessages="errorMessages"
        :pushToStor="pushToStor"
        :handleFilesUpload="handleFilesUpload"
        :isNotValidFile="isNotValidFile"
        :isValidFile="isValidFile"
        :justUploadLogo="justUploadLogo"
      />
      <div class="ds-modal__box__action-bar">
        <button
          @click.stop
          class="ds-modal__box__action-bar__action ds-button ds-button--primary ds-m-x-m"
          type="button"
          :disabled="!isValidFile() && !loading || iconUrl === imgSrc"
          @click="pushToStor"
        >
          Save
        </button>
        <button
          @click.stop
          class="ds-modal__box__action-bar__action ds-button ds-button--secondary ds-m-none"
          type="button"
          @click="closeAction"
        >
          {{ $t('helptext.partner.editor.discard.agreement.cancelButton') }}
        </button>
      </div>
    </div>
    </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.modal-container-inner-upload {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 208px);
}
.pf-popup-file-upload-model {
  z-index: 101;
}

.pf-popup-file-upload-model_box {
  width: 632px;
  height: 385px;
  margin: auto;
  min-height: auto;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}

.pf-popup-file-upload-button {
  border: solid 1px #007db8;
  background-color: rgba(255, 255, 255, 0);
  margin: 0;
  color: #007db8;
}

.ds-modal__box__header__heading {
  &:extend(.font-expanded);
  color: #000000;
}
</style>
