<script>
import { mapState } from 'vuex';
import PFCompetencyModalComponent from './PFCompetencyModalComponent';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFIECompetencyModalComponent',
  data: () => ({
    learnMoreLink: urlLinks.HELP_TEXT_LEARN_MORE.COMPETENCIES,
    showProfileCompetencyModal: { status: false, current: 0 },
    styles: { boxShadow: '', backgroundColor: '' },
  }),
  props: {
    toggleModal: { type: Function, default: () => {} },
    ieCompetency: { type: Object, default: () => ({}) },
  },
  watch: {
    'showProfileCompetencyModal.status': function (newBoolVal) {
      if (newBoolVal) {
        this.$refs.pfCompetencyModal.style.backgroundColor = 'rgba(0, 0, 0, 0)';
        this.$refs.competencyModal.style.boxShadow = '0 0 20px 0 rgba(0, 0, 0, 0)';
      } else {
        this.$refs.pfCompetencyModal.style.backgroundColor = this.styles.backgroundColor;
        this.$refs.competencyModal.style.boxShadow = this.styles.boxShadow;
      }
    },

  },
  computed: {
    ...mapState('partnerProfile', {
      solutionTransformed: (state) => state.solutionTransformed,
    }),
    solutionsL1() {
      return this.ieCompetency.solutionsL1
        .map((s) => this.solutionTransformed.find((st) => st.solutionL1Key === s.id))
        .sort((a, b) => a.sortOrder - b.sortOrder);
    },
  },
  methods: {
    toggleProfileCompetencyModal(val, obj = -1) {
      if (obj !== -1) {
        this.showProfileCompetencyModal.current = obj;
      }
      this.showProfileCompetencyModal.status = val;
      if (val) {
        this.emitAdobeEvent(this.solutionsL1[obj].solutionL1Name);
      }
    },
    emitAdobeEvent(name) {
      if (this.$route.name === 'profilePage') {
        this.$eventBus.$emit('PFAACustomEvent',
          { eventName: 'ClickModal', custom2: `IECompetency_Solution_${name}` });
      }
    },
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
  },
  components: { PFCompetencyModalComponent },
  mounted() {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
    this.styles.boxShadow = this.$refs.competencyModal.style.boxShadow;
    this.styles.backgroundColor = this.$refs.pfCompetencyModal.style.backgroundColor;
  },
  beforeUnmount() {
    document.body.style.paddingRight = '0px';
    document.body.style.overflow = 'scroll';
  },
};
</script>

<template>
  <div class="profile-competency-modal-container">
    <div class="ds-modal ds-modal--active ds-modal--scrollable"
         ref="pfCompetencyModal" tabindex="0" @keyup.esc="toggleModal(false)">
      <div class="modal-container">
      <div class="modal-container-inner-upload" id="profile-competency-modal">
      <div class="ds-modal__box profile-competency-modal" ref="competencyModal">
        <div class="ds-modal__box__header pf-ie-modal-header" ref="modalProfileCompetencyHeader">
          <span class="ds-modal__box__header__heading competency-modal-header">
            {{ ieCompetency.name }}</span>
            <i
              class="ds-icon ds-icon--close modal-close-icon modal-icon ds-icon-profile-close"
              @click.prevent="toggleModal(false)"
            ></i>
        </div>
        <div class="ds-modal__box__content ds-m-none ds-m-l-l ds-m-r-s ds-m-t-s ds-m-b-s"
        ref="modalProfileCompetencyContent">

          <div class="profile-competency-text profile-competency-table">
            <table class="ds-table ds-m-none">
              <tbody>
                <tr>
                  <td class="top-cell" colspan="2">
                    <div class="profile-competency-level-description">
                      {{ `${this.$t('ie.competency.modal.description')}`
                      + ` "${ieCompetency.name}", `
                      + ieCompetency.description.charAt(0).toLowerCase() + ieCompetency.description.slice(1) }}
                      <a class="cursor-pointer"
                        @click.prevent="openInNewTab(learnMoreLink)">
                        {{ $t("learn.more") }}
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="profile-competency-level-description mid-cell-bot">{{ $t('solution.competencies') }}</td>
                  <td class="col-data mid-cell-bot">
                     <div class="solution-item" v-for="(solution, idx) in solutionsL1"
                      :key="solution.solutionL1Key + '-' + idx">
                      <div class="solution-competency-item inline-word-text">
                       &nbsp; &#8226; &nbsp;
                        {{ solution.solutionL1Name }}&nbsp;
                      </div>
                      <PFCompetencyModalComponent
                        v-if="this.showProfileCompetencyModal.status && this.showProfileCompetencyModal.current === idx"
                        :hasCompetency="solution.hasCompetency"
                        :toggleModal="toggleProfileCompetencyModal"
                        :modalHeader="solution.solutionL1Name"
                        :competencyLevel="solution.hasCompetency ? solution.level : ''"
                        :L2s="solution.L2Solution"
                        :specializations="solution.specializations"
                        :countries="solution.hasCompetency ? solution.countries : {}"
                        :industries="solution.hasCompetency ? solution.industries : {}"
                        :isOpenedFromIECompetencyModal="true"
                        :closeParent="() => toggleModal(false)"
                      />
                    </div>
                  </td>
                </tr>
                <!-- <tr>
                  <td class="profile-competency-level-description mid-cell-bot" v-html="$t('enterprise.architect')">
                  </td>
                  <td class="col-data mid-cell-bot">
                    <div><i class="ds-icon ds-icon--checked"></i></div>
                  </td>
                </tr> -->
                <tr>
                  <td class="profile-competency-level-description mid-cell-bot" v-html="$t('customers.past.months')">
                  </td>
                  <td class="col-data mid-cell-bot"> {{ ieCompetency.projectsNum }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <div class="ds-modal__box__action-bar" ref="modalProfileCompetencyActionBar">
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary ds-m-l-m"
            type="button"
            @click="toggleModal(false)"
            id="profile-competency-button__cancel"
          >
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="less" scoped>

.ds-icon--checked::before {
  font-size: 19px;
}
.inline-word-text {
  display: inline-block;
}
.no-split-text {
  display: inline-flex;
  align-content: center;
  white-space: nowrap;
  text-indent: 0px;
}
.cursor-pointer {
  cursor: pointer;
}
.profile-competency-level-description {
  //margin-top: 20px;
  margin-left: -12px;
  margin-bottom: -12px;
}
.project-text {
  font-size: 14px;
  line-height: 21px;
}
.ds-modal__box__action-bar {
  border-color: #eaeaea !important;
  border-top: none;
}
.pf-ie-modal-header {
  padding-bottom: 14px !important;
  border-bottom: none;
}
.top-cell {
  padding-top: 0px !important;
  padding-bottom: 32px !important;
  border-color: #eaeaea !important;
}
.mid-cell-top {
  padding-top: 20px !important;
  padding-left: 0px !important;
}
.mid-cell-bot {
  //padding-bottom: 20px !important;
  border-color: #eaeaea !important;
}
.specialization-tag {
  text-indent: 0px !important;
  min-width: max-content;
  border: 1px solid #007DB8;
  border-radius: 10px;
  padding: 0 2% 0 2%;
  margin-left: 5px;
  height: 19px;
  font-family: 'BentonSans';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  transform: translateY(2px);
  align-items: center;
  text-align: center;
  color: #007DB8;
  display: inline-flex;
}
.solution-item {
  text-indent: -12px;
  padding-left: 12px;
}
.country-name {
  width: 95% !important;
  vertical-align: middle;
  border-color: #eaeaea !important;
}
.inner-table {
  margin-left: 10%;
}
.bottom-border {
  border-bottom: 1px solid #eaeaea !important;
  padding-bottom: 0.75rem;
}
.country-number {
  min-width:40px;
  border-color: #eaeaea !important;
}
.modal-header {
  padding-bottom: 0 !important;
  border-color: #eaeaea !important;
}
.category-text {
  border-bottom: none !important;
}
.td-border-none {
  border: none !important;
}
.td-min-width {
  min-width: 37px !important;
}
.text-align-right {
  text-align: right !important;
}
.profile-project-header, .profile-project-number {
  color: @color-grey-0;
  &:extend(.font-bold);
  font-size: 16px;
  line-height: 24px;
}
.competency-industry-col-name {
  border-bottom: transparent !important;
}
.col-data {
  &:extend(.font-bold);
  vertical-align: middle;
  border-color: #eaeaea !important;
}
.profile-project-number {
  text-align: right;
  width: fit-content !important;
}
.competency-flexRow {
  display: flex;
}
.profile-project-text {
  color: @color-grey-0;
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
}
.competency-level-img {
  height: 30px;
  width: 170px;
}
.certer-text {
  text-align: center;
}
.competency-modal-header {
  color: @color-grey-0;
}
.col-name {
  &:extend(.font-bold);
  color: @color-grey-0;
  width: 30% !important;
}
.profile-competency-level-text {
  &:extend(.font-bold);
  font-size: 21px;
  line-height: 27px;
}
.flex-content-container {
  display: flex;
}
.profile-name-column {
  flex-grow: 5;
}
.profile-addr-column {
  margin-left: 4px;
  flex-grow: 4;
}
.profile-notification-button {
  margin-left: 52px;
  color: @color-link;
  &:extend(.font-expanded);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  .ds-icon::before {
    color: @color-grey-0;
    line-height: 80px;
    font-size: 24px;
  }
  .profile-notification-button-text {
    line-height: 80px;
    font-size: 16px;
  }
}
.profile-notification-more-text:hover,.profile-notification-button:hover {
  color: @color-link__hover;
}
.profile-notification-more-text {
  &:extend(.font-normal);
  color: @color-link;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  white-space: pre-wrap;
}
.profile-competency-text {
  &:extend(.font-normal);
  color: @color-grey-0 !important;
  font-size: 16px;
  line-height: 24px;
}

.solution-competency-item {
  &:extend(.font-bold);
  color: @color-grey-0 !important;
  font-size: 16px;
  line-height: 24px;
}

.solution-competency-item:hover {
  // color: @color-link__hover !important;
  cursor: default;
}

.profile-specialization-text {
  &:extend(.font-normal);
  color: @color-grey-0;
}
.modal-icon {
  color: @color-gray-2;
}
.modal-icon:hover {
  color: @color-gray-1;
  cursor: pointer;
}
.ds-modal--active {
  z-index: 200;
}
.modal-container-inner-upload {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 250px);
}
.profile-competency-modal {
  // padding-top: 0 !important;
  max-width: 669px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  max-height: 620px;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  border-color: #eaeaea !important;
}
.ds-modal__box__header__heading {
  padding-right: 40px !important;
  &:extend(.font-expanded);
  font-size: 27px;
  line-height: 35px;
}
.ds-icon-profile-close::before {
    font-size: 19px;
    font-weight: 800;
    line-height: 35px;
}
.ds-modal__box__content {
  padding-right: 40px !important;
  margin-right: 10px;
  border-color: #eaeaea !important;
}
.ds-modal__box__content::-webkit-scrollbar-track {
  background: @color-slight-gray;
  border-radius: 20px;
}
.ds-modal__box__content::-webkit-scrollbar-thumb {
  background-color: @color-gray-4;
  border-radius: 20px;
}
.ds-table {
  width: 100%;
}
</style>
