<script>
import PEFileSelectionComponent from './PEFileSelectionComponent';
import PEFileUpdateComponent from './PEFileUpdateComponent';

export default {
  name: 'PEFileUploadBoxComponent',
  props: {
    iconUrl: { type: String, default: '' },
    handleFilesUpload: { type: Function, default: () => true },
    pushToStor: { type: Function, default: () => true },
    isNotValidFile: { type: Function, default: () => true },
    isValidFile: { type: Function, default: () => true },
    fileName: { type: String, default: '' },
    errorMessages: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    justUploadLogo: { type: Boolean, default: false },
  },
  components: {
    PEFileUpdateComponent,
    PEFileSelectionComponent,
  },
};
</script>

<template>
  <div class="ds-modal__box__content ds-m-none ds-p-none">
    <div class="container pf-popup-file-upload-model_box-container ds-p-x-l ds-p-t-l">
      <div class="ds-upload">
        <label class="ds-label"></label>
        <PEFileSelectionComponent
          v-if="isNotValidFile() || loading"
          :loading="loading"
          :fileName="fileName"
          :errorMessages="errorMessages"
          :titleTrunslationKeyMessage="'editor.logo.upload.popup.form.body.title'"
          :supportedFilesTrunslationKeyMessage="'editor.logo.upload.popup.form.body.description.type'"
          :fileSizeTrunslationKeyMessage="'editor.logo.upload.popup.form.body.description.size'"
          :handleFilesUpload="handleFilesUpload"
        />
        <PEFileUpdateComponent
          v-if="isValidFile() && !loading"
          :iconUrl="iconUrl"
          :fileName="fileName"
          :handleFilesUpload="handleFilesUpload"
          :justUploadLogo="justUploadLogo"
        />
      </div>
    </div>
  </div>
</template>

<style lang="less">
</style>
