<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import responseHandlerUtil from '@/plugins/search/responseHandler';

export default {
  name: 'PFMultiLevelDropDownComponent',
  props: {
    highlightText: { type: Function, default: () => true },
    isShown: { type: Boolean, default: false },
    mobileMode: { type: Boolean, default: false },
  },

  data: () => ({
    lock: false,
    clickLocation: false,
  }),
  mounted() {
    this.sortSolution();
  },
  methods: {
    ...mapActions('solutionL2Store', {
      unselectAllSolution: 'unselectAllSolution',
      removeSelectedL1Item: 'removeSelectedL1Item',
      removeSelectedL2Item: 'removeSelectedL2Item',
      addSelectedL2Item: 'addSelectedL2Item',
      addSelectedL1Item: 'addSelectedL1Item',
    }),
    ...mapMutations('solutionL2Store', {
      sortSolution: 'sortSolution',
      toggleL1: 'toggleL1',
      expandOrCloseL1: 'expandOrCloseL1',
    }),
    addSpaceAfterComma(text) {
      return responseHandlerUtil.addSpaceAfterComma(text);
    },
    checkAllSelected(L1) {
      let selectedFlag = 1;
      this.solutionDataMap.get(L1).L2Data.forEach((value) => {
        if (value.available) {
          selectedFlag = selectedFlag && value.status;
        }
      });
      return selectedFlag;
    },
    addOrRemoveL1Item(L1) {
      if (this.solutionDataMap.get(L1).status === 2
      || (this.solutionDataMap.get(L1).status === 1 && this.checkAllSelected(L1))) {
        this.removeSelectedL1Item({ L1 });
        this.toggleL1({ L1, expand: false });
      } else {
        this.addSelectedL1Item({ L1 });
        this.toggleL1({ L1, expand: true });
      }
    },
    addOrRemoveL2Item(solution) {
      if (this.solutionDataMap.get(solution.L1)
        .L2Data.get(solution.L2).status === 1) {
        this.removeSelectedL2Item(solution);
      } else {
        this.addSelectedL2Item(solution);
      }
    },
    getL1Title(L1Option) {
      let selectedItems = '';
      if (this.solutionDataMap?.get(L1Option.title)?.status !== 0) {
        const count = L1Option.L2
          .filter((L2Item) => this.solutionDataMap?.get(L1Option.title)?.L2Data?.get(L2Item)?.status === 1)
          .length;
        selectedItems = ` (${count})`;
      }
      const L1Title = this.solutionDataMap.get(L1Option.title).title;
      return this.addSpaceAfterComma(L1Title) + selectedItems;
    },
    getL2Title(L2Option) {
      const L1Title = this.reverseL2L1Mapping.get(L2Option);
      return this.solutionDataMap.get(L1Title).L2Data.get(L2Option).title;
    },
    getDisplayedLength() {
      return this.sortedSolution.filter((solution) => solution.display).length;
    },
    showNoResult() {
      if (this.getDisplayedLength() == 0) {
        return true;
      }
      const noSolutionAvailable = Array.from(this.solutionDataMap.values()).filter((L1) => L1.available === 1);
      if ([...this.solutionBadge.keys()].length === 0 && noSolutionAvailable.length === 0) {
        return true;
      }
      return false;
    },
    async scrollIntoView(isExpanded, idx) {
      if (!isExpanded) return;
      await this.$nextTick();
      const options = this.$refs.dsDropdown.querySelectorAll('.solution-wrapper');
      this.$refs.dsDropdown.scrollTop = options[idx].offsetTop - options[0].offsetTop;
    },
  },
  computed: {
    ...mapState('solutionL2Store', {
      updateSolutionFlag: (state) => state.updateSolutionFlag,
      sortedSolution: (state) => state.sortedSolution,
      solutionDataMap: (state) => state.solutionDataMap,
      reverseL2L1Mapping: (state) => state.reverseL2L1Mapping,
      solutionBadge: (state) => state.solutionBadge,
    }),
  },
  watch: {
    isShown() {
      this.$nextTick(() => {
        this.$refs.dsDropdown.scrollTop = 0;
      });
    },
    updateSolutionFlag(val, oldVal) {
      if (oldVal && !val && !this.isShown) {
        this.sortSolution();
      }
    },
  },
};
</script>

<template>
  <div class="ds-dropdown__options-wrapper" ref="dsDropdown">
    <div class="solution-wrapper no-results-text" v-if="showNoResult()">{{$t('filter.no.results')}}</div>
    <div class="solution-wrapper"
      v-for="(L1Option, idx) in sortedSolution"
      :key="L1Option.title + 'products'"
      :class="[L1Option.expanded ? 'expanded_L1' : '',
        L1Option.expanded && !sortedSolution[idx+1]?.expanded ? 'bottom-border' : '']"
      v-show="L1Option.display">
      <div class="L1-solution-wrapper ds-checkbox-multiselect"
      :class="[solutionDataMap?.get(L1Option.title)?.available ? '' : 'disabled-ds-checkbox__box',
      mobileMode ? 'mobile-L1_padding' : '']">
        <label
          :for="L1Option.title"
          class="ds-checkbox L1-solution-item"
          :class="solutionDataMap?.get(L1Option.title)?.available ? '' : 'disabled-selection'"
          @click.prevent="toggleL1({L1:L1Option.title, expand: !L1Option.expanded}); scrollIntoView(L1Option.expanded, idx);"
          >
          <input
            :disabled="!solutionDataMap?.get(L1Option.title)?.available"
            :id="L1Option.title"
            :checked="solutionDataMap?.get(L1Option.title)?.status"
            type="checkbox"
            class="ds-checkbox__control"
            @click.stop.prevent
          />
          <span v-if="false" class="ds-checkbox__box search-drop-down-margin solution-check-box checkbox_L1"
          :class="solutionDataMap?.get(L1Option.title)?.status === 1 ? 'half-checked' : ''">
            <i
              v-if="solutionDataMap?.get(L1Option.title)?.status === 2"
              class="ds-icon ds-icon--checked"></i>
            <i
              v-if="solutionDataMap?.get(L1Option.title)?.status === 1"
              class="ds-icon ds-icon--half-checked"></i>
          </span>
          <span class="ds-checkbox__info ds-checkbox__label">
            <span
              class="ds-checkbox__title"
              :class="solutionDataMap?.get(L1Option.title)?.available ?
                    '' : 'ds-checkbox__title_disabled'"
              v-html="highlightText(getL1Title(L1Option))">
            </span>
          </span>
        </label>
        <div
          class="L1-filter-sort__dropdown-icon-block"
          @click.prevent="toggleL1({L1:L1Option.title, expand: !L1Option.expanded}); scrollIntoView(L1Option.expanded, idx);"
        >
          <i class="ds-icon solution-expand-icon"
          :class="[L1Option.expanded ? 'ds-icon--arrow-up' : 'ds-icon--arrow-down']"></i>
        </div>
      </div>
      <div
          v-if="L1Option.expanded"
          class="L2-checkbox-container">
          <div v-for="(L2Option, idx) in L1Option.L2"
            :key="L2Option"
            class="L2-item-container"
            :class="solutionDataMap?.get(L1Option.title)?.L2Data?.get(L2Option)?.available ?
            '' : 'disabled-l2-selection'">
            <label
            :for="L2Option"
            class="ds-checkbox ds-checkbox-multiselect checkbox-multiselect_L2"
            :class="[solutionDataMap?.get(L1Option.title)?.L2Data?.get(L2Option)?.available ?
            '' : 'disabled-l2-ds-checkbox__box',
            idx === L1Option.L2.length - 1 ? 'last-solution-option' : '',
            mobileMode ? 'mobile-L2_padding' : '']"
            @click.prevent="addOrRemoveL2Item({L1: L1Option.title, L2: L2Option})"
          >
            <input
              :id="L2Option"
              type="checkbox"
              class="ds-checkbox__control"
              :checked="solutionDataMap?.get(L1Option.title)?.L2Data?.get(L2Option)?.status"
              :disabled="!solutionDataMap?.get(L1Option.title)?.L2Data?.get(L2Option)?.available"
              @click.stop.prevent
            />
            <span class="ds-checkbox__box search-drop-down-margin checkbox_L2 solution-check-box">
              <i class="ds-icon ds-icon--checked"></i>
            </span>
            <span class="ds-checkbox__info ds-checkbox__label checkbox_L2__label">
              <span
                class="ds-checkbox__title ds-checkbox__title_L2"
                :class="solutionDataMap?.get(L1Option.title)?.L2Data?.get(L2Option)?.available ?
                '' : 'ds-checkbox__title_disabled'"
                v-html="highlightText(getL2Title(L2Option))">
              </span>
            </span>
          </label>
          </div>
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.no-results-text {
  white-space: normal;
  margin-left: 12px;
  &:extend(.font-expanded);
  font-size: 16px;
  line-height: 24px;
  color: @color-grey-0;
}
.expanded_L1 {
  background-color: @color-light-grey;
  border-top: 1px solid #eaeaea;
}
.last-solution-option {
  padding-bottom: 8px !important;
}
.bottom-border {
  border-bottom: 1px solid #eaeaea;
}

.disabled-l2-ds-checkbox__box {
  cursor: not-allowed;
  pointer-events: none;
  .solution-expand-icon {
    color: #c6c6c6;
  }
}
.solution-check-box {
  background-color: @white-color;
}

.L1-solution-wrapper {
  &:extend(.font-normal);
  display: flex;
  padding-left: 12px !important;
  .L1-solution-item {
    width: 100%;
  }
  .half-checked::before {
    top: 4px;
    left: 4px;
    bottom: 4px;
    right: 4px;
  }
}
.mobile-L1_padding {
    padding-left: 24px !important;
}
.L2-checkbox-container {
  &:extend(.font-normal);
  .checkbox-multiselect_L2 {
    padding: 6px 0 4px 24px;
  }
  .ds-checkbox__title_L2 {
    font-size: 14px !important;
    line-height: 21px !important;
  }
  .checkbox_L2 {
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    .ds-icon--checked::before {
      font-size: 14px;
    }
  }
  .checkbox_L2__label {
    line-height: 20px !important;
  }
}
.mobile-L2_padding {
  padding-left: 36px !important;
}
.solution-expand-icon {
    padding-top: 4px;
}
.L1-filter-sort__dropdown-icon-block {
    width: 16px;
    height: 24px;
    margin-left: 8px;
    margin-right: 16px;
    cursor: pointer;

    .ds-filter-sort__dropdown-icon {
      color: @color-glance-consultants;
    }
    .ds-filter-sort__dropdown-icon:before {
      line-height: 24px;
    }
}
.disabled-ds-checkbox__box {
  cursor: not-allowed;
  .ds-filter-sort__dropdown-icon {
    color: @color-gray-4;
  }
  .solution-expand-icon {
    color: #c6c6c6;
  }
}
.disabled-selection, .disabled-l2-selection {
  cursor: not-allowed;
}
</style>
