<script>
import { mapState } from 'vuex';
import { RouterLink } from 'vue-router';
import PFResultConsultantComponent from '@/components/common/PFResultConsultantComponent';

const LARGESCREEN = 525;
const SMALLSCREEN = 332;
const NODE_ENV = PROCESS_NODE_ENV;
const SHOW_CLOUD_IMPL_ENV = 'none';
const NOT_FOUND_VALUE = 'not found';

export default {
  name: 'PFCardItemComponent',
  props: {
    ...RouterLink.props,
    data: { type: Object, default: () => {} },
    isLocationApplied: { type: Boolean, default: false },
    idx: { type: Number, default: 0 },
  },
  components: {
    PFResultConsultantComponent,
  },
  data: () => ({
    defaultIconText: '',
    MAX_CHAR_SHOW: 40,
    MAX_CHAR_SHOW_SHORT: 23,
    MAX_CHAR_SHOW_DESCRIPTION: 80,
    urlError: false,
    nodeEnv: NODE_ENV,
    showCloudImplEnv: SHOW_CLOUD_IMPL_ENV,
    textWidth: 267,
    maxChar: 35,
  }),
  computed: {
    query: {
      get() {
        return this.$store.state.autocomplete.query;
      },
    },
    ...mapState('profileSearch', {
      lastUsedQuery: (state) => state.lastUsedQuery,
      pageNumber: (state) => state.pageNumber,
    }),
  },
  methods: {
    imgUrlAlt() {
      this.urlError = true;
    },
    extractDataFromTitle() {
      const myStr = this.data.title ? this.data.title : NOT_FOUND_VALUE;
      const matches = myStr.trim().replace(/\s\s+/g, ' ').split(' ');
      return matches.length > 1 ? matches
        .slice(0, 2)
        .map((letter) => letter.charAt(0).toUpperCase())
        .join('') : myStr.charAt(0);
    },
    getLocation(option) {
      const countries = option.country;
      return countries ? countries.join('') : '';
    },
    getTitleSubstring(title) {
      const match = /\w/;
      if (match.test(title)) {
        return title.substring(0, this.MAX_CHAR_SHOW);
      }
      return title.substring(0, this.MAX_CHAR_SHOW_SHORT);
    },
    showTitleOverlay(title) {
      return title.length > this.getTitleSubstring(title).length;
    },
    onResize() {
      this.textWidth = this.$refs.cardItem.clientWidth;
      if (this.textWidth > LARGESCREEN) {
        this.maxChar = Math.round(this.textWidth / 10);
      } else if (this.textWidth <= LARGESCREEN && this.textWidth > SMALLSCREEN) {
        this.maxChar = Math.round(this.textWidth / 14);
      } else {
        this.maxChar = Math.round(this.textWidth / 20);
      }
    },
    mergeAndTruncateText(brandNames) {
      let showedBrandNames = '';

      brandNames.forEach((brandName) => {
        showedBrandNames += brandName;
        showedBrandNames += ', ';
      });
      if (showedBrandNames.length > this.maxChar) {
        showedBrandNames = `${showedBrandNames.substring(0, this.maxChar - 3)}...`;
      } else {
        showedBrandNames = showedBrandNames.substring(0, showedBrandNames.length - 2);
      }
      return showedBrandNames;
    },
    getBrandNames(data) {
      const brandNames = data.brandNames.slice();
      if (!brandNames.length) { return data.favoriteBrandName; }
      return this.mergeAndTruncateText(brandNames);
    },
    openProfilePage() {
      this.$eventBus.$emit('PFAACustomEvent',
        {
          eventName: 'clickSearchResult', custom2: this.lastUsedQuery, custom3: (this.idx + (this.pageNumber - 1) * 12), custom4: this.data.profileId,
        });
      this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', true);
    },
  },
};
</script>

<template>
  <router-link
  :to="{name: 'profilePage', params: { profileId: data.profileId }}"
  class="ds-card__tile--inner-content" ref="cardItem"
   @click="openProfilePage()">
    <resize-observer @notify="this.onResize"></resize-observer>
      <div class="ds-card__tile-data">
        <div
          v-bind:class="[data.logoUrl && !urlError ? 'ds-card__tile-data__icon' : 'ds-card__tile-data__icon-default']"
        >
          <div class="ds-card__tile--link ds-card__tile--icon">
            <img class="search-partner-icon" v-if="data.logoUrl && !urlError" :src="data.logoUrl" @error="imgUrlAlt"/>
            <div class="search-partner-icon-text" v-if="!data.logoUrl || urlError">{{ extractDataFromTitle() }}</div>
          </div>
        </div>
        <div class="search-card-header">
          <div class="ds-card__tile-name">
            <span>{{data.title ? getTitleSubstring(data.title)  : ''}}</span>
            <span class="fade-overlay" v-show="data.title && showTitleOverlay(data.title)"></span>
          </div>
          <div v-if="data.brandNames" class="ds-card__tile-brand-names">
            {{getBrandNames(data)}}
          </div>
          <div v-if="isLocationApplied" class="ds-card__tile-counties">{{getLocation(data)}}</div>
        </div>
      </div>
      <div class="ds-card__tile-description">
        <span v-html="data.description ? $sanitize(data.description.substring(0, MAX_CHAR_SHOW_DESCRIPTION)) : ''">
        </span>
        <span class="fade-overlay" v-show="data.description && data.description.length > MAX_CHAR_SHOW_DESCRIPTION">
        </span>
      </div>
      <div class="consultant-cloud-container">
          <PFResultConsultantComponent v-if="nodeEnv === this.$showConsultantEnv"
            :number="data.consultants" :consultantMode="true"/>
          <PFResultConsultantComponent v-if="nodeEnv === showCloudImplEnv"
            :cloudImplementationMode="true" :number="data.cloudImplementations"/>
          <PFResultConsultantComponent
            v-if="data.competencyTotal && data.competencyTotal > 0 && !this.$hideCompetencyEnv"
            :competencyMode="true" :number="data.competencyTotal"/>
      </div>
  </router-link>
</template>

<style lang="less">
@import "../../assets/css/common.less";
.search-partner-icon-text {
  overflow-wrap: normal;
}

.ds-card__tile--inner-content {
  height: 297px;
  margin: 26px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 0.9375rem 0 rgba(0, 0, 0, 0.2);
  padding: 32px;
  border: solid 1px transparent;
  cursor: pointer;

  a {
    text-decoration: none;
  }

  .ds-card__tile-data {
    display: flex;
    float: left;
    height: 62px;
    margin-bottom: 20px;

    .ds-card__tile-data__icon {
      display: table-cell;
      vertical-align: middle;
      margin-right: 12px;
      width: 62px;
      height: 62px;
      .ds-card__tile--icon {
        width: 100%;
        height: 100%;
        max-height: 62px !important;
        object-fit: fill;
      }
      .ds-card__tile--icon img {
        max-height: none !important;
      }
    }

    .ds-card__tile-data__icon-default {
      margin-right: 12px;
      width: 62px;
      height: 62px;
      background-color: #eaeaea;

      div {
        &:extend(.font-bold-expanded);
        width         : 56px;
        height        : 56px;
        font-size     : 27px;
        font-stretch  : normal;
        font-style    : normal;
        line-height   : 56px;
        letter-spacing: normal;
        text-align    : center;
        color         : @color-gray-3;
        margin        : 2px 3px 2px 3px;
      }
    }
    .search-card-header {
      height: fit-content;
      margin-top: auto;
      margin-bottom: auto;
    }
    .ds-card__tile-name {
      &:extend(.font-expanded);
      overflow      : hidden;
      font-size     : 16px;
      font-stretch  : normal;
      font-style    : normal;
      line-height   : 1.31;
      letter-spacing: normal;
      color: #222222;
    }
    .ds-card__tile-brand-names {
      &:extend(.font-expanded);
      color: @color-gray-2;
    }

    .ds-card__tile-counties {
      height        : 18px;
      overflow      : hidden;
      font-size     : 12px;
      font-stretch  : normal;
      font-style    : normal;
      line-height   : 1.5;
      letter-spacing: normal;
      color: #222222;
    }
  }

  .ds-card__tile-description {
    &:extend(.font-normal);
    overflow      : hidden;
    text-overflow : ellipsis;
    width         : 100%;
    margin-top    : 20px;
    margin-bottom : 20px;
    font-size     : 16px;
    font-stretch  : normal;
    font-style    : normal;
    line-height   : 1.5;
    letter-spacing: normal;
    color: @color-black;
    height: 72px;
  }

  .ds-card-rating-block {
    display: flex;
    margin-top: 5%;
    text-align: center;

    .ds-card-rating__stars {
      display: block;
      margin-right: auto;
      width: 40%;
    }
  }
}
.ds-card__tile--inner-content:hover {
  border: solid 1px @color-blue-border;
  box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
  transition: transform .25s, visibility .25s ease-in;
}
.fade-overlay {
  position: relative;
}

.fade-overlay::before{
  content: "";
  background-image: linear-gradient(90deg,hsla(0,0%,100%,0),@color-white);
  width: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
.consultant-cloud-container {
  display: -moz-box;
  display: -webkit-box;
  justify-content: center;
}
.search-partner-icon {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.ds-card-conteiner .ds-card .ds-card__tile {
  min-width: 290px;
}
</style>
