class PartnerAddress {
  static prepareLocalAddress(localProfileData, isPostPayload) {
    if (localProfileData) {
      return localProfileData
        .map(JSON.stringify) // make a clone an object
        .map(JSON.parse)
        .map((countryData) => PartnerAddress.handleAddress(countryData, isPostPayload));
    }
    return {};
  }

  static handleAddress(countryData, isPostPayload) {
    if (countryData.addresses) {
      countryData.addresses = countryData.addresses
        .map(JSON.stringify)
        .map(JSON.parse)
        .map((address) => PartnerAddress.buildAddressPayload(address, isPostPayload));
    }
    return countryData;
  }

  static buildAddressPayload(payload, postPayload) {
    if (postPayload) {
      delete payload.id;
    }
    return payload;
  }
}

export default PartnerAddress;
