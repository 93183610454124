<script>
import { mapState } from 'vuex';

const INCORRECT_ERR = 'incorrectEmailErr';
const MANDATORY_ERR = 'contactEmailErr';

export default {
  name: 'PEContactEmailComponent',
  data: () => ({
    isModalShow: false,
    errorEmail: false,
    emptyEmail: false,
    changedErrorEmail: false,
    changedEmptyEmail: false,
    updatedContactEmail: '',
    emailTimer: null,
    disableSave: true,
  }),
  methods: {
    toggleModal() {
      this.isModalShow = !this.isModalShow;
    },
    saveContactEmail() {
      this.$store.dispatch(
        'partnerProfile/updateContactEmail',
        this.updatedContactEmail,
      );
      this.closeModal();
    },
    clearChanges() {
      this.updatedContactEmail = this.contactEmail;
      this.disableSave = true;
      clearTimeout(this.emailTimer);
    },
    closeModal() {
      this.clearChanges();
      this.toggleModal();
      this.checkField(this.contactEmail);
      this.errorEmail = this.changedErrorEmail;
      this.emptyEmail = this.changedEmptyEmail;
    },
    updateEmail(event) {
      this.updatedContactEmail = event.target.value;
      this.disableSave = false;
      if (this.emailTimer) {
        clearTimeout(this.emailTimer);
      }
      this.emailTimer = setTimeout(() => this.checkField(event.target.value), 2000);
    },
    getContactEmail() {
      if (this.updatedContactEmail != null) {
        return this.updatedContactEmail;
      }
      if (this.contactEmail && this.contactEmail.length > 0) {
        return this.contactEmail;
      }
      return '';
    },
    checkField(email) {
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!email || email.length === 0) {
        this.changedErrorEmail = true;
        this.changedEmptyEmail = true;
      } else if (re.test(email)) {
        this.changedErrorEmail = false;
        this.changedEmptyEmail = false;
      } else {
        this.changedErrorEmail = true;
        this.changedEmptyEmail = false;
      }
    },
    handleContactEmailError(val, err, removeErr) {
      if (val) {
        this.addEmailError(err);
        this.removeEmailError(removeErr);
      } else {
        this.removeEmailError(err);
      }
      if (!val && err === MANDATORY_ERR && this.errorEmail) {
        this.addEmailError(INCORRECT_ERR);
      }
    },
    addEmailError(errorId) {
      this.$store.dispatch(
        'partnerProfile/addErrorField',
        {
          id: errorId,
          field: this.$t('field.contact.request.email'),
          message: this.getMessageByErrorId(errorId),
        },
      );
    },
    removeEmailError(errorId) {
      this.$store.dispatch('partnerProfile/removeErrorField', errorId);
    },
    getMessageByErrorId(errorId) {
      return errorId === MANDATORY_ERR
        ? this.$t('email.contact.mandatory')
        : this.$t('local.office.email.error.message');
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      contactEmail: (state) => state.profileData.contactEmail,
      mountedComponent: (state) => state.mountedComponent,
    }),
  },
  watch: {
    contactEmail(val) {
      if (this.mountedComponent.includes(this.$options.name)) {
        this.updatedContactEmail = val;
        this.checkField(val);
        this.errorEmail = this.changedErrorEmail;
        this.emptyEmail = this.changedEmptyEmail;
      } else {
        this.$store.dispatch(
          'partnerProfile/addMountedComponent',
          this.$options.name,
        );
      }
    },
    errorEmail(val) {
      this.handleContactEmailError(val, INCORRECT_ERR, MANDATORY_ERR);
    },
    emptyEmail(val) {
      this.handleContactEmailError(val, MANDATORY_ERR, INCORRECT_ERR);
    },
    isModalShow(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
        window.setTimeout(() => this.$refs.contactEmailModal.focus(), 0);
      } else {
        document.body.style.overflow = 'scroll';
        document.body.style.paddingRight = '0px';
      }
    },
  },
  mounted() {
    this.updatedContactEmail = this.contactEmail;
    if (this.mountedComponent.includes(this.$options.name)) {
      this.checkField(this.contactEmail);
      this.errorEmail = this.changedErrorEmail;
      this.emptyEmail = this.changedEmptyEmail;
    }
    this.$eventBus.$on('*', (type) => {
      if (['footerSaveClicked', 'footerPublishClicked', 'editorToggleButtonClicked'].includes(type)) {
        this.checkField(this.contactEmail);
        this.errorEmail = this.changedErrorEmail;
        this.emptyEmail = this.changedEmptyEmail;
      }
    });
    this.$eventBus.$on('contactEmailErr', () => {
      this.emptyEmail = true;
    });
  },
};
</script>

<template>
  <div class="contact-email-container ds-m-t-l">
    <button
      class="ds-button ds-button--primary contact-email-button"
      :class="contactEmail && contactEmail.length > 0 ? 'ds-p-y-none' : ''"
      type="button"
      @click="toggleModal"
    >
      <span :class="contactEmail && contactEmail.length > 0 ? 'email-edit-small-text' : ''">
        {{
          contactEmail && contactEmail.length > 0
            ? $t("edit.email.contact")
            : $t("add.email.contact")
        }}
      </span>
      <span class="email-small-text">
        {{contactEmail}}
      </span>
    </button>
    <div class="ds-textField__message" v-show="errorEmail || emptyEmail">
      <i class="ds-icon--close-outline ds-m-r-xs"></i
      >{{
        emptyEmail
          ? $t("email.contact.mandatory")
          : $t("local.office.email.error.message")
      }}
    </div>
    <div class="ds-modal ds-modal--active" v-if="isModalShow"
         ref="contactEmailModal" tabindex="0" @keyup.esc="closeModal">
      <div class="modal-container">
      <div class="modal-container-inner-upload">
      <div class="ds-modal__box contact-email-modal">
        <div class="ds-modal__box__header ds-p-y-none">
          <div class="ds-modal__box__header__heading">
            {{
              contactEmail && contactEmail.length > 0
                ? $t("edit.email.contact")
                : $t("add.email.contact")
            }}
          </div>
        </div>
        <div class="ds-modal__box__content">
          <div class="edit-email-text ds-m-b-m">
            {{ $t("edit.email.text") }}
          </div>
          <label
            class="ds-m-none ds-textField ds-textField--fixed email-input-field"
            :class="changedErrorEmail ? 'ds-textField--error' : ''"
          >
            <input
              class="ds-textField__input ds-m-t-m"
              type="text"
              :placeholder="$t('label.email')"
              :value="getContactEmail()"
              @input="updateEmail"
              id="partner-contact-email-text"
            />
            <span class="ds-textField__placeholder" v-if="getContactEmail()">
              {{ $t("label.email") }}
            </span>
            <span class="ds-textField__message" v-show="changedErrorEmail">
              <i class="ds-icon--close-outline ds-m-r-xs"></i>
              {{
                changedEmptyEmail
                  ? $t("mandatory.field.error.message")
                  : $t("local.office.email.error.message")
              }}
            </span>
          </label>
        </div>
        <div class="ds-modal__box__action-bar ds-p-y-none">
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary save-button ds-m-x-none"
            type="button"
            @click="saveContactEmail"
            :disabled="disableSave"
            id="partner-contact-email-button__done"
          >
            {{ $t("button.done") }}
          </button>
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--secondary ds-m-l-m"
            type="button"
            @click="closeModal"
            id="partner-contact-email-button__cancel"
          >
            {{ $t("button.cancel") }}
          </button>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.contact-email-container {
  .contact-email-button {
    &:extend(.font-normal);
    max-width: none;
    font-size: 16px;
    text-align: center;
    display: block;
  }
  .ds-modal__box__header__heading {
    &:extend(.font-expanded);
  }
  .email-edit-small-text {
    &:extend(.font-normal);
    font-size: 14px;
    line-height: 23px;
    display: block;
  }
  .email-small-text {
    &:extend(.benton-sans-light);
    font-size: 12px;
    line-height: 17px;
    display: block;
  }

  .ds-textField__message {
    &:extend(.font-normal);
    font-size: 14px;
    color: @color-field-error;
    .ds-icon--close-outline {
      line-height: 17px;
    }
  }

  .ds-modal--active {
    z-index: 200;
  }
  .contact-email-modal {
    max-width: 520px;
    height: fit-content;
    min-height: 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  }

  .edit-email-text {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
    color: @color-gray-1;
  }

  .email-input-field {
    width: 274px;
  }
  .ds-button {
    &:extend(.font-normal);
    font-size: 16px;
  }
  .save-button {
    width: 83px;
  }
  .modal-container-inner-upload {
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    top: calc(50% - 145px);
  }
}
</style>
