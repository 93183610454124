import axios from 'axios';
import router from '../../router/routes';

const IS_ENABLED_SECURITY = true;

const endpoints = {
  isAuthenticated: '/is_authenticated',
  userInfo: '/sap/details/apis/userinfo',
};

let sessionExpiredTimerId;

const UserProfileStore = {
  namespaced: true,
  state: {
    user: {
      isInformed: true,
    },
    language: 'EN',
    loggedIn: false,
    isLoginDataRequested: false,
    isAuthorizationWithError: false,
    isEnableSecurity: IS_ENABLED_SECURITY,
    sessionExpired: false,
    cancelSessionExtendingClicked: false,
    isGuidanceCloseButtonClicked: false,
    showGuidancePopup: false,
  },
  actions: {
    doLoginStatus(context) {
      if (IS_ENABLED_SECURITY && !context.state.isLoginDataRequested) {
        context.commit('setIsLoginDataRequested', true);
        axios.get(endpoints.isAuthenticated, {}, { withCredentials: true })
          .then(() => {
            context.commit('setIsLoginDataRequested', false);
            context.dispatch('requestUserDetails');
          })
          .catch(() => context.commit('refresh'));
      }
    },
    doLogin(context) {
      if (IS_ENABLED_SECURITY) {
        axios.get(endpoints.isAuthenticated, {}, { withCredentials: true })
          .then(() => context.dispatch('requestUserDetails'))
          .catch((error) => {
            if (error.response) {
              context.dispatch('triggerRedirect', error.response);
            } else {
              console.log('Error', error.message);
            }
          });
      }
    },
    doLogout(context) {
      context.commit('refresh');
      context.dispatch('windowNavigate', '/logout');
    },
    triggerRedirect(context, response) {
      if (response.status === 401 && response.headers.location) {
        context.dispatch('windowNavigate', response.headers.location);
      } else {
        console.log('unexpected output from authentication endpoint');
      }
    },
    requestUserDetails(context) {
      if (IS_ENABLED_SECURITY) {
        axios.get(endpoints.userInfo, {}, { withCredentials: true })
          .then((response) => {
            if (response.headers['content-type'].startsWith('application/json', 0)) {
              context.commit('setResponse', response.data);
              context.commit('loggedIn', true);
              context.commit('setAutorisationError', false);
              context.dispatch('setSessionExpirationTimer');
            } else if (response.headers['content-type'].startsWith('text/html', 0)) {
              context.commit('loggedIn', false);
              context.commit('setResponse', {});
            } else {
              console.log('Unexpected response from user info API');
              context.commit('loggedIn', false);
              context.commit('setResponse', {});
              console.log(`error Login${response}`);
              router.push({ name: 'systemErrorPage', query: { q: '' } });
            }
          })
          .catch((error) => {
            context.commit('setAutorisationError', true);
            console.log(`error Login${error}`);
          });
      }
    },
    setSessionExpirationTimer(context) {
      context.dispatch('resetSessionExpired');
      if (!window.location.href.endsWith('edit')) {
        return;
      }
      sessionExpiredTimerId = setTimeout(
        () => context.commit('setSessionExpired', true),
        Date.parse(context.state.user.expiration) - Date.now(),
      );
    },
    resetSessionExpired(context) {
      context.commit('setSessionExpired', false);
      if (sessionExpiredTimerId) {
        clearTimeout(sessionExpiredTimerId);
      }
    },
    windowNavigate(context, path) {
      window.location.assign(path);
    },
    sendDoNotShowGuidanceRequest(context, value) {
      if (IS_ENABLED_SECURITY) {
        axios.patch(endpoints.userInfo, { isInformed: value }, {
          headers: {
            'x-csrf-token': context.rootState.partnerProfile.csrfToken,
          },
        })
          .catch((error) => {
            console.log('Error', error);
          });
      }
    },
  },
  mutations: {
    setResponse(context, response) {
      context.user = response;
    },
    setAutorisationError(context, value) {
      context.isAuthorizationWithError = value;
    },
    loggedIn(context, value) {
      context.loggedIn = value;
    },
    refresh(context) {
      context.loggedIn = false;
      context.user = {};
    },
    setIsLoginDataRequested(context, value) {
      context.isLoginDataRequested = value;
    },
    setSessionExpired(context, value) {
      context.sessionExpired = value;
    },
    setCancelSessionExtendingClicked(context, value) {
      context.cancelSessionExtendingClicked = value;
    },
    setIsGuidanceCloseButtonClicked(context, value) {
      context.isGuidanceCloseButtonClicked = value;
    },
    setShowGuidancePopup(context, value) {
      context.showGuidancePopup = value;
    },
  },
};

export default UserProfileStore;
