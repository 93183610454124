<script>
export default {
  name: 'PFValidatedInputComponent',
  props: {
    localizedPlaceholder: { type: String, default: '' },
    localizedErrorMessage: { type: String, default: '' },
    value: { type: String, default: '' },
    name: { type: String, default: '' },
    validAction: { type: Function, default: () => true },
    changeAction: { type: Function, default: () => true },
    maxLength: { type: Number, default: null },
  },
  methods: {
    isNotEmpty(str) {
      return str !== null && str.length > 0;
    },
  },
};
</script>

<template>
  <label class="ds-textField ds-textField--fixed">
    <input
      class="ds-textField__input"
      :class="{
        'pf-validation-error-textField': !validAction(name),
        'ds-textField__input--active' : value !== null && value.length > 0
      }"
      type="text"
      :placeholder="$t(localizedPlaceholder)"
      :maxlength="maxLength"
      @input="changeAction($event, name)"
      :value="value"
    />
    <span
      class="ds-textField__placeholder"
      :class="!validAction(name) ? 'pf-validation-error-textField-placeholder':''"
      v-if="isNotEmpty(value)">
      {{ $t(localizedPlaceholder) }}
    </span>
    <div class="ds-textField__message pf-validation-error-message" v-if="!validAction(name)">
      <i class="ds-icon--close-outline ds-m-r-xs"></i>
      {{ $t(localizedErrorMessage) }}
    </div>
  </label>
</template>

<style scoped lang="less">
@import "../../assets/css/common.less";

.pf-validation-error-textField:focus {
  border-color: @color-field-error;
}

.pf-validation-error-textField {
  border-color: @color-field-error;
}

.pf-validation-error-textField-placeholder, .pf-validation-error-message {
  color: @color-field-error !important;
}

.pf-validation-error-message {
  font-size: 14px;
  .ds-icon--close-outline {
    line-height: 17px;
    padding-left: 1px;
  }
}

.ds-textField__input {
  opacity: 1 !important;
  position: static !important;
  font-size: inherit;
  &:extend(.font-normal);
}

.ds-textField__input::placeholder {
  font-size: inherit;
}
</style>
