<script>
/* eslint-disable vue/require-v-for-key */
/* eslint-disable vue/require-v-for-key */
/* eslint-disable vue/no-template-key */

export default {
  name: 'PFSpecializationTblComponent',
  data: () => ({
    showTable: false,
  }),
  props: {
    specialization: {
      type: Object,
      default: () => ({}),
    },
    showTableInitial: {
      type: Boolean, default: false,
    },
    ERPMode: {
      type: Boolean, default: false,
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    this.showTable = this.showTableInitial;
  },
  computed: {
    sortedCountries() {
      const countryArray = [];
      if (this.specialization.countries && Object.keys(this.specialization.countries).length > 0) {
        Object.keys(this.specialization.countries).forEach((country) => {
          countryArray.push({ name: country, number: this.specialization.countries[country] });
        });
      }
      countryArray.sort((a, b) => {
        if (a.number - b.number === 0) {
          return a.name.localeCompare(b.name);
        }
        return b.number - a.number;
      });
      return countryArray;
    },
    sortedIndustries() {
      const industryArray = [];
      if (this.specialization.industries && Object.keys(this.specialization.industries).length > 0) {
        Object.keys(this.specialization.industries).forEach((industry) => {
          industryArray.push({ name: industry, number: this.specialization.industries[industry] });
        });
      }
      industryArray.sort((a, b) => {
        if (a.number - b.number === 0) {
          return a.name.localeCompare(b.name);
        }
        return b.number - a.number;
      });
      return industryArray;
    },
  },
  methods: {
    async toggleTable() {
      this.showTable = !this.showTable;
      this.$emit('checkOverflow');

      if (!this.showTable) return;
      const speTable = this.$parent.$refs.speTable;
      await this.$nextTick();
      const specializations = speTable.querySelectorAll('.specialization-table-container');
      specializations[this.idx].scrollIntoView();
    },
  },
};
</script>

<template>
  <div class="specialization-table-container">
    <div v-if="!ERPMode" class="specialization-menu icon-label-group" @click="toggleTable">
      <i class="ds-icon solution-expand-icon"
                :class="showTable ? 'ds-icon--arrow-up ds-icon--big'
                : 'ds-icon--arrow-down ds-icon--big'">
              </i>
        <div class="solution-name-text">{{specialization.name}}</div>
    </div>
          <div v-show="showTable" class="profile-competency-text">
            <table class="ds-table ds-m-none"
            :class="{'profile-competency-table':!ERPMode}">
              <tbody>
                <tr>
                  <td class="col-name top-border border-bottom ">{{$t("cloud.competency.consultants")}}</td>
                  <td class="col-data top-border border-bottom"><i class="ds-icon ds-icon--checked ds-p-l-m"></i></td>
                </tr>
                <tr>
                  <td class="col-name td-border-none">
                    <span>
                    {{$t("profile.competency.project")}}<br>
                    <span class="project-text">{{$t("profile.competency.project2")}}</span>
                    </span>
                  </td>
                  <td class="col-data td-border-none ds-p-r-none">
                    <table class="ds-table">
                      <tbody>
                        <template v-for="(country,idx) in sortedCountries" :key="country.name+'-number-competency'">
                          <tr v-if="specialization.countries && Object.keys(specialization.countries).length > 0">
                            <td class="ds-p-none text-align-right country-number"
                            :class="{'td-border-none' : idx !== sortedCountries.length - 1,
                            'ds-p-b-s' : idx === sortedCountries.length - 1}">
                              <span class="profile-project-number ds-m-r-xs">{{country.number}}</span>
                            </td>
                            <td class="ds-p-none country-name"
                            :class="{'td-border-none' : idx !== sortedCountries.length - 1,
                            'ds-p-b-s' : idx === sortedCountries.length - 1}">
                              <span class="profile-project-text ds-m-r-xxs">{{country.name}}</span>
                            </td>
                          </tr>
                        </template>
                        <template v-for="(industry,idx) in sortedIndustries" :key="industry.name+'-number-competency'" >
                          <tr v-if="specialization.industries && Object.keys(specialization.industries).length > 0">
                            <td class="ds-p-none td-border-none text-align-right country-number"
                            :class="{'ds-p-t-s' : idx === 0}">
                              <span class="profile-project-number ds-m-r-xs">{{industry.number}}</span>
                            </td>
                            <td class="ds-p-none td-border-none country-name"
                            :class="{'ds-p-t-s' : idx === 0}">
                              <span class="profile-project-text ds-m-r-xxs">{{industry.name}}</span>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                      </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

  </div>
</template>

<style lang="less" scoped>
.profile-competency-table {
  padding-top: 6px !important;
}
.icon-label-group {
  margin-bottom: 6px;
  cursor: pointer;
    display: flex;
    justify-content: left;
    .solution-expand-icon {
      margin-right: 12px;
      color:@color-gray-2;
    }
  }
.specialization-menu:hover {
  color: @color-link;
  .solution-name-text {
    color: @color-link;
  }
}
.solution-name-text {
  color: @color-grey-0;
  &:extend(.font-expanded);
  font-size: 16px;
  line-height: 24px;
}
.solution-expand-icon {
      margin-right: 12px;
}
.inline-word-text {
  white-space: pre;
  display: inline-block;
}

.ds-modal__box__action-bar__action {
  margin-left: 0;
}
.no-split-text {
  display: inline-flex;
  align-content: center;
  white-space: nowrap;
  text-indent: 0px;
}
.cursor-pointer {
  cursor: pointer;
}
.profile-competency-level-description {
  margin-top: 20px;
  margin-left: -12px;
  margin-bottom: -12px;
}
.project-text {
  font-size: 14px;
  line-height: 21px;
}
.ds-modal__box__action-bar {
  border-color: #eaeaea !important;
  justify-content: space-between;
}
.top-cell {
  padding-top: 20px !important;
  padding-bottom: 32px !important;
  border-color: #eaeaea !important;
}
.top-border {
  border-top: 1px solid #eaeaea;
}
.border-bottom {
  border-bottom: 1px solid #eaeaea;
}
.mid-cell-top {
  padding-top: 20px !important;
  padding-left: 0px !important;
}
.mid-cell-bot {
  padding-bottom: 20px !important;
  border-color: #eaeaea !important;
}
.country-name {
  width: 95% !important;
  vertical-align: middle;
  border-color: #eaeaea !important;
}
.inner-table {
  margin-left: 10%;
}
.bottom-border {
  border-bottom: 1px solid #eaeaea !important;
  padding-bottom: 0.75rem;
}
.country-number {
  min-width:40px;
  border-color: #eaeaea !important;
}
.modal-header {
  padding-bottom: 0 !important;
  border-color: #eaeaea !important;
}
.category-text {
  border-bottom: none !important;
}
.td-border-none {
  border: none !important;
}
.td-min-width {
  min-width: 37px !important;
}
.text-align-right {
  text-align: right !important;
}
.profile-project-header, .profile-project-number {
  color: @color-grey-0;
  &:extend(.font-bold);
  font-size: 16px;
  line-height: 24px;
}
.competency-industry-col-name {
  border-bottom: transparent !important;
}
.col-data {
  color: @color-grey-0;
  vertical-align: middle;
  border-color: #eaeaea !important;
}
.profile-project-number {
  text-align: right;
  width: fit-content !important;
}
.competency-flexRow {
  display: flex;
}
.profile-project-text {
  color: @color-grey-0;
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
}
.competency-level-img {
  height: 30px;
  width: 170px;
}
.certer-text {
  text-align: center;
}
.competency-modal-header {
  color: @color-grey-0;
}
.col-name {
  color: @color-grey-0;
  &:extend(.font-normal);
  border-color: #eaeaea !important;
  width: 40% !important;
}
.profile-competency-level-text {
  &:extend(.font-bold);
  font-size: 21px;
  line-height: 27px;
}
.flex-content-container {
  display: flex;
}
.profile-name-column {
  flex-grow: 5;
}
.profile-addr-column {
  margin-left: 4px;
  flex-grow: 4;
}
.profile-competency-text {
  &:extend(.font-normal);
  color: @color-grey-0 !important;
  font-size: 16px;
  line-height: 24px;
}

.profile-specialization-text {
  &:extend(.font-normal);
  color: @color-grey-0;
}
.ds-table {
  width: 100%;
}
</style>
