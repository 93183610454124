<script>
import { mapState, mapActions } from 'vuex';
import PEFileSelectionComponent from './popup/PEFileSelectionComponent';

const MANDATORY_ERR = 'videoUploadError';

export default {
  name: 'PEVideoUploadBoxComponent',
  computed: {
    ...mapState('partnerProfile', {
      profileId: (state) => state.profileData.profileId,
      profileVideoUrl: (state) => state.profileData.videoUrl,
      profileVideoImageUrl: (state) => state.profileData.videoImageUrl,
      loading: (state) => state.videoContainerLoading,
    }),
  },
  data: () => ({
    videoUrl: null,
    fileName: null,
    errorMessages: [],
    step: 0,
  }),
  props: {
    updateErrorMessages: { type: Array, default: () => [] },
    updateStep: { type: Number, default: 0 },
    handleThumbnailFilesUpdate: { type: Function, default: () => {} },
    handleFilesUpdate: { type: Function, default: () => {} },
    updatedVideo: { type: String, default: '' },
  },
  components: {
    PEFileSelectionComponent,
  },
  methods: {
    ...mapActions('partnerProfile', {
      updateVideoUrl: 'updateVideoUrl',
      updateVideoImageUrl: 'updateVideoImageUrl',
    }),
    handleFilesUpload(file) {
      if (!file) {
        return;
      }
      if (this.$fileValidator.constructor.isValidImageFormat(file)) {
        this.handleThumbnailFilesUpload(file);
        return;
      }
      this.videoUrl = null;
      this.fileName = file.name;
      this.errorMessages = [];
      this.$store.commit('partnerProfile/setVideoContainerLoading', true);
      this.$fileValidator
        .validateAndUploadVideo(file, this.profileId, this.$store.state.partnerProfile.csrfToken)
        .then((data) => {
          this.$store.commit('partnerProfile/setVideoContainerLoading', false);
          this.videoUrl = data;
          this.updateVideoUrl(this.videoUrl);
          this.step = 1;
        })
        .catch((err) => {
          this.$store.commit('partnerProfile/setVideoContainerLoading', false);
          this.videoUrl = null;
          this.errorMessages = this.$fileValidator.constructor.handleResponseError(err);
        });
    },
    handleThumbnailFilesUpload(file) {
      if (!file) {
        return;
      }
      this.fileName = file.name;
      this.errorMessages = [];
      this.$store.commit('partnerProfile/setVideoContainerLoading', true);
      this.$fileValidator
        .validateAndUploadThumbnail(file, this.profileId, this.$store.state.partnerProfile.csrfToken)
        .then((data) => {
          this.$store.commit('partnerProfile/setVideoContainerLoading', false);
          this.step = 3;
          this.errorMessages = [];
          this.removeErrorField();
          this.updateVideoUrl(this.videoUrl);
          this.updateVideoImageUrl(data);
        })
        .catch((err) => {
          this.$store.commit('partnerProfile/setVideoContainerLoading', false);
          this.videoImageUrl = null;
          this.errorMessages = this.$fileValidator.constructor.handleResponseError(err);
        });
    },
    checkImgUrlEmpty() {
      if (this.step === 1 && this.profileVideoUrl && !this.profileVideoImageUrl) {
        this.errorMessages = ['video.thumbnail.mandatory'];
        this.$store.dispatch(
          'partnerProfile/addErrorField',
          {
            id: MANDATORY_ERR,
            field: 'Video Thumbnail',
            message: 'Video Thumbnail is mandatory.',
          },
        );
      } else {
        this.removeErrorField();
      }
    },
    removeErrorField() {
      this.$store.dispatch(
        'partnerProfile/removeErrorField', MANDATORY_ERR,
      );
    },
  },
  watch: {
    profileVideoUrl() {
      if (this.profileVideoUrl && !this.profileVideoImageUrl) {
        this.step = 1;
        this.videoUrl = this.profileVideoUrl;
      }
    },
  },
  mounted() {
    if (this.updateErrorMessages.length > 0) {
      this.errorMessages = this.updateErrorMessages;
      this.step = this.updateStep;
    }
    if (this.updatedVideo && this.updateErrorMessages.length === 0) {
      this.videoUrl = this.updatedVideo;
      this.step = this.updateStep;
    }
    if (this.profileVideoUrl && !this.profileVideoImageUrl) {
      this.step = 1;
      this.videoUrl = this.profileVideoUrl;
    }
    this.$eventBus.$on('*', (type) => {
      if (['footerSaveClicked', 'footerPublishClicked', 'editorToggleButtonClicked'].includes(type)) {
        this.checkImgUrlEmpty();
      }
    });
  },
};
</script>

<template>
  <div class="ds-modal__box__content ds-m-none ds-p-none">
    <div class="pf-media-upload-container container">
      <div class="ds-upload">
        <label class="ds-label"></label>
        <PEFileSelectionComponent
          v-if="step === 0"
          :isVideoUpload="true"
          :loading="loading"
          :fileName="fileName"
          :errorMessages="errorMessages"
          :handleFilesUpload="updateErrorMessages.length > 0 ? handleFilesUpdate : handleFilesUpload"
          :titleTrunslationKeyMessage="'editor.video.upload.popup.form.body.title'"
          :supportedFilesTrunslationKeyMessage="'editor.video.upload.popup.form.body.description.type'"
          :fileSizeTrunslationKeyMessage="'editor.video.upload.popup.form.body.description.size'"
        />
        <PEFileSelectionComponent
          v-if="step === 1"
          :isVideoUpload="true"
          :loading="loading"
          :fileName="fileName"
          :buttonText="'editor.logo.upload.popup.form.body.description.edit.button.thumbnail.text'"
          :uploadedFile="videoUrl"
          :errorMessages="errorMessages"
          :handleFilesUpload="updateErrorMessages.length > 0 ? handleThumbnailFilesUpdate : handleThumbnailFilesUpload"
          :titleTrunslationKeyMessage="'editor.video.upload.popup.form.body.thumbnail.title'"
          :supportedFilesTrunslationKeyMessage="'editor.video.upload.popup.form.body.thumbnail.description.type'"
          :fileSizeTrunslationKeyMessage="'editor.video.upload.popup.form.body.thumbnail.description.size'"
        />
      </div>
    </div>
  </div>
</template>

<style lang="less">
.pf-media-upload-container {
  width: 608px;
}
</style>
