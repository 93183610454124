import i18n from '@/localization/i18n';

class SendEmail {
  static sendEmailBackendError(subject, profileId, userId, pageTitle, url) {
    const date = new Date();
    const body = `${i18n.tc('help.mail.body.backend.error')}\n${
      i18n.tc('help.mail.body.backend.error.date')}${date.toString()}\n${
      i18n.tc('help.mail.body.backend.error.id')}${profileId}\n${
      i18n.tc('help.mail.body.backend.error.user.id')}${userId}\n${
      i18n.tc('help.mail.body.backend.error.page')}${pageTitle}\n${
      i18n.tc('help.mail.body.backend.error.url')}${url}`;
    const emailBody = encodeURIComponent(body);
    const email = encodeURIComponent(i18n.tc('help.mail.contact.email'));
    SendEmail.openInNewTab(`mailto:${email}?subject=${subject}&display-name=SAP&body=${emailBody}`);
  }

  static openInNewTab(url) {
    const win = window.open(url, '_blank');
    win.focus();
  }
}

export default SendEmail;
