<script>
import PFLinkComponent from '@/components/common/PFLinkComponent';

export default {
  name: 'PFLinkContainer',
  components: {
    PFLinkComponent,
  },
  props: {
    links: { type: Array, default: () => [] },
    isLight: { type: Boolean, default: false },
  },
};
</script>

<template>
<div class="pf-link-container" v-bind:class="[ isLight ? 'pf-link-item_light_font' : 'pf-link-item_regular_font']">
  <div class="pf-link-container__item" v-for="link in links" v-bind:key="link.title">
    <PFLinkComponent :link="link" :target="link.title === 'Privacy' ? '' : '_blank'" />
  </div>
</div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.pf-link-container {
  display: inline-block;

  .pf-link-container__item {
    margin-right: 20px;
    display: inline-block;
    margin-bottom: 19px;

    a {
      height         : 24px;
      font-size      : 16px;
      font-stretch   : normal;
      font-style     : normal;
      line-height    : 1.5;
      letter-spacing : normal;
      color          : @color-gray-4;
      text-decoration: none;
    }
    a:hover {
      color          : @color-white;
    }
  }
}

.pf-link-item_light_font {
  .pf-link-container__item {
    margin-bottom: 19px;
    a {
      &:extend(.font-normal);
    }
  }
}

.pf-link-item_regular_font {
  .pf-link-container__item {
    margin-bottom: 18px;
    a {
      &:extend(.font-expanded);
    }
  }
}
</style>
