<script>
import PEPartnerLogoComponent from '@/components/editor/PEPartnerLogoComponent';
import PEPartnerTitleComponent from '@/components/editor/PEPartnerTitleComponent';
import PFPartnerAnchorLinksComponent from '@/components/common/PFPartnerAnchorLinksComponent';

export default {
  name: 'PEPartnerDetailHeaderComponent',
  props: {
    profile: { type: Object, default: () => {} },
  },
  components: {
    PEPartnerLogoComponent,
    PEPartnerTitleComponent,
    PFPartnerAnchorLinksComponent,
  },
};
</script>

<template>
  <div class="profile-header ds-p-none ds-m-x-none ds-p-x-xl">
    <div class="profile-header-inner">
      <PEPartnerLogoComponent :logo="profile.logoUrl" :title="profile.name" :profileId="profile.profileId"/>
      <PEPartnerTitleComponent
        :title="profile.name"
        :link="profile.webSiteUrl"
      />
      <PFPartnerAnchorLinksComponent :editMode="true"/>
    </div>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.profile-header {
  height: 144px;
  // box-shadow: 0 0 1.25rem 0 rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid @color-gray-4;
  background-color: @color-white;
  display: flex;
  justify-content: center;

  .profile-header-inner {
    display: flex;
    width: 100%;
    max-width: 1680px;
  }
}
</style>
