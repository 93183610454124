<script>
import { mapState } from 'vuex';
import PEErrorLostComponent from './PEErrorLostComponent';

export default {
  name: 'PEFileSelectionComponent',
  props: {
    isVideoUpload: { type: Boolean, default: false },
    uploadedFile: { type: String, default: null },
    fileName: { type: String, default: '' },
    errorMessages: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    handleFilesUpload: { type: Function, default: () => true },
    titleTrunslationKeyMessage: { type: String, default: '' },
    supportedFilesTrunslationKeyMessage: { type: String, default: '' },
    fileSizeTrunslationKeyMessage: { type: String, default: '' },
    buttonText: {
      type: String,
      default: 'editor.logo.upload.popup.form.body.description.button.text',
    },
  },
  computed: {
    ...mapState('userProfile', {
      cancelSessionExtendingClicked: (state) => state.cancelSessionExtendingClicked,
    }),
  },
  components: {
    PEErrorLostComponent,
  },
  methods: {
    getFileName(url) {
      return url.substring(url.lastIndexOf('/') + 1);
    },
    getBestRatio() {
      return this.$fileValidator.isBestVideoRatio;
    },
    getTruncatedFileName(url) {
      const modifiedName = url.substring(url.lastIndexOf('/') + 1);
      const fileName = modifiedName.substring(modifiedName.indexOf('-') + 1);
      const maxShowingLen = 40;
      if (fileName.length <= maxShowingLen) {
        return fileName;
      }
      return `${fileName.substring(0, 20)}...${fileName.substring(fileName.length - 10, fileName.length)}`;
    },
    getTruncatedUploadingFileName(fileName) {
      const maxShowingLen = 40;
      if (fileName.length <= maxShowingLen) {
        return fileName;
      }
      return `${fileName.substring(0, 20)}...${fileName.substring(fileName.length - 10, fileName.length)}`;
    },
    resolveCssClasses() {
      const logoClass = this.isVideoUpload ? 'pf-upload__block_video' : 'pf-upload__block_logo';
      const errorBorderClass = this.errorMessages.length === 0 ? '' : 'ds-upload__block_error';
      return [logoClass, errorBorderClass];
    },
    checkSessionExpiration(event) {
      if (this.cancelSessionExtendingClicked) {
        this.$store.commit('userProfile/setSessionExpired', true);
        event.preventDefault();
      }
    },
  },
};
</script>
<template>
  <div
    class="ds-upload__block ds-m-none ds-p-y-m "
    v-bind:class="resolveCssClasses()"
  >
    <div v-if="uploadedFile" class="ds-upload__block-uploaded">
      <i class="ds-icon ds-icon--checked ds-m-r-xs" />
      <div class="upload-message">{{getTruncatedFileName(uploadedFile)}} {{$t('partner.video.uploaded')}}</div>
    </div>
    <div v-if="uploadedFile && !getBestRatio()"
      class="ds-upload__block-uploaded ds-upload__block-warning  ds-m-t-s ds-m-b-l">
      <i class="ds-icon ds-icon--exclamation-circle ds-m-r-xs" />
      <div class="upload-message">{{$t('editor.video.upload.best.ratio')}}</div>
    </div>
    <input
      class="ds-input"
      type="file" ref="file"
      @click="checkSessionExpiration"
      @change="handleFilesUpload($refs.file.files[0])"
    />
    <PEErrorLostComponent v-if="errorMessages.length > 0" :errorMessages="errorMessages" />
    <div v-if="loading" class="ds-upload__block__button__text--loading ds-m-l">
      <i class="ds-loading-indicator m-r-xs ds-m-r-s"></i>
      <div>{{$t('editor.logo.upload.popup.form.upload.message.postfix')}}
        {{getTruncatedUploadingFileName(fileName)}}</div>
    </div>
    <div
      v-if="!loading && errorMessages.length === 0"
      class="pf-upload__block__divider" v-html="$t(titleTrunslationKeyMessage)">
    </div>
    <div v-if="!loading" class="pf-upload__block__text">
      <div
        class="ds-upload__block__text"
        :class="errorMessages.length === 0?'':'pf-upload__block__text__error'"
      >{{$t(supportedFilesTrunslationKeyMessage)}}</div>
      <div
        class="ds-upload__block__text"
        :class="errorMessages.length === 0?'':'pf-upload__block__text__error'"
      >{{$t(fileSizeTrunslationKeyMessage)}}</div>
    </div>
    <button v-if="!loading" type="button" class="ds-upload__block__button">
      <div class="ds-upload__block__button__text">{{$t(buttonText)}}</div>
    </button>
  </div>
</template>

<style scoped lang="less">
.pf-upload__block_logo {
  min-height: 176px;
}
.pf-upload__block_video {
  min-width: 608px;
  min-height: 342px;
}
.ds-upload__block {
  justify-content: center;
  align-items: center;
  &:hover {
    .ds-upload__block__button{
      background-color: @color-link__hover !important;
    }
  }
  .ds-upload__block-uploaded {
    .ds-icon--checked {
      &:before {
        font-size: 24px;
      }
    }
    .ds-icon--exclamation-circle {
      &:before {
        font-size: 24px;
      }
    }
    display: flex;
    margin: 0 0 0 8px;
    font-size: 16px;
    color: @color-green;
  }
  .ds-upload__block-warning {
    color: @light-orange !important;
  }
  .upload-message {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
  }
  .ds-upload__block__button__text--loading {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    min-width: 80%;
    div {
      text-align: start;
      max-width: 80%;
    }
  }
  .pf-upload__block__divider {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
    color: #3c3c3c;
    padding: 0 0 12px 0;
  }
  .pf-upload__block__text {
    &:extend(.font-normal);
    padding: 0 0 12px 0;
    width: 100%;
    .ds-upload__block__text {
      text-align: center;
    }
    .pf-upload__block__text__error {
      color: #a4360e;
    }
  }
  .ds-upload__block__button {
    height: 42px !important;
    max-width: none;
    background-color: @color-link !important;

    .ds-upload__block__button__text {
      &:extend(.font-normal);
      color: #ffffff;
    }
  }
}
</style>
