<script>
import { mapState } from 'vuex';
import PFBannerComponent from '@/components/common/PFBannerComponent';

const IE_BROWSER_PAGE = 'browserNotSupportedPage';

export default {
  name: 'PFHomePage',
  components: {
    PFBannerComponent,
  },
  data: () => ({
    showDisclaimer: true,
  }),
  beforeCreate() {
    if (/Trident\/|MSIE /i.test(navigator.userAgent)) {
      this.$router.replace({
        name: IE_BROWSER_PAGE,
      }).catch(() => {});
    }
  },
  computed: {
    ...mapState('profileSearch', {
      pathChangedFromUiFlag: (state) => state.pathChangedFromUiFlag,
    }),
  },
  mounted() {
    this.showDisclaimer = true;
  },
  methods: {
    closeDisclaimer() {
      this.showDisclaimer = false;
    },
  },
  watch: {
    $route: {
      handler(newValue, oldValue) {
        if (oldValue?.fullPath !== newValue.fullPath && newValue.name != 'partnerNavigatorPage') {
          if (this.pathChangedFromUiFlag) {
            this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', false);
          } else {
            this.$store.dispatch('profileSearch/restoreSelection');
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<template>
<div class="pf-home ds-flexCol ds-p-none">
  <PFBannerComponent />
</div>
</template>
