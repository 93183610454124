<script>
import { mapState } from 'vuex';
import PFIECompetencyModalComponent from './PFIECompetencyModalComponent';

export default {
  name: 'PFIECompetencyItemComponent',
  data: () => ({
    showProfileCompetencyModal: { status: false, current: 0 },
    // mockBusinessProcesses: [1, 2],
  }),
  props: {
    ieCompetency: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState('solutionL2Store', {
      solutionDataMap: (state) => state.solutionDataMap,
    }),
  },
  methods: {
    toggleModal(val, obj = -1) {
      if (obj !== -1) {
        this.showProfileCompetencyModal.current = obj;
      }
      this.showProfileCompetencyModal.status = val;
      if (val) {
        this.emitAdobeEvent(this.ieCompetency[obj].name);
      }
    },
    emitAdobeEvent(name) {
      if (this.$route.name === 'profilePage') {
        this.$eventBus.$emit('PFAACustomEvent',
          { eventName: 'ClickModal', custom2: `IECompetency_${name}` });
      }
    },
    toggleShowProfileCompetencyModal() {
      this.showProfileCompetencyModal.status = !this.showProfileCompetencyModal.status;
    },
    ifCompetencySolutionLevelEqual(sol, target) {
      if (!sol.hasCompetency) return false;
      return sol.level.toUpperCase() === target;
    },
    sortL2Solution(solL2, solL1Key) {
      return [...solL2].sort((a, b) => this.solutionDataMap.get(solL1Key).L2Data.get(a.solutionL2Key).order
          - this.solutionDataMap.get(solL1Key).L2Data.get(b.solutionL2Key).order);
    },
  },
  components: {
    PFIECompetencyModalComponent,
  },
};
</script>

<template>
<div>
<div class="process-group competency-content" v-for="(comp, idx) in ieCompetency"
    :key="comp.id+'-competency-'+idx">
    <div class="process-item" @click.prevent="toggleModal(true,idx)">
      <div class="competency-header-font ds-m-b-xxs"> {{ comp.name }}</div>
      <div class="description-text"> {{ comp.description }}</div>
    </div>
    <PFIECompetencyModalComponent
      v-if="this.showProfileCompetencyModal.status && this.showProfileCompetencyModal.current === idx"
      :ieCompetency="comp"
      :toggleModal="toggleModal"
    />
</div>
</div>
</template>

<style lang="less" scoped>

.process-item {
  width: 321px;
}

.process-group {
display: flex;
justify-content: left;
margin-bottom: 14px;
}

.competency-header-font {
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  &:extend(.font-expanded);
}

.description-text {
  &:extend(.font-normal);
  font-size: 14px;
  line-height: 21px;
  color: @color-gray-1;
  cursor: pointer;
  margin-bottom: 3px;
}

.profile-competency-more-text:hover,.competency-content:hover {
  color: @color-link__hover;
  .competency-header, .description-text {
    color: @color-link !important;
  }
  .competency-level-img {
    padding-left: 90px;
  }
  .competency-level1-img {
    background: url('../../assets/Competency-lvl1-hover.png') no-repeat;
    background-size: 90px 16px;
  }
  .competency-level2-img {
    background: url('../../assets/Competency-lvl2-hover.png') no-repeat;
    background-size: 90px 16px;
  }
  .competency-level3-img {
    background: url('../../assets/Competency-lvl3-hover.png') no-repeat;
    background-size: 90px 16px;
  }
}
</style>
