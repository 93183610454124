<script>
import PFCompetencyModalComponent from './PFCompetencyModalComponent';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFCompetencyServiceComponent',
  data: () => ({
    showProfileCompetencyModal: false,
    faqLink: urlLinks.LEARN_MORE_COMPETENCY,
    tooltipOpen: false,
    showAllCompentency: false,
    DEFAULT_COMPETENCY_NUM: 3,
    competency_modal_data: {},
  }),
  props: {
    competencies: { type: Array, default: () => [] },
  },
  components: {
    PFCompetencyModalComponent,
  },
  methods: {
    toggleModal(val) {
      this.showProfileCompetencyModal = val;
    },
    openCompetencyModal(competency) {
      this.competency_modal_data = competency;
      this.toggleModal(true);
    },
    toggleTooltip() {
      this.tooltipOpen = false;
    },
    openLearnMore() {
      const win = window.open(this.faqLink, '_blank');
      win.focus();
    },
    emitSWAEvent() {
      if (this.$route.name === 'profilePage') {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$eventBus.$emit('PFAACustomEvent',
          { eventName: 'ClickModal', custom2: 'Partner_Competency' });
      }
    },
  },
  computed: {
    displayCompetencies() {
      if (!this.competencies) {
        return [];
      }
      if (this.showAllCompentency) {
        return this.competencies;
      }
      return this.competencies.slice(0, this.DEFAULT_COMPETENCY_NUM);
    },
    moreCompetencies() {
      if (this.competencies) {
        return this.competencies.length - this.displayCompetencies.length;
      }
      return 0;
    },
  },
};
</script>

<template>
  <div class="profile-competency-modal-container">
    <h3 class="ds-heading--s item-list--heading ds-m-t-s ds-m-b-xxs default-cursor">
        <div class="competency-section-header" id="competency-section-header-for-scoll">
          {{$t('competency.specialization')}}
        <div class="tool-tip-container" v-click-outside="toggleTooltip">
          <i class="ds-icon ds-icon--info tool-tip-icon ds-m-r-xs"
        :class="tooltipOpen ? 'tool-tip-icon-clicked' : ''" @click="tooltipOpen=!tooltipOpen"></i>
        <div class='ds-tooltip-icon toolitp-icon-item-list'>
            <div
              :class="[tooltipOpen ? 'display-block' :
              'display-none','tooltiptext','ds-tooltip-icon__tooltip--service']">
              {{ $t('competency.service') }}
              <span @click="openLearnMore" class="help-text-link">
                <b> {{$t('learn.more')}}</b>
              </span>
            </div>
          </div>
        </div>
        </div>
    </h3>
    <div class="ds-m-t-xs competency-content"
      v-for="(competency, idx) in displayCompetencies" :key="competency.name+'-service-'+idx"
       @click="openCompetencyModal(competency);emitSWAEvent()">
      <div class="competency-header ds-flexGrid ds-m-none ds-p-none">
        <div class="ds-flexRow ds-m-none ds-p-none">
          <div class="ds-flexCol-8 ds-m-none ds-p-none competency-header-font">{{competency.name}}</div>
          <div class="ds-flexCol-4 ds-m-none ds-p-none">
            <div class="competency-level-img-container ds-m-l-s">
              <img v-if="competency.level.toUpperCase() === 'ESSENTIAL'" alt=""
                class="competency-level-img competency-level1-img" src="../../assets/Competency-Level1.png"/>
              <img v-if="competency.level.toUpperCase() === 'ADVANCED'" alt=""
                class="competency-level-img competency-level2-img" src="../../assets/Competency-Level2.png"/>
              <img v-if="competency.level.toUpperCase() === 'EXPERT'" alt=""
                class="competency-level-img competency-level3-img" src="../../assets/Competency-Level3.png"/>
            </div>
          </div>
        </div>
      </div>
          <div v-for="(specialization, idx) in competency.specializations"
            :key="competency.name+specialization.name+'-service-'+idx"
            class="specialization-text">
             &nbsp;&nbsp;&#8226;&nbsp;&nbsp;{{specialization.name}}</div>
    </div>
    <div class="profile-competency-more-text ds-m-t-xs"
                v-if="competencies.length > DEFAULT_COMPETENCY_NUM"
                @click="showAllCompentency=!showAllCompentency">
                {{ showAllCompentency ? $t('show.less') : (moreCompetencies+$t('title.brand.names.more')) }}
    </div>
    <PFCompetencyModalComponent v-if="showProfileCompetencyModal"
      :toggleModal="toggleModal"
      :modalHeader="competency_modal_data.name"
      :competencyLevel="competency_modal_data.level"
      :specializations="competency_modal_data.specializations"
      :countries="competency_modal_data.countries"
      :industries="competency_modal_data.industries"/>
  </div>
</template>

<style lang="less" scoped>
.competency-section-header {
  &:extend(.font-expanded);
  font-size: 21px;
  line-height: 27px;
  color: @color-grey-0;
  display: inline-block;
  position: relative;
}
.tool-tip-container {
  display: inline-block;
  position: relative;
}
.competency-header-font {
  &:extend(.font-expanded);
}
.competency-header {
  font-size: 16px;
  line-height: 24px;
  color: @color-grey-0;
  cursor: pointer;
}
.specialization-text {
  &:extend(.font-normal);
  font-size: 14px;
  line-height: 21px;
  color: @color-gray-1;
  cursor: pointer;
}
.profile-competency-more-text:hover,.competency-content:hover {
  color: @color-link__hover;
  .competency-header, .specialization-text {
    color: @color-link !important;
  }
  .competency-level-img {
    padding-left: 90px;
  }
  .competency-level1-img {
    background: url('../../assets/Competency-lvl1-hover.png') no-repeat;
    background-size: 90px 16px;
  }
  .competency-level2-img {
    background: url('../../assets/Competency-lvl2-hover.png') no-repeat;
    background-size: 90px 16px;
  }
  .competency-level3-img {
    background: url('../../assets/Competency-lvl3-hover.png') no-repeat;
    background-size: 90px 16px;
  }
}
.profile-competency-more-text {
  &:extend(.font-normal);
  color: @color-link;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
}
.competency-level-img-container {
  top: calc(50% - 6px);
  position: relative;
  height: 16px;
  width: 90px;
}
.competency-level-img {
  height: 16px;
  width: 90px;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
