import axios from 'axios';

import EncodeSearchTerm from '@/plugins/search/encodeSearchTerm';

const MIN_CHAR_QUERY_LENGTH = 3;
let controller;

const AutocompleteStore = {
  namespaced: true,
  state: {
    query: '',
    searchResults: [],
    openSearchDropdown: true,
    showSearchSuggestion: false,
    searchSuggestion: false,
    lastUsedQuery: false,
  },
  /*
   * Actions can be used to make async http calls ot fetch data, manipulate/process data and call
   * mutation functions to affect the state.
   */
  actions: {
    async getAutocompleteResults(context) {
      context.dispatch('autocomplete/abortController', '', { root: true });
      controller = new AbortController();

      const q = context.state.query;
      if (q !== '' && q.length >= MIN_CHAR_QUERY_LENGTH) {
        context.commit('setLastUsedQuery', q);
        const signal = controller.signal;
        const searchType = context.rootState.profileSearch.queryTermTypeState === 'keyword' ? 'keyword' : 'partner';
        await axios.get(`/sap/search/api/search/bm/suggestion?q=${EncodeSearchTerm.searchTermEncode(q)}&qField=${searchType}`,
          { signal })
          .then((results) => context.dispatch('autocomplete/cleanResults', results, { root: true }))
          .catch((err) => {
            if (!signal.aborted) {
              console.log(`response error: ${err}`);
            }
          });
        if (context.state.openSearchDropdown) {
          context.dispatch('openSearchSuggestion', context);
        } else {
          context.commit('setShowSearchSuggestion', false);
          context.commit('setOpenSearchDropdown', true);
        }
      } else {
        context.commit('setResults', []);
      }
    },

    abortController(context) {
      if (controller) {
        controller.abort();
      }
    },

    cleanResults(context, results) {
      results.data.length === 0 ? context.commit('setResults', []) : context.commit('setResults', results.data);
    },

    keyPressed(context, value) {
      context.commit('newValue', value);
      context.dispatch('openSearchSuggestion', context);
      context.dispatch('getAutocompleteResults', context);
    },

    clearResults(context) {
      context.commit('setShowSearchSuggestion', false);
      context.commit('newValue', '');
      context.commit('setResults', []);
      context.commit('setSearchSuggestion', false);
    },

    clearQuery(context) {
      context.commit('newValue', '');
      context.commit('setSearchSuggestion', false);
    },

    setQueryValue(context, value) {
      context.commit('newValue', value);
    },

    setShowSearchSuggestion(context, value) {
      context.commit('setShowSearchSuggestion', value);
    },

    openSearchSuggestion(context) {
      if (context.state.query.length >= MIN_CHAR_QUERY_LENGTH && context.state.searchResults.length > 0) {
        context.commit('setShowSearchSuggestion', true);
      } else {
        context.commit('setShowSearchSuggestion', false);
      }
    },
    setOpenSearchDropdown(context, value) {
      context.commit('setOpenSearchDropdown', value);
    },
  },

  /*
   * Mutations - and only mutator functions - should ever change the state explicitly!
   * Never update the state explicity from a component or another view without calling an
   * action. The called action can then be used for processing - if necessary - and ultimately
   * then calling a mutator function from within an action to commit a mutation to the store.
   */
  mutations: {
    newValue(context, value) {
      context.query = value;
    },
    setResults(context, results) {
      context.searchResults = results;
    },
    setFromHomePage(context, value) {
      context.fromHomePage = value;
    },
    setShowSearchSuggestion(context, value) {
      context.showSearchSuggestion = value;
    },
    setOpenSearchDropdown(context, value) {
      context.openSearchDropdown = value;
    },
    setSearchSuggestion(context, value) {
      context.searchSuggestion = value;
    },
    setLastUsedQuery(context, query) {
      context.lastUsedQuery = query;
    },
  },
};

export default AutocompleteStore;
