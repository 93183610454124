<script>
import { mapState } from 'vuex';
import PFCompetencyModalComponent from './PFCompetencyModalComponent';
import PFERPModalComponent from './PFERPModalComponent';

export default {
  name: 'PFCompetencyAtAGlanceComponent',
  data: () => ({
    showProfileCompetencyModal: false,
    competencyModalData: {},
    showERPModal: false,
    selectedERP: null,
  }),
  components: {
    PFCompetencyModalComponent,
    PFERPModalComponent,
  },
  props: {
    editMode: { type: Boolean, default: false },
    competencies: { type: Array, default: () => [] },
    growWithSap: { type: Boolean, default: false },
  },
  methods: {
    openERPModal(val, ERP = null) {
      this.showERPModal = val;
      this.selectedERP = ERP;
      if (val) {
        this.emitAdobeEvent(`ERP_${ERP.solutionL2Name}`);
      }
    },
    toggleModal(flag) {
      this.showProfileCompetencyModal = flag;
    },
    scrollTo(positionId) {
      const sectionEle = document.getElementById(
        `${positionId}`,
      );
      const partnerDetailHeaderOffsetHeight = document.getElementById(
        'partner-detail-header',
      ).offsetHeight;
      const editHeaderContainerEl = document.getElementsByClassName(
        'edit-header-container',
      )[0];
      const { top } = sectionEle.getBoundingClientRect();
      let result = window.scrollY === 0
        ? top
            - (editHeaderContainerEl ? editHeaderContainerEl.offsetHeight : 0)
        : top + window.scrollY;
      result -= partnerDetailHeaderOffsetHeight;
      if (editHeaderContainerEl) {
        result -= editHeaderContainerEl.offsetHeight;
      }
      window.scrollTo({ left: 0, top: result, behavior: 'smooth' });
    },
    getCompentencyModalData(competency) {
      if (competency.ERPComp) {
        this.openERPModal(true, competency);
      } else {
        this.competencyModalData = competency;
        this.toggleModal(true);
      }
    },
    emitAdobeEvent(name) {
      if (!this.editMode) {
        this.$eventBus.$emit('PFAACustomEvent',
          { eventName: 'ClickModal', custom2: `At_A_Glance_Competency_${name}` });
      }
    },

  },
  computed: {
    ...mapState('partnerProfile', {
      ieCompetency: (state) => state.profileData.ieCompetency,
      ERPCompetency: (state) => state.ERPCompetency,
    }),
    solutionWithCompetency() {
      const comp = this.competencies.filter((item) => item.hasCompetency
        && item.solutionL1Key !== 'PFERP'
        && item.solutionL1Key !== 'PFFIN'
        && item.level
        && item.level.toUpperCase() !== 'ZERO');
      const erp = this.ERPCompetency;
      const combined = [...erp, ...comp];
      return combined;
    },
    getIELength() {
      let lengthOfIE = this.ieCompetency?.ieSpecializations?.length;
      lengthOfIE = (!this.$hideIECompetencyEnv && lengthOfIE && lengthOfIE > 0) ? 1 : 0;
      return lengthOfIE;
    },
    getItems() {
      let sortedCompetencies = this.solutionWithCompetency;
      if (this.growWithSap) {
        const erp = this.ERPCompetency;
        const btp = sortedCompetencies.filter((c) => c.solutionL1Key === 'PFBTP');
        sortedCompetencies = [
          ...(erp || []),
          ...(btp || []),
          ...sortedCompetencies.filter((c) => !(['PFERP', 'PFBTP'].includes(c.solutionL1Key))),
        ];
      }
      if (sortedCompetencies.length + this.getIELength > 3) {
        return sortedCompetencies.slice(0, 2);
      }
      return sortedCompetencies;
    },
    getCompetencyLength() {
      return this.solutionWithCompetency.length + this.getIELength;
    },
  },
  watch: {
    getItems: {
      handler(newValue) {
        this.$emit('update-competency-exist', newValue && newValue.length > 0);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<template>
  <div class="competency-container" v-if="getItems && getItems.length > 0">
    <div class="competency-data-container partner-detail-at-glance-font"
      v-on:click="scrollTo('competency-section-header-for-scoll')">
      <span class="font-bold">{{getCompetencyLength}}</span>
      <span class="" v-html="$tc('search.result.competency', getCompetencyLength)"></span>
      <img alt="Right Arrow" class="competency-right-arrow" src="../../assets/Right_Arrow_Icon.png"/>
    </div>
    <PFERPModalComponent
            v-if="showERPModal"
            :toggleModal="openERPModal"
            :modalHeader="selectedERP.solutionL2Name"
            :hasCompetency="selectedERP.specializationAvailable"
            :competencyLevel="selectedERP.level"
            :L2="selectedERP"/>
    <PFCompetencyModalComponent v-if="showProfileCompetencyModal"
      :hasCompetency="competencyModalData.hasCompetency"
      :toggleModal="toggleModal"
      :modalHeader="competencyModalData.solutionL1Name"
      :competencyLevel="competencyModalData.hasCompetency ? competencyModalData.level : ''"
      :L2s="competencyModalData.L2Solution"
      :specializations="competencyModalData.specializations"
      :countries="competencyModalData.hasCompetency ? competencyModalData.countries : {}"
      :industries="competencyModalData.hasCompetency ? competencyModalData.industries : {}"
    />
  </div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";
.competency-container {
  display: -moz-box;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  min-height: 0 !important;
  cursor: default;
}
.competency-data-container {
  display: flex;
  flex-direction: row;
  column-gap: 4px;
  align-items: center;
  width: fit-content;
}
.competency-data-container:hover {
  cursor: pointer;
  color: @color-link;
}
.competency-right-arrow {
  width: 16px;
  height: 16px;
  margin: 4px 0;
}

</style>
