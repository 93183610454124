<script>
export default {
  name: 'PFAlternativeNamesComponent',
  props: {
    brandNames: { type: Array, default: () => [] },
    title: { type: String, default: '' },
  },
  data: () => ({
    alternativeNamesModalOpen: false,
    editedBrandNames: [],
    favouriteName: '',
  }),
  computed: {
    query: {
      get() {
        return this.$store.state.autocomplete.query;
      },
    },
  },
  mounted() {
    this.editedBrandNames = this.sortedBrandNames();
    this.matchKeywordBrandName();
  },
  methods: {
    toggleAlternativeNamesModal() {
      this.alternativeNamesModalOpen = !this.alternativeNamesModalOpen;
    },
    sortedBrandNames() {
      const tempBrandNames = this.brandNames.slice();
      tempBrandNames.sort((a, b) => a.brandName.localeCompare(b.brandName));
      for (let i = 0; i < tempBrandNames.length; i++) {
        if (tempBrandNames[i].isFavoriteName) {
          this.favouriteName = tempBrandNames[i].brandName;
          const buffer = tempBrandNames[i];
          tempBrandNames.splice(i, 1);
          tempBrandNames.unshift(buffer);
          break;
        }
      }
      return tempBrandNames;
    },
    matchKeywordBrandName() {
      const searchValueRegExp = new RegExp(
        this.query.replace(/[*%.\\[\](){}+?\-^$|]/g, ''),
        'ig',
      );
      if (String(searchValueRegExp) === '/(?:)/gi') {
        return;
      }
      const findName = this.editedBrandNames.find((brandNameObj) => brandNameObj.brandName.match(searchValueRegExp));
      if (findName) {
        this.favouriteName = findName.brandName;
      }
    },
    isOverflown() {
      this.$nextTick(() => {
        if (
          this.$refs.modalBrandNameContent.scrollHeight
          > this.$refs.modalBrandNameContent.clientHeight
        ) {
          this.$refs.modalBrandNameHeader.style.borderBottom = '1px solid #c6c6c6';
          this.$refs.modalBrandNameActionBar.style.borderTop = '1px solid #c6c6c6';
        } else {
          this.$refs.modalBrandNameHeader.style.borderBottom = 'none';
          this.$refs.modalBrandNameActionBar.style.borderTop = 'none';
        }
      });
    },
  },
  watch: {
    alternativeNamesModalOpen(val) {
      if (val) {
        this.isOverflown();
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      } else {
        document.body.style.overflow = 'scroll';
        document.body.style.paddingRight = '0px';
      }
    },
  },
};
</script>

<template>
  <div class="alt-names">
    <div class="partner-name--brand-names">
      <a
        class="partner-name--brand-names"
        href=""
        @click.prevent="toggleAlternativeNamesModal()"
      >
        <div class="partner-name--brand-names-text">
          <span v-if="!favouriteName" class="alt-name-by-default">{{ brandNames.length }}
          {{ $tc('title.brand.names.more.names', brandNames.length) }}</span>
          <span v-else class="alt-name-by-favourite-brand">{{ favouriteName }}
            <span v-if="brandNames.length > 1" class="alt-name-by-favourite-and-more">
            {{ $t('alt.name.title.and')}}{{brandNames.length -1}}{{ $t('title.brand.names.more')}}</span></span>
        </div>
      </a>
    </div>
    <div
      class="ds-modal ds-modal--scrollable ds-modal--active"
      v-if="alternativeNamesModalOpen"
      tabindex="0"
      @keyup.esc="toggleAlternativeNamesModal()"
    >
      <div class="modal-container">
        <div class="modal-container-inner-upload" id="alt-names-modal">
          <div class="ds-modal__box alt-names-modal-box">
            <div class="ds-modal__box__header" ref="modalBrandNameHeader">
              <span class="ds-modal__box__header__heading">{{ title }}</span>
              <i
                class="ds-icon ds-icon--close modal-close-icon modal-icon"
                @click="toggleAlternativeNamesModal()"
              ></i>
            </div>
            <div class="ds-modal__box__content" ref="modalBrandNameContent">
              <div class="ds-m-b-m alt-name-main-first">
                {{ $tc('alt.name.main.first', brandNames.length) }}
              </div>
              <div
                class="alt-name-main-content-list"
                v-for="info of editedBrandNames"
                :key="info.id"
              >
                <div class="alt-name-main-content-name">
                  {{ info.brandName }}
                </div>
                <div class="alt-name-main-content-description">
                  {{ info.shortDescription }}
                </div>
              </div>
            </div>
            <div
              class="ds-modal__box__action-bar"
              ref="modalBrandNameActionBar"
            >
              <button
                class="
                  ds-modal__box__action-bar__action
                  ds-button ds-button--primary
                "
                type="button"
                @click="toggleAlternativeNamesModal()"
              >
                {{ $t('button.close') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.alt-names{
  min-width: 150px;
  width: 100%;

.partner-name--brand-names {
  position: static;
}
.partner-name--brand-names :hover {
  color: @color-gray-1;
}
.partner-name--brand-names-text {
  &:extend(.font-normal);
  font-size: 16px;
  color: @color-gray-2;
}
.alt-names-modal {
  display: none;
}
.ds-modal--active {
  z-index: 200;
}
.modal-container-inner-upload {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 250px);
}
.alt-names-modal-box {
  max-width: 669px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  max-height: 572px;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}
.ds-modal__box__header__heading {
  padding-right: 40px !important;
  &:extend(.font-expanded);
  font-size: 27px;
  line-height: 35px;
}
  .ds-modal__box__content {
    padding-right: 40px !important;
    margin-right: 10px;
    }

.alt-name-main-first {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0px !important;
}
.alt-name-main-content-name {
  font-family: 'BentonSansMedium';
  font-size: 16px;
  line-height: 24px;
  margin-top: 20px;
}
.alt-name-main-content-description {
  &:extend(.font-normal);
  font-size: 14px;
  line-height: 21px;
}
.modal-icon {
  line-height: 200%;
  color: @color-gray-2;
}
.modal-icon:hover {
  color: @color-gray-1;
  cursor: pointer;
}
.alt-name-by-favourite-and-more{
  font-size: 12px;
}
}
</style>
