<script>
import { mapState } from 'vuex';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PEProfileNotificationComponent',
  data: () => ({
    showProfileNotificationModal: false,
    faqLink: urlLinks.FOOTER.FAQ_PROFILE,
    showAllAccount: false,
    maxDefaultShowNum: 9,
    maxProfileAddrChar: 15,
    modalProfileAddrWidth: 118,
    modalProfileNameWidth: 354,
  }),
  methods: {
    toggleModal(val) {
      this.showProfileNotificationModal = val;
    },
    getMaxNameLength(id) {
      return Math.round(this.modalProfileNameWidth / 9) - (id ? (id.length + 2) : 0);
    },
    onResize() {
      this.modalProfileNameWidth = this.$refs.modalProfileName0[0].clientWidth;
      this.modalProfileAddrWidth = this.$refs.modalProfileAddr0[0].clientWidth;
      this.maxProfileAddrChar = Math.round(this.modalProfileAddrWidth / 8);
    },
    isOverflown() {
      this.$nextTick(() => {
        if (
          this.$refs.modalProfileNotificationContent.scrollHeight
          > this.$refs.modalProfileNotificationContent.clientHeight
        ) {
          this.$refs.modalProfileNotificationHeader.style.borderBottom = '1px solid #c6c6c6';
          this.$refs.modalProfileNotificationActionBar.style.borderTop = '1px solid #c6c6c6';
        } else {
          this.$refs.modalProfileNotificationHeader.style.borderBottom = 'none';
          this.$refs.modalProfileNotificationActionBar.style.borderTop = 'none';
        }
      });
    },
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
    getTruncatedText(text, maxChar) {
      if (text && text.length > maxChar) { return `${text.substring(0, maxChar - 3)}...`; }
      return text;
    },
    sortByCountry(arr) {
      return arr.sort((a, b) => a.countryName.localeCompare(b.countryName));
    },
  },
  watch: {
    showProfileNotificationModal(val) {
      if (val) {
        this.isOverflown();
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      } else {
        document.body.style.overflow = 'scroll';
        document.body.style.paddingRight = '0px';
      }
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      profileAccounts: (state) => state.profileData.offices,
    }),
    profileAccountsDisplay() {
      if (!this.profileAccounts) {
        return [];
      }
      if (this.showAllAccount) {
        return this.sortByCountry([...this.profileAccounts]);
      }
      return this.sortByCountry([...this.profileAccounts]).slice(0, this.maxDefaultShowNum);
    },
    moreProfiles() {
      if (this.profileAccounts) {
        return this.profileAccounts.length - this.profileAccountsDisplay.length;
      }
      return 0;
    },
  },
};
</script>

<template>
  <div class="profile-notification-container">
    <div class="profile-notification-button"  @click="toggleModal(true)">
      <i class="ds-icon ds-icon--info profile-notification-icon ds-m-r-xs"></i>
      <span class="profile-notification-button-text">{{ $t('editor.profile.notification.header') }}</span>
    </div>
    <div class="ds-modal ds-modal--active ds-modal--scrollable" v-if="showProfileNotificationModal"
         ref="profileNotificationModal" tabindex="0" @keyup.esc="toggleModal(false)">
      <div class="modal-container">
      <div class="modal-container-inner-upload" id="profile-notification-modal">
      <div class="ds-modal__box profile-notification-modal">
        <div class="ds-modal__box__header" ref="modalProfileNotificationHeader">
          <span class="ds-modal__box__header__heading">{{ $t('editor.profile.notification.header') }}</span>
            <i
              class="ds-icon ds-icon--close modal-close-icon modal-icon ds-icon-profile-close"
              @click="toggleModal(false)"
            ></i>
        </div>
        <div class="ds-modal__box__content ds-m-none ds-m-l-l ds-m-r-s ds-m-y-m"
        ref="modalProfileNotificationContent">
          <div class="profile-notification-text ds-m-b-s">
            {{ $t("editor.profile.notification.text") }}
          </div>
          <resize-observer @notify="this.onResize"></resize-observer>
          <div class="flex-content-container ds-flexGrid ds-m-none ds-p-none">
              <div v-for="(account, idx) in profileAccountsDisplay"
                :key="account.profileId + '-notification-' + idx"
                class="profile-notification-text-small ds-flexRow ds-m-none ds-p-none">
                <div class="ds-flexCol-9 ds-m-none ds-p-none profile-notification-text-small"
                  :ref="'modalProfileName'+idx">
                  <span class="profile-name-text"> &nbsp; &#8226;
                  {{getTruncatedText(account.partnerLocalName, getMaxNameLength(account.profileId))}}</span>
                  ({{account.profileId}})
                </div>
                <div class="ds-flexCol-3 ds-m-none ds-p-none" :ref="'modalProfileAddr'+idx">
                  <span class="profile-notification-text-small ds-m-l-xxs">
                    {{getTruncatedText(account.countryName, maxProfileAddrChar)}}</span>
                </div>
              </div>
          </div>
          <div class="profile-notification-more-text"
                v-if="this.profileAccounts && moreProfiles > 0"
                @click="showAllAccount=true">
                &nbsp; &nbsp;{{ moreProfiles }} {{ $tc('editor.accounts.more', moreProfiles)}}
          </div>
          <div class="profile-notification-text ds-m-y-s">
            <span>{{ $t("editor.profile.notification.text.faq") }}</span>
            <a
              href=""
              @click.prevent="openInNewTab(faqLink)">
              {{ $t("editor.FAQ") }}
            </a>
          </div>
          <div class="profile-notification-text">
            {{ $t("editor.profile.notification.text2") }}
          </div>
        </div>
        <div class="ds-modal__box__action-bar" ref="modalProfileNotificationActionBar">
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary ds-m-l-m"
            type="button"
            @click="toggleModal(false)"
            id="profile-notification-button__cancel"
          >
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.profile-addr-column {
  min-width: 63px;
  margin-left: 4px;
}
.profile-notification-button {
  margin-left: 52px;
  color: @color-link;
  &:extend(.font-expanded);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  .ds-icon::before {
    line-height: 80px;
    font-size: 24px;
  }
  .profile-notification-button-text {
    line-height: 80px;
    font-size: 16px;
  }
}
.profile-notification-more-text:hover,.profile-notification-button:hover {
  color: @color-link__hover;
}
.profile-notification-more-text {
  &:extend(.font-normal);
  color: @color-link;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  white-space: pre-wrap;
}
.profile-notification-text {
  &:extend(.font-normal);
  color: @color-grey-0;
  font-size: 16px;
  line-height: 24px;
}
.profile-notification-text-small {
  &:extend(.font-normal);
  color: @color-grey-0;
  font-size: 14px;
  line-height: 24px;
}
.profile-name-text {
  &:extend(.font-bold);
}
.modal-icon {
  line-height: 200%;
  color: @color-gray-2;
}
.modal-icon:hover {
  color: @color-gray-1;
  cursor: pointer;
}
.ds-modal--active {
  z-index: 200;
}
.modal-container-inner-upload {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 250px);
}
.profile-notification-modal {
  max-width: 564px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  max-height: 572px;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}
.ds-modal__box__header__heading {
  padding-right: 40px !important;
  &:extend(.font-expanded);
  font-size: 27px;
  line-height: 35px;
}
.ds-icon-profile-close::before {
    font-size: 19px;
    font-weight: 800;
}
.ds-modal__box__content {
  padding-right: 40px !important;
  margin-right: 10px;
}
</style>
