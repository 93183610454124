<script>
import { mapState } from 'vuex';
import PFAddressesComponent from '@/components/common/PFAddressesComponent';
import PFSocialMediasBarComponent from '@/components/common/PFSocialMediasBarComponent';
import PEAddressesComponent from '@/components/common/PEAddressesComponent';

const urlLinks = require('@/plugins/linkUrl');

const FAQ_LINK = urlLinks.FAQ;
const MMP_LINK = urlLinks.MANAGE_MY_PARTNER;

export default {
  name: 'PFOfficesComponent',
  components: {
    PFAddressesComponent,
    PFSocialMediasBarComponent,
    PEAddressesComponent,
  },
  data: () => ({
    emptyAddress: false,
    componentName: '',
    faqLink: FAQ_LINK,
    mmpLink: MMP_LINK,
  }),
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    countryProfile: {
      type: Object,
      default: () => {},
    },
    partnerProfileId: { type: String },
    singleLocalProfileView: {
      type: Boolean,
      default: false,
    },
    multipleCountries: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      mountedComponent: (state) => state.mountedComponent,
    }),
    ...mapState('fullData', {
      fullCountry: (state) => state.allowedCountries,
    }),
    hasAddress() {
      return this.countryProfile.addresses && this.countryProfile.addresses.length > 0;
    },
    allowedCountryName() {
      const countryCode = this.countryProfile.country;
      const countryName = this.fullCountry.find((country) => country.country === countryCode);
      return countryName ? countryName.text : this.countryProfile.countryName;
    },
  },
  mounted() {
    if (this.countryProfile && this.countryProfile.country) {
      this.componentName = this.$options.name + this.countryProfile.country;
      if (this.mountedComponent.includes(this.componentName)) {
        this.emptyAddress = !this.hasAddress;
        this.addRemoveOfficeErr(this.emptyAddress);
      } else {
        this.$store.dispatch(
          'partnerProfile/addMountedComponent',
          this.componentName,
        );
      }
    }
    this.$eventBus.$on('*', (type) => {
      if (['footerSaveClicked', 'footerPublishClicked', 'editorToggleButtonClicked'].includes(type)) {
        this.emptyAddress = !this.hasAddress;
      }
    });
  },
  methods: {
    generateValidationError() {
      return {
        id: this.generateErrorId(),
        country: this.countryProfile.country,
        field: this.singleLocalProfileView ? `${this.$t('section.locations.title.offices')}`
          : `${this.allowedCountryName}: ${this.$t(
            'section.locations.title.offices',
          )}`,
        message: this.$t('local.office.select.error.message'),
      };
    },
    generateErrorId() {
      return `${this.countryProfile.country}OfficesMandatoryErr`;
    },
    addRemoveOfficeErr(val) {
      if (val) {
        this.$store.dispatch('partnerProfile/addErrorField', this.generateValidationError());
      } else {
        this.$store.dispatch('partnerProfile/removeErrorField', this.generateErrorId());
      }
    },
  },
  watch: {
    emptyAddress(val) {
      this.addRemoveOfficeErr(val);
    },
    hasAddress() {
      if (this.mountedComponent.includes(this.componentName)) {
        this.emptyAddress = !this.hasAddress;
      }
    },
  },
};
</script>
<template>
  <div class="ds-flexRow">
    <div
      class="office-heading-container ds-m-t-xl"
      :class="singleLocalProfileView ? '' : 'ds-m-b-s'"
    >
      <h5 v-if="multipleCountries" class="ds-heading--s offices__title ds-m-none">
        {{ $t('section.locations.title.offices') }}
      </h5>
    </div>
    <PFSocialMediasBarComponent
      v-if="!singleLocalProfileView"
      :editMode="editMode"
      :countryName="countryProfile.country"
    />
    <div v-show="editMode" class="ds-flexCol-12 ds-m-t-m ds-p-none">
      <div class="office-guidance-text center-office-guidance-text ds-m-b-m">
        <div :class="!emptyAddress ? '' : 'office-field-error__message'">
          <i v-show="emptyAddress " class="ds-icon--close-outline ds-p-none ds-m-r-xs"></i>
          <div>{{ $t('local.office.single.message.a', { country: allowedCountryName }) }}</div>
        </div>
      </div>
    </div>
    <PFAddressesComponent
      v-show="!editMode"
      :addresses="countryProfile.addresses"
    />
    <PEAddressesComponent
      v-if="editMode"
      :partnerProfileId="partnerProfileId"
      :localProfileId="countryProfile.id"
      :addresses="countryProfile.addresses"
      :countryName="countryProfile.country"
      :countryCodeText="allowedCountryName"
      :multipleCountries="multipleCountries"
    />
  </div>
</template>
<style lang="less">
.office-heading-container {
  width: fit-content;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.offices__title {
  width: 100%;
  display: block;
  text-align: center;
  &:extend(.font-normal);
}
.office-field-error__message {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
  color: @color-field-error;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  .ds-icon--close-outline {
    padding-left: 25px;
    font-size: 24px;
  }
}
.office-guidance-text {
  font-size: 16px;
  line-height: 24px;
  &:extend(.font-normal);
  color: @color-gray-1;
  cursor: default;
  a {
    &:extend(.font-normal);
    color: @color-link;
  }
}
.center-office-guidance-text {
  margin-left: auto;
  margin-right: auto;
  max-width: 778px;
}
</style>
