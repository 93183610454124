import axios from 'axios';

const ROOT_ENDPOINT = '/sap/details/api/downtimes/active';

function getUserLocalTime(plannedTime) {
  const myDate = new Date(plannedTime);
  const options = { dateStyle: 'long', timeStyle: 'short', hour12: false };
  return myDate.toLocaleString('en-GB', options);
}

const SystemMaintenanceStore = {
  namespaced: true,
  state: {
    header: '',
    id: '',
    message: '',
    activeMaintenance: [],
    enableMaintenanceBanner: false,
    plannedStartTime: '',
    plannedEndTime: '',
    plannedShowTime: '',
    system: '',
  },
  actions: {
    async fetchActiveMaintenanceData(context) {
      await axios.get(ROOT_ENDPOINT)
        .then((result) => {
          context.commit('setActiveMaintenance', result.data);
          if (result.data.length > 0) {
            context.commit('setEnableMaintenanceBanner', true);
            const startTime = result.data[0].plannedStartDatetime
              ? getUserLocalTime(result.data[0].plannedStartDatetime) : null;
            const endTime = getUserLocalTime(result.data[0].plannedEndDatetime);
            const header = result.data[0].header;
            const message = result.data[0].message;
            context.commit('setPlannedStartTime', startTime);
            context.commit('setPlannedEndTime', endTime);
            context.commit('setHeader', header);
            context.commit('setMessage', message);
          }
        });
    },
  },
  mutations: {
    setActiveMaintenance(context, val) {
      context.activeMaintenance = val;
    },
    setEnableMaintenanceBanner(context, val) {
      context.enableMaintenanceBanner = val;
    },
    setPlannedStartTime(context, val) {
      context.plannedStartTime = val;
    },
    setPlannedEndTime(context, val) {
      context.plannedEndTime = val;
    },
    setHeader(context, val) {
      context.header = val;
    },
    setMessage(context, val) {
      context.message = val;
    },
  },
};

export default SystemMaintenanceStore;
