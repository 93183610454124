<script>
export default {
  name: 'PFLinkComponent',
  props: ['link', 'target'],
};
</script>

<template>
    <a v-bind:href="link.link" v-bind:target="target">
            {{link.title}}
    </a>
</template>
