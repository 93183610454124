<script>
export default {
  name: 'PFConsultantTableComponent',
  props: {
    consultant: { type: Array, default: () => [] },
  },

};
</script>

<template>
  <table class="ds-table">
    <thead>
      <tr>
        <th class="ds-table__horizontal-heading consultant-heading" scope="col">
          {{$t('service.consultant.table.solution')}}
        </th>
        <th class="ds-table__horizontal-heading consultant-heading" scope="col">
          {{$t('service.consultant.table.associates')}}
        </th>
        <th class="ds-table__horizontal-heading consultant-heading" scope="col">
          {{$t('service.consultant.table.professionals')}}
        </th>
        <th class="ds-table__horizontal-heading consultant-heading" scope="col">
          {{$t('service.consultant.table.specialists')}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="data in consultant" :key="data.solutionL3Name">
        <td class="solution-name">{{data.solutionL3Name}}</td>
        <td class="consultant-count">{{data.associate === 0 ? '' : data.associate}}</td>
        <td class="consultant-count">{{data.professional === 0 ? '' : data.professional}}</td>
        <td class="consultant-count">{{data.specialist === 0 ? '' : data.specialist}}</td>
      </tr>
    </tbody>
  </table>
</template>

<style lang="less" scoped>
  .ds-table {
    width: 100%;
  }

  .consultant-heading {
    &:extend(.font-normal);
    font-size: 12px;
  }

  .solution-name {
    &:extend(.font-normal);
    font-size: 12px;
    vertical-align: middle;
    width: 40%;
  }

  .consultant-count {
    &:extend(.font-normal);
    vertical-align: middle;
    font-size: 16px;
  }
</style>
