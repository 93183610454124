import axios from 'axios';

const PartnerConsultantsStorage = {
  namespaced: true,
  state: {
    consultants: {},
  },
  actions: {
    async findByProfileId(context, { profileId, country }) {
      const queryCountry = country ? `?country=${country}` : '';
      const destinationEndPoint = `/sap/odp/api/profiles/${profileId}/consultants${queryCountry}`;
      if (!country && context.state.consultants.root) {
        return;
      }
      const countryContent = context.state.consultants.data;
      if (countryContent && countryContent[country]) {
        return;
      }
      context.commit('partnerProfile/setLoading', true, { root: true });
      await axios.get(destinationEndPoint)
        .then((result) => context.dispatch('dispatchResponseHandler', { country, results: result.data }))
        .catch((err) => {
          context.commit('partnerProfile/setLoading', false, { root: true });
          throw new Error(`Response error: ${err}`);
        });
    },
    dispatchResponseHandler(context, { country, results }) {
      context.commit('partnerProfile/setLoading', false, { root: true });
      context.commit('setResults', { country, results });
    },
    reset(context) {
      context.commit('reset');
    },
  },
  mutations: {
    setResults(context, { country, results }) {
      if (country) {
        if (!context.consultants.data) {
          context.consultants.data = new Map();
        }
        context.consultants.data.set(country, results);
      } else {
        context.consultants.root = results;
      }
    },
    reset(context) {
      context.consultants = {};
    },
  },
};

export default PartnerConsultantsStorage;
