import { createStore as _createStore } from 'vuex';
import AddressAutocompleteStore from '@/store/modules/addressAutoComplete';
import AutocompleteStore from './modules/autocomplete';
import ChangelogStore from './modules/changelog';
import ContactEmailStore from './modules/contactEmail';
import FullDataStore from './modules/fullData';
import LocationAddressAutoCompleteStore from '@/store/modules/locationAddressAutoComplete';
import PartnerConsultantsStorage from './modules/consultant';
import PartnerProfileStorage from './modules/partnerProfile';
import PartnerProfilesSearch from './modules/profileSearch';
import SolutionL2Store from './modules/solutionL2';
import SystemMaintenanceStore from './modules/systemMaintenance';
import UserProfileStore from './modules/userProfile';

export default _createStore({
  strict: true, // This ensures that any changes to the store are only done via mutations
  modules: {
    addressAutoComplete: AddressAutocompleteStore,
    autocomplete: AutocompleteStore,
    changelog: ChangelogStore,
    contactEmail: ContactEmailStore,
    consultant: PartnerConsultantsStorage,
    fullData: FullDataStore,
    locationAddressAutoComplete: LocationAddressAutoCompleteStore,
    partnerProfile: PartnerProfileStorage,
    profileSearch: PartnerProfilesSearch,
    solutionL2Store: SolutionL2Store,
    systemMaintenance: SystemMaintenanceStore,
    userProfile: UserProfileStore,
  },
});
