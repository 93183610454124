import axios from 'axios';

const ROOT_ENDPOINT = '/sap/details/api';

const FullDataStore = {
  namespaced: true,
  state: {
    allIndustries: [],
    allSupportedLanguages: [],
    allCountries: {},
    allowedCountries: [],
    allBusinessModels: [],
    allSolutions: [],
  },
  actions: {
    async fetchAllData(context) {
      await axios.get(`${ROOT_ENDPOINT}/data/full`)
        .then((result) => context.dispatch('dispatchResponseHandler', result));
    },
    dispatchResponseHandler(context, results) {
      context.commit('setResults', results.data);
    },
    async fetchAllAllowedCountryData(context) {
      await axios.get(`${ROOT_ENDPOINT}/data/countries/allowed`)
        .then((result) => context.dispatch('dispatchAllowedCountryResponseHandler', result));
    },
    dispatchAllowedCountryResponseHandler(context, results) {
      context.commit('setAllowedCountryResults', results.data);
    },
  },
  mutations: {
    setResults(context, results) {
      if (results.industries) {
        Object.entries(results.industries).map((industry) => context.allIndustries.push({
          industryId: industry[0],
          title: industry[1],
        }));
      }
      if (results.engagement) {
        Object.entries(results.engagement).map((businessModel) => context.allBusinessModels.push({
          businessModelId: businessModel[0],
          title: businessModel[1],
        }));
      }
      if (results.languages) {
        Object.entries(results.languages).map((language) => context.allSupportedLanguages.push({
          language: language[0],
          text: language[1],
        }));
      }
      if (results.allowedCountries) {
        context.allCountries = results.allowedCountries;
        Object.entries(results.allowedCountries).map((country) => context.allowedCountries.push({
          country: country[0],
          text: country[1],
        }));
      }
    },
    setAllowedCountryResults(context, results) {
      if (results) {
        Object.entries(results).map((country) => context.allowedCountries.push({
          country: country[0],
          text: country[1],
        }));
      }
    },
  },
};

export default FullDataStore;
