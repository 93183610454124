<script>
import PFCloudCompetencyToggleComponent from '../common/PFCloudCompetencyToggleComponent';
import PFDropdownsFilterComponent from './PFDropdownsFilterContainer';

export default {
  name: 'PFFilterConfigurationBarComponent',
  components: {
    PFDropdownsFilterComponent,
    PFCloudCompetencyToggleComponent,
  },
  data: () => ({
    isShownFilters: false,
  }),
};
</script>

<template>
  <div class="ds-filter-sort__configuration-bar">
    <div
      class="ds-filter-sort__configuration-bar__filters"
      v-bind:class="[isShownFilters ? 'ds-open-filters' : '' ]"
    >
      <div class="ds-filter-sort__configuration-bar__filters__clear-link">Clear All</div>
      <div class="ds-filter-sort__configuration-bar__filters__inner-wrapper">
        <div class="ds-filter-sort__configuration-bar__multi-filter-wrapper search-page-multi-filter-container">
          <PFDropdownsFilterComponent
            :type="'locationCheckbox'"
            :filterType="'location'"
            :title="$t('search.filter.location')"
          />
          <PFDropdownsFilterComponent
            :type="'checkbox'"
            :filterType="'products'"
            :title="$t('search.filter.solution')"
          />
          <PFDropdownsFilterComponent
            :type="'checkbox'"
            :filterType="'designation'"
            :title="$t('search.filter.designation')"
          />
          <PFDropdownsFilterComponent
            :type="'checkbox'"
            :filterType="'industry'"
            :title="$t('search.filter.industry')"
          />
          <PFDropdownsFilterComponent
            :type="'checkbox'"
            :filterType="'engagement'"
            :title="$t('search.filter.business.model')"
          />
            <PFCloudCompetencyToggleComponent
            :title="$t('search.filter.cloud.competency')"
            />
</div>
      </div>
      <button
        class="ds-filter-sort__configuration-bar__filters__results-button"
      />
    </div>
  </div>
</template>

<style lang="less" scoped>

.ds-filter-sort__configuration-bar {
  .ds-open-filters {
    display: flex;
  }

  .ds-filter-sort__configuration-bar__filters {
    margin-left: 0;

    .search-page-multi-filter-container{
      max-width: 1680px;
    }

    .ds-filter-sort__configuration-bar__filters__inner-wrapper {
      .ds-filter-sort__configuration-bar__multi-filter-wrapper {
        max-height: none;
        margin-left: auto !important;
        margin-right: auto !important;
      }

      @media screen and (min-width: 1440px) {
        .ds-filter-sort__configuration-bar__multi-filter-wrapper {
          margin-left: 30%;
        }
      }
    }
  }
}
</style>
