import axios from 'axios';

const ROOT_SECURED_ENDPOINT = '/sap/details/apis/';
const ChangelogStore = {
  namespaced: true,
  state: {
    changelogData: [],
    endOfData: false,
    page: 0,
    size: 25,
  },
  actions: {
    async getChangelog(context, profileId) {
      if (!context.state.endOfData) {
        await axios.get(`${ROOT_SECURED_ENDPOINT}profileChangeHistory/findProfileChangeHistory?profileId=${profileId}&page=${context.state.page}&size=${context.state.size}`)
          .then((result) => context.commit('setChangelog', result.data))
          .catch((err) => console.log(`response error: ${err}`));
      }
    },
    async reloadChangelog(context, profileId) {
      context.commit('reset');
      await axios.get(`${ROOT_SECURED_ENDPOINT}profileChangeHistory/findProfileChangeHistory?profileId=${profileId}&page=${context.state.page}&size=${context.state.size}`)
        .then((result) => context.commit('setChangelog', result.data))
        .catch((err) => console.log(`response error: ${err}`));
    },
  },
  mutations: {
    setChangelog(context, result) {
      const changelog = result;
      if (changelog.length > 0) {
        context.changelogData.push.apply(context.changelogData, changelog);
        context.page += 1;
      } else {
        context.endOfData = true;
      }
    },
    reset(context) {
      context.changelogData = [];
      context.page = 0;
      context.endOfData = false;
    },
  },
};

export default ChangelogStore;
