<script>
const MAX_TEXTAREA_INPUT_LENGTH = 1000;
const MAX_CHAR_DEFAULT_SHOW = 600;
export default {
  name: 'PFLocationValuePropComponent',
  props: {
    editMode: {
      type: Boolean,
    },
    countryProfile: { type: Object, default: () => {} },
  },
  data: () => ({
    maxChar: MAX_TEXTAREA_INPUT_LENGTH,
    initialDisplayCount: MAX_CHAR_DEFAULT_SHOW,
    showAllToggle: false,
  }),
  computed: {
    PropSummary: {
      get() {
        return this.countryProfile.valueProposition;
      },
      set(newProposition) {
        this.$store.dispatch('partnerProfile/updateLocationValueProposition', {
          valueProp: newProposition,
          countryName: this.countryProfile.country,
        });
      },
    },
    charLeft() {
      if (this.PropSummary) {
        return this.maxChar - this.PropSummary.length;
      }
      return this.maxChar;
    },
    getText() {
      if (!this.showAllToggle && this.PropSummary && this.PropSummary.length > 0) {
        const text = this.PropSummary.substring(0, this.initialDisplayCount);
        return this.$sanitize(text);
      } if (this.PropSummary) {
        return this.$sanitize(this.PropSummary);
      }
      return '';
    },
    showMoreText() {
      return !!(this.PropSummary && this.PropSummary.length > this.initialDisplayCount);
    },
  },
};
</script>

<template>
<div class="location-prop-container default-cursor">
  <div v-show="editMode">
    <div class="ds-textarea location-prop-textarea">
      <label class="ds-textField ds-required-textfield ds-m-none">
        <textarea
          class="ds-textField__input ds-textField__input--textarea location-textarea"
          type="text"
          :spellcheck="true"
          :maxlength="maxChar"
          :placeholder="$t('helptext.location.valueProp.edit.placeholder')"
          v-model="PropSummary"></textarea>
      </label>
      <div class="char-left ds-m-t-xxs">{{charLeft}} {{ $tc('editor.global.characters.left', charLeft) }}</div>
    </div>
  </div>
  <div v-show="!editMode" class="location-prop-summary">
    <span v-html="getText"></span>
    <span class="fade-overlay" v-show="showMoreText && !showAllToggle"></span>
    &nbsp;
    <span v-show="showMoreText">
      <a
        class="show-more-link"
        href=""
        @click.prevent="showAllToggle = !showAllToggle">
        {{showAllToggle ? $t('show.less.text') : $t('title.brand.names.more')}}
      </a>
    </span>
  </div>
</div>
</template>

<style scoped lang="less">
@import "../../assets/css/common.less";

.location-prop-summary {
  white-space: pre-wrap;
  &:extend(.font-normal);
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  margin: auto;
  color: @color-black;
}

.location-prop-textarea {
  & > label textarea.ds-textField__input--textarea {
    min-height: 120px;
  }
}

.char-left {
  &:extend(.font-normal);
  height: 18px;
  font-size: 12px;
  line-height: 1.5;
  text-align: right;
  color: @color-glance-consultants;
}

.location-textarea {
  resize: vertical;
}

a.show-more-link {
  color: @color-link-show-more;
}

.fade-overlay {
  position: relative;
}

.fade-overlay::before {
  content: "";
  background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), @color-white);
  width: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
</style>
