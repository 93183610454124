<script>
export default {
  name: 'PEChangelogComponent',
  props: {
    toggleChangelog: {
      type: Function,
      default: () => {},
    },
    changelogOpen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    defaultChangelog: [
      {
        user: {},
      },
    ],
    onscroll: false,
  }),
  watch: {
    changelogOpen(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
        window.setTimeout(() => this.$refs.changelogModal.focus(), 0);
      } else {
        document.body.style.overflow = 'scroll';
        document.body.style.paddingRight = '0px';
      }
    },
  },
  computed: {
    changelog() {
      return this.$store.state.changelog.changelogData
        ? this.$store.state.changelog.changelogData
        : this.defaultChangelog;
    },
  },
  methods: {
    getNextpage() {
      this.$store.dispatch(
        'changelog/getChangelog',
        this.$route.params.profileId,
      );
    },
    getDate(date) {
      const myDate = new Date(date);
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
      };
      return myDate.toLocaleString('en-US', options);
    },
    loadMore() {
      const offset = 412;
      if (
        this.onscroll
        && this.$refs.changelogContent.scrollHeight
          <= this.$refs.changelogContent.scrollTop + offset
      ) {
        this.getNextpage();
      }
      // eslint-disable-next-line no-unused-expressions
      this.onscroll ? '' : (this.onscroll = true);
    },
    closeModal() {
      this.onscroll = false;
      this.toggleChangelog();
    },
  },
};
</script>

<template>
  <div
    class="ds-modal ds-modal--scrollable changelog-modal"
    :class="changelogOpen ? 'ds-modal--active' : ''"
    ref="changelogModal" tabindex="0" @keyup.esc="closeModal"
  >
  <div class="modal-container">
    <div class="modal-container-inner-changelog">
    <div class="ds-modal__box changelog-modal__box">
      <div class="ds-modal__box__header">
        <div class="ds-modal__box__header__heading">
          {{ $t('editor.changelog.title') }}
        </div>
      </div>
      <div
        class="ds-modal__box__content"
        ref="changelogContent"
        @scroll="loadMore"
      >
        <div
          v-for="log in changelog"
          :key="log.id"
          :id="log.id"
          class="changelog-text ds-m-b-m"
        >
          <div class="changelog-item-header">
            <div
              v-if="log.changeStatus == 'CHANGES'"
              class="ds-badge ds-badge--warning changelog-item-label"
            >
              {{ $t('editor.changelog.change') }}
            </div>
            <div v-else class="ds-badge ds-badge--success changelog-item-label">
              {{ $t('editor.changelog.publish') }}
            </div>
            <div class="changelog-date">{{ getDate(log.createdAt) }}</div>
            <div class="changelog-username ds-m-l-xs">
              {{
                log.user
                  ? $t('editor.changelog.by') +
                    ' ' +
                    log.user.firstName +
                    ' ' +
                    log.user.lastName
                  : ''
              }}
            </div>
          </div>
          <div
            v-if="log.changeStatus == 'PUBLISH'"
            class="changelog-comment ds-p-r-m"
          >
            {{ log.comment }}
          </div>
        </div>
      </div>
      <div class="ds-modal__box__action-bar">
        <button
          class="ds-modal__box__action-bar__action ds-button ds-button--primary close-button ds-m-x-none"
          type="button"
          @click="closeModal"
          id="changelog-button__close"
        >
          {{ $t('button.close') }}
        </button>
      </div>
    </div>
    </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.modal-container-inner-changelog {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 301px);
  .ds-modal__box__header__heading {
    &:extend(.font-expanded);
  }
}
.changelog-modal {
  z-index: 201 !important;
}
.changelog-modal__box {
  // position: fixed;
  width: 632px;
  height: 602px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}
.changelog-text {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
  color: @color-gray-1;
}
.ds-button {
  &:extend(.font-normal);
  font-size: 16px;
}
.close-button {
  width: 83px;
}
.changelog-item-header {
  display: flex;
  align-items: flex-start;
}
.changelog-username {
  &:extend(.font-normal);
  font-size: 14px;
  line-height: 26px;
  color: @color-gray-1;
}
.changelog-date {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 26px;
  color: @black-color;
  margin-left: 13px;
}
.changelog-comment {
  &:extend(.font-normal);
  font-size: 14px;
  line-height: 20px;
  color: @color-gray-2;
  margin-top: 6px;
}
.ds-modal__box__header__icon {
  color: @color-glance-consultants !important;
}
</style>
