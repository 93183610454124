<script>
/* eslint-disable import/extensions */
import { mapState } from 'vuex';
import PFCompetencyModalComponent from './PFCompetencyModalComponent.vue';
import PFERPModalComponent from './PFERPModalComponent.vue';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFSolutionItemComponent',
  data: () => ({
    showProfileCompetencyModal: { status: false, current: 0 },
    tooltipOpen: {},
    selectedSolution: null,
    selectedERP: null,
    showSolutionModal: false,
    showERPModal: false,
    competencyTiers: {
      ZERO: 0, ESSENTIAL: 1, ADVANCED: 2, EXPERT: 3,
    },
    faqLinkSolution: urlLinks.HELP_TEXT_LEARN_MORE.SAP_SOLUTION,
    faqLinkCompetency: urlLinks.LEARN_MORE_SPECIALIZATION,
  }),
  props: {
    solutionTransformed: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState('solutionL2Store', {
      solutionDataMap: (state) => state.solutionDataMap,
    }),
    ERPObject() {
      return this.solutionTransformed.find((s) => s.solutionL1Key === 'PFERP');
    },
    checkedBoxes: {
      get() {
        return this.$store.state.partnerProfile.expandedL1s;
      },
      set(L1) {
        this.$store.dispatch('partnerProfile/toggleL1', L1);
      },
    },
  },
  methods: {
    toggleTooltip(id) {
      this.tooltipOpen[id] = false;
    },
    openSolutionModal(val, solution = null) {
      this.showSolutionModal = val;
      this.selectedSolution = solution;
      if (val) {
        this.emitAdobeEvent(solution.solutionL1Name);
      }
    },
    openERPModal(val, ERP = null) {
      this.showERPModal = val;
      this.selectedERP = ERP;
      if (val) {
        this.emitAdobeEvent(`ERP_${ERP.solutionL2Name}`);
      }
    },
    emitAdobeEvent(name) {
      if (this.$route.name === 'profilePage') {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$eventBus.$emit('PFAACustomEvent',
          { eventName: 'ClickModal', custom2: `Solution_Competency_${name}` });
      }
    },
    toggleShowProfileCompetencyModal() {
      this.showProfileCompetencyModal.status = !this.showProfileCompetencyModal.status;
    },
    ifCompetencySolutionLevelEqual(sol, target) {
      if (!sol.level && !sol.hasCompetency) return false;
      return sol.level.toUpperCase() === target;
    },
    sortL2Solution(solL2, solL1Key) {
      return [...solL2].sort((a, b) => b.hasSpecialization - a.hasSpecialization
      || a.solutionL2Name.localeCompare(b.solutionL2Name));
    },
    sortERPSolution(solL2, solL1Key) {
      return [...solL2].sort((a, b) => this.solutionDataMap.get(solL1Key).L2Data.get(a.solutionL2Key).order
          - this.solutionDataMap.get(solL1Key).L2Data.get(b.solutionL2Key).order);
    },
    sortL1Solution(L1s) {
      return L1s.sort((a, b) => (!b.competencyAvailable ? -1 : this.competencyTiers[b.level ? b.level.toUpperCase() : 'ZERO'])
      - (!a.competencyAvailable ? -1 : this.competencyTiers[a.level ? a.level.toUpperCase() : 'ZERO'])
      || a.solutionL1Name.localeCompare(b.solutionL1Name));
    },
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
  },
  mounted() {
    const nonERP = this.solutionTransformed.filter((s) => s.solutionL1Key !== 'PFERP');
    if (nonERP.length === 1) {
      this.$store.dispatch('partnerProfile/toggleL1', [nonERP[0].solutionL1Key]);
    } else {
      const L1s = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [id, L1] of this.solutionDataMap) {
        if (L1.status > 0) {
          L1s.push(id);
        }
      }
      this.$store.commit('partnerProfile/toggleL1', L1s);
    }
  },
  components: {
    PFCompetencyModalComponent,
    PFERPModalComponent,
  },
};
</script>

<template>
<table>
  <tbody>
    <tr v-if="ERPObject">
      <td :class="solutionTransformed.length > 1 ? 'top-cell' : ''">
        <div class="erp-name">
          <div>{{ ERPObject.solutionL1Name }}</div>
        </div>
        <div v-for="(L2, idx) in sortERPSolution(ERPObject.L2Solution, ERPObject.solutionL1Key)"
                :key="ERPObject.solutionL1Name+L2.solutionL2Name+'-service-'+idx"
                class="solution competency-content erp-solution"
                :class="!L2.specializationAvailable ? ['competency-header-font'] : []"
                @click.self="openERPModal(true,L2)">
                <div @click.self="openERPModal(true,L2)">{{L2.solutionL2Name}}</div>
                <!-- tooltip -->
                <div v-if="!L2.specializationAvailable"
                  class="tool-tip-container"
                  v-click-outside="() => toggleTooltip(L2.solutionL2Key)">
                  <button
                    class="ds-icon ds-icon--info tool-tip-icon ds-m-l-xs ds-m-r-xs"
                    :class="tooltipOpen[L2.solutionL2Key] ? 'tool-tip-icon-clicked' : ''"
                    @click="tooltipOpen[L2.solutionL2Key] = !tooltipOpen[L2.solutionL2Key];">
                  </button>
                  <div class='ds-tooltip-icon toolitp-icon-item-list'>
                    <div
                      :class="[tooltipOpen[L2.solutionL2Key] ? 'display-block' :
                      'display-none','tooltiptext','ds-tooltip-icon__tooltip--service']">
                      {{ $t('competency.not.available.helptext') }}
                      <span @click.prevent="(e)=>{ e.stopPropagation();openInNewTab(faqLinkSolution);}" class="help-text-link">
                        <b> {{$t('learn.more')}}</b>
                      </span>
                    </div>
                  </div>
                </div>

                <div v-else class="solution-logo competency-level-img-container">
                  <img v-if="ifCompetencySolutionLevelEqual(L2.specializationData, 'ESSENTIAL')" alt=""
                    class="competency-level-img competency-level1-img" src="../../assets/Competency-Level1.png"/>
                  <img v-else-if="ifCompetencySolutionLevelEqual(L2.specializationData, 'ADVANCED')" alt=""
                    class="competency-level-img competency-level2-img" src="../../assets/Competency-Level2.png"/>
                  <img v-else-if="ifCompetencySolutionLevelEqual(L2.specializationData, 'EXPERT')" alt=""
                    class="competency-level-img competency-level3-img" src="../../assets/Competency-Level3.png"/>
                  <img v-else alt=""
                    class="competency-level-img competency-level0-img" src="../../assets/Competency-Level0.png"/>
                </div>
        </div>
        <PFERPModalComponent
            v-if="showERPModal"
            :toggleModal="openERPModal"
            :modalHeader="selectedERP.solutionL2Name"
            :hasCompetency="selectedERP.specializationAvailable"
            :competencyLevel="selectedERP.hasSpecialization ? selectedERP.specializationData.level : ''"
            :L2="selectedERP"/>
      </td>
    </tr>
    <tr>
      <td :class="ERPObject ? 'bottom-cell' : ''">
        <PFCompetencyModalComponent
            v-if="showSolutionModal"
            :hasCompetency="selectedSolution.competencyAvailable"
            :toggleModal="openSolutionModal"
            :modalHeader="selectedSolution.solutionL1Name"
            :L1Key="selectedSolution.solutionL1Key"
            :competencyLevel="selectedSolution.level ? selectedSolution.level
              : (selectedSolution.competencyAvailable ? 'ZERO' : '')"
            :L2s="sortL2Solution(selectedSolution.L2Solution)"
            :specializations="[...selectedSolution.specializations].sort((a, b) => a.name.localeCompare(b.name))"
            :countries="selectedSolution.hasCompetency ? selectedSolution.countries : {}"
            :industries="selectedSolution.hasCompetency ? selectedSolution.industries : {}"
          />
        <div class="solution-wrapper"
          v-for="(solution, idx) in sortL1Solution(solutionTransformed.filter((s) => s.solutionL1Key !== 'PFERP'))"
          :key="solution.solutionL1Name+'-competency-'+idx">
          <label :for="solution.solutionL1Key" class="link-cursor">
          <div class="solution competency-content">

            <input
              :id="solution.solutionL1Key"
              :value="solution.solutionL1Key"
              type="checkbox"
              name="items"
              v-model="checkedBoxes"
            />
            <div class="icon-label-group">
              <i class="ds-icon solution-expand-icon"
                :class="[checkedBoxes.includes(solution.solutionL1Key)
                ? 'ds-icon--arrow-up ds-icon--big'
                : 'ds-icon--arrow-down ds-icon--big']">
              </i>
              <div class="solution-title">
                  <div class="competency-header-font ds-m-b-xxs">
                    <div>{{solution.solutionL1Name}}</div>
                    <!-- tooltip -->
                    <div v-if="!solution.competencyAvailable"
                      class="tool-tip-container"
                      v-click-outside="() => toggleTooltip(solution.solutionL1Key)">
                      <button @onClick="(e) => e.stopPropagation()"
                        class="ds-icon ds-icon--info tool-tip-icon ds-m-l-xs ds-m-r-xs"
                        :class="tooltipOpen[solution.solutionL1Key] ? 'tool-tip-icon-clicked' : ''"
                        @click="tooltipOpen[solution.solutionL1Key] = !tooltipOpen[solution.solutionL1Key]"></button>
                      <div class='ds-tooltip-icon toolitp-icon-item-list'>
                        <div
                          :class="[tooltipOpen[solution.solutionL1Key] ? 'display-block' :
                          'display-none','tooltiptext','ds-tooltip-icon__tooltip--service']">
                          {{ $t('competency.not.available.helptext') }}
                          <span @click="openInNewTab(faqLinkSolution)" class="help-text-link">
                            <b> {{$t('learn.more')}}</b>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

            </div>
            <div v-if="solution.competencyAvailable" class="solution-logo competency-level-img-container">

                <img v-if="ifCompetencySolutionLevelEqual(solution, 'ESSENTIAL')" alt=""
                        class="competency-level-img competency-level1-img" src="../../assets/Competency-Level1.png"/>
                <img v-else-if="ifCompetencySolutionLevelEqual(solution, 'ADVANCED')" alt=""
                        class="competency-level-img competency-level2-img" src="../../assets/Competency-Level2.png"/>
                <img v-else-if="ifCompetencySolutionLevelEqual(solution, 'EXPERT')" alt=""
                        class="competency-level-img competency-level3-img" src="../../assets/Competency-Level3.png"/>
                <img v-else alt=""
                        class="competency-level-img competency-level0-img" src="../../assets/Competency-Level0.png"/>
            </div>
          </div>
          </label>
          <div class="L2-solutions">
            <div :class="checkedBoxes.includes(solution.solutionL1Key) ? 'display' : 'hidden'">
              <div v-for="(L2, idx) in sortL2Solution(solution.L2Solution, solution.solutionL1Key)"
                :key="solution.solutionL1Name+L2.solutionL2Name+'-service-'+idx"
                :class="L2.specializationAvailable ? 'L2-wrapper' : 'L2-wrapper-tooltip'"
                :style="{ marginBottom: idx == sortL2Solution(solution.L2Solution, solution.solutionL1Key).length - 1 ? '8px' : '5px' }">
                <div
                  class="specialization-text">&nbsp;&nbsp;&#8226;&nbsp;&nbsp;{{L2.solutionL2Name}}
                </div>
                <div v-if="L2.hasSpecialization" class="specialization-tag">
                  {{$t("profile.competency.Specializations")}}
                </div>
                <div v-if="solution.solutionL1Key !== 'PFFIN' && !L2.specializationAvailable"
                    class="tool-tip-container"
                    :style="{ marginLeft: '-1px'}"
                    v-click-outside="() => toggleTooltip(L2.solutionL2Name)">
                    <button @onClick="(e) => e.stopPropagation()"
                      class="ds-icon ds-icon--info tool-tip-icon ds-m-l-xs ds-m-r-xs ds-p-none"
                      :class="tooltipOpen[L2.solutionL2Name] ? 'tool-tip-icon-clicked' : ''"
                      @click="tooltipOpen[L2.solutionL2Name] = !tooltipOpen[L2.solutionL2Name]">
                    </button>
                    <div class='ds-tooltip-icon'
                    :class="L2.solutionL2Name.length > 30 ? 'toolitp-icon-item-list-ERP'
                    : 'toolitp-icon-item-list'">
                      <div
                        :class="[tooltipOpen[L2.solutionL2Name] ? 'display-block' :
                        'display-none','tooltiptext',
                        tooltipOpen[L2.solutionL2Name] && L2.solutionL2Name.length > 30 ?
                        'ds-tooltip-icon__tooltip--ERP':'ds-tooltip-icon__tooltip--service']">
                        {{ $t('specialization.not.available.helptext') }}
                        <span @click="openInNewTab(faqLinkCompetency)" class="help-text-link">
                          <b> {{$t('learn.more')}}</b>
                        </span>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="specialization-text profile-competency-more-text"
                @click="openSolutionModal(true,solution)">{{ $t('show.details') }}</div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
</template>

<style lang="less" scoped>

.tool-tip-container {
  height: 20px;
  margin-left: -7px;
  text-align: left;
  display: inline-block;
  position: relative;

  .tool-tip-icon::before {
    line-height: 20px !important;
  }

  button {
    background: none;
    border: none;
  }
}
.top-cell {
  padding-bottom: 6px !important;
  border-bottom: 1px solid #eaeaea !important;
}
.bottom-cell {
  padding-top: 20px;
}

.erp-name {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 14px;
  color: @color-gray-1;
  &:extend(.font-bold);
  line-height: 21px; /* 150% */
}

.solution-wrapper, .erp-solution {
  margin-bottom: 14px;
}

.erp-solution {
  width: 424px !important;
  margin-left: 36px;
}

.specialization-tag {
  text-indent: 0px !important;
  min-width: max-content;
  border: 0.5px solid @color-gray-1;
  border-radius: 10px;
  padding: 0 2% 0 2%;
  margin-left: 8px;
  height: 19px;
  line-height: 13px;
  font-family: 'BentonSans';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  transform: translateY(2px);
  align-items: center;
  text-align: center;
  color: @color-gray-1;
  display: inline-flex;
}

.solution {
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .icon-label-group {
    display: flex;
    justify-content: left;
    .solution-expand-icon {
      margin-right: 12px;
      color: @color-gray-2;
    }
  }
  display: flex;
  height: 24px;
  justify-content: space-between;

  width: 460px;
}

.solution-title {
  width: 314px;
}
.solution-logo {
  margin-left: 8px;
  margin-top: 3px;
  width: 90px;
}

.competency-header-font {
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 21px;
  &:extend(.font-expanded);
}

.L2-solutions {
  margin-left: 36px;
  margin-top: 8px;
}

.L2-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 314px;
  margin-bottom: 5px;
}
.L2-wrapper-tooltip {
  display: flex;
  justify-content: left;
  align-items: center;
  max-width: 314px;
  margin-bottom: 5px;
}
.specialization-text {
  display: inline-block;
  text-indent: -20px;
  &:extend(.font-normal);
  font-size: 14px;
  line-height: 20px;
  color: @color-gray-1;
  margin-left: 20px;
}

.competency-level-img-container {
  position: relative;
  height: 16px;
  width: 90px;
}
.competency-level-img {
  height: 16px;
  width: 90px;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.profile-competency-more-text {
  &:extend(.font-normal);
  color: @color-link;
  cursor: pointer;
  display: inline-block;
}
.profile-competency-more-text:hover, label:hover, .erp-solution:hover {
  color: @color-link__hover;

  cursor: pointer;
  .competency-header {
    color: @color-link !important;
  }
  .competency-level-img {
    padding-left: 90px;
  }
  .competency-level0-img {
    background: url('../../assets/Competency-lvl0-hover.png') no-repeat;
    background-size: 90px 16px;
  }
  .competency-level1-img {
    background: url('../../assets/Competency-lvl1-hover.png') no-repeat;
    background-size: 90px 16px;
  }
  .competency-level2-img {
    background: url('../../assets/Competency-lvl2-hover.png') no-repeat;
    background-size: 90px 16px;
  }
  .competency-level3-img {
    background: url('../../assets/Competency-lvl3-hover.png') no-repeat;
    background-size: 90px 16px;
  }
}
</style>
