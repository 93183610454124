import axios from 'axios';

const MAIL_ENDPOINT = '/sap/details/api/mail/partner/';
const CONTACT_FORM_PRFIX = '/contact';
const ContactEmailStore = {
  namespaced: true,
  state: {
    captchaResponseToken: '',
    captchaPublicKey: '',
    requestStatus: null,
    customerInfo: {
      email: '',
      firstName: '',
      lastName: '',
      company: '',
      phone: '',
      country: '',
      comments: '',
    },
    errorMessage: null,
    currentSelectedCountry: '',
  },
  actions: {
    async sendData(context) {
      context.commit('updateCurrentSelectedCountry', context.state.customerInfo.country);
      const partnerId = context.rootState.partnerProfile.profileData.profileId;
      context.commit('partnerProfile/setLoading', true, { root: true });
      const payload = { formFields: context.state.customerInfo, reCaptchaResponse: context.state.captchaResponseToken };
      context.commit('updateRequestStatus', 1);
      await axios.post(`${MAIL_ENDPOINT}${partnerId}${CONTACT_FORM_PRFIX}`, payload)
        .then(() => {
          context.commit('partnerProfile/setLoading', false, { root: true });
          context.commit('updateRequestStatus', 2);
          context.commit('clear');
        })
        .catch((err) => {
          context.commit('updateErrorMessage', err);
          context.commit('partnerProfile/setLoading', false, { root: true });
          context.commit('updateRequestStatus', false);
          throw new Error(err);
        });
    },
    updateContactEmail(context, val) {
      context.commit('updateContactEmail', val);
    },
    updateFirstName(context, val) {
      context.commit('updateFirstName', val);
    },
    updateLastName(context, val) {
      context.commit('updateLastName', val);
    },
    updateCompany(context, val) {
      context.commit('updateCompany', val);
    },
    updatePhone(context, val) {
      context.commit('updatePhone', val);
    },
    updateCountry(context, val) {
      context.commit('updateCountry', val);
    },
    updateComments(context, val) {
      context.commit('updateComments', val);
    },
    updateRequestStatus(context, val) {
      context.commit('updateRequestStatus', val);
    },
    updateCaptchaResponseToken(context, val) {
      context.commit('updateCaptchaResponseToken', val);
    },
  },
  mutations: {
    clear(context) {
      context.customerInfo.email = '';
      context.customerInfo.firstName = '';
      context.customerInfo.lastName = '';
      context.customerInfo.company = '';
      context.customerInfo.phone = '';
      context.customerInfo.country = '';
      context.customerInfo.comments = '';
      context.errorMessage = null;
    },
    updateCurrentSelectedCountry(context, val) {
      context.currentSelectedCountry = val;
    },
    updateErrorMessage(context, val) {
      context.errorMessage = val;
    },
    updateContactEmail(context, val) {
      context.customerInfo.email = val;
    },
    updateFirstName(context, val) {
      context.customerInfo.firstName = val;
    },
    updateLastName(context, val) {
      context.customerInfo.lastName = val;
    },
    updateCompany(context, val) {
      context.customerInfo.company = val;
    },
    updatePhone(context, val) {
      context.customerInfo.phone = val;
    },
    updateCountry(context, val) {
      context.customerInfo.country = val;
    },
    updateComments(context, val) {
      context.customerInfo.comments = val;
    },
    updateRequestStatus(context, val) {
      context.requestStatus = val;
    },
    updateCaptchaResponseToken(context, val) {
      context.captchaResponseToken = val;
    },
  },
};

export default ContactEmailStore;
