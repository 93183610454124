<script>
import { mapState } from 'vuex';

const MAX_LENGTH_KEYWORD = 40;
const MAX_KEYWORD = 10;

export default {
  name: 'PEKeywordsComponent',
  data: () => ({
    inputKeyword: '',
    maxChar: MAX_LENGTH_KEYWORD,
    maxKeywords: MAX_KEYWORD,
  }),
  computed: {
    ...mapState('partnerProfile', {
      tags: (state) => state.profileData.tags,
    }),
    remainingKeywordLength() {
      if (this.tags) {
        return this.maxChar - this.inputKeyword.length;
      }
      return this.maxChar;
    },
    remainingKeyword() {
      if (this.tags) {
        return this.tags.length;
      }
      return 0;
    },
    disableAddKeyword() {
      return this.tags && this.tags.length === 10;
    },
  },
  methods: {
    removeKeyword(keyword) {
      this.$store.dispatch('partnerProfile/removeKeyword', keyword);
    },
    addKeyword(event) {
      event.target.blur();
      if (this.inputKeyword) {
        this.$store.dispatch('partnerProfile/addKeyword', this.inputKeyword);
        this.inputKeyword = '';
      }
    },
    pressEnter() {
      if (this.inputKeyword) {
        this.$store.dispatch('partnerProfile/addKeyword', this.inputKeyword);
        this.inputKeyword = '';
      }
    },
  },
};
</script>

<template>
  <div class="row-max-width-keyword-section">
    <div class="keyword-section-description-short ds-m-b-l">
      {{ $t('editor.keyword.description.short') }}
    </div>
    <div
      class="keyword-section-description-long"
      v-html="$t('editor.keyword.description.long')"
    ></div>
    <div class="keywords-content ds-m-t-m">
      <div class="keywords-header ds-m-b-s">
        {{
          $t('editor.keyword.title') +
          ' (' +
          remainingKeyword +
          '/' +
          maxKeywords +
          ')'
        }}
      </div>
      <div class="keywords-list">
        <ul
          class="ds-list items-list ds-m-y-none service-item-size item-list-line-height-global"
        >
          <li class="ds-list__item ds-p-b-xs" v-for="tag in tags" :key="tag">
            <span class="ds-p-l-none">
              {{ tag }}
              <i class="ds-icon ds-icon--clear" @click="removeKeyword(tag)"></i>
            </span>
          </li>
        </ul>
      </div>
      <div class="keywords-input ds-m-t-xs">
        <div class="keyword-input-section ds-m-r-xs">
          <label
            class="ds-m-none ds-textField ds-textField--fixed keyword-input-field"
          >
            <input
              class="ds-textField__input"
              type="text"
              :placeholder="$t('editor.keyword.label')"
              :maxlength="maxChar"
              v-model="inputKeyword"
              :disabled="disableAddKeyword"
              @keydown.enter="pressEnter"
            />
            <span class="ds-textField__placeholder" v-if="inputKeyword">
              {{ $t('editor.keyword.label') }}
            </span>
          </label>
          <div class="char-left ds-m-t-xxs" v-show="!disableAddKeyword">
            {{ remainingKeywordLength }}
            {{ $tc('editor.global.characters.left', remainingKeywordLength) }}
          </div>
        </div>
        <button
          class="ds-button ds-button--inline ds-button--primary keyword-add-button ds-m-none"
          type="button"
          @click="addKeyword"
          :disabled="disableAddKeyword"
        >
          {{ $t('button.add') }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.row-max-width-keyword-section {
  max-width: 817px;
  margin: auto;
}
.keyword-section-description-short {
  &:extend(.font-normal);
  text-align: center;
  font-size: 12px;
  line-height: 18px;
  color: @color-gray-2;
}

.keyword-section-description-long {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
  color: @color-gray-1;
}

.keywords-header {
  &:extend(.font-expanded);
  font-size: 21px;
  line-height: 27px;
  color: @color-black;
}

.items-list {
  list-style: none;
  padding-left: 0;
  font-size: 16px;
  line-height: 21px;
  color: @color-black;

  li {
    display: flex;
    span {
      text-align: left;
      &:extend(.font-normal);
    }
  }
}
.ds-icon {
  &::before {
    color: @color-icon-before;
    font-size: 0.85rem;
    cursor: grab;
  }

  &:hover::before {
    color: @color-icon__hover-before;
  }
}

.ds-icon.ds-icon--clear {
  &::before {
    font-size: 0.65rem;
  }
  &:hover {
    cursor: pointer;
  }

  &:hover::before {
    cursor: pointer;
  }
}
.char-left {
  &:extend(.font-normal);
  height: 18px;
  font-size: 12px;
  line-height: 1.5;
  text-align: right;
  color: @color-glance-consultants;
}
.keywords-input {
  display: flex;
}
.keyword-input-section {
  width: 273px;
}

.keyword-add-button {
  height: 42px;
}
</style>
