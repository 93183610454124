import { createI18n } from 'vue-i18n/index';
// If not import from vue-i18n/index, there will be console error. This is a known bug apparently. They say it will be fixed in the 9.2 version.

/* Load localization assets and construct in-memory map for locale-based lookup */
function loadMessages() {
  const context = require.context('./locales', true, /[a-z0-9-_]+\.json$/i);

  const messages = context
    .keys()
    .map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
    .reduce(
      (messages, { key, locale }) => ({
        ...messages,
        [locale]: context(key),
      }),
      {},
    );
  return { context, messages };
}

function loadSubTranslationFiles() {
  const context = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  const uniqueKeys = [];
  context.keys().forEach((key) => {
    const filename = key.match(/[a-z0-9-_]+/i)[0];
    const language = filename.substr(0, filename.indexOf('-'));
    if (!uniqueKeys.includes(language)) {
      uniqueKeys.push(language);
    }
  });
  uniqueKeys.forEach((language) => {
    messages[language] = findSubTranslationFiles(language);
  });
  return { context, messages };
}

function findSubTranslationFiles(key) {
  const context = require.context('./locales', true, /[a-z0-9-_]+\.json$/i);
  const fileNames = context.keys().filter((fileName) => fileName.startsWith(`./${key}`));
  const subTranslationFiles = fileNames.reduce(combineFiles, {});
  return subTranslationFiles;
}

function combineFiles(file, filename) {
  return Object.assign(file, require(`./locales/${filename.match(/[a-z0-9-_]+/i)[0]}.json`));
}

const { context, messages } = loadSubTranslationFiles();

/* Initialize Vue i18n library */
export default createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  warnHtmlInMessage: 'off',
});

if (module.hot) {
  module.hot.accept(context.id, () => {
    const { messages: newMessages } = loadSubTranslationFiles();

    Object.keys(newMessages)
      .filter((locale) => messages[locale] !== newMessages[locale])
      .forEach((locale) => {
        messages[locale] = newMessages[locale];
        i18n.setLocaleMessage(locale, messages[locale]);
      });
  });
}
