import axios from 'axios';

const AddressAutocompleteStore = {
  namespaced: true,
  state: {
    query: '',
    searchResults: [],
    lastUsedQuery: '',
  },
  /*
     * Actions can be used to make async http calls ot fetch data, manipulate/process data and call
     * mutation functions to affect the state.
     */
  actions: {
    async getAddressAutocompleteResults(context) {
      const q = context.state.query;
      if (q !== '' && q.length >= 3) {
        context.commit('setLastUsedQuery', q);
        await axios.get(`/sap/search/api/search/place/autocomplete?input=${q}`)
          .then((results) => context.dispatch('addressAutoComplete/cleanResults', results, { root: true }))
          .catch((err) => {
            throw new Error(`Response error: ${err}`);
          });
      } else {
        await context.dispatch('clearResults', context);
      }
    },

    cleanResults(context, results) {
      results === {} ? context.commit('setResults', []) : context.commit('setResults', results.data);
    },

    keyPressed(context, value) {
      if (value === context.state.lastUsedQuery && context.state.lastUsedQuery
        && context.state.lastUsedQuery.length > 3) {
        return;
      }
      context.commit('newValue', value);
      context.dispatch('getAddressAutocompleteResults', context);
    },

    clearResults(context) {
      context.commit('setResults', []);
    },

    clearQuery(context) {
      context.commit('newValue', '');
    },

    setQueryValue(context, value) {
      context.commit('newValue', value);
    },
  },

  mutations: {
    newValue(context, value) {
      context.query = value;
    },
    setResults(context, results) {
      context.searchResults = results;
    },
    setLastUsedQuery(context, value) {
      context.lastUsedQuery = value;
    },
  },
};

export default AddressAutocompleteStore;
