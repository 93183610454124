<script>
import { mapState } from 'vuex';
import PFPopUpComponent from '@/components/common/PFPopUpComponent';
import PEBrandNameAddComponent from '@/components/editor/popup/PEBrandNameAddComponent';

export default {
  name: 'PEBrandNameComponent',
  components: {
    PFPopUpComponent,
    PEBrandNameAddComponent,
  },
  data: () => ({
    showBrandNameModal: false,
    showBrandNameEditModal: false,
    showDeleteConfirmModal: false,
    editedBrandNames: [],
    disableAddBrandNameButton: false,
    brandNameProp: {
      idx: null,
      brandName: null,
    },
    brandNameBorderTimer: null,
    modalWidth: 625,
    maxBrandHeaderChar: 56,
  }),
  mounted() {
    this.editedBrandNames = this.brandNames ? JSON.parse(JSON.stringify(this.brandNames)) : [];
    this.disableAddBrandNameButton = this.editedBrandNames.length >= 10;
    this.sortByBrandName();
    this.$eventBus.$on('STEP4_BRAND', () => {
      this.toggleBrandNameModal(true);
    });
  },
  computed: {
    ...mapState('partnerProfile', {
      brandNames: (state) => state.profileData.brandNames,
    }),
    isModalShow() {
      return this.showBrandNameModal || this.showBrandNameEditModal || this.showDeleteConfirmModal;
    },
  },
  watch: {
    brandNames: {
      handler(val) {
        this.editedBrandNames = val ? JSON.parse(JSON.stringify(val)) : [];
        this.sortByBrandName();
      },
      deep: true,
    },
    editedBrandNames: {
      handler(val) {
        this.disableAddBrandNameButton = val.length >= 10;
      },
      deep: true,
    },
    isModalShow(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
        window.setTimeout(() => this.$refs.brandNameModal.focus(), 0);
      } else {
        document.body.style.overflow = 'scroll';
        document.body.style.paddingRight = '0px';
      }
    },
    showBrandNameModal(val) {
      if (this.brandNameBorderTimer) {
        clearTimeout(this.brandNameBorderTimer);
      }
      if (val) {
        this.brandNameBorderTimer = setTimeout(() => this.isOverflown(), 10);
      }
    },
  },
  methods: {
    toggleBrandNameModal(val) {
      this.showBrandNameModal = val;
    },
    toggleBrandNameEditModal(val) {
      this.showBrandNameEditModal = val;
      if (!val) {
        this.showBrandNameModal = true;
      } else {
        this.showBrandNameModal = false;
        this.showDeleteConfirmModal = false;
      }
    },
    toggleDeleteConfirmModal(val) {
      this.showDeleteConfirmModal = val;
      if (!val) {
        this.showBrandNameModal = true;
      } else {
        this.showBrandNameModal = false;
        this.showBrandNameEditModal = false;
      }
    },
    sortByBrandName() {
      this.editedBrandNames.sort((a, b) => a.brandName.localeCompare(b.brandName));
      let favouriteNameIdx = null;
      this.editedBrandNames.forEach((name, idx) => {
        if (name.isFavoriteName) {
          favouriteNameIdx = idx;
        }
      });
      if (favouriteNameIdx) {
        const favouriteNameObj = this.editedBrandNames[favouriteNameIdx];
        this.editedBrandNames.splice(favouriteNameIdx, 1);
        this.editedBrandNames.unshift(favouriteNameObj);
      }
    },
    addBrandName(newBrandName) {
      if (newBrandName.isFavoriteName && this.editedBrandNames[0] && this.editedBrandNames[0].isFavoriteName) {
        this.editedBrandNames[0].isFavoriteName = false;
      }
      this.editedBrandNames.push(newBrandName);
      this.sortByBrandName();
      this.toggleBrandNameEditModal(false);
      this.saveEditedBrandNames();
    },
    editBrandName(newBrandName, idx) {
      if (newBrandName.isFavoriteName && this.editedBrandNames[0].isFavoriteName) {
        this.editedBrandNames[0].isFavoriteName = false;
      }
      this.editedBrandNames[idx] = newBrandName;
      this.sortByBrandName();
      this.toggleBrandNameEditModal(false);
      this.saveEditedBrandNames();
    },
    deleteBrandName(idx) {
      this.editedBrandNames.splice(idx, 1);
      this.sortByBrandName();
      this.toggleDeleteConfirmModal(false);
      this.saveEditedBrandNames();
    },
    saveEditedBrandNames() {
      this.$store.commit('partnerProfile/updateBrandNames', this.editedBrandNames);
    },
    updateBrandNameProp(brandName, idx) {
      this.brandNameProp.idx = idx;
      this.brandNameProp.brandName = brandName;
    },
    isOverflown() {
      if (this.$refs.modalBrandNameContent.scrollHeight > this.$refs.modalBrandNameContent.clientHeight) {
        this.$refs.modalBrandNameHeader.style.borderBottom = '1px solid #c6c6c6';
        this.$refs.modalBrandNameActionBar.style.borderTop = '1px solid #c6c6c6';
      } else {
        this.$refs.modalBrandNameHeader.style.borderBottom = 'none';
        this.$refs.modalBrandNameActionBar.style.borderTop = 'none';
      }
    },
    onResize() {
      this.modalWidth = this.$refs.modalBrandNameContent.clientWidth;
      if (this.modalWidth > 525) {
        this.maxBrandHeaderChar = Math.round(this.modalWidth / 11);
      } else if (this.modalWidth > 332 && this.modalWidth <= 525) {
        this.maxBrandHeaderChar = Math.round(this.modalWidth / 13);
      } else {
        this.maxBrandHeaderChar = Math.round(this.modalWidth / 15);
      }
    },
    getTruncatedText(brand) {
      let maxChar = brand.isFavoriteName ? this.maxBrandHeaderChar - 14 : this.maxBrandHeaderChar;
      maxChar = maxChar < 5 ? 5 : maxChar;
      if (brand.brandName.length <= maxChar) {
        return brand.brandName;
      }
      return `${brand.brandName.substring(0, maxChar - 2)}...`;
    },
  },
};
</script>
<template>
    <div class="brand-name-container ds-m-l-s ds-m-y-m">
      <div class="brand-name-button ds-m-t-xs" @click="toggleBrandNameModal(true)">
        <div class="brand-name-more-text-container" v-if="brandNames && brandNames.length > 0">
          <span class="brand-name-more-text">{{brandNames.length}}
            {{ $tc("editor.brand.name.header.more.names", brandNames.length) }}</span>
          <span class="brand-name-text-header-edit ds-m-l-xs">{{ $t("button.edit") }}</span>
        </div>
        <div v-else class="brand-name-text-header-add">{{ $t("editor.brand.name.header.add") }}</div>
      </div>
      <div class="ds-modal ds-modal--active ds-modal--scrollable" v-if="showBrandNameModal"
         ref="brandNameModal" tabindex="0" @keyup.esc="toggleBrandNameModal(false)">
      <div class="modal-container">
      <div class="modal-container-inner-upload" id="brand-name-modal">
      <div class="ds-modal__box brand-name-modal">
        <div class="ds-modal__box__header" ref="modalBrandNameHeader">
          <div class="ds-modal__box__header__heading">
            {{ $tc('editor.brand.name.header', editedBrandNames.length) }}
          </div>
        </div>
        <div class="ds-modal__box__content ds-m-none ds-m-l-l ds-m-r-s ds-m-y-m"
        ref="modalBrandNameContent">
        <resize-observer @notify="this.onResize"></resize-observer>
          <div class="brand-name-text ds-m-b-m">
            {{ $t("editor.brand.name.text") }}
          </div>
          <div v-for="(brandName, idx) in editedBrandNames"
            :key="brandName.brandName + '-' + idx"
            class="brand-name-text ds-m-b-m">
            <div class="brand-name-text-header">
              <div class="brand-name-text-header-text">
              <span>{{getTruncatedText(brandName)}}</span>
              <span class="brand-name-favorite" v-show="brandName.isFavoriteName">
                {{ $t('editor.brand.favorite.name.text') }}</span>
              <div class="brand-name-text-header-delete"
                @click="updateBrandNameProp(brandName, idx);toggleDeleteConfirmModal(true)">
                {{ $t("button.delete") }}</div>
              <div class="brand-name-text-brand-header-edit  ds-m-x-xs"
                @click="updateBrandNameProp(brandName, idx);toggleBrandNameEditModal(true)">
                {{ $t("button.edit") }}</div>
              </div>
            </div>
            <div class="brand-name-text-description">{{brandName.shortDescription}}</div>
          </div>
          <div class="add-brand-name-button-container">
            <button
            class="ds-modal__box__action-bar__action ds-button ds-button--secondary add-name-button ds-m-x-none"
            type="button"
            @click="updateBrandNameProp(null, null);toggleBrandNameEditModal(true)"
            :disabled="disableAddBrandNameButton"
            id="brand-name-button__add"
          >
            {{ $t("editor.brand.add.name") }}
          </button>
          <div v-show="disableAddBrandNameButton" class="add-max-brand-name ds-m-l-xs">
            {{ $t('editor.brand.name.max') }}</div>
          </div>
          <div v-show="disableAddBrandNameButton" class="add-max-brand-name-xs">
            {{ $t('editor.brand.name.max') }}</div>
        </div>
        <div class="ds-modal__box__action-bar" ref="modalBrandNameActionBar">
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary ds-m-l-m"
            type="button"
            @click="toggleBrandNameModal(false)"
            id="brand-name-button__cancel"
          >
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
      </div>
    </div>
    </div>
    <PFPopUpComponent
      v-if="showDeleteConfirmModal"
      class="brand-name-confirm-delete-popup"
      :isShown="showDeleteConfirmModal"
      :text="$t('editor.brand.delete.name.text')"
      :submitText="$t('button.delete')"
      :cancelText="$t('helptext.partner.editor.discard.agreement.cancelButton')"
      :headerText="$t('editor.brand.delete.name')"
      :submitAction="() => deleteBrandName(brandNameProp.idx)"
      :cancelAction="() => toggleDeleteConfirmModal(false)"/>
    <PEBrandNameAddComponent
      v-if="showBrandNameEditModal"
      :brandNameProp="brandNameProp"
      :submitAction="editBrandName"
      :addAction="addBrandName"
      :cancelAction="() => toggleBrandNameEditModal(false)"
      :deleteAction="() => toggleDeleteConfirmModal(true)"/>
  </div>
</template>
<style lang="less" scoped>
.brand-name-container {
  display: inline-block;
  width: 100%;
  min-width: 150px;
  cursor: default;
  .brand-name-more-text-container{
    &:extend(.font-expanded);
    text-align: left;
  }
  .brand-name-button {
    &:extend(.font-normal);
    max-width: 180px;
    font-size: 16px;
    display: block;
  }
  .ds-modal__box__header__heading {
    &:extend(.font-expanded);
    font-size: 27px;
    line-height: 35px;
  }
  .ds-modal__box__content {
    padding-right: 40px !important;
  }

  .ds-modal--active {
    z-index: 200;
  }
  .brand-name-modal {
    max-width: 669px;
    height: fit-content;
    max-height: 572px;
    min-height: 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  }
  .brand-name-favorite {
    font-size: 16px;
    line-height: 24px;
    color: @color-gray-3;
  }
  .brand-name-more-text {
    font-size: 16px;
    line-height: 24px;
    color: @color-gray-2;
  }

  .brand-name-text {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
    color: @color-grey-0;
  }
  .brand-name-text-header-text {
    &:extend(.font-bold);
    font-size: 16px;
    line-height: 24px;
    color: @color-grey-0;
  }
  .brand-name-text-header-edit, .brand-name-text-header-add, .brand-name-more-text
  ,.brand-name-text-brand-header-edit, .brand-name-text-header-delete {
    &:extend(.font-expanded);
  }
  .brand-name-favorite {
    &:extend(.font-normal);
  }
  .brand-name-text-header-edit, .brand-name-text-header-add, .brand-name-text-brand-header-edit{
    font-size: 16px;
    line-height: 24px;
    color: @color-link;
    cursor: pointer;
  }
  .brand-name-text-header-edit:hover, .brand-name-text-header-add:hover, .brand-name-text-brand-header-edit:hover{
    color: @color-link__hover;
  }
  .brand-name-text-header-delete{
    color: @color-red-delete-button;
    cursor: pointer;
  }
  .brand-name-text-brand-header-edit, .brand-name-text-header-delete {
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    float: right;
  }
  .brand-name-text-header-delete:hover{
    color: @color-editor-footer-error;
  }
  .brand-name-text-description{
    &:extend(.font-normal);
    font-size: 14px;
    line-height: 21px;
    color: @color-grey-0;
  }
  .ds-button {
    &:extend(.font-normal);
    font-size: 16px;
  }
  .save-button {
    width: fit-content;
  }
  .add-name-button {
    width: fit-content;
    margin-bottom: 1px;
  }
  .modal-container-inner-upload {
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    top: calc(50% - 250px);
  }
  .add-brand-name-button-container {
    display: flex;
  }
  .add-max-brand-name, .add-max-brand-name-xs {
      &:extend(.font-normal);
      font-size: 14px;
      line-height: 40px;
      color: @color-gray-3;
    }
  }
  .add-max-brand-name-xs {
    display: none;
  }
</style>
