<script>
export default {
  name: 'PEModalWithTextAreaComponent',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    headerText: {
      type: String,
      default: '',
    },
    submitText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    textareaPlaceholder: {
      type: String,
      default: '',
    },
    confirmMessage: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
    },
    submitAction: {
      type: Function,
      default: () => false,
    },
    cancelAction: {
      type: Function,
      default: () => false,
    },
  },
  data: () => ({
    confirm: false,
    confirmRead: 'confirm',
    isRed: false,
  }),
  computed: {
    textAreaValue: {
      get() {
        return this.$store.state.partnerProfile.publishComment;
      },
      set(comment) {
        this.$store.dispatch('partnerProfile/updatePublishComment', comment);
      },
    },
  },
  watch: {
    isShown() {
      if (this.isShown) {
        window.setTimeout(() => this.$refs.modalWithTextarea.focus(), 0);
      }
      if (!this.isShown) {
        this.isRed = false;
        this.confirm = false;
      }
    },
    confirm() {
      if (this.confirm) {
        this.isRed = false;
      }
    },
  },

  methods: {
    clearAndCancel() {
      this.clearTextArea();
      this.cancelAction();
      this.isRed = false;
      this.confirm = false;
    },
    clearTextArea() {
      this.$store.dispatch('partnerProfile/updatePublishComment', '');
    },
    changeColorToRed() {
      this.isRed = true;
    },
    refresh() {
      this.isRed = false;
      this.confirm = false;
    },
    submitPublish() {
      if (this.confirm) {
        this.submitAction();
        this.refresh();
      } else {
        this.changeColorToRed();
      }
    },
  },
};
</script>
<template>
  <div
    ref="modalWithTextarea"
    class="ds-modal textarea-modal"
    :class="isShown ? 'ds-modal--active' : ''"
    tabindex="0"
    @keyup.esc="clearAndCancel"
  >
    <div class="modal-container">
      <div class="modal-container-inner-textarea">
        <div class="ds-modal__box modal-box">
          <div class="ds-modal__box__header ds-p-y-none">
            <div class="ds-modal__box__header__heading">{{ headerText }}</div>
          </div>
          <div
            class="ds-modal__box__content"
            :class="textAreaValue.length > 0 ? 'with-char-left' : ''"
          >
            <div class="modal-text">
              {{ text }}
            </div>
            <label class="ds-textField">
              <textarea
                v-model="textAreaValue"
                class="ds-textField__input ds-textField__input--textarea modal-textarea"
                :spellcheck="true"
                :placeholder="textareaPlaceholder"
                :maxlength="maxLength"
              />
            </label>
            <label class="ds-checkbox checkbox-area"
              :for="confirmRead">
                <input
                  class="ds-checkbox__control"
                  type="checkbox"
                  :id="confirmRead"
                  v-model="confirm"
                  name="items"
                />
                <span class="ds-checkbox__box">
                  <i class="ds-icon ds-icon--checked"></i>
                </span>
                <span class="ds-checkbox__label modal-text check-box-text">
                  <div class="confirm-message">
                    <div :class="isRed ? 'modal-text-red': ''">{{ confirmMessage }}</div>
                    <a
                      href="/partner-terms-of-use"
                      target="_blank"
                    >{{ $t('partner.terms.of.use') }}</a>
                  </div>
                </span>
              </label>
          </div>
          <div class="ds-modal__box__action-bar">
            <button
              class="ds-button ds-button--primary ds-m-none"
              type="button"
              @click=" submitPublish()"
            >
              {{ submitText }}
            </button>
            <button
              class="ds-button ds-button--secondary ds-m-none ds-m-l-m"
              type="button"
              @click="clearAndCancel"
            >
              {{ cancelText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.textarea-modal {
  z-index: 101;
}
.modal-container-inner-textarea {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 200px);
}
.modal-box {
  max-width: 480px;
  height: fit-content;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);

  .modal-text-red {
    color: #bb3e10;
  }

  .ds-modal__box__content {
    margin: 32px;
    margin-top: .625rem;

    .char-left-container {
      justify-content: flex-end;
    }

    .ds-textField {
      margin-top: 20px;
      margin-bottom: 0;

      textarea {
        resize: none;
        height: 105px;
      }
    }
  }

  .with-char-left {
    margin-bottom: 14px;
  }

  .ds-modal__box__action-bar {
    padding: 0 32px;
  }
}

.modal-text {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
}
.ds-modal__box__header__heading {
  &:extend(.font-expanded);
  font-size: 21px;
  line-height: 27px;
}
.confirm-message{
  display: inline-block;
  padding-left: 8px;
}
.checkbox-area{
  margin-top: 12px !important;
}
.check-box{
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;
}
.check-box-text{
  vertical-align: top !important;
}
</style>
