<script>
const MAX_ITEM_DEFAULT_SHOW = 4;
export default {
  name: 'PFServiceCompetencyComponent',
  data: () => ({
    showAll: false,
    checkedBoxes: [],
  }),
  props: {
    editMode: { type: Boolean, default: false },
    competencies: { type: Array, default: () => [] },
  },
  methods: {
    groupBy(objectArray, property) {
      return objectArray.reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(obj);
        return acc;
      }, {});
    },
    addUpTotal(groupArray, property) {
      const groupedByNameArray = this.groupBy(groupArray, 'name');
      if (Object.keys(groupedByNameArray).includes(property)) {
        const objectArray = groupedByNameArray[property];
        return objectArray.reduce((total, obj) => total + obj.total, 0);
      }
      return '0';
    },
    getUniqueValue(objectArray, property) {
      return [...new Set(objectArray.map((item) => item[property]))];
    },
    objectToArray(obj) {
      return Object.keys(obj).map((k) => obj[k]);
    },
    sortByTier(arr) {
      const levelOrder = ['EXPERT', 'ADVANCED', 'BASIC'];
      arr.sort((a, b) => levelOrder.indexOf(a.tier) - levelOrder.indexOf(b.tier));
    },
    sortLabel(arr) {
      const levelOrder = ['EXPERT', 'ADVANCED', 'BASIC'];
      arr.sort((a, b) => levelOrder.indexOf(a[0].tier) - levelOrder.indexOf(b[0].tier));
    },
    convertToLowerCase(text) {
      return text.charAt(0) + text.slice(1).toLowerCase();
    },
    showRegion(groupArr, tier) {
      let result = '';
      const targetTier = this.groupBy(groupArr, 'tier')[tier];
      const regionArr = this.getUniqueValue(targetTier, 'region').sort();
      for (let index = 0; index < regionArr.length; index++) {
        (index === regionArr.length - 1) ? result += regionArr[index] : result += `${regionArr[index]} and `;
      }
      return result;
    },
  },
  computed: {
    getItems() {
      if (!this.editMode && this.groupedCompentency.length > MAX_ITEM_DEFAULT_SHOW && !this.showAll) {
        this.showAll = false;
        return this.groupedCompentency.slice(0, MAX_ITEM_DEFAULT_SHOW);
      }
      this.showAll = true;
      return this.groupedCompentency;
    },
    groupedCompentency() {
      const groupedCompetencyArray = this.objectToArray(this.groupBy(this.competencies, 'group'));
      groupedCompetencyArray.map((item) => this.sortByTier(item));
      this.sortLabel(groupedCompetencyArray);
      return groupedCompetencyArray;
    },
  },

};
</script>

<template>
  <div class="competency-container">
    <div class="competency-header ds-m-y-s" :class="editMode ? 'edit-color' : 'view-header-color'">
      {{$t('cloud.competency.header.service')}}
    </div>

    <div class="competency-data-container ds-m-l-xxs">

      <div v-show="getItems.length > 0" v-for="data in getItems" :key="data[0].id" class="competency-data ds-m-b-s">
          <div class="competency-checkbox-container">
            <input
              :id="data[0].id"
              :value="data[0].id"
              type="checkbox"
              name="items"
              class="competency-checkbox"
              v-model="checkedBoxes" />

            <label :for="data[0].id" class="competency-checkbox-label">
              <span>
                <i class="ds-icon competency-plus-icon ds-m-r-xs"
                   :class="checkedBoxes.includes(data[0].id) ? 'ds-icon--minus' : 'ds-icon--plus'">
                </i>
              </span>
              <div>
                <span class="competency-name" :class="
                [
                  editMode ? 'edit-color' : 'view-name-color',
                  checkedBoxes.includes(data[0].id) ? 'competency-name-expanded' : ''
                ]">
                  {{data[0].group}}
                </span>
                &nbsp;
                <span class="competency-level" :class="editMode ? 'edit-color' : 'view-level-color'">
                  {{convertToLowerCase(data[0].tier)}}
                </span>
              </div>
            </label>
          </div>

          <div v-show="checkedBoxes.includes(data[0].id)" class="competency-data-expanded">
            <div class="competency-region ds-m-b-xxs">{{showRegion(data, data[0].tier)}}</div>
            <div v-if="getUniqueValue(data, 'tier').length > 1">
              <div v-for="tier in getUniqueValue(data, 'tier').slice(1)" :key="tier"
                   class="competency-basic ds-m-b-xxs">
                <span class="competency-name-expanded" :class="editMode ? 'edit-color' : 'view-name-color'">
                  {{ data[0].group }}
                </span>                &nbsp;
                <span class="competency-level" :class="editMode ? 'edit-color' : 'view-level-color'">
                  {{ convertToLowerCase(tier) }}
                </span>
                <div class="competency-region">{{ showRegion(data, tier) }}</div>
              </div>
            </div>

            <div class="competency-consultant ds-m-b-xxs">
              <div class="competency-number competency-name-expanded"
                   :class="editMode ? 'edit-color' : 'view-name-color'">
                {{ addUpTotal(data, 'SAP Certified Consultants') }}
              </div>
              <span class="competency-name" :class="editMode ? 'edit-color' : 'view-name-color'">
                {{ $t("cloud.competency.consultants") }}</span>
            </div>
            <div class="competency-cloud">
              <div class="competency-number competency-name-expanded"
                   :class="editMode ? 'edit-color' : 'view-name-color'">
                {{ addUpTotal(data, 'Cloud Implementations') }}
              </div>
              <span class="competency-name" :class="editMode ? 'edit-color' : 'view-name-color'">
                {{ $t("cloud.competency.clouds") }}
              </span>
            </div>
          </div>
      </div>

      <div class="show-all-button" v-show="!editMode && !showAll">
        <a
          href=""
          class="competency-name"
          @click.prevent="showAll = !showAll"
        >
          {{ $t("cloud.competency.show.more") }}
        </a>
      </div>

      <div v-show="competencies.length === 0" class="competency-data-null"
           :class="editMode ? 'edit-color' : 'view-name-color'"
      >
        &mdash;
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";

.competency-container {
  display: -moz-box;
  display: -webkit-box;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  min-height: 0 !important;
  cursor: default;
}
.competency-checkbox-container {
  position: relative;
  display: flex;
}
.competency-header {
  &:extend(.font-expanded);
  font-size: 21px;
  line-height: 27px;
}
.competency-data-container {
  font-size: 16px;
  line-height: 21px;
}
.competency-plus-icon::before {
  color: @color-glance-consultants;
  font-size: 14px;
}
.competency-data-expanded {
  margin-left: 22px;
}
.competency-checkbox {
  opacity: 0;
  z-index: -1;
}
.competency-checkbox-label {
  position: relative;
  display: flex;
  left: -20px;
  cursor: pointer;
}
.competency-region {
  &:extend(.font-bold);
  font-size: 12px;
  line-height: 14px;
  color: @color-gray-4;
}
.competency-number {
  display: inline-block;
 min-width: 34px;
}

.competency-checkbox-label:hover {
  .view-name-color {
    color: @color-link;
  }
  .competency-plus-icon::before {
    color: @color-link;
  }
}

.competency-name {
  &:extend(.font-normal);
}
.competency-name-expanded {
  &:extend(.font-expanded);
}
.competency-level {
  &:extend(.font-bold-expanded);
}
.competency-data-null {
  &:extend(.font-normal);
}

.edit-color {
  color: @color-gray-3;
}
.view-header-color {
  color: @black-color;
}
.view-name-color {
  color: @black-color;
}
.view-level-color {
  color: @color-link;
}
</style>
