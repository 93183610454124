<script>
/* eslint-disable vue/custom-event-name-casing */
import { mapState } from 'vuex';
import PFPopUpComponent from '@/components/common/PFPopUpComponent';
import PEModalWithTextAreaComponent from '@/components/common/PEModalWithTextAreaComponent';
import PEValidationComponent from '@/components/editor/PEValidationComponent';
import PEProfileNotificationComponent from '@/components/editor/PEProfileNotificationComponent';

export default {
  name: 'PEFooterComponent',
  components: {
    PFPopUpComponent,
    PEModalWithTextAreaComponent,
    PEValidationComponent,
    PEProfileNotificationComponent,
  },
  props: {
    editMode: { type: Boolean, default: true },
  },
  data: () => ({
    isHqAddressEdited: false,
    isPopupShown: false,
    isPublishModalShown: false,
    isValidationDropUpShown: false,
    triggerSurveyAfterPublish: false,
  }),
  methods: {
    publishProfileData() {
      this.triggerSurveyAfterPublish = true;
      if (this.cancelSessionExtendingClicked) {
        this.$store.commit('userProfile/setSessionExpired', true);
        return;
      }
      this.$store.dispatch('partnerProfile/publishProfile');
      this.hidePopup();
    },
    saveProfileData() {
      if (this.cancelSessionExtendingClicked) {
        this.$store.commit('userProfile/setSessionExpired', true);
        return;
      }
      this.$eventBus.$emit('footerSaveClicked');
      this.pfProfileData.id && this.pfProfileData.status !== 'PUBLISHED'
        ? this.patchData()
        : this.postData();
    },
    discardProfileData() {
      this.$eventBus.$emit('footerDiscardClicked');
      this.$store.dispatch('partnerProfile/findEditableProfileByProfileId', this.$route.params.profileId);
      this.hidePopup();
    },
    postData() {
      this.$store.dispatch('partnerProfile/postProfileData');
    },
    patchData() {
      this.$store.dispatch('partnerProfile/patchProfileData');
    },
    showPopup() {
      if (this.cancelSessionExtendingClicked) {
        this.$store.commit('userProfile/setSessionExpired', true);
        return;
      }
      if (this.profileChangedData.size > 0 || this.isHqAddressEdited) {
        this.isPopupShown = true;
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      }
    },
    validateAndPublish() {
      if (this.cancelSessionExtendingClicked) {
        this.$store.commit('userProfile/setSessionExpired', true);
        return;
      }
      this.$eventBus.$emit('footerPublishClicked');
      if (this.errorFields.length === 0) {
        setTimeout(() => {
          this.errorFields.length > 0 ? this.showValidationDropUp() : this.showPublishModal();
        });
      } else {
        this.showValidationDropUp();
      }
    },
    showPublishModal() {
      this.isPublishModalShown = true;
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
    },
    hidePopup() {
      this.isPopupShown = false;
      this.isPublishModalShown = false;
      document.body.style.overflow = 'scroll';
      document.body.style.paddingRight = '0px';
    },
    showValidationDropUp() {
      this.isValidationDropUpShown = true;
    },
    hideValidationDropUp(event) {
      if (event && event.target.id === 'publish-button') {
        return;
      }
      this.isValidationDropUpShown = false;
    },
    toggleValidationDropUp() {
      this.isValidationDropUpShown = !this.isValidationDropUpShown;
    },
    closeMessage() {
      this.$store.dispatch('partnerProfile/updateAllMessages', false);
    },
    getFooterMessage() {
      if (this.saveSuccess) {
        return this.$t('editor.footer.message.success');
      }
      if (this.publishSuccess) {
        return this.$t('editor.footer.message.publish.success');
      }
      const errorMessage = `editor.footer.message.${this.errorPath}`;
      return this.$t(errorMessage);
    },
    isSuccessfulResponse() {
      return this.saveSuccess || this.publishSuccess;
    },
    getErrorMessage() {
      const errorMessage = `editor.footer.error.message${this.errorFields.length > 1 ? '.plural' : ''}`;
      return `${this.errorFields.length}
      ${this.$t(errorMessage)}`;
    },
    sendEmailBackendError() {
      const subject = encodeURIComponent(this.$tc('help.mail.body.backend.error.subject'));
      const profileId = this.pfProfileData.profileId ? this.pfProfileData.profileId : 'N/A';
      const userId = this.profileAuthorizations?.userName ? this.profileAuthorizations.userName : 'N/A';
      const pageTitle = this.$route.name;
      const url = window.location.href;
      this.$sendEmail.constructor.sendEmailBackendError(subject, profileId, userId, pageTitle, url);
    },
    openProfile() {
      const route = this.$router.resolve({
        path: `/profile/${this.$route.params.profileId}`,
      });
      window.open(route.href, '_self');
    },
    triggerQualtricsSurvey() {
      // eslint-disable-next-line no-underscore-dangle
      window._qsie = [];
      // eslint-disable-next-line no-underscore-dangle
      window._qsie.push('AfterPublish');
      if (typeof QSI !== 'undefined') {
        QSI.API.unload();
        QSI.isDebug = false;
        QSI.API.load();
        QSI.API.run();
      }
      window.setTimeout(() => { window._qsie.push('AfterPublish'); }, 1000);
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      pfProfileData: (state) => state.profileData,
      profileChangedData: (state) => state.profileChangedData,
      changedData: (state) => state.changedData,
      errorFields: (state) => state.errorFields,
      isMessageShow: (state) => state.isMessageShow,
      saveSuccess: (state) => state.saveSuccess,
      publishSuccess: (state) => state.publishSuccess,
      errorPath: (state) => state.errorPath,
      customErrorMessage: (state) => state.customErrorMessage,
      videoContainerLoading: (state) => state.videoContainerLoading,
    }),
    ...mapState('userProfile', {
      profileAuthorizations: (state) => state.user.profileAuthorizations,
      cancelSessionExtendingClicked: (state) => state.cancelSessionExtendingClicked,
    }),
  },
  watch: {
    isMessageShow(val) {
      if (val && (this.saveSuccess || this.publishSuccess)) {
        window.setTimeout(this.closeMessage, 5000);
      }
      if (val && this.triggerSurveyAfterPublish) {
        this.triggerQualtricsSurvey();
        this.triggerSurveyAfterPublish = false;
      }
    },
    errorFields: {
      handler(val) {
        if (val.length === 0) {
          this.hideValidationDropUp();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$eventBus.$on('hqAddressEditingStarted', () => {
      this.isHqAddressEdited = true;
    });
    this.$eventBus.$on('hqAddressEditingFinished', () => {
      this.isHqAddressEdited = false;
    });
  },
};
</script>

<template>
  <div class="editor-footer sticky-edit-edit-footer" id="editor__footer">
    <div class="editor-footer-container">
      <PEProfileNotificationComponent
        v-if="editMode && pfProfileData.localProfiles && pfProfileData.localProfiles.length > 1"/>
      <div v-else class="footer-space"></div>
      <div class="editor-footer-container-button">
      <PFPopUpComponent
        :isShown="isPopupShown"
        :text="$t('helptext.partner.editor.discard.agreement.message')"
        :submitText="
          $t('helptext.partner.editor.discard.agreement.submitButton')
        "
        :cancelText="
          $t('helptext.partner.editor.discard.agreement.cancelButton')
        "
        :headerText="$t('helptext.partner.editor.discard.agreement.title')"
        :submitAction="discardProfileData"
        :cancelAction="hidePopup"/>

      <PEModalWithTextAreaComponent
        :isShown="isPublishModalShown"
        :headerText="$t('editor.publish.modal.title')"
        :text="$t('editor.publish.modal.message.pilot')"
        :textareaPlaceholder="$t('editor.publish.modal.placeholder')"
        :confirmMessage="$t('editor.publish.modal.checkbox.first.line')"
        :maxLength="150"
        :submitText="$t('button.publish')"
        :cancelText="$t('button.cancel')"
        :submitAction="publishProfileData"
        :cancelAction="hidePopup"
      />

      <div
        v-if="isMessageShow"
        :class="[
          isSuccessfulResponse() ?
          'ds-toast-message--success border-success ' :
          'ds-toast-message--error border-error ',
          'ds-toast-message editor-footer--message',
        ]"
      >
        <div class="ds-message__content footer-error-message" id="editor_footer__message">
          <div class="footer-message-container">
            <div
              :class="[
                isSuccessfulResponse() ?
                'message-success-color ' :
                'message-error-color ',
                'footer-message-bold-container',
              ]"
            >
              <span class="font-bold">{{ getFooterMessage() }}</span>
              <span
                v-show="publishSuccess"
                class="open-profile-link"
                @click="openProfile">
                {{ $t('editor.footer.message.publish.success.open.profile') }}
              </span>
              <span
                v-show="!isSuccessfulResponse()"
                class="font-normal">
                {{ $t('editor.footer.message.error.further.actions') }}
              </span>
            </div>
            <div class="font-normal footer-message-normal-container"
              v-if="!isSuccessfulResponse() && customErrorMessage == 'contact.admin'"
            >
              <span>{{ $t('editor.footer.message.contact.admin') }}</span>
              <span>{{ $t('editor.footer.message.contact') }}</span>
              <a class="footer-contact-email" href=""  @click.prevent="sendEmailBackendError()">
                <u>{{ $t('editor.footer.message.contact.email') }}</u></a>
              <span>{{ $t('editor.footer.message.contact.b') }}</span>
            </div>
            <div class="font-normal footer-message-normal-container"
              v-if="!isSuccessfulResponse() && customErrorMessage === 'validation.error'"
            >
              {{ $tc('editor.footer.message.validation.error', errorFields.length) }}
            </div>
            <div class="font-normal footer-message-normal-container"
              v-if="!isSuccessfulResponse() &&
              (customErrorMessage === 'profile.updated.sametime' || customErrorMessage === 'come.later'
                || customErrorMessage === 'network.error')"
            >
              {{ $t(`editor.footer.message.${customErrorMessage}`) }}
            </div>
          </div>
          <i
            v-show="!isSuccessfulResponse()"
            class="ds-icon ds-icon--close footer-message-icon"
            @click="closeMessage"
          >
          </i>
        </div>
      </div>

      <div class="errors-container" id="editor_footer__validations">
        <PEValidationComponent
          :isShown="isValidationDropUpShown"
          :errorFields="errorFields"
          :errorMessage="this.getErrorMessage()"/>

        <div
          v-if="errorFields.length > 0"
          class="ds-message__content editor-warning-message"
          @click="toggleValidationDropUp"
          v-click-outside="hideValidationDropUp"
        >
          <i class="ds-icon--big ds-icon--close-outline ds-message__content__icon"/>
          {{ getErrorMessage() }}
        </div>
        <div v-if="isValidationDropUpShown" class="ds-bg-gold ds-bg-line"/>
      </div>

      <button
        id="publish-button"
        class="ds-button ds-button--inline ds-button--primary editor-publish-button ds-m-l-m"
        type="button"
        @click="validateAndPublish"
        :disabled="!changedData && pfProfileData.status === 'PUBLISHED' || videoContainerLoading"
      >
        {{ $t('button.publish') }}
      </button>
      <button id="editor_primary_save"
              class="ds-button ds-button--inline ds-button--secondary editor-save-button ds-m-l-m"
              type="button"
              @click="saveProfileData"
              :disabled="!changedData || videoContainerLoading"
      >
        {{ $t('button.save') }}
      </button>
      <button id="editor_primary_discard"
              class="ds-button ds-button--inline ds-button--tertiary editor-discard-button ds-m-l-m ds-m-r-xl ds-p-none
        ds-p-y-xs ds-p-l-xs"
              type="button"
              @click="showPopup"
              :disabled="!changedData && !isHqAddressEdited|| videoContainerLoading"
      >
        {{ $t('button.discard') }}
      </button>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import '../../assets/css/common.less';

.editor-footer {
  min-height: 80px;
  max-height: 80px;
  height: 80px;
  width: 100%;
  background-color: @light-grey;
  display: flex;
  justify-content: center;
.editor-footer-container {
    display: flex;
    justify-content: space-between;
    -moz-box-orient: horizontal;
    -webkit-box-orient: horizontal;
    -moz-box-align: center;
    -webkit-box-align: center;
    width: 100%;
    max-width: 1784px;
  }
  .editor-footer-container-button {
    display: -moz-box;
    display: -webkit-box;
    -moz-box-pack: end;
    -webkit-box-pack: end;
    -moz-box-orient: horizontal;
    -webkit-box-orient: horizontal;
    -moz-box-align: center;
    -webkit-box-align: center;
    width: fit-content;
  }
}

.errors-container {
  display: flex;
  position: relative;
  height: 100%;

  .ds-message__content {
    padding: 12px 0;
  }

  .ds-bg-line {
    height: 4px;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: @color-sap-orange;
  }
}

.editor-button {
  display: inline-block;
  width: fit-content;
  height: 42px;
}

.editor-save-button {
  &:extend(.editor-button);
  margin-right: 0;
}

.editor-publish-button {
  &:extend(.editor-button);
  margin-right: 0;
  margin-left: 32px !important;
}

.editor-discard-button {
  &:extend(.editor-button);
  color: @color-link;
  border: none;
  background-color: transparent !important;
}

.editor-discard-button:hover {
  color: @color-link__hover;
}

.editor-discard-button:disabled {
  color: @color-gray-4;
}

.sticky-edit-edit-footer {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 100;
}

.editor-warning-message {
  &:extend(.font-expanded);
  font-size: 16px;
  line-height: 21px;
  color: @color-field-error;
  cursor: pointer;
}
.footer-contact-email {
  color: @color-editor-footer-error !important;
  cursor: pointer;
}
.footer-message-container {
  cursor: default;
}

.editor-footer--message {
  height: fit-content;
  border: none;
  position: absolute;
  bottom: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  max-width: 95%;
}

.footer-message-icon {
  margin-left: 10px;
  margin-right: 0;
  cursor: pointer;
  width: 15px;
  height: 15px;
}

.editor-footer .footer-error-message {
  width: 100%;
  white-space: pre-wrap !important;
}
.footer-message-bold-container, .footer-message-normal-container {
  display: inline-block;
}

.message-error-color {
  color: @color-red-dark;
}

.message-success-color {
  color: @color-green;
}

.border-error {
  border: 1px solid @color-editor-footer-error;
}

.border-success {
  border: 1px solid @color-green;
}

.open-profile-link {
  cursor: pointer;
  color: @color-link-show-more;
}
.open-profile-link:hover {
  color: @color-link__hover;
}
</style>
