<script>
import { mapMutations } from 'vuex';
import responseHandlerUtil from '@/plugins/search/responseHandler';

export default {
  name: 'SingleSelectDropDownComponent',
  props: {
    searchQuery: { type: String, default: () => null },
    activeOptions: { type: [Array, Object], default: () => [] },
    filterType: { type: String, default: '' },
    highlightText: { type: Function, default: () => true },
    isShown: { type: Boolean, default: false },
    closeFilter: { type: Function, default: () => true },
  },

  data: () => ({
    lock: false,
    clickLocation: false,
  }),
  methods: {
    ...mapMutations('profileSearch', {
      uncheckAllLocation: 'uncheckAllLocation',
    }),
    selectOption(_option) {
      if (this.searchQuery && this.searchQuery.length > 0) {
        this.clickLocation = true;
      }
      this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', true);
      const option = { ..._option };
      if (option.isSelected) {
        option.optionType = this.filterType;
        this.$store.dispatch('profileSearch/uncheckOption', option);
      } else {
        this.uncheckAllLocation();
        const dispatchPayload = { optionType: this.filterType, value: option };
        this.$store.dispatch('profileSearch/addSelectedOption', dispatchPayload);
      }
      this.closeFilter();
    },
    addSpaceAfterComma(text) {
      return responseHandlerUtil.addSpaceAfterComma(text);
    },
  },
  watch: {
    isShown() {
      this.$nextTick(() => { this.$refs.dsDropdown.scrollTop = 0; });
    },
    searchQuery() {
      if (!this.lock) {
        this.lock = true;
        setTimeout(() => {
          if (!this.clickLocation) {
            this.$store.dispatch(
              'profileSearch/updateLocations',
              this.searchQuery,
            );
          } else {
            this.clickLocation = false;
          }
          this.lock = false;
        }, 500);
      }
    },
  },
};
</script>

<template>
  <div class="ds-dropdown__options-wrapper" ref="dsDropdown">
    <label
      :for="filterType + shownOption.title"
      v-bind:key="shownOption.title"
      class="ds-checkbox-multiselect ds-checkbox"
      v-for="shownOption in activeOptions.filter(option => option.isVisible !== false)"
      @click="selectOption(shownOption)"
    >
      <input
        :id="filterType + shownOption.title"
        type="checkbox"
        class="ds-checkbox__control"
        :checked="shownOption.isSelected"
        v-bind:value="shownOption"
        @click.stop
      />
      <span class="ds-checkbox__info ds-checkbox__label search-drop-down-margin">
        <span
          class="ds-checkbox__title"
          v-html="highlightText(addSpaceAfterComma(shownOption.title))">
        </span>
        <span
          v-if="shownOption.description"
          class="ds-checkbox__description"
          v-html="highlightText(shownOption.description)">
        </span>
      </span>
    </label>
  </div>
</template>
<style lang="less" scoped>
.search-drop-down-margin {
  margin-left: 6px;
}
</style>
