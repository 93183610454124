<script>
export default {
  name: 'PFPopUpComponent',
  props: {
    isShown: { type: Boolean, default: false },
    headerText: { type: String, default: '' },
    submitText: { type: String, default: '' },
    cancelText: { type: String, default: '' },
    text: { type: String, default: '' },
    textSecond: { type: String, default: '' },
    submitAction: { type: Function, default: () => false },
    cancelAction: { type: Function, default: () => false },
    submitButtonClass: { type: String, default: 'ds-button--destructive' },
    cancelButtonClass: { type: String, default: 'ds-button--secondary discard-cancel-button' },
    headerIconClass: { type: String, default: '' },
    checkBoxLabel: { type: String, default: '' },
    checkBoxParentValue: { type: Boolean, default: false },
    checkBoxToggleAction: { type: Function, default: () => {} },
  },
  watch: {
    isShown() {
      if (this.isShown === true) {
        document.body.style.overflow = 'hidden';
        window.setTimeout(() => this.$refs.deleteModal.focus(), 0);
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      } else {
        document.body.style.overflow = 'scroll';
        document.body.style.paddingRight = '0px';
      }
    },
    checkBoxParentValue(val) {
      this.isCheckBoxChecked = val;
    },
    isCheckBoxChecked(val) {
      this.checkBoxToggleAction(val);
    },
  },
  mounted() {
    this.isCheckBoxChecked = this.checkBoxParentValue;
  },
  data: () => ({
    isCheckBoxChecked: false,
  }),
};
</script>
<template>
<div class="ds-modal" :class="isShown ? 'ds-modal--active' : ''"
     ref="deleteModal" tabindex="0" @keyup.esc="cancelAction"
>
  <div class="modal-container">
    <div class="modal-container-inner-session">
      <div class="ds-modal__box leave-dialog-modal">
        <div class="ds-modal__box__header ds-p-y-none ds-p-x-l">
          <i v-if="headerIconClass" class="ds-icon" :class="headerIconClass"/>
          <img v-else class="icon__img" src="../../assets/icons/delete.svg">
          <div class="ds-modal__box__header__heading">{{headerText}}</div>
        </div>
        <div class="ds-modal__box__content">
          <div class="dialog-text ds-m-t-s">
            <div class="dialog-text"> {{ text }}</div>
            <div v-if="textSecond" class="ds-m-t-s dialog-text">{{ textSecond }}</div>
          </div>
          <div class="ds-m-t-m info-check-box" v-if="checkBoxLabel">
            <label class="ds-checkbox"
                for="set-popup-checkbox">
                <input
                  class="ds-checkbox__control"
                  type="checkbox"
                  id="set-popup-checkbox"
                  v-model="isCheckBoxChecked"
                />
                <span class="ds-checkbox__box">
                  <i class="ds-icon ds-icon--checked"></i>
                </span>
                <span class="ds-checkbox__label">
                  {{ checkBoxLabel }}
                </span>
            </label>
          </div>
        </div>
        <div class="ds-modal__box__action-bar ds-p-y-none">
          <button
            class="ds-modal__box__action-bar__action ds-button ds-m-x-none"
            :class="submitButtonClass"
            type="button"
            @click="submitAction"
            v-if="submitText"
          >
            {{ submitText }}
          </button>
          <button
            class="ds-modal__box__action-bar__action ds-button ds-m-none ds-m-l-m"
            :class="cancelButtonClass"
            type="button"
            @click="cancelAction"
            v-if="cancelText"
          >
            {{ cancelText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style lang="less" scoped>
.ds-modal--active {
    z-index: 150;
    cursor: default;
}
.info-check-box {
  margin-bottom: 6px;
  margin-left: -6px;
}
.ds-modal__box__header {
  margin-top: 4px;
}
.modal-container-inner-session {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 142px);
}
.leave-dialog-modal {
  text-align: left;
  max-width: 480px;
  height: fit-content;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  .ds-modal__box__header__heading {
    padding-left: 16px;
  }
  .guidance-text-header {
    &:extend(.font-expanded);
    line-height: 35px;
    color: @color-grey-0;
  }
  .dialog-text, .info-check-box {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
    color: @color-gray-1;
  }
  .ds-button {
    &:extend(.font-normal);
    font-size: 16px;
    max-width: none;
    width: fit-content !important;
  }
  .ds-icon--info::before {
    color: @color-link;
    font-size: 32px;
    line-height: 32px;
    position: relative;
  }
  .icon__img {
    width: 32px;
    position: relative;
  }
  .ds-modal__box__content {
    margin-top: -5px;
    margin-bottom: 36px;
    padding-left: 48px;
  }
}

</style>
