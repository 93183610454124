/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import { createRouter as _createRouter, createWebHistory } from 'vue-router';
import PFHeaderComponent from '../components/common/PFHeaderComponent';
import PFHomePage from '../pages/PFHomePage';
import PFFooterComponent from '../components/common/PFFooterComponent';
import PFPartnerNavigatorPage from '../pages/PFPartnerNavigatorPage';
import PFProfilePage from '../pages/PFProfilePage';
import PFEditorPage from '../pages/PFEditorPage';
import PEHeaderComponent from '../components/editor/PEHeaderComponent';
import PEFooterComponent from '../components/editor/PEFooterComponent';
import PEContentAreaComponent from '../components/editor/PEContentAreaComponent';
import PEPagePreviewComponent from '../components/editor/PEPagePreviewComponent';
import PFNotFoundPage from '../pages/PFNotFoundPage';
import PFPrivacyComponent from '../components/common/PFPrivacyComponent';
import PETermsOfUseComponent from '../components/common/PETermsOfUseComponent';

export default _createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (
      (to.path.endsWith('/preview') && from.path.endsWith('/edit'))
      || (to.path.endsWith('/edit') && from.path.endsWith('/preview'))
      || (to.name === 'home' && from.name === 'home')
    ) {
      console.log('remain current page position');
    } else {
      return {
        left: 0,
        top: 0,
      };
    }
  },
  history: createWebHistory(),
  routes: [
    {
      path: '/client_id:id(.*)',
      name: 'homePage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFHomePage,
        sitefooter: PFFooterComponent,
      },
      meta: {
        showSearchBar: false,
      },
    },
    {
      path: '/',
      name: 'home',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFHomePage,
        sitefooter: PFFooterComponent,
      },
      meta: {
        showSearchBar: false,
      },
    },
    {
      path: '/partnerNavigator:_(\\?.*)?',
      name: 'partnerNavigatorPage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFPartnerNavigatorPage,
        sitefooter: PFFooterComponent,
      },
      props: (route) => ({ queryData: route.q }),
    },
    {
      path: '/pfeditor',
      name: 'pfeditorPage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFEditorPage,
        sitefooter: PFFooterComponent,
      },
    },
    {
      path: '/editor/:profileId(\\d+)',
      name: 'profileEditorPage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFEditorPage,
        sitefooter: PFFooterComponent,
      },
      children: [
        {
          path: 'edit',
          name: 'profileEditorPageEdit',
          components: {
            editorPageHeader: PEHeaderComponent,
            editorPageContent: PEContentAreaComponent,
            editorPageFooter: PEFooterComponent,
          },
          props: { editorPageFooter: { editMode: true } },
        },
        {
          path: 'preview',
          name: 'profileEditorPagePreview',
          components: {
            editorPageHeader: PEHeaderComponent,
            editorPageContent: PEPagePreviewComponent,
            editorPageFooter: PEFooterComponent,
          },
          props: { editorPageFooter: { editMode: false } },
        },
      ],
    },
    {
      path: '/profile/:profileId(\\d+)',
      name: 'profilePage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFProfilePage,
        sitefooter: PFFooterComponent,
      },
    },
    {
      path: '/404',
      name: 'notFoundPage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFNotFoundPage,
        sitefooter: PFFooterComponent,
      },
      props: {
        contentarea: { mode: '404' },
      },
    },
    {
      path: '/NotAllowed',
      name: 'editorNotFoundPage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFNotFoundPage,
        sitefooter: PFFooterComponent,
      },
      props: {
        contentarea: { mode: '403', isFromPfEditor: true },
      },
    },
    {
      path: '/400',
      name: 'badRequestPage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFNotFoundPage,
        sitefooter: PFFooterComponent,
      },
      props: {
        contentarea: { mode: '400' },
      },
    },
    {
      path: '/401',
      name: 'unauthorizedPage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFNotFoundPage,
        sitefooter: PFFooterComponent,
      },
      props: {
        contentarea: { mode: '401' },
      },
    },
    {
      path: '/403/:forbiddenCodes*',
      name: 'accessRestrictedPage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFNotFoundPage,
        sitefooter: PFFooterComponent,
      },
      props: {
        contentarea: { mode: '403' },
      },
    },
    {
      path: '/500',
      name: 'systemErrorPage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFNotFoundPage,
        sitefooter: PFFooterComponent,
      },
      props: {
        contentarea: { mode: '500' },
      },
    },
    {
      path: '/networkError',
      name: 'networkErrorPage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFNotFoundPage,
        sitefooter: PFFooterComponent,
      },
      props: {
        contentarea: { mode: 'networkError' },
      },
    },
    {
      path: '/maintenance',
      name: 'maintenancePage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFNotFoundPage,
        sitefooter: PFFooterComponent,
      },
      props: {
        contentarea: { mode: 'systemMaintenance' },
      },
    },
    {
      path: '/503',
      name: 'systemUnavailablePage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFNotFoundPage,
        sitefooter: PFFooterComponent,
      },
      props: {
        contentarea: { mode: '503' },
      },
    },
    {
      path: '/browserNotSupported',
      name: 'browserNotSupportedPage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFNotFoundPage,
        sitefooter: PFFooterComponent,
      },
      props: {
        contentarea: { mode: 'browserNotSupported' },
      },
    },
    {
      path: '/privacy',
      name: 'privacyStatementPage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFPrivacyComponent,
        sitefooter: PFFooterComponent,
      },
    },
    {
      path: '/partner-terms-of-use',
      name: 'partnerTermsOfUsePage',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PETermsOfUseComponent,
        sitefooter: PFFooterComponent,
      },
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/404',
      components: {
        siteheader: PFHeaderComponent,
        contentarea: PFNotFoundPage,
        sitefooter: PFFooterComponent,
      },
    },
  ],
});
