<script>
export default {
  name: 'PFResultConsultantComponent',
  data: () => ({

  }),
  props: {
    competencyMode: { type: Boolean, default: false },
    cloudImplementationMode: { type: Boolean, default: false },
    consultantMode: { type: Boolean, default: false },
    number: { type: Number, default: 0 },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
  },

};
</script>

<template>
  <div class="consultant-container">
    <div class="consultant-number">{{number > 0 ? numberWithCommas(number) : '&mdash;'}}</div>
    <div class="consultant-text" v-if="competencyMode" v-html="$tc('search.result.competency', number)"/>
    <div class="consultant-text" v-if="cloudImplementationMode" v-html="$tc('search.result.cloud', number)"/>
    <div class="consultant-text" v-if="consultantMode" v-html="$tc('search.result.consultant', number)"/>
  </div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";
.consultant-container {
  text-align: center;
  -moz-box-flex: 1;
  -webkit-box-flex: 1;
}
.consultant-number {
  &:extend(.font-expanded);
  color: @color-gray-1;
  font-size: 27px;
  line-height: 35px;
  margin-bottom: 4px;
}
.consultant-text {
  &:extend(.font-normal);
  color: @black-color;
  font-size: 14px;
  line-height: 18px;
}

</style>
