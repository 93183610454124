<script>
import PFIECompetencyItemComponent from './PFIECompetencyItemComponent';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFIECompetencyComponent',
  data: () => ({
    faqLink: urlLinks.HELP_TEXT_LEARN_MORE.COMPETENCIES,
    tooltipOpen: false,
    competency_modal_data: {},
    competency_level: 'EXPERT',
  }),
  props: {
    ieSpecializations: { type: Array, default: () => [] },
    level: { type: String, default: () => '' },
  },
  components: {
    PFIECompetencyItemComponent,
  },
  methods: {
    toggleModal(val) {
      this.showProfileCompetencyModal = val;
    },
    toggleTooltip() {
      this.tooltipOpen = false;
    },
    openLearnMore() {
      const win = window.open(this.faqLink, '_blank');
      win.focus();
    },
  },
  computed: {
    populatedCompetencies() {
      return this.$store.state.solutionL2Store.ieCompetency
        .filter((c) => this.ieSpecializations.find((c2) => c.id === c2.id))
        .map((c) => {
          const spec = this.ieSpecializations.find((s) => s.id === c.id);
          return { ...c, name: spec.name, projectsNum: spec.projectsNum };
        })
        .sort((a, b) => a.sortOrder - b.sortOrder);
    },
    leftColumnProcesses() {
      if (!this.populatedCompetencies || this.populatedCompetencies?.length <= 1) {
        return this.populatedCompetencies ? this.populatedCompetencies : [];
      }
      const leftNum = Math.ceil(this.populatedCompetencies.length / 2);
      return this.populatedCompetencies.slice(0, leftNum);
    },
    rightColumnProcesses() {
      if (!this.populatedCompetencies || this.populatedCompetencies?.length <= 1) {
        return [];
      }
      const rightNum = Math.ceil(this.populatedCompetencies.length / 2);
      return this.populatedCompetencies.slice(rightNum);
    },
  },
};
</script>

<template>
  <div class="solution-section-title ds-m-b-m" id="competency-section-header-for-scoll">
    <span>
      {{$t('ie.competency.title')}}
    </span>
    <div class="solution-logo competency-level-img-container">
        <img v-if="level.toUpperCase() === 'ADVANCED'" alt=""
                class="competency-level-img competency-level2-img" src="../../assets/Competency-Level2.png"/>
        <img v-if="level.toUpperCase() === 'EXPERT'" alt=""
                class="competency-level-img competency-level3-img" src="../../assets/Competency-Level3.png"/>
    </div>
    <div class="tool-tip-container" v-click-outside="toggleTooltip">
          <i class="ds-icon ds-icon--info tool-tip-icon ds-m-r-xs"
        :class="tooltipOpen ? 'tool-tip-icon-clicked' : ''" @click="tooltipOpen=!tooltipOpen"></i>
        <div class='ds-tooltip-icon toolitp-icon-item-list'>
            <div
              :class="[tooltipOpen ? 'display-block' :
              'display-none','tooltiptext','ds-tooltip-icon__tooltip--service']">
              {{ $t('ie.competency.helptext') }}
              <span @click="openLearnMore" class="help-text-link">
                <b> {{$t('learn.more')}}</b>
              </span>
            </div>
          </div>
    </div>
  </div>
  <div class="solution-container">
    <div class="left-column solution-column">
      <PFIECompetencyItemComponent
        :ieCompetency="leftColumnProcesses"
      />
    </div>
    <div class="right-column solution-column">
      <PFIECompetencyItemComponent
        :ieCompetency="rightColumnProcesses"
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
.solution-section-title {
  &:extend(.font-expanded);
  text-align: center;
  font-size: 21px;
  line-height: 31px;
  color: #222222;
}

.solution-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 52px;
}

.solution-column {
  line-height: 75px;
  font-size: 30px;
}
.left-column {
margin-left: 26px;
margin-right: 26px;
}
.right-column {
margin-left: 26px;
margin-right: 26px;
}

.tool-tip-container {
  text-align: left;
  display: inline-block;
  position: relative;
  margin-left: 11px;
}

.solution-logo {
  margin-left: 12px;
  margin-top: 3px;
  width: 90px;
  text-align: left;
  display: inline-block;
  position: relative;
}

.competency-level-img-container {
  position: relative;
  height: 16px;
  width: 90px;
}
.competency-level-img {
  height: 16px;
  width: 90px;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.profile-competency-more-text:hover,.competency-content:hover {
  color: @color-link__hover;
  .competency-header, .description-text {
    color: @color-link !important;
  }
  .competency-level-img {
    padding-left: 90px;
  }
  .competency-level1-img {
    background: url('../../assets/Competency-lvl1-hover.png') no-repeat;
    background-size: 90px 16px;
  }
  .competency-level2-img {
    background: url('../../assets/Competency-lvl2-hover.png') no-repeat;
    background-size: 90px 16px;
  }
  .competency-level3-img {
    background: url('../../assets/Competency-lvl3-hover.png') no-repeat;
    background-size: 90px 16px;
  }
}

</style>
