<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import responseHandlerUtil from '@/plugins/search/responseHandler';

export default {
  name: 'PFBadgeBarComponent',
  methods: {
    ...mapActions('profileSearch', {
      cleanFilters: 'cleanFilters',
    }),
    ...mapActions('solutionL2Store', {
      unselectAllSolution: 'unselectAllSolution',
      removeSelectedL1Item: 'removeSelectedL1Item',
      removeSelectedL2Item: 'removeSelectedL2Item',
    }),
    ...mapMutations('solutionL2Store', {
      sortSolution: 'sortSolution',
    }),
    uncheckOption(option) {
      this.$store.dispatch('profileSearch/uncheckOption', option);
      if (option.optionType === 'location') {
        this.$store.commit('profileSearch/toggleOptionSelectedStatus', option);
        if (option.title.includes(',')) {
          this.$store.commit('profileSearch/deleteActiveLocation', option.title);
        }
      }
    },
    addSpaceAfterComma(text) {
      return responseHandlerUtil.addSpaceAfterComma(text);
    },
    uncheckSolutionOption(option) {
      if (this.solutionBadge.get(option).length > 0) {
        this.removeSelectedL2Item({ L1: this.solutionBadge.get(option), L2: option });
      } else {
        this.removeSelectedL1Item({ L1: option });
      }
      this.sortSolution();
    },
    getSolutionTitle(SolutionId) {
      const L1Title = this.reverseL2L1Mapping.get(SolutionId);
      if (L1Title) {
        return this.solutionDataMap.get(L1Title).L2Data.get(SolutionId).title;
      }
      return this.solutionDataMap.get(SolutionId).title;
    },
  },
  computed: {
    ...mapState('profileSearch', {
      selectedOptions: (state) => [
        ...state.selectedOptions.industry,
        ...state.selectedOptions.engagement,
        ...state.selectedOptions.location,
        ...state.selectedOptions.designation,
        ...state.selectedOptions.isCompetency,
      ],
      optionDisplayTitles: (state) => state.optionDisplayTitles,
    }),
    ...mapState('solutionL2Store', {
      solutionBadge: (state) => state.solutionBadge,
      solutionDataMap: (state) => state.solutionDataMap,
      reverseL2L1Mapping: (state) => state.reverseL2L1Mapping,
    }),
  },
};
</script>

<template>
  <div class="ds-filter-sort__badge-bar">
    <div v-for="option in solutionBadge.keys()" :item="option" :key="option">
      <div class="ds-badge" @click="uncheckSolutionOption(option)">
        <div class="ds-badge__title">{{ addSpaceAfterComma(getSolutionTitle(option)) }}</div>
        <i class="ds-m-l-xs ds-close"></i>
      </div>
    </div>
    <div v-for="option in selectedOptions" v-bind:item="option" :key="option.title">
      <div class="ds-badge" @click="uncheckOption(option)">
        <div class="ds-badge__title">{{ addSpaceAfterComma(optionDisplayTitles[option.title] ?? option.title) }}</div>
        <i class="ds-m-l-xs ds-close"></i>
      </div>
    </div>
    <div
      class="ds-filter-sort__configuration-bar__filters__clear-link ds-m-l-xs"
      v-bind:class="[selectedOptions.length > 0
      || [...solutionBadge.keys()].length > 0 ? 'ds-filter-sort__clear-token' : '' ]"
      @click="cleanFilters()"
    >Clear All</div>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.ds-filter-sort__badge-bar {
  margin: 5px 0 0 0 !important;
  flex-wrap: wrap !important;

  .ds-badge {
    height: 25px;
    border: solid 1px #eaeaea;
    background-color: #eaeaea;
    margin: 4px 14px 0 0 !important;

    .ds-badge__title {
      height: 18px;
      font-size: 12px;
      &:extend(.font-normal);
      line-height: 1.5;
      letter-spacing: normal;
      color: @color-black;
      text-transform: none;
    }
  }

  .ds-badge_radius {
    font-size: 0.556rem;
    border-radius: 40px;
  }

  .ds-filter-sort__clear-token {
    display: flex;
    margin: 4px 0 0 0 !important;
    height: 25px;
  }
}
</style>
