<script>
import { mapState } from 'vuex';
import AutocompleteComponent from './AutocompleteComponent';
import responseHandlerUtil from '@/plugins/search/responseHandler';

const DEFAULT_PARTNER_FINDER_SEARCH_PAGE = 'index.html';
const PARTNER_NAVIGATOR_PAGE = 'partnerNavigatorPage';
const SMALLSCREEN = 547;
const MIDSCREEN = 509;
const LARGECREEN = 707;
const PARTNER_NAME = 'Partner Name';
const KEYWORD = 'Keyword';
const DEFAULT_QUERY_TERM_TYPE = 'partner';
const KEYWORD_QUERY_TYPE = 'keyword';

export default {
  name: 'PFSearchBarComponent',
  components: {
    AutocompleteComponent,
  },
  props: {
    target: {
      type: String,
      validator: (val) => ['_self', '_blank'].includes(val),
      default: '_self',
    },
    searchPage: { type: String },
    placeHolderText: { type: String, default: 'Search by Solution, Industry Focus, Location, Partner Name' },
    placement: { type: String },
  },
  computed: {
    ...mapState('autocomplete', {
      searchResults: (state) => state.searchResults,
      showSearchSuggestion: (state) => state.showSearchSuggestion,
      fromHomePage: (state) => state.fromHomePage,
    }),
    lastUsedQuery: {
      get() {
        return this.$store.state.autocomplete.lastUsedQuery;
      },
    },
    query() {
      return this.$store.state.profileSearch.query;
    },
    groupedSearchResults() {
      const titleItems = this.searchResults.filter((result) => result.title === 'title');
      const suggestionItems = this.searchResults.filter((result) => result.title !== 'title');
      return suggestionItems.concat(titleItems);
    },
    truncatePlaceHolderText() {
      if (this.maxChar >= 58) {
        return this.placeHolderText;
      }
      return `${this.placeHolderText.substring(0, this.maxChar - 3)}...`;
    },
    currentOption() {
      return this.$store.state.profileSearch.queryTermTypeState === KEYWORD_QUERY_TYPE ? KEYWORD : PARTNER_NAME;
    },
    availableOption() {
      return this.$store.state.profileSearch.queryTermTypeState === DEFAULT_QUERY_TERM_TYPE
      || this.$store.state.profileSearch.queryTermTypeState === '' ? KEYWORD : PARTNER_NAME;
    },
    hasText() {
      return this.query?.length > 0;
    },
  },
  watch: {
    query(val) {
      this.changeUpdatedData(val);
    },
  },
  mounted() {
    this.updatedData = this.query;
    this.onResize();
  },
  data: () => ({
    showDropdown: false,
    openInNewTab: false,
    partnerFinderSearchPage: null,
    updatedData: '',
    selectedItemsIdx: -1,
    keyCodes: {
      END: 35,
      HOME: 36,
      SHIFT: 16,
      COMMAND: 91,
      OPTION: 18,
      CONTROL: 17,
      DOWN: 40,
      ENTER: 13,
      ESCAPE: 27,
      LEFT: 37,
      RIGHT: 39,
      TAB: 9,
      UP: 38,
      CONTINUOUS_KEY_PRESSING: 229,
    },
    maxChar: 58,
  }),
  created() {
    this.partnerFinderSearchPage = this.searchPage
      ? this.searchPage
      : DEFAULT_PARTNER_FINDER_SEARCH_PAGE;
    this.openInNewTab = this.target === '_blank';
  },
  methods: {
    closeDropDownList() {
      this.showDropdown = false;
    },
    changeCurrentOption() {
      this.$store.commit('profileSearch/updateQueryTypeState', this.availableOption === KEYWORD ? KEYWORD_QUERY_TYPE : DEFAULT_QUERY_TERM_TYPE);
      this.closeDropDownList();
      this.$store.dispatch('autocomplete/keyPressed', this.query);
      if (!this.isNotCurrentPath()) {
        this.$store.commit('profileSearch/changeTriggeredQuery', this.query);
        this.$eventBus.$emit('PFAACustomEvent',
          { eventName: 'SearchInitiated', custom2: this.query });
        this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', true);
        this.$store.dispatch('profileSearch/triggerSelectedQueryType');
      }
    },
    dropDownClicked() {
      this.showDropdown = !this.showDropdown;
    },
    onResize() {
      const textWidth = this.$refs.searchBarContainer.clientWidth;
      if (this.placement === 'homepage') {
        if (textWidth <= LARGECREEN && textWidth > MIDSCREEN) {
          this.maxChar = Math.round(textWidth / 12);
        } else if (textWidth <= MIDSCREEN) {
          this.maxChar = Math.round(textWidth / 13);
        } else {
          this.maxChar = 58;
        }
      } else if (textWidth <= SMALLSCREEN) {
        this.maxChar = Math.round(textWidth / 10);
      } else {
        this.maxChar = 58;
      }
    },
    updateQuery(event) {
      this.$store.dispatch('profileSearch/setQueryWithoutSearch', event.target.value);
      this.updatedData = event.target.value;
    },
    changeUpdatedData(newValue) {
      this.updatedData = newValue;
    },
    submitForm() {
      if (this.isNotCurrentPath()) {
        this.$store.dispatch('profileSearch/routerSetQuery', this.query);
      }
    },
    clearFilter() {
      this.$store.commit('profileSearch/cleanFilters');
      this.$store.commit('profileSearch/cleanResults');
      this.$store.commit('profileSearch/setQuery', null);
      this.$store.commit('profileSearch/setPageNumber', 1);
    },
    isNotCurrentPath() {
      return !(this.$router.currentRoute.value.name === PARTNER_NAVIGATOR_PAGE);
    },
    onMagnifierClick() {
      this.$store.commit('profileSearch/changeTriggeredQuery', this.query);
      if (this.groupedSearchResults[this.selectedItemsIdx]
        && this.groupedSearchResults[this.selectedItemsIdx].title === 'title') {
        this.$store.commit('profileSearch/setSearchPartner', true);
      }
      this.clearFilter();
      this.$store.dispatch('solutionL2Store/clearSelectedSolution');
      this.discardSelected();
      this.$store.commit('autocomplete/setResults', []);
      const dataPayload = this.updatedData ? this.updatedData : '';
      this.$store.commit('autocomplete/setSearchSuggestion', false);
      if (this.isNotCurrentPath()) {
        this.$store.dispatch(
          'profileSearch/setQueryWithoutSearch',
          dataPayload,
        );
        this.$store.commit('profileSearch/setSearchFromHomePage', true);
      } else {
        this.$store.dispatch('profileSearch/setQuery', dataPayload);
      }
      this.submitForm();
      this.$store.dispatch('autocomplete/abortController');
      this.$store.commit('autocomplete/setFromHomePage', true);
      this.$eventBus.$emit('PFAACustomEvent',
        { eventName: 'SearchInitiated', custom2: this.query });
    },
    clearSearchField() {
      this.$store.commit('profileSearch/changeTriggeredQuery', '');
      this.$store.dispatch('autocomplete/clearResults');
      // as computed properties are cached we have to "update" them.
      if (this.query === '') {
        this.$store.commit('profileSearch/cleanFilters');
        this.$store.dispatch('solutionL2Store/unselectAllSolution', false);
        this.$store.commit('profileSearch/setQuery', null);
      }
      if (this.isNotCurrentPath()) {
        this.$store.dispatch('profileSearch/setQueryWithoutSearch', '');
      } else {
        this.$store.commit('profileSearch/cleanFilters');
        this.$store.dispatch('solutionL2Store/unselectAllSolution', false);
        this.$store.dispatch('profileSearch/setQuery', '');
      }
      this.discardSelected();
    },
    inputKeyup(event) {
      const isSpecialKey = Object.values(this.keyCodes)
        .findIndex((value) => value === event.keyCode) !== -1;
      if (isSpecialKey) {
        return;
      }
      this.discardSelected();
      this.$store.dispatch('autocomplete/keyPressed', event.target.value);
    },
    up() {
      if (!this.showSearchSuggestion) {
        return;
      }
      if (this.selectedItemsIdx === 0) {
        this.selectedItemsIdx--;
        this.$store.dispatch('profileSearch/setQueryWithoutSearch', this.lastUsedQuery);
        return;
      }
      if (this.selectedItemsIdx === -1) {
        this.selectedItemsIdx = this.searchResults.length - 1;
      } else {
        this.selectedItemsIdx--;
      }
      this.$store.dispatch('profileSearch/setQueryWithoutSearch',
        this.groupedSearchResults[this.selectedItemsIdx].value);
    },
    down() {
      if (!this.showSearchSuggestion) {
        return;
      }
      if (this.selectedItemsIdx === this.searchResults.length - 1) {
        this.discardSelected();
        this.$store.dispatch('profileSearch/setQueryWithoutSearch', this.lastUsedQuery);
        return;
      }
      this.selectedItemsIdx++;
      this.$store.dispatch('profileSearch/setQueryWithoutSearch',
        this.groupedSearchResults[this.selectedItemsIdx].value);
    },
    discardSelected() {
      this.selectedItemsIdx = -1;
    },
    closeSearchSuggestion() {
      this.$store.dispatch('autocomplete/setShowSearchSuggestion', false);
    },
    openSearchSuggestion() {
      this.$store.dispatch('autocomplete/openSearchSuggestion');
    },
    triggerSearchSuggestion() {
      if (this.$store.state.autocomplete.searchResults.length === 0) {
        this.$store.dispatch('autocomplete/getAutocompleteResults');
      } else {
        this.openSearchSuggestion();
      }
    },
    addSpaceAfterComma(text) {
      return responseHandlerUtil.addSpaceAfterComma(text);
    },
  },
};
</script>

<template>
  <div class="pf-search-bar-outer-container">
  <div
    v-bind:class="{ 'ds-p-y-xxl': this.placement === 'homepage' }"
    class="pf-search-bar-container"
    ref="searchBarContainer"
  >
  <div class="ds-select-box guide-dropdown"
  v-click-outside="closeDropDownList">
    <label class="ds-textField ds-select-box__wrapper guide-dropdown__wrapper">
        <input id="selectbox-search-bar" class="ds-textField__input ds-select-box__wrapper__input" type="text"
        @click="dropDownClicked"/>
        <div class="ds-combo-box__placeholder">
            <span class="editlist__placeholder__text home-filter-placeholder"
                  ref="placeHolderText"
                  id="cancel-open-dropdown">
              <span>{{currentOption}}</span>
            </span>
        </div>
        <i class="ds-select-box__wrapper__icon--down ds-icon--dropdown down-arrow"
        :class="showDropdown ? 'rotate-180' : ''"></i>
        <div class="ds-select-box__wrapper__dropdown dropdown-item"
        :class="showDropdown ? 'display-block' : 'display-none'">
        <div class="ds-dropdown__inner" @click.prevent="changeCurrentOption">
            <div class="ds-dropdown__inner__option option-text">{{availableOption}}</div>
        </div>
        </div>
    </label>
</div>
  <resize-observer @notify="this.onResize"></resize-observer>
    <section
      v-bind:class="[openInNewTab ? 'pf-search-bar_light' : '']"
      class="pf-search-bar"
      v-click-outside="closeSearchSuggestion"
    >
      <form
        class="pf-search-bar_form-wrapper"
        ref="pf-search-input-form"
        @submit.prevent="submitForm"
      >
        <div class="form-group">
          <input
            type="search"
            class="form-control srch-autocomplete-input top-search-bar"
            :value="query"
            @input="updateQuery"
            @keyup="inputKeyup"
            @keyup.esc="clearSearchField"
            @keydown.enter="onMagnifierClick"
            @keydown.up.prevent="up"
            @keydown.down.prevent="down"
            @focus="triggerSearchSuggestion"
          />
          <div class="input-button-search">
            <button
              class="search__submit-btn"
              type="button"
              @click="onMagnifierClick"
            ></button>
          </div>
          <div class="input-button-group" key="clearn-button">
            <button
              class="search__clear-btn"
              :class="hasText ? 'display-block' : 'display-none'"
              type="button"
              @click="clearSearchField"
            ></button>
          </div>
        </div>
      </form>
    </section>
  </div>
    <AutocompleteComponent
          @submit-form-handler="submitForm"
        />
  </div>
</template>

<style lang="less">
@import './styles/css/search-form';
@import './styles/css/autocomplete';
@import '../../../assets/css/common.less';

.pf-search-bar {
  width: 100%;
}

.pf-search-bar-container {
  display: flex;

  .rotate-180 {
    display: block !important;
    transform: matrix(1,0,0,-1,0,0);
  }
  .ds-dropdown__inner__option {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .ds-dropdown__inner__option:hover {
    background-color: #727272;
  }
  .ds-textField__input{
    min-height: 40px;
    height: 40px;
  }
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }

  .search__clear-btn:hover::after {
    position: absolute;
    right: 10px;
    content: "";
    display: block;
    font-family: "SAP.com Icons, sans-serif";
    font-size: 18px;
    transform: translate(0, -50%);
    color: #C6C6C6;
  }
  .guide-dropdown {
    cursor: pointer;
    max-width: 100px !important;
    min-width: 9rem !important;
    height: 40px;
  }

  .ds-combo-box__placeholder {
    width: fit-content;
  }
  .guide-dropdown__wrapper {
    cursor: pointer;
  }
  #selectbox-search-bar {
    cursor: pointer;
    width: 100% !important;
    background-color: #3c3c3c;
    border-right: unset;
  }

  .home-filter-placeholder {
    font-size: 16px;
    line-height: 21px;
    background-color: #3c3c3c;
    color: #c6c6c6;
    padding-left: 8px;
    position: absolute;
    top: 50%;
    left: 2%;
    transform: translateY(-50%);
  }
  .down-arrow {
    display: block !important;
    top: 13px;
    color: #c6c6c6;
  }
  .dropdown-item {
    max-width: 100px !important;
    min-width: 9rem !important;
    background-color: #3c3c3c;
  }
  .option-text {
    width: 144px;
    height: 40px;
    font-weight: 390;
    font-size: 16px;
    line-height: 21px;
    background-color: #3c3c3c;
    color: #c6c6c6;
  }

  .top-search-bar{
    padding: 12px 70px 12px 12px;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  input {
    border-width: 0;
    width: 100%;
    height: 24px;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #eaeaea;
  }

  input::placeholder {
    color: @color-gray-3;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  button {
    cursor: pointer;
  }

  input:focus {
    outline: none;
  }

  p {
    margin: 0;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
</style>
