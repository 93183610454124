const showBestMatchDefault = false;
let SORT_OPTIONS = {
  'Best match': 'RELEVANCE',
  'Alphabetical (A-Z)': 'AZ',
  'Alphabetical (Z-A)': 'ZA',
};
if (showBestMatchDefault) {
  SORT_OPTIONS = {
    'Best match': 'RELEVANCE',
    'Alphabetical (A-Z)': 'AZ',
    'Alphabetical (Z-A)': 'ZA',
  };
}
module.exports = {
  SORT_OPTIONS,
  SORT_MAPPING: {
    RELEVANCE: 'bestmatch',
    AZ: 'title:asc',
    ZA: 'title:desc',
  },
};
