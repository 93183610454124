<script>
/* eslint-disable no-nested-ternary */
import { mapState } from 'vuex';
import PEOfficeEditComponent
from '@/components/editor/popup/PEOfficeEditComponent';

export default {
  name: 'PEHeadquartersAddressComponent',
  components: {
    PEOfficeEditComponent,
  },
  data: () => ({
    isHqFieldEmpty: false,
    addressEditModalOpen: false,
    currentHq: null,
    addressModel: {},
    mainCountry: null,
    hqLocalAddressIdx: null,
    selectedHqIdx: -1,
    showDropdown: false,
  }),
  mounted() {
    if (this.mountedComponent.includes(this.$options.name)) {
      this.isHqFieldEmpty = !(this.hqAddressData.fullAddress && this.hqAddressData.fullAddress.length > 0);
      this.removeAddHQError(this.isHqFieldEmpty);
    } else {
      this.$store.dispatch('partnerProfile/addMountedComponent', this.$options.name);
    }
    this.$eventBus.$on('*', (type) => {
      if (['footerSaveClicked', 'footerPublishClicked', 'editorToggleButtonClicked'].includes(type)) {
        this.isHqFieldEmpty = !(this.hqAddressData.fullAddress && this.hqAddressData.fullAddress.length > 0);
      }
    });
  },
  computed: {
    ...mapState('partnerProfile', {
      mountedComponent: (state) => state.mountedComponent,
      hqAddressData: (state) => state.profileData.hqAddress,
      localProfiles: (state) => state.profileData.localProfiles,
    }),
    fullCountry() {
      return this.$store.state.fullData.allowedCountries;
    },
    availableLocations() {
      const locations = [];
      const cities = new Map();
      let selectedAddress = null;
      this.localProfiles.forEach((profile) => {
        if (profile.addresses) {
          profile.addresses.forEach((address) => {
            if (address.mainLocation) {
              selectedAddress = { ...address };
            } else {
              locations.push({ ...address });
            }
            if (address.city && cities.has(address.city)) {
              cities.set(address.city, true);
            } else if (address.city && !cities.has(address.city)) {
              cities.set(address.city, false);
            }
          });
        }
      });
      locations.forEach((location) => {
        if (location.city && cities.get(location.city)) {
          location.dropdownName = `${location.city} (${location.addressName})`;
        } else {
          location.dropdownName = location.city ? location.city : location.countryCodeText;
        }
      });
      locations.sort((a, b) => {
        if (a.dropdownName.toUpperCase() < b.dropdownName.toUpperCase()) {
          return -1;
        }
        if (a.dropdownName.toUpperCase() > b.dropdownName.toUpperCase()) {
          return 1;
        }
        return 0;
      });
      if (selectedAddress) {
        if (selectedAddress.city && cities.get(selectedAddress.city)) {
          selectedAddress.dropdownName = `${selectedAddress.city} (${selectedAddress.addressName})`;
        } else {
          selectedAddress.dropdownName = selectedAddress.city ? selectedAddress.city : selectedAddress.countryCodeText;
        }
        locations.unshift(selectedAddress);
      }
      const lastAddOption = { dropdownName: `${this.$t('location.section.add')}...` };
      locations.push(lastAddOption);
      return locations;
    },
    elementOffset() {
      if (this.selectedHqIdx === -1) {
        return 0;
      }
      const str = this.selectedHqIdx.toString();
      const ind = `#HQ-option-${str}`;
      const elem = this.$el.querySelector(ind);
      return elem.offsetTop - 12;
    },
  },
  watch: {
    hqAddressData(val) {
      if (this.mountedComponent.includes(this.$options.name)) {
        this.isHqFieldEmpty = !(val.fullAddress && val.fullAddress.length > 0);
      }
    },
    isHqFieldEmpty(val) {
      this.removeAddHQError(val);
    },
  },
  methods: {
    removeAddHQError(val) {
      const id = 'hq-address';
      if (val) {
        this.$store.dispatch(
          'partnerProfile/addErrorField',
          {
            id,
            field: this.$t('location.address.section.title'),
            message: this.$t('mandatory.field.error.message'),
          },
        );
      } else {
        this.$store.dispatch('partnerProfile/removeErrorField', id);
      }
    },
    openAddressEditModal() {
      this.addressModel = this.hqAddressData;
      this.addressEditModalOpen = true;
    },
    openAddressAddModal() {
      this.addressModel = {};
      this.addressEditModalOpen = true;
    },
    closeAddressEditModal() {
      this.addressModel = {};
      this.addressEditModalOpen = false;
      this.currentHq = null;
      this.$store.dispatch('locationAddressAutoComplete/clearQuery');
      this.$store.dispatch('locationAddressAutoComplete/clearResults');
    },
    removeHq() {
      this.$store.commit('locationAddressAutoComplete/removeHqByFullAddress');
    },
    deleteLocalOffice() {
      this.$store.commit('locationAddressAutoComplete/deleteLocalOfficeByHq');
    },
    selectCurrentHq(location) {
      if (location.dropdownName === `${this.$t('location.section.add')}...`) {
        this.openAddressAddModal();
      }
      this.currentHq = location;
    },
    selectHq(location) {
      this.$store.commit('locationAddressAutoComplete/setHq', location);
      this.currentHq = null;
    },
    trimDropdownName(name, isSelectedLocation) {
      let trimName = name;
      if (isSelectedLocation && name.length > 20) {
        trimName = `${name.substring(0, 20)}...`;
      } else if (!isSelectedLocation && name.length > 22) {
        trimName = `${name.substring(0, 22)}...`;
      }
      return trimName;
    },
    up() {
      if (this.selectedHqIdx <= -1) {
        this.selectedHqIdx = 0;
      } else if (this.selectedHqIdx === 0) {
        this.selectedHqIdx = this.availableLocations.length - 1;
      } else {
        this.selectedHqIdx--;
      }
      if (this.selectedHqIdx < this.availableLocations.length - 1) {
        this.selectCurrentHq(this.availableLocations[this.selectedHqIdx]);
      } else {
        this.currentHq = null;
      }
      this.$el.querySelector('#HQ-option-list').scrollTo(0, this.elementOffset);
    },
    down() {
      if (this.selectedHqIdx < this.availableLocations.length - 1) {
        this.selectedHqIdx++;
      } else {
        this.selectedHqIdx = 0;
      }
      if (this.selectedHqIdx < this.availableLocations.length - 1) {
        this.selectCurrentHq(this.availableLocations[this.selectedHqIdx]);
      } else {
        this.currentHq = null;
      }
      this.$el.querySelector('#HQ-option-list').scrollTo(0, this.elementOffset);
    },
    selectHqEnter() {
      if (this.selectedHqIdx === this.availableLocations.length - 1) {
        this.openAddressAddModal();
      }
      this.closeDropdown();
    },
    closeDropdown() {
      this.showDropdown = false;
      if (this.selectedHqIdx === this.availableLocations.length - 1) {
        this.currentHq = null;
      }
      this.selectedHqIdx = -1;
    },
    openDropdown() {
      this.showDropdown = true;
    },
  },
};
</script>

<template>
  <div class="ds-flexGrid hq-address-container">
    <div class="ds-m-none ds-m-t-xl ds-m-b-m ds-flexRow hq-title-container hq-center-items">
      {{$t('location.address.section.title')}}
    </div>
    <div class="ds-m-none ds-m-y-m ds-flexCol-12 hq-text-container hq-center-items"
    :class="!isHqFieldEmpty ? '' : 'office-field-error__message'">
      <i v-show="isHqFieldEmpty " class="ds-icon--close-outline ds-p-none ds-m-r-xs"></i>
      {{$t('local.office.main.message')}}
    </div>
    <div class="hq-dropdown-container ds-m-b-m">
      <label class="ds-select-box ds-textField ds-select-box__wrapper hq-dropdown"
        v-if="availableLocations.length > 1">
        <input class="ds-textField__input ds-select-box__wrapper__input hq-dropdown-input"
              :class="{ 'ds-textField__input--active' : currentHq && currentHq.fullAddress , }"
              :readonly="true"
              type="text"
              @keydown.up.prevent="up"
              @keydown.down.prevent="down"
              @keydown.enter="selectHqEnter"
              @blur.prevent="closeDropdown"
              @focus.prevent="openDropdown"
              :value="currentHq && currentHq.fullAddress ?
              trimDropdownName(currentHq.dropdownName) : ''"
        />
        <span class="ds-textField__placeholder ds-select-box__wrapper__placeholder pf-combobox-placeholder"
        :class="showDropdown ? 'open-dropdown-color' : 'close-dropdown-color'">
            {{ $t('main.office.dropdown.select') }}
        </span>
        <i class="HQ-select-box__wrapper__icon ds-icon--big ds-icon--arrow-down"></i>
        <div class="ds-select-box__wrapper__dropdown" v-if="showDropdown">
          <div class="ds-dropdown__inner ds-dropdown__inner--scrollable" id="HQ-option-list">
            <div class="HQ-dropdown__inner__option__wrapper">
              <div class="ds-dropdown__inner__option" v-for="(location, idx) in availableLocations"
              :id="'HQ-option-' + idx"
              :key="'HQ-'+idx"
              :class="[location.mainLocation && selectedHqIdx <= 0 ? 'ds-dropdown_inner__option--selected' : '',
                idx === selectedHqIdx ? 'selected-option'  : '' ]"
              v-on:mousedown="selectCurrentHq(location); closeDropdown()">
              <i v-if="location.mainLocation" class="ds-icon ds-icon--checked"></i>
              {{ trimDropdownName(location.dropdownName, location.mainLocation) }}
            </div>
            </div>
          </div>
        </div>
      </label>
      <button
            class="ds-button hq-button-large ds-button--primary
            ds-button--socialmedia-edit hq-location-show ds-m-none ds-m-l-s"
            type="button"
            @click="selectHq(currentHq)"
            id="partner-HQ-button__replace"
            :disabled="currentHq == null || (hqAddressData.fullAddress === currentHq.fullAddress
            && hqAddressData.suite === currentHq.suite && hqAddressData.phone === currentHq.phone
            && hqAddressData.email === currentHq.email)"
            v-if="availableLocations.length > 1"
          >
            {{ hqAddressData && hqAddressData.fullAddress ?
            $t("location.section.replace") : $t("location.section.show") }}
      </button>
    </div>
    <button
            class="ds-button hq-button-small ds-button--primary ds-button--socialmedia-edit hq-location-show ds-m-b-m"
            type="button"
            @click="selectHq(currentHq)"
            id="partner-HQ-button__replace"
            :disabled="currentHq == null || (hqAddressData.fullAddress === currentHq.fullAddress
            && hqAddressData.suite === currentHq.suite && hqAddressData.phone === currentHq.phone
            && hqAddressData.email === currentHq.email)"
            v-if="availableLocations.length > 1"
          >
            {{ hqAddressData && hqAddressData.fullAddress ?
            $t("location.section.replace") : $t("location.section.show") }}
    </button>
    <div v-if="hqAddressData && hqAddressData.fullAddress" class="ds-flexRow hq-center-items ds-p-t-s ds-p-b-s">
      <div class="hq-address-container">
        <label class="ds-checkbox pf-disable-select hq-address-label"
          :for="'checkbox-hq-address'">
          <div class="ds-checkbox__box">
            <i class="ds-icon ds-icon--edit office-edit-icon office-icon-style"
            @click="openAddressEditModal()"></i>
          </div>
          <span class="ds-checkbox__label">
            <div class="location-name-label">
              <div class="address__line-1" v-for="(addressLine, idx) in hqAddressData.addressLines"
                :key="'hq-addressLine-' + idx">
                <span>{{ addressLine }}</span>
              </div>
              <div class="address__line-2">
                {{ hqAddressData.suite ? hqAddressData.suite : ''}}
              </div>
              <div class="address__phone">
                {{ hqAddressData.phone }}
              </div>
              <div class="address__email">
                {{ hqAddressData.email }}
              </div>
              <div class="main-location-remove-tag" @click="removeHq">
                {{$t('hq.address.hq.remove')}}
              </div>
            </div>
          </span>
        </label>
      </div>
    </div>
    <div class="ds-m-none ds-m-y-m ds-flexRow hq-center-items" v-if="availableLocations.length <= 1">
      <button
        class="ds-button ds-button--primary ds-button--socialmedia-edit office-location-add"
        type="button"
        @click="openAddressAddModal()"
        id="partner-HQ-button__add"
      >
        {{ $t("location.section.add") }}
      </button>
    </div>
    <PEOfficeEditComponent v-if="addressEditModalOpen"
      :countryCode="addressModel.countryCode"
      :countryCodeText="addressModel.countryCodeText"
      :addressModel="addressModel"
      :closeModal="closeAddressEditModal"
      :multipleCountries="false"
      :allowedCountries="fullCountry"
      @removeOfficeFromChild="deleteLocalOffice"/>
  </div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";

.hq-address-container {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
  width: fit-content;
  &:extend(.font-normal);
  line-height: 21px;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  color: @color-black;

  .hq-center-items {
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .ds-checkbox__box {
    border: none !important;
  }
  .address__office-name {
    &:extend(.font-expanded);
    font-size: 16px;
    line-height: 24px;
  }
  .address__line-1,
  .address__line-2,
  .address__line-3,
  .address__phone,
  .address__email {
    &:extend(.font-normal);
    font-size: 14px;
    line-height: 21px;
  }

  .main-location-remove-tag {
    &:extend(.font-normal);
    cursor: pointer;
    font-size: 14px;
    line-height: 21px;
    color: @color-link;
  }
  .main-location-remove-tag:hover {
    color: @color-link__hover;
  }
  .hq-title-container {
    &:extend(.font-expanded);
    font-size: 21px;
    line-height: 27px;
  }
  .hq-text-container {
    &:extend(.font-normal);
    color: @color-icon__hover-before;
    font-size: 16px;
    line-height: 24px;
  }
  .office-field-error__message {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
    color: @color-field-error;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    .ds-icon--close-outline {
      padding-left: 25px;
      font-size: 24px;
    }
  }
  .ds-checkbox .office-icon-style::before {
    color: @color-gray-4 !important;
    font-size: 24px;
  }
  .ds-checkbox .office-icon-style:hover::before{
    color: @color-gray-1 !important;
    font-size: 24px;
  }
  .office-edit-icon {
    position: absolute;
    top: 0;
    left: 0;
  }
  .hq-dropdown-container {
    display: flex;
    .hq-dropdown {
      max-width: 227px;
      margin-left: calc(50% - 113.5px);
      .hq-dropdown-input {
        cursor: pointer;
      }
    }
  }
  .hq-button-small {
    display: none;
    margin-left: auto;
    margin-right: auto;
  }
  .selected-option {
    background-color: @color-slight-gray;
    &:extend(.font-bold);
  }
  .ds-dropdown_inner__option--selected {
    background-color: @color-slight-gray ;
  }
  .HQ-select-box__wrapper__icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    color: @color-gray-2;
    cursor: pointer;
  }
  .pf-combobox-placeholder {
    top: 1.3125rem !important;
  }
  .ds-dropdown__inner__option {
    min-width: 237px;
  }
  .HQ-dropdown__inner__option__wrapper {
    width: fit-content;
  }
  .open-dropdown-color {
    color: @color-blue-border;
  }
  .close-dropdown-color {
    color: @color-gray-1;
  }
}

</style>
