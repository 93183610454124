<script>
import { mapState } from 'vuex';
import PFLoadingIndicatorComponent from '@/components/common/PFLoadingIndicatorComponent';

const SYSTEM_ERROR_PAGE_ROUTE = { name: 'systemErrorPage', query: { q: '' } };
const IE_BROWSER_PAGE = 'browserNotSupportedPage';

export default {
  name: 'ProfileEditorPage',
  components: {
    PFLoadingIndicatorComponent,
  },
  beforeCreate() {
    if (/Trident\/|MSIE /i.test(navigator.userAgent)) {
      this.$router.replace({
        name: IE_BROWSER_PAGE,
      }).catch(() => {});
    } else if (this.$router.currentRoute.value.name === 'pfeditorPage') {
      this.$store.commit('partnerProfile/setLoading', true);
      if (this.loggedIn) {
        this.toMyPartnerProfile();
      }
    } else {
      this.$store.dispatch('partnerProfile/findEditableProfileByProfileId', this.$route.params.profileId);
      this.$store.dispatch('consultant/reset');
    }
  },
  beforeUpdate() {
    const userData = this.$store.state.userProfile;

    if (userData.isEnableSecurity && userData.isAuthorizationWithError) {
      this.$router.push(SYSTEM_ERROR_PAGE_ROUTE);
    }
  },
  created() {
    window.addEventListener('beforeunload', this.siteLeave);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.siteLeave);
  },
  computed: {
    ...mapState('partnerProfile', {
      loading: (state) => state.loading,
      changedData: (state) => state.changedData,
    }),
    ...mapState('userProfile', {
      loggedIn: (state) => state.loggedIn,
      profileAuthorizations: (state) => state.user.profileAuthorizations,
      sessionExpired: (state) => (PROCESS_NODE_ENV !== 'local'
        ? state.sessionExpired : false),
    }),
    ...mapState('solutionL2Store', {
      buildInitSolutionFlag: (state) => state.buildInitSolutionFlag,
    }),
  },
  methods: {
    siteLeave(event) {
      if (this.changedData && !this.sessionExpired) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    toMyPartnerProfile() {
      if (this.profileAuthorizations && this.profileAuthorizations.pgiId) {
        window.location = `/editor/${this.profileAuthorizations.pgiId}/edit`;
      } else {
        window.location = '/NotAllowed';
      }
    },
  },
  watch: {
    loggedIn(val) {
      if (val && this.$router.currentRoute.value.name === 'pfeditorPage') {
        this.toMyPartnerProfile();
      }
    },
    loading(val, oldVal) {
      if (oldVal && !val && !this.buildInitSolutionFlag) {
        this.$store.dispatch('partnerProfile/createL2sEntries');
      }
    },
    buildInitSolutionFlag(val, oldVal) {
      if (oldVal && !val && !this.loading) {
        this.$store.dispatch('partnerProfile/createL2sEntries');
      }
    },
  },
};
</script>

<template>
  <div class="editorPageContainer ds-flexGrid ds-p-none">
    <PFLoadingIndicatorComponent :active="loading" :fullScreen="true"/>
    <router-view name="editorPageHeader"></router-view>
    <router-view name="editorPageContent"></router-view>
    <router-view name="editorPageFooter"></router-view>
  </div>
</template>

<style lang="less">
  body.pf-modal-open {
    overflow-y: hidden !important;
  }
</style>
