<script>
import PEUploadFilePopupComponent from '@/components/editor/popup/PEUploadFilePopupComponent';

export default {
  name: 'PEPartnerLogoComponent',
  components: {
    PEUploadFilePopupComponent,
  },
  props: {
    logo: { type: String, default: '/cq5dam.thumbnail.120.90.png' },
    title: { type: String, default: 'not found' },
    profileId: { type: String, default: null },
  },
  data: () => ({
    isShownIconUpload: false,
    addLogoError: false,
    addLogoFirstTime: false,
    partnerLogoError: {},
    justUploadLogo: false,
  }),
  methods: {
    closeUploadPopup() {
      this.isShownIconUpload = false;
      this.addLogoFirstTime = true;
      this.justUploadLogo = false;
    },
    openUploadPopup() {
      this.isShownIconUpload = true;
    },
    validateIcon(file, profileId) {
      return this.$fileValidator.validateAndUploadIcons(file, profileId, this.$store.state.partnerProfile.csrfToken);
    },
    toggleJustUploadLogo() {
      this.justUploadLogo = true;
    },
  },
  watch: {
    isShownIconUpload(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      } else {
        document.body.style.overflow = 'scroll';
        document.body.style.paddingRight = '0px';
      }
    },
    addLogoFirstTime() {
      const errors = this.$refs.uploadLogo.errorMessages;
      if (!this.logo && errors && errors.length > 0) {
        this.partnerLogoError.message = this.$t(errors[0]);
        this.addLogoError = true;
        this.$store.dispatch('partnerProfile/addErrorField', this.partnerLogoError);
      }
    },
    logo(val) {
      if (val) {
        this.addLogoError = false;
        this.$store.dispatch('partnerProfile/removeErrorField', this.partnerLogoError.id);
      }
    },
  },
  mounted() {
    this.partnerLogoError = {
      id: 'partnerLogoError',
      field: this.$t('field.name.value.logo'),
    };
    if (this.$store.state.partnerProfile.errorFields.includes(this.partnerLogoError)) {
      this.addLogoError = true;
    }
    this.$eventBus.$on('STEP1_LOGO', () => {
      this.openUploadPopup();
    });
  },
};
</script>

<template>
  <div class="ds-profile-edit-logo  ds-p-none ds-m-y-l ds-m-r-m" @click="openUploadPopup">
    <div class="ds-profile-logo ds-p-none" :class="addLogoError ? 'ds-profile-logo-error' : 'ds-profile-logo-no-error'">
      <img class="partner-logo-img" v-if="logo" :src="logo" />
      <div class="ds-p-none ds-m-none">
        <i v-if="!logo" class="ds-icon ds-icon--plus"></i>
        <div
          v-if="!logo"
          class="pf-profile-edit-logo_help__upload__text ds-p-none ds-m-l-xxs"
        >Add Logo</div>
      </div>
      <button
        @click.stop
        v-if="logo"
        class="ds-modal__box__action-bar__action pf-button_update_logo_action ds-button
          ds-button--primary pf-popup-file-upload-button ds-m-none ds-p-none"
        type="button"
        @click="openUploadPopup"
      >
      <span class="edit-logo-button">Edit Logo</span>
      </button>
    </div>
    <PEUploadFilePopupComponent
      ref="uploadLogo"
      v-if="isShownIconUpload"
      :closeAction="closeUploadPopup"
      :uploadAndValidateFunction="validateIcon"
      :pushToStoreAction="'partnerProfile/updateLogo'"
      :profileId="profileId"
      :imgSrc="logo === '/cq5dam.thumbnail.120.90.png' ? null : logo"
      :justUploadLogo="justUploadLogo"
      :toggleJustUploadLogo="toggleJustUploadLogo"
    />
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.ds-profile-edit-logo:hover {
  box-shadow: 0 0 10px 0 @color-gray-5 !important;
  .pf-button_update_logo_action {
    background-color: @color-link__hover;
  }
  .pf-profile-edit-logo_help__upload__text {
    color: @color-gray-1
  }
}
.ds-profile-edit-logo {
  position: relative;
  display: grid;
  min-width: 80px;
  min-height: 80px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.16), 0px 0px 8px rgba(0, 0, 0, 0.16);
  .ds-profile-logo {
    box-shadow: none;
    border: none;
    cursor: pointer;
    div {
      color: @color-glance-consultants;
      &:hover {
        color: @color-gray-1;
      }
    }
  }
  ::before {
    font-size: 21px;
  }
  .ds-icon--plus {
    margin-top: 23px;
  }
  .pf-profile-edit-logo_help__upload__text {
    &:extend(.font-normal);
    position: absolute;
    bottom: 4px;
    width: 72px;
    height: 18px;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
  }
  .pf-button_update_logo_action {
    position: absolute;
    font-size: 12px;
    bottom: 0;
    left: 0;
    width: 80px;
    height: 26px;
    .edit-logo-button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.ds-profile-logo-no-error:hover {
    outline: solid 1px @color-link__hover;
    outline-offset: -1px;
}

.ds-profile-logo-error {
  outline: solid 1px @color-editor-footer-error;
  outline-offset: -1px;
  background-color: @color-red-error-background !important;
  .pf-profile-edit-logo_help__upload__text {
    color: @color-editor-footer-error !important;
  }
  .ds-icon--plus {
    color: @color-editor-footer-error !important;
  }

}
</style>
