export function compareStrings(string1, string2) {
  return (!string1 && !string2) || string1 === string2;
}

export function compareSimpleObject(obj1, obj2) {
  if ((!obj1 && Object.keys(obj2).length === 0) || (Object.keys(obj1).length === 0 && Object.keys(obj2).length === 0)) {
    return true;
  }
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;

  if (obj1Length === obj2Length) {
    return Object.keys(obj1)
      .every((key) => Object.prototype.hasOwnProperty.call(obj2, key)
        && ((!obj2[key] && !obj1[key]) || obj2[key] === obj1[key]));
  }
  return false;
}

export function compareSimpleObjectKeyProperty(obj1, obj2, keys) {
  return keys.every((key) => ((!obj2[key] && !obj1[key]) || obj2[key] === obj1[key]));
}

export function compareArray(arr1, arr2) {
  if ((!arr1 && arr2.length === 0) || (arr1 && arr1.length === 0 && arr2.length === 0)) {
    return true;
  }
  if (!arr1 || arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((element, idx) => element === arr2[idx]);
}

export function compareSocialMediaArray(socialMediaMap, socialMediaArray) {
  const MapSize = socialMediaMap.size;
  if ((MapSize === 0 && !socialMediaArray) || (MapSize === 0 && socialMediaArray.length === 0)) {
    return true;
  }
  if (MapSize !== socialMediaArray.length) {
    return false;
  }
  return socialMediaArray.every(
    (socialMedia) => {
      if (!socialMediaMap.has(socialMedia.socialMediaType)) {
        return false;
      }
      const originalLink = socialMediaMap.get(socialMedia.socialMediaType);
      return (!originalLink && !socialMedia.link) || originalLink === socialMedia.link;
    },
  );
}

export function compareArrayOfObject(arr1, arr2, keys) {
  if ((!arr1 && arr2.length === 0) || (arr1 && arr1.length === 0 && arr2.length === 0)) {
    return true;
  }

  if (!arr1 || arr1.length !== arr2.length) {
    return false;
  }
  if (keys) {
    return arr1.every((element, idx) => compareSimpleObjectKeyProperty(element, arr2[idx], keys));
  }
  return arr1.every((element, idx) => compareSimpleObject(element, arr2[idx]));
}

export function deepCopyFunction(inObject) {
  if (typeof inObject !== 'object' || inObject === null) {
    return inObject;
  }
  const outObject = Array.isArray(inObject) ? [] : {};
  if (Array.isArray(inObject)) {
    inObject.forEach((item, index) => {
      outObject[index] = deepCopyFunction(item);
    });
  } else {
    Object.entries(inObject).forEach(([key, value]) => {
      outObject[key] = deepCopyFunction(value);
    });
  }
  return outObject;
}

export function removeUnchangedField(profileOriginalData, postPayload) {
  if (profileOriginalData.size === 0) {
    postPayload.clear();
  }
  postPayload.forEach((value, key) => {
    if (key !== 'localProfiles') {
      if (profileOriginalData.has(key)) {
        profileOriginalData.delete(key);
      } else {
        postPayload.delete(key);
      }
    }
  });
  if (profileOriginalData.size === 0) {
    postPayload.delete('localProfiles');
  }
}
