<script>
export default {
  name: 'PFActionButtonComponent',
  props: {
    title: { type: String, default: 'Apply' },
    endTitle: { type: String, default: '' },
    action: { type: Function, default: () => true },
    selectedItems: { type: Number },
    showNumber: { type: Boolean, default: true },
    mobileMode: { type: Boolean, default: false },
    homeMode: { type: Boolean, default: false },
  },
};
</script>

<template>
  <button
    :class="[!mobileMode && !selectedItems && showNumber ? 'ds-button--primary' : 'ds-button--primary', 'ds-button']"
    type="button"
    @click="action">
    {{ title }}
    <template v-if="!homeMode && (mobileMode || (selectedItems && showNumber))">{{ selectedItems }}</template>
    {{ endTitle }}
  </button>
</template>
