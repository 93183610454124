<script>
import PFConsultantAccordionComponent from '@/components/common/PFConsultantAccordionComponent';
import PFCloudImplementationAccordionComponent from '@/components/common/PFCloudImplementationAccordionComponent';

export default {
  name: 'PFConsultantPopupComponent',
  components: {
    PFConsultantAccordionComponent,
    PFCloudImplementationAccordionComponent,
  },
  props: {
    closeAction: {
      type: Function,
      default: () => false,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
    isConsultants: {
      type: Boolean,
      default: true,
    },
    country: {
      type: String,
      default: '',
    },
    headerText: {
      type: String,
      default: '',
    },
    closeText: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const modalHeight = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentRect.height >= 410) {
          entry.target.classList.add('ds-p-r-m');
        } else {
          entry.target.classList.remove('ds-p-r-m');
        }
      });
    });
    modalHeight.observe(this.$refs.consultantModalContent.children[0]);
  },
  watch: {
    isShown() {
      window.setTimeout(() => this.$refs.consultantModal.focus(), 0);
    },
  },
};
</script>
<template>
  <div :class="isShown ? 'ds-modal--active' : ''" class="ds-modal ds-modal--scrollable consultant-model"
        ref="consultantModal" tabindex="0" @keyup.esc="closeAction">>
    <div class="modal-container">
      <div class="modal-container-inner-consultant">
        <div class="ds-modal__box consultant-model_box">
          <div class="ds-modal__box__header">
            <div class="ds-modal__box__header__heading">{{ headerText }}</div>
            <i class="ds-modal__box__header__icon ds-icon ds-icon--close" @click="closeAction"></i>
          </div>
          <div ref="consultantModalContent"
               class="ds-modal__box__content consultant-modal-content ds-p-none">
            <PFConsultantAccordionComponent
              v-if="isConsultants"
              :country="country"
              :isShown="isShown"
            />
            <PFCloudImplementationAccordionComponent
              v-else
              :country="country"
              :isShown="isShown"/>
          </div>
          <div class="ds-modal__box__action-bar">
            <button class="ds-modal__box__action-bar__action ds-button ds-button--primary consultant-button"
                    type="button"
                    @click="closeAction">{{ closeText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";

.consultant-model {
  z-index: 101;
}

.modal-container-inner-consultant {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 301px);
}

.consultant-model_box {
  width: 632px;
  height: 601px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}

.consultant-button {
  width: 83px;
  height: 42px;
  margin-right: 0;
  margin-left: auto;
}

.consultant-modal-content {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.ds-modal__box__header__heading {
  color: @color-black;
  &:extend(.font-expanded);
}
</style>
