<script>
import PFSiteLogoComponent from '@/components/common/PFSiteLogoComponent';
import PFLinkContainer from '@/components/common/PFLinkContainer';
import PFSocialMediaComponent from '@/components/common/PFSocialMediaComponent';
import PFSwaComponent from '@/components/common/PFSwaComponent';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFFooterComponent',
  components: {
    PFSiteLogoComponent,
    PFLinkContainer,
    PFSocialMediaComponent,
    PFSwaComponent,
  },
  mounted() {
    this.isIE = /Trident\/|MSIE /i.test(navigator.userAgent);
    if (this.$router.currentRoute.value.name === 'profileEditorPageEdit' || this.$router.currentRoute.value.name === 'profileEditorPagePreview' || this.$router.currentRoute.value.name === 'partnerTermsOfUsePage') {
      this.advance[0].link = urlLinks.FOOTER.FAQ_PE;
      this.advance[2].title = 'Partner Terms of Use';
      this.advance[2].link = urlLinks.FOOTER.PARTNER_TERM_OF_USE;
    }
  },
  watch: {
    $route: {
      handler(to) {
        if (to.name === 'profileEditorPageEdit' || to.name === 'profileEditorPagePreview') {
          this.advance[0].link = urlLinks.FOOTER.FAQ_PE;
        } else {
          this.advance[0].link = urlLinks.FOOTER.FAQ_PF;
        }
      },
      deep: true,
    },
  },
  data: () => ({
    main: [
      { title: 'Partner with Us', link: urlLinks.FOOTER.PARTNER_WITH_US },
      { title: 'About SAP Partner Finder', link: urlLinks.FOOTER.ABOUT_PF },
    ],
    advance: [
      {
        title: 'FAQ',
        link: urlLinks.FOOTER.FAQ_PF,
      },
      { title: 'Privacy', link: urlLinks.FOOTER.PRIVACY },
      { title: 'Terms of Use', link: urlLinks.FOOTER.TERM_OF_USE },
      { title: 'Legal Disclosure', link: urlLinks.FOOTER.LEGAL_DISCLOSURE },
      { title: 'Copyright', link: urlLinks.FOOTER.COPY_RIGHT },
      { title: 'Trademark', link: urlLinks.FOOTER.TRADE_MARK },
    ],
    cookiePreference: { title: 'Cookie Preferences', link: '/' },
    isIE: true,
    BTPLink: urlLinks.FOOTER.BTP,
  }),
};
</script>

<template>
<div class="pf-page-footer">
    <div class="pf-page-footer-container" :class="isIE ? 'footer-none-margin' : 'footer-auto-margin'">
        <div class="pf-page-footer__main-links">
            <PFLinkContainer :links="main" />
        </div>
        <div class="pf-page-footer__block-separator" />
        <div class="pf-page-footer__advance-links">
          <PFLinkContainer :links="advance" :isLight="true" />
          <PFSwaComponent class="cookie-text" />
          <PFSocialMediaComponent class="PF-social-media-component-small"/>
        </div>
        <div class="pf-page-footer__block-separator" />
        <div class="pf-page-footer_bottom">
            <div class="pf-page-footer__logo ds-m-r-s">
                <PFSiteLogoComponent :isTitleVisible="false" />
                <div class="pf-page-footer__text">
                  <a :href="BTPLink" target="_blank">{{$t('section.footer.BTP')}}</a>
                </div>
            </div>
            <PFSocialMediaComponent class="PF-social-media-component-large" />
            <div class="network-err-img-preload"></div>
        </div>
    </div>
</div>
</template>

<style lang="less">
@import "../../assets/css/common.less";
.PF-social-media-component-large {
  display: block;
}
.PF-social-media-component-small {
  display: none;
}

.pf-page-footer {
  bottom          : 0;
  width           : 100%;
  height          : fit-content;
  background-color: @color-black;
  display: flex;
  justify-content: center;
  .footer-auto-margin {
    margin-left : auto;
  }
  .footer-none-margin {
    margin-left : 0;
  }
  .network-err-img-preload {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1; // hide images
    content: url('../../assets/282678_BrokenChain_R_orange.png'); // preload images
  }

  .pf-page-footer-container {
    max-height  : none;
    margin-right: auto;
    width       : 86.18%;
    max-width: 1680px;
    .cookie-text {
      margin-right: 20px;
      margin-bottom: 19px;
      display: inline-block;
      height         : 24px;
      font-size      : 16px;
      font-stretch   : normal;
      font-style     : normal;
      line-height    : 1.5;
      letter-spacing : normal;
      a {
        &:extend(.font-normal);
        color          : @color-gray-4 !important;
        cursor         : pointer;
      }
      a:hover{
        color          :  @color-white !important;
      }
    }
    .cookie-text:hover {
      color          : @color-white;
    }
    .pf-page-footer__text {
      padding-top: 29px;
      a {
        &:extend(.font-normal);
        color          : @color-gray-4 !important;
        cursor         : pointer;
      }
      a:hover{
        color          :  @color-white !important;
      }
    }

    .pf-page-footer_bottom {
      display:flex;
      flex-wrap: wrap;

      .pf-page-footer__logo {
        // width: 77.22%;
        display:flex;
        width: fit-content;
      }

      .pf-page-footer__social {
        &:extend(.font-normal);
        height: 24px;
        font-size: 16px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: @color-white;
        margin: 1.2rem 0;
      }
    }

    .pf-page-footer__block-separator {
      width : 100%;
      height: 2px;
      border: solid 1px @color-seperator-border;
    }

    .pf-page-footer__main-links {
      height : fit-content;
      padding: 30px 0 0 0;
    }

    .pf-page-footer__advance-links {
      height : fit-content;
      padding: 19px 0 0 0;
    }
  }
}
</style>
