<script>
import { mapState, mapActions } from 'vuex';
import PEVideoUploadBoxComponent from '@/components/editor/PEVideoUploadBoxComponent';
import PFPopUpComponent from '@/components/common/PFPopUpComponent';

export default {
  name: 'PEGlanceVideoComponent',
  components: {
    PEVideoUploadBoxComponent,
    PFPopUpComponent,
  },
  data: () => ({
    isShownIconUpload: false,
    isPlaing: true,
    isPopupShown: false,
    errorMessages: [],
    updateStep: 0,
    updateThumbnail: false,
    videoUploadError: 'videoUploadError',
    updateFile: false,
  }),
  props: {
    profileId: {
      type: String,
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      videoImageUrl: (state) => state.profileData.videoImageUrl,
      videoUrl: (state) => state.profileData.videoUrl,
      loading: (state) => state.videoContainerLoading,
    }),
    ...mapState('userProfile', {
      cancelSessionExtendingClicked: (state) => state.cancelSessionExtendingClicked,
      sessionExpired: (state) => (PROCESS_NODE_ENV !== 'local'
        ? state.sessionExpired : false),
    }),
  },
  mounted() {
    if (this.videoUrl && !this.videoImageUrl) {
      this.updateStep = 1;
    }
  },
  created() { this.$eventBus.$on('footerDiscardClicked', () => { this.errorMessages = []; }); },
  methods: {
    onPlayerPlay() {
      if (this.isPlaing) {
        this.isPlaing = false;
        this.$refs.videoRef.play();
      } else {
        this.isPlaing = true;
        this.$refs.videoRef.pause();
      }
    },
    ...mapActions('partnerProfile', {
      updateVideoUrl: 'updateVideoUrl',
      updateVideoImageUrl: 'updateVideoImageUrl',
    }),
    discard() {
      this.updateVideoUrl('');
      this.updateVideoImageUrl('');
      this.hidePopup();
    },
    checkSessionExpiration(event) {
      if (this.cancelSessionExtendingClicked) {
        this.$store.commit('userProfile/setSessionExpired', true);
        event.preventDefault();
      }
    },
    handleFilesUpload(file) {
      if (this.sessionExpired) {
        return;
      }
      if (!file) {
        return;
      }
      this.updateStep = 0;
      this.updateThumbnail = false;
      if (this.$fileValidator.constructor.isValidImageFormat(file)) {
        this.updateVideoImageUrl('loading');
        this.handleThumbnailFilesUpload(file);
        return;
      }
      this.$store.commit('partnerProfile/setVideoContainerLoading', true);
      this.errorMessages = [];
      this.updateVideoUrl('loading');
      this.updateVideoImageUrl('loading');
      this.$fileValidator
        .validateAndUploadVideo(file, this.profileId, this.$store.state.partnerProfile.csrfToken)
        .then((data) => {
          this.$store.dispatch('partnerProfile/removeErrorField', this.videoUploadError);
          this.$store.commit('partnerProfile/setVideoContainerLoading', false);
          this.updateVideoUrl(data);
          this.updateStep = 1;
          this.updateVideoImageUrl(null);
        })
        .catch((err) => {
          this.errorMessages = this.$fileValidator.constructor.handleResponseError(err);
          this.$store.dispatch('partnerProfile/addErrorField', {
            id: this.videoUploadError,
            field: this.$t('field.name.profile.video'),
            message: this.errorMessages[0],
          });
          this.$store.commit('partnerProfile/setVideoContainerLoading', false);
        });
    },
    handleThumbnailFilesUpload(file) {
      if (!file) {
        return;
      }
      this.fileName = file.name;
      this.errorMessages = [];
      this.$store.commit('partnerProfile/setVideoContainerLoading', true);
      this.updateStep = 0;
      this.updateVideoImageUrl('loading');
      this.$fileValidator
        .validateAndUploadThumbnail(file, this.profileId, this.$store.state.partnerProfile.csrfToken)
        .then((data) => {
          this.$store.dispatch('partnerProfile/removeErrorField', this.videoUploadError);
          this.$store.commit('partnerProfile/setVideoContainerLoading', false);
          this.updateVideoImageUrl(data);
          if (!this.updateThumbnail) {
            this.updateVideoUrl('');
          }
        })
        .catch((err) => {
          this.errorMessages = this.$fileValidator.constructor.handleResponseError(err);
          this.$store.dispatch('partnerProfile/addErrorField', {
            id: this.videoUploadError,
            field: this.$t('field.name.profile.thumbnail'),
            message: this.errorMessages[0],
          });
          if (this.updateThumbnail) {
            this.updateStep = 1;
          }
          this.$store.commit('partnerProfile/setVideoContainerLoading', false);
        });
    },
    hidePopup() {
      this.isPopupShown = false;
      document.body.style.overflow = 'scroll';
      document.body.style.paddingRight = '0px';
    },
    showPopup() {
      if (this.cancelSessionExtendingClicked) {
        this.$store.commit('userProfile/setSessionExpired', true);
        return;
      }
      this.isPopupShown = true;
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
    },
    changeThumbnail(file) {
      if (this.sessionExpired || !file) {
        return;
      }
      this.updateThumbnail = true;
      this.handleThumbnailFilesUpload(file);
    },
  },
};
</script>

<template>
  <div class="video-upload-container" id="pe-video-upload-container">
    <PFPopUpComponent
      :isShown="isPopupShown"
      :text="videoUrl ? $t('video.upload.delete.video.text') : $t('video.upload.delete.image.text')"
      :submitText="$t('video.upload.delete')"
      :cancelText="$t('helptext.partner.editor.discard.agreement.cancelButton')"
      :headerText="videoUrl ? $t('video.upload.delete.video') : $t('video.upload.delete.image')"
      :submitAction="discard"
      :cancelAction="hidePopup"
    />
    <PEVideoUploadBoxComponent
      v-if="!videoImageUrl || errorMessages.length > 0"
      :updateErrorMessages="errorMessages"
      :updateStep="updateStep"
      :handleThumbnailFilesUpdate="changeThumbnail"
      :handleFilesUpdate="handleFilesUpload"
      :updatedVideo="videoUrl"/>
    <div class="video-upload-container" v-else>
      <div v-if="videoUrl && isPlaing" class="pf-video-player-play-indicator pf-video-player-play-indicator-edit">
        <div class="pf-video-player-play-icon"></div>
      </div>
      <video class="pf-media-box"
        id="pf-media-video"
        v-if="videoUrl || videoImageUrl"
        ref="videoRef"
        width="608"
        height="342"
        :poster="videoImageUrl"
        :src="videoUrl ? videoUrl :videoImageUrl"
      >
        <!-- <source :src="videoUrl ? videoUrl :videoImageUrl" type="video/mp4" />
        <source :src="videoUrl ? videoUrl :videoImageUrl" type="video/ogg" /> -->
      </video>
      <div v-if="loading" class="pf-file-upload-loading-indicator">
        <i class="ds-loading-indicator m-r-xs ds-m-r-s"></i>
      </div>
      <div v-if="videoUrl || videoImageUrl" class="pf-ditor-player-manage-bar">
        <div class="ds-m-none ds-m-none ds-m-l-m" >
          <div class="ds-upload ds-p-none">
            <div class="ds-upload__block change-file-container ds-m-none ds-p-y-m" style="border:none;">
              <input
                class="ds-upload__input"
                type="file"
                ref="file2"
                @click="checkSessionExpiration"
                @change="handleFilesUpload($refs.file2.files[0])"
              />
              <button class="ds-button ds-button--primary ds-update__block__button" type="button"
                      :class="loading ? 'ds-button--disabled' : ''">
                Change File
              </button>
            </div>
          </div>
        </div>
        <div v-if="videoUrl" class="ds-m-none ds-p-x-none ds-m-l-m">
          <div class="ds-upload ds-p-none">
            <div class="ds-upload__block change-thumbnail-container ds-m-none ds-p-y-m" style="border:none;">
              <input
                class="ds-upload__input"
                type="file"
                ref="file1"
                @click="checkSessionExpiration"
                @change="changeThumbnail($refs.file1.files[0])"
              />
              <button
                class="ds-button ds-button--primary pf-ditor-player-Thumbnail-button ds-update__block__button"
                type="button"
                :class="loading ? 'ds-button--disabled' : ''"
              >Change Thumbnail</button>
            </div>
          </div>
        </div>
        <div>
          <div class="ds-upload ds-p-none">
            <button
              @click.stop
              class="ds-button ds-button--destructive ds-m-y-m ds-m-l-m"
              type="button"
              @click="showPopup"
              :class="loading ? 'ds-button--disabled' : ''"
            >Delete</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.pf-file-upload-loading-indicator {
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 6;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: @white-color ;
  .ds-loading-indicator {
    position: absolute;
    // top: 50%;
    // left: 40%;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
}
.pf-ditor-player-manage-bar {
  display: flex;
  position: absolute;
  bottom: 0;
  z-index: 5;
  width: 608px;
  height: 82px;
  opacity: 0.85;
  background-color: #ffffff;
  .pf-ditor-player-Thumbnail-button {
    max-width: 181px;
    background-color: rgba(255, 255, 255, 0);
    border: solid 1px #007db8;
    color: #007db8;
  }
}
.change-thumbnail-container:hover {
  .pf-ditor-player-Thumbnail-button {
    border: solid 1px @color-link__hover;
    background-color: @color-link__hover;
    color: @white-color;
  }
}
.change-file-container:hover {
  .ds-update__block__button {
    background-color: @color-link__hover;
  }
}
.video-upload-container {
  height: 342px;
  width: fit-content;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  video{
   object-fit: cover;
  }
}
.pf-video-player-play-indicator-edit {
  opacity: 0.33;
  cursor: default !important;
}
</style>
