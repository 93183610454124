class MobileDrag {
  static addElementBorder(originalY, ele) {
    if (ele) {
      originalY > this.getOffset(ele).top
        ? ele.classList.add('reorder-border-top')
        : ele.classList.add('reorder-border-bottom');
    }
  }

  static removeElementBorder(originalY, ele) {
    if (ele) {
      originalY > this.getOffset(ele).top
        ? ele.classList.remove('reorder-border-top')
        : ele.classList.remove('reorder-border-bottom');
    }
  }

  static getOffset(element) {
    if (!element.getClientRects().length) {
      return { top: 0, left: 0 };
    }

    const rect = element.getBoundingClientRect();
    const win = element.ownerDocument.defaultView;
    return (
      {
        top: rect.top + win.pageYOffset,
        left: rect.left + win.pageXOffset,
      });
  }

  static getOriginOffset(X, Y, element) {
    return (
      {
        top: Y - this.getOffset(element).top,
        left: X - this.getOffset(element).left,
      });
  }

  static cloneDragItem(currentTarget, originalWidth) {
    const cloneEle = currentTarget.cloneNode(true);
    cloneEle.id = `${currentTarget.id}-clone`;
    cloneEle.style.position = 'absolute';
    cloneEle.style.width = originalWidth;
    return cloneEle;
  }

  static filterOutEle(cloneEle, validIds, itemWidth) {
    const rect = cloneEle.getBoundingClientRect();
    const rectLeft = rect.left;
    const rectTop = rect.top;
    const currentOnDropEleArr = document.elementsFromPoint(rectLeft, rectTop);

    let targetELe = null;

    currentOnDropEleArr.forEach((ele) => {
      if (ele.id && validIds.indexOf(ele.id) !== -1) {
        targetELe = ele;
      }
    });
    if (!targetELe) {
      const rectLeft2 = rectLeft + itemWidth;
      const currentOnDropEleArr2 = document.elementsFromPoint(rectLeft2, rectTop);
      currentOnDropEleArr2.forEach((ele) => {
        if (ele.id && validIds.indexOf(ele.id) !== -1) {
          targetELe = ele;
        }
      });
    }
    return targetELe;
  }

  static filterOutLargeEle(touchX, touchY, validIds) {
    const currentOnDropEleArr = document.elementsFromPoint(touchX - window.pageXOffset, touchY - window.pageYOffset);

    let targetELe = null;

    currentOnDropEleArr.forEach((ele) => {
      if (ele.id && validIds.indexOf(ele.id) !== -1) {
        targetELe = ele;
      }
    });
    return targetELe;
  }
}

export default MobileDrag;
