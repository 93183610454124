<script>
import { mapState } from 'vuex';

export default {
  name: 'PFDisclaimerComponent',
  props: {
    closeDisclaimer: { type: Function, default: () => {} },
  },
  computed: {
    ...mapState('systemMaintenance', {
      plannedStartTime: (state) => state.plannedStartTime,
      plannedEndTime: (state) => state.plannedEndTime,
      header: (state) => state.header,
      message: (state) => state.message,
    }),
  },
  methods: {
    getUserTimezone() {
      return new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
    },
  },
};
</script>

<template>
  <div class="disclaimer-container">
    <div class="disclaimer-text-area font-normal">
      <div class="disclaimer-first-line">
        <span class="font-bold">
          <span v-if="header">
            {{header}}
          </span>
          <span v-else>
            {{$t('disclaimer.text.first.line.boldtext')}}
          </span>
          <span v-if="plannedStartTime">
            {{$t('disclaimer.text.first.line.boldtext.from')}}
            {{plannedStartTime}}
            {{$t('disclaimer.text.first.line.boldtext.to')}}
            {{plannedEndTime}}
          </span>
          <span v-if="plannedStartTime">{{getUserTimezone()}}</span>
          </span>
      </div>
      <div class="disclaimer-second-line">
        <span v-if="message">
          {{message}}
          </span>
        <span v-else>{{$t('disclaimer.text.second.line')}}</span>
      </div>
      <div class="disclaimer-text-area-small">
        <span class="font-bold ">
          <span v-if="header">
            {{header}}
          </span>
          <span v-else>{{$t('disclaimer.text.first.line.boldtext')}}</span>
          <span v-if="plannedStartTime">
            {{$t('disclaimer.text.first.line.boldtext.from')}}
            {{plannedStartTime}}
            {{$t('disclaimer.text.first.line.boldtext.to')}}
            {{plannedEndTime}}
          </span>
          <span v-if="plannedStartTime">{{getUserTimezone()}}</span>
        </span>
        <span v-if="message">{{message}}</span>
        <span v-else>{{$t('disclaimer.text.second.line')}}
        </span>
      </div>
    </div>
    <i class="ds-icon ds-icon--close close-button" @click="closeDisclaimer"></i>
  </div>
</template>

<style lang="less" scoped>
  .disclaimer-container {
    background-color: @warning-orange;
    color: @white-color;
    min-height: 80px;
    width: 100%;
    z-index: 101;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .disclaimer-text-area {
      font-size: 16px;
      line-height: 24px;
      max-width: 80%;
    }
    .learn-more-link {
      text-decoration: underline;
    }
    .close-button {
      position: absolute;
      right: 21px;
      cursor: pointer;
    }
    .ds-icon::before {
      font-size: 15px !important;
    }
    .disclaimer-text-area-small {
      display: none;
    }
  }
</style>
