<script>
import { getCurrentInstance } from 'vue';
import MobileDrag from '@/plugins/mobileDrag';

export default {
  name: 'PFProfileResourceComponent',
  props: {
    editMode: { type: Boolean, default: false },
    editAction: { type: Function },
    removeAction: { type: Function },
    reorderAction: { type: String, default: '' },
    addNewItem: { type: Boolean, default: false },
    visibilityAction: { type: Function },
    isSliderData: { type: Boolean, default: true },
    data: { type: Object, default: () => {} },
    countryProfileId: { type: Number, default: 0 },
    countryName: { type: String, default: '' },
    countryText: { type: String, default: '' },
    showAllToggle: { type: Boolean, default: false },
    resourceLength: { type: Number, default: 0 },
  },
  data: () => ({
    resourceTypes: {
      SUCCESS_STORY: 'Success Story',
      SAP_REFERENCE: 'SAP Reference',
      WHITE_PAPER: 'White Paper',
      BROCHURE: 'Brochure',
      FACT_SHEET: 'Factsheet',
      OTHER: '',
      OWN_IP: 'Own IP',
      PACKAGES: 'Package',
      BLOG: 'Blog',
      EVENT: 'Event',
    },
    resourceIcon: {
      SUCCESS_STORY: 'ds-icon--award',
      SAP_REFERENCE: 'ds-icon--document-type-outline',
      WHITE_PAPER: 'ds-icon--whitepaper',
      BROCHURE: 'ds-icon--brochure',
      FACT_SHEET: 'ds-icon--document-type-outline',
      OTHER: 'ds-icon--document-type-outline',
      OWN_IP: 'ds-icon--idea',
      PACKAGES: 'ds-icon--package',
      BLOG: 'ds-icon--document-type-outline',
      EVENT: 'ds-icon--calendar',
    },
    resourcePrefixWord: {
      OWN_IP: 'Open',
      PACKAGES: 'See',
      EVENT: 'Show',
      SUCCESS_STORY: 'Show',
      OTHER: 'Show',
    },
    showMoreText: false,
    showMoreTitle: false,
    disableLink: false,
    startDragging: false,
    componentIdx: 0,
    originalWidth: '',
    activeEvent: '',
    preEle: null,
    cloneEle: null,
    oriOffset: null,
  }),
  methods: {
    editResource() {
      this.editAction(this.data);
    },
    removeResource() {
      this.removeAction(this.data);
    },
    onDrop(event) {
      event.preventDefault();
      const draggedElementId = event.dataTransfer.getData('text');
      const targetElementId = event.target.closest('.pf-resource-item-edit').id;
      if (targetElementId !== draggedElementId) {
        const payload = this.buildReorderPayload(
          draggedElementId,
          targetElementId,
        );
        this.$store.dispatch(this.reorderAction, payload);
      }
    },
    buildReorderPayload(draggedElementId, targetElementId) {
      return this.countryName !== 'global'
        ? {
          source: draggedElementId,
          target: targetElementId,
          id: this.countryName,
        }
        : {
          source: draggedElementId,
          target: targetElementId,
        };
    },
    onDragStart(event) {
      event.dataTransfer.setData('text', event.target.id);
    },
    onDragOver(event) {
      event.preventDefault();
    },
    openWindow(url) {
      let openedUrl = url;
      if (!/^https?:\/\//.test(url)) {
        openedUrl = `http://${url}`;
      }
      window.open(openedUrl, '_blank');
    },
    getResourcePrefixWord(resource) {
      if (Object.prototype.hasOwnProperty.call(this.resourcePrefixWord, resource)) {
        return this.resourcePrefixWord[resource];
      }
      return 'Read';
    },
    checkOverflow() {
      if (!this.showAllToggle) {
        this.showMoreText = (this.$refs.itemDescription ? this.$refs.itemDescription.scrollHeight : null)
        > (this.$refs.itemDescription ? this.$refs.itemDescription.clientHeight : null);
        this.showMoreTitle = (this.$refs.resourceTitle ? this.$refs.resourceTitle.scrollHeight : null)
        > (this.$refs.resourceTitle ? this.$refs.resourceTitle.clientHeight : null);
      }
    },
    expandResourceCard() {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('toggleCardHeight');
    },
    handleTouchStart(e) {
      if (this.editMode && e.target.id === 'office-drag-drop-icon') {
        e.preventDefault();
        this.originalWidth = `${e.currentTarget.clientWidth}px`;
        this.oriOffset = MobileDrag
          .getOriginOffset(e.targetTouches[0].pageX, e.targetTouches[0].pageY, e.currentTarget);
        this.activeEvent = 'start';
      }
    },
    handleTouchMove(e) {
      if (this.editMode && e.target.id === 'office-drag-drop-icon') {
        e.preventDefault();
        const listArea = document.getElementById(`${this.countryProfileId}-pe-resource-row`);
        if (!this.cloneEle) {
          this.cloneEle = MobileDrag.cloneDragItem(e.currentTarget, this.originalWidth);
          listArea.appendChild(this.cloneEle);
        }
        const touchLocation = e.targetTouches[0];
        const resourceDragIconWid = 12;

        const pageX = `${touchLocation.pageX - MobileDrag.getOffset(listArea).left - this.oriOffset.left - resourceDragIconWid}px`;
        const pageY = `${touchLocation.pageY - MobileDrag.getOffset(listArea).top - this.oriOffset.top - resourceDragIconWid}px`;

        this.cloneEle.style.left = pageX;
        this.cloneEle.style.top = pageY;
        this.activeEvent = 'move';
        const validIds = [];

        let n = 1;
        while (n <= this.resourceLength) {
          const key = `${n - 1}`;
          if (key !== `${e.currentTarget.id}-clone`) {
            validIds.push(key);
          }
          n++;
        }
        const currentTargetEle = MobileDrag
          .filterOutLargeEle(touchLocation.pageX, touchLocation.pageY, validIds);
        if (currentTargetEle && (!this.preEle || currentTargetEle.id !== this.preEle.id)) {
          this.preEle = currentTargetEle;
        }
      }
    },
    handleTouchEnd(e) {
      if (this.editMode && e.target.id === 'office-drag-drop-icon') {
        e.preventDefault();
        if (this.activeEvent === 'move' && this.preEle && this.cloneEle) {
          const draggedElementId = e.currentTarget.id;
          const targetElementId = this.preEle.id;
          if (targetElementId !== draggedElementId) {
            const payload = this.buildReorderPayload(
              draggedElementId,
              targetElementId,
            );
            this.$store.dispatch('partnerProfile/reorderResourceses', payload);
          }
        }
        if (this.cloneEle) {
          document.getElementById(`${this.countryProfileId}-pe-resource-row`).removeChild(this.cloneEle);
        }
        this.cloneEle = null;
        this.preEle = null;
        this.activeEvent = '';
      }
    },
    emitSWAEvent() {
      if (!this.editMode) {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$eventBus.$emit('PFCustomEvent',
          {
            eventName: 'Country_Resource', custom2: this.countryName, custom3: this.countryText, custom4: 'Resource', custom5: this.data.type,
          });
      }
    },
  },
  mounted() {
    if (!this.editMode) {
      this.checkOverflow();
    }
    const component = getCurrentInstance();
    this.componentIdx = component.vnode.key;
  },
  computed: {
    checkedBoxes() {
      return this.$store.state.partnerProfile.expandedCountry;
    },
  },
  watch: {
    checkedBoxes: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        if (!this.editMode && this.countryProfileId !== 0) {
          this.$nextTick(function () {
            this.checkOverflow();
          });
        }
      },
      deep: true,
    },
    data() {
      if (!this.editMode && this.countryProfileId !== 0) {
        this.$nextTick(function () {
          this.checkOverflow();
        });
      }
    },
  },
};
</script>

<template>
  <div v-if="addNewItem" class="pf-resource-item-add-new">
    <span class="pf-resource-item-add-new-text">
      {{ $t('editor.resource.add.text') }}
    </span>
    <button class="ds-button ds-button--primary pf-resource-item-add-new-button ds-m-t-xxs"
            @click="visibilityAction">
      Add Resource
    </button>
  </div>
  <div v-else-if="typeof data !== 'undefined'"
       :class="isSliderData ? 'pf-slider-resource-item-filled' :'pf-resource-item-filled'"
       :id="componentIdx"
       :draggable="startDragging"
       @drop="onDrop"
       @dragover="onDragOver"
       @dragstart="onDragStart"
       @touchstart="handleTouchStart"
       @touchmove="handleTouchMove"
       @touchend="handleTouchEnd">
    <a v-if="!editMode && !disableLink" class="pf-resource-item-link" target="_blank" href=""
       v-on:click.stop.prevent="openWindow(data.link);emitSWAEvent()"
    />
    <div class="pf-resource-item-title pf-disable-select ds-m-b-s ds-m-l-m ds-m-r-m"
      ref="resourceTitle"
      :class="[countryProfileId !== 0 || isSliderData ? 'pf-resource-local-title ds-m-t-m' : 'ds-m-t-xl',
      showAllToggle ? 'expanded-resource-title' : '']">
      {{ data.name }}
      <div v-if="editMode || (!showAllToggle && showMoreTitle)"
        :class="countryProfileId === 0 ? 'pf-resource-item-global-title-blur' : 'pf-resource-item-title-blur'"/>
    </div>
    <div class="ds-m-l-m ds-m-r-m pf-resource-item-description pf-disable-select"
      ref="itemDescription"
      :class="[countryProfileId === 0 ? 'pf-resource-global-item-description'
      : 'pf-resource-local-item-description',showAllToggle ? 'expanded-resource-description' : '']" >
      {{ data.description }}
      <div
        class="pf-resource-item-description-blur">
        <div v-if="editMode || (!showAllToggle && (showMoreText || showMoreTitle))"
          class="pf-resource-item-description-blur-box"></div>
        <a v-if="!editMode && !showAllToggle && (showMoreText || showMoreTitle)"
          @mouseover="disableLink = true"
          @mouseleave="disableLink = false"
          class="show-more-link"
          href=""
          @click.prevent="expandResourceCard()">
          {{ "more" }}
        </a>
      </div>
    </div>
    <div v-if="editMode" class="pf-resource-item-action-buttons"
         :class="countryProfileId !== 0 ? 'ds-m-b-m' : 'ds-m-b-l'">
      <i
        @mouseover="startDragging=true"
        @mouseleave="startDragging=false"
        id="office-drag-drop-icon"
        class="ds-icon ds-icon--move office-move-icon"></i>
      <button class="ds-button ds-button--secondary pf-resource-item-edit-button ds-m-none"
              @click="editResource">
        {{$t('button.edit')}}
      </button>
      <button class="ds-button ds-button--tertiary pf-resource-item-delete-button"
              @click="removeResource">
        {{$t('button.delete')}}
      </button>
    </div>
    <div v-else class="pf-resource-item-read-text ds-m-l-m ds-m-r-m ds-m-b-m">
      <i class="ds-icon--large" :class="[resourceIcon[data.type],
       countryProfileId !== 0 ? 'pf-resource-item-read-icon-local' : 'pf-resource-item-read-icon']"></i>
      <span :class="countryProfileId !== 0 ? 'pf-resource-item-read-message-local' : 'pf-resource-item-read-message'">
        {{getResourcePrefixWord(data.type) + ' ' + resourceTypes[data.type]}}
      </span>
    </div>
  </div>
  <div v-else/>
</template>

<style lang="less">
@import "../../assets/css/common.less";
.pf-slider-resource-item-filled {
  display: flex;
  flex-direction: column;
  &:extend(.font-normal);
  .pf-resource-item-title {
    position: relative;
    margin-top: 23px;
    margin-left: 21px;
  }
}
.pf-resource-local-title {
&:extend(.font-expanded);
    font-size: 16px;
    line-height: 21px;
    height: 44px;
}

.pf-resource-item-read-icon {
  color: @color-glance-consultants !important;
  font-size: 24px;
}
.pf-resource-item-read-icon-local {
  color: @color-glance-consultants !important;
  font-size: 21px;
  margin-right: 10px;
}

.pf-resource-item-read-text {
  position: absolute;
  bottom: 0;
  display: flex;
  &:extend(.font-normal);
}

.pf-resource-item-read-message {
  line-height: 24px;
}
.pf-resource-item-read-message-local {
  line-height: 21px;
  font-size: 14px;
}

.pf-resource-item-read-icon {
  height: 24px;
  margin-right: 10px;
}

.pf-resource-item-link {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
}

.pf-resource-item-filled, .pf-slider-resource-item-filled {
  border: solid 1px @color-slight-gray;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.05);
  position: relative;
}

.pf-resource-item-add-new {
  border: dashed 1px @color-glance-consultants;
  text-align: center;
}

.pf-resource-item-filled {
  display: flex;
  flex-direction: column;
}

.pf-resource-global-item {
  .pf-resource-item-add-new-text {
    margin-top: 125px;
    cursor: default;
    &:extend(.font-normal);
  }
  .pf-resource-item-title {
    &:extend(.font-expanded);
    font-size: 21px;
    line-height: 27px;
    height: 84px;
    margin-top: 50px !important;
  }
}
.pf-resource-local-item {
  .pf-resource-item-add-new-text {
    margin-top: 59px;
    cursor: default;
    &:extend(.font-normal);
  }
}

.pf-resource-item-add-new-text {
  margin-left: 35px;
  margin-right: 35px;
  display: block;
  text-align: center;
  font-size: 16px;
  color: @color-glance-consultants;
  line-height: 1.5;
  cursor: default;
}

.pf-resource-item-title {
  overflow: hidden;
  margin-right: 20px !important;
  margin-left: 20px !important;
  position: relative;
  cursor: default;
  &:extend(.font-normal);
}

.pf-resource-global-item-description {
  hyphens: auto;
  cursor: default;
  &:extend(.font-normal);
}

.pf-resource-local-item-description {
  cursor: default;
  &:extend(.font-normal);
}

.pf-resource-item-description {
  margin-right: 20px !important;
  margin-left: 20px !important;
  color: #727272;
  font-size: 14px;
  line-height: 21px;
  height: fit-content;
  max-height: 126px;
  position: relative;
  overflow: hidden;
  cursor: default;
  &:extend(.font-normal);
}
.pf-resource-item-title-blur {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 4rem;
  height: 21px;
  background-image: linear-gradient(90deg,hsla(0,0%,100%,0),@color-white);
}
.pf-resource-item-global-title-blur {
  &:extend(.pf-resource-item-title-blur);
  height: 27px;
}

.pf-resource-item-description-blur {
  position: absolute;
  display: inline-flex;
  bottom: 0;
  right: 0;
  .pf-resource-item-description-blur-box {
    width: 4rem;
    height: 21px;
    background-image: linear-gradient(90deg,hsla(0,0%,100%,0),@color-white);
  }
  .show-more-link {
    z-index: 10;
    display: inline-block;
    line-height: 21px;
    background-color: white;
    margin-left: auto;
  }
  .show-more-link:hover {
    color: @color-link__hover;
  }
}

.pf-resource-item-action-buttons {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.pf-resource-item-edit-button, .pf-resource-item-delete-button {
  width: auto;
}

.pf-resource-item-delete-button {
    color: @color-red-delete-button;
    border: none;
    background-color: transparent !important;
    margin-right: 8px;
    padding: 8px 20px;
}
.pf-resource-item-delete-button:hover {
    color: @color-editor-footer-error;
}
.descrition-text-container {
  margin-bottom: 20px;
  height: 100%;
}
.expanded-resource-title {
  height: fit-content !important;
  max-height: none !important;
}
.expanded-resource-description {
  height: fit-content !important;
  max-height: none !important;
}
.office-move-icon {
  cursor: pointer;
  position: absolute;
  top: 8px;
  left: 17px;
}
.ds-icon--move::before {
  color: @color-gray-4 !important;
  font-size: 24px;
}
.ds-icon--move:hover::before{
  color: @color-gray-1 !important;
  font-size: 24px;
}
.ds-icon--close-outline {
  line-height: 17px;
}

</style>
