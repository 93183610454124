<script>
import { mapState } from 'vuex';
import PFSolutionItemComponent from './PFSolutionItemComponent';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFSolutionCompetencyComponent',
  data: () => ({
    faqLink: urlLinks.HELP_TEXT_LEARN_MORE.SAP_SOLUTION,
    tooltipOpen: false,
    competency_modal_data: {},
  }),
  props: {
    solutionTransformed: { type: Array, default: () => [] },
  },
  components: {
    PFSolutionItemComponent,
  },
  methods: {
    toggleModal(val) {
      this.showProfileCompetencyModal = val;
    },
    toggleTooltip() {
      this.tooltipOpen = false;
    },
    openLearnMore() {
      const win = window.open(this.faqLink, '_blank');
      win.focus();
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      ERPCompetency: (state) => state.ERPCompetency,
    }),
    leftColumnSolutions() {
      if (!this.solutionTransformed || this.solutionTransformed?.length <= 1) {
        return this.solutionTransformed ? this.solutionTransformed : [];
      }
      return this.solutionTransformed;
    },
    hasCompetency() {
      return this.solutionTransformed.some((comp) => comp.hasCompetency && comp.level.toUpperCase() !== 'ZERO')
        || this.ERPCompetency.some((comp) => comp.hasCompetency);
    },
    countSolutions() {
      const ERP = this.solutionTransformed.find((c) => c.solutionL1Key === 'PFERP');
      if (ERP) {
        return this.solutionTransformed.length - 1 + ERP.L2Solution.length;
      }
      return this.solutionTransformed.length;
    },
  },
};
</script>

<template>
  <div class="solution-section-title ds-m-b-m" id="competency-section-header-for-scoll">
    <span v-if="hasCompetency">
      {{$t('solution.competency.title')}}&nbsp;
    </span>
    <span v-else>{{$tc('solution.only.title', countSolutions)}}&nbsp;</span>
    <div class="tool-tip-container" v-click-outside="toggleTooltip">
          <i class="ds-icon ds-icon--info tool-tip-icon ds-m-l-xs ds-m-r-xs"
        :class="tooltipOpen ? 'tool-tip-icon-clicked' : ''" @click="tooltipOpen=!tooltipOpen"></i>
        <div class='ds-tooltip-icon toolitp-icon-item-list'>
            <div
              :class="[tooltipOpen ? 'display-block' :
              'display-none','tooltiptext','ds-tooltip-icon__tooltip--service']">
              {{ $t('solution.competency.helptext') }}
              <span @click="openLearnMore" class="help-text-link">
                <b> {{$t('learn.more')}}</b>
              </span>
            </div>
          </div>
    </div>
  </div>
  <div class="solution-container">
      <PFSolutionItemComponent
        :solutionTransformed="leftColumnSolutions"
      />
  </div>
</template>

<style lang="less" scoped>
.solution-section-title {
  &:extend(.font-expanded);
  text-align: center;
  font-size: 21px;
  line-height: 31px;
  color: #222222;
}
.solution-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 52px;
}

.solution-column {
  line-height: 75px;
  font-size: 30px;
  width: 460px;
}
.left-column {
margin-left: 26px;
margin-right: 26px;
}
.right-column {
margin-left: 26px;
margin-right: 26px;
}

.tool-tip-container {
  margin-left: -7px;
  text-align: left;
  display: inline-block;
  position: relative;
}

.specialization-text {
  text-indent: -20px;
  &:extend(.font-normal);
  font-size: 14px;
  line-height: 20px;
  color: @color-gray-1;
  cursor: pointer;
  margin-bottom: 3px;
}

</style>
