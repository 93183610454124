<script>
import { mapActions } from 'vuex';
import PFConsultantTableComponent from '@/components/common/PFConsultantTableComponent';

export default {
  name: 'PFConsultantAccordionComponent',
  components: {
    PFConsultantTableComponent,
  },
  data: () => ({
    checkedBoxes: [],
    isFirstExpand: true,
  }),
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    country: {
      type: String,
      default: '',
    },
  },
  methods: {
    ...mapActions('consultant', {
      findConsultantsByProfileId: 'findByProfileId',
    }),
    expandRecords(data) {
      if (data && data.length <= 2) {
        if (this.isFirstExpand && data.flatMap((l2) => l2.consultantsL3).length <= 4) {
          data.forEach((element) => this.checkedBoxes.push(element.solutionL2Name + this.country));
        }
        this.isFirstExpand = false;
      }
    },
  },
  computed: {
    singleLevel2() {
      const data = this.consultant;
      return !!(data && data.length === 1);
    },
    consultant() {
      if (!this.isShown) {
        return [];
      }
      if (this.$store.state.consultant.consultants.data && this.country.length > 0) {
        const data = this.$store.state.consultant.consultants.data.get(this.country);
        this.expandRecords(data);
        return data;
      }
      if (this.$store.state.consultant.consultants.root) {
        const data = this.$store.state.consultant.consultants.root;
        this.expandRecords(data);
        return data;
      }
      return [];
    },
  },
  watch: {
    isShown() {
      this.checkedBoxes = [];
      if (this.isShown) {
        const profileId = this.$store.state.partnerProfile.profileData.profileId;
        this.findConsultantsByProfileId({
          profileId,
          country: this.country,
        });
      } else {
        this.isFirstExpand = true;
      }
    },
  },
};
</script>

<template>
  <div>
    <div v-if="singleLevel2" class="ds-flexCol-12 ds-accordion ds-p-none">
      <div
        v-for="consultantProfile in consultant"
        :key="consultantProfile.solutionL2Name + country"
        class="ds-accordion__item">
        <input
          :id="consultantProfile.solutionL2Name + country"
          :value="consultantProfile.solutionL2Name + country"
          type="checkbox"
          name="items"
          v-model="checkedBoxes"/>
        <div class="ds-accordion__item-header--icon "></div>
        <div class="ds-accordion__item-content ds-flexCol-11 ds-p-none ds-p-r-s">
          <div class="ds-accordion__item-content--header consultant-modal-header ds-p-none ds-m-t-s
              ds-p-l-xxs font-expanded consultant-single--hover">
            <div class="consultant-single-number ds-m-r-m">{{ consultantProfile.count }}</div>
            {{ consultantProfile.solutionL2Name }}
          </div>
          <div class="ds-accordion__item-content--body ds-p-none ds-flexCol-12">
            <PFConsultantTableComponent class="consultant-single" :consultant="consultantProfile.consultantsL3"/>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="ds-flexCol-12 ds-accordion ds-p-none">
      <div
        v-for="consultantProfile in consultant"
        :key="consultantProfile.solutionL2Name + country"
        class="ds-accordion__item">
        <input
          :id="consultantProfile.solutionL2Name + country"
          :value="consultantProfile.solutionL2Name + country"
          type="checkbox"
          name="items"
          v-model="checkedBoxes"/>
        <div class="ds-accordion__item-header--icon">
          <label :for="consultantProfile.solutionL2Name + country" class="link-cursor">
            <i class="ds-icon--big" :class="checkedBoxes.includes(consultantProfile.solutionL2Name + country)
              ? 'ds-icon--minus' : 'ds-icon--plus'">
            </i>
          </label>
        </div>
        <div class="ds-accordion__item-content ds-flexCol-11 ds-p-none ds-p-r-s">
          <div class="ds-accordion__item-content--header consultant-modal-header ds-p-none link-cursor ds-m-t-s
        ds-p-l-xxs" :class="checkedBoxes.includes(consultantProfile.solutionL2Name + country) ? 'font-expanded'
           : 'font-normal'">
            <label
              class="link-cursor"
              :for="consultantProfile.solutionL2Name + country">
              <div class="consultant-number ds-m-r-m">{{ consultantProfile.count }}</div>
              {{ consultantProfile.solutionL2Name }}
            </label>
          </div>
          <div class="ds-accordion__item-content--body ds-p-none ds-flexCol-12"
               :class="checkedBoxes.includes(consultantProfile.solutionL2Name + country) ? 'display' : 'hidden'">
            <PFConsultantTableComponent :consultant="consultantProfile.consultantsL3"/>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped lang="less">
.consultant-modal-header {
  font-size: 21px;
  line-height: 1.52;
  margin-left: 0;
}

.consultant-single-number {
  display: inline-block;
  text-align: right;
}

.consultant-number {
  &:extend(.consultant-single-number);
  width: 49px;
}

.consultant-single--hover:hover {
  color: black !important;
}

.consultant-single {
  margin-left: 1.5rem;
}

.display {
  display: block !important;
}

.hidden {
  display: none !important;
}

.ds-accordion__item-header--icon {
  padding-left: 0;
}
</style>
