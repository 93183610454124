<script>
import PFOfficesComponent from '@/components/common/PFOfficesComponent';

export default {
  name: 'PFSingleLocationComponent',
  components: {
    PFOfficesComponent,
  },
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    countryProfile: {
      type: Object,
      default: () => {
      },
    },
    partnerProfileId: { type: String },
  },
};
</script>

<template>
  <PFOfficesComponent
    :editMode="editMode"
    :countryProfile="countryProfile"
    :partnerProfileId="partnerProfileId"
    :singleLocalProfileView="true"
    :multipleCountries="false"/>
</template>

<style>
</style>
