<script>

const MIN_AUTOCOMPLETE_LENGTH = 1;

export default {
  name: 'PFDropdownMultiComponent',
  props: {
    allOptions: { type: Array },
    preSelectedOptions: { type: Array },
    placeholderText: { type: String },
    textKey: { type: String },
    idKey: { type: String },
    selectAction: { type: String },
    removeAction: { type: String },
    localProfileId: { type: Number, default: null },
    countryName: { type: String, default: 'global' },
  },
  data: () => ({
    filterText: '',
    isOptionsOverflow: false,
    isComboBoxSizeOverflow: false,
    selectedOptions: [],
    numOfLetters: 0,
    comboBoxWidth: 0,
    minAutocompleteLength: MIN_AUTOCOMPLETE_LENGTH,
  }),
  computed: {
    filteredAllOptions() {
      const result = this.preSelectedOptions === null
        ? this.allOptions
        : this.allOptions
          .filter((item) => !this.preSelectedOptions.find((preItem) => item[this.textKey] === preItem[this.textKey]));
      if (this.filterText.length >= this.minAutocompleteLength) {
        return result.filter(
          (option) => option[this.textKey]
            .toLowerCase()
            .includes(this.filterText.toLowerCase()),
          this,
        );
      }
      return result;
    },
    optionsWithOverflow() {
      let size = 0;
      if (this.numOfLetters > 10 || this.comboBoxWidth < 258) {
        size = 1;
      } else {
        size = 2;
      }
      return this.selectedOptions.filter((option, idx) => idx < size);
    },
    numberOfLettersDependOnWidth() {
      if (this.comboBoxWidth < 225) {
        return Math.round(this.comboBoxWidth / 16);
      }
      return Math.round(this.comboBoxWidth / 13);
    },
  },
  methods: {
    clearInput() {
      this.filterText = '';
    },
    removeItem(option) {
      const index = this.selectedOptions.indexOf(option);
      if (index > -1) {
        this.selectedOptions.splice(index, 1);
      }
    },
    pushSelectionToStore(selected) {
      const payload = this.countryName !== 'global'
        ? { industries: selected, countryName: this.countryName }
        : selected;
      this.$store.dispatch(this.selectAction, payload);
    },
    pushSelectedItems() {
      const concat = this.preSelectedOptions === null
        ? this.selectedOptions
        : this.preSelectedOptions.concat(this.selectedOptions);
      this.pushSelectionToStore(concat);
    },
    toggleAddLink() {
      this.$emit('toggle-add-link');
    },
    closeDropdown() {
      this.pushSelectedItems();
      this.selectedOptions = [];
      this.$emit('closeDropdown');
    },
    clearSearchQuery() {
      this.filterText = '';
    },
    changeTextStyle() {
      this.$refs.placeHolderText.classList.add('placeholder__text-transform', 'ds-p-x-xxs');
      this.$refs.dropDownIcon.classList.add('rotate-icon');
    },
    changeBackTextStyle() {
      if (this.selectedOptions.length === 0) {
        this.$refs.placeHolderText.classList.remove('placeholder__text-transform', 'ds-p-x-xxs');
      }
      this.$refs.dropDownIcon.classList.remove('rotate-icon');
    },
    highlightSearchTerm(item) {
      const searchValueRegExp = new RegExp(
        this.filterText.replace(/[*%.\\[\](){}+?\-^$|]/g, ''),
        'ig',
      );
      return item.replace(searchValueRegExp, (str) => `<b>${str}</b>`);
    },
    optionBoxComputed(option) {
      if (option.length < 14 && option.length > 6 && this.comboBoxWidth < 225 && this.selectedOptions.length > 1) {
        return option.substr(0, 5).concat('...');
      }
      if (option.length >= 14 && this.selectedOptions.length === 1) {
        const substr = option.substr(0, this.numberOfLettersDependOnWidth);
        return substr.length === option.length ? substr : substr.concat('...');
      }
      if (option.length >= 14) {
        return option.substr(0, this.numberOfLettersDependOnWidth - 8).concat('...');
      }
      return option;
    },
    onResize() {
      this.comboBoxWidth = this.$refs.comboBox.clientWidth;
      this.isComboBoxSizeOverflow = this.comboBoxWidth < 258;
    },
  },
  watch: {
    selectedOptions: {
      handler() {
        if (this.selectedOptions.length === 2) {
          this.numOfLetters = this.selectedOptions.reduce((acc, cur) => acc + cur[this.textKey].length, 0);
        }
        if (this.selectedOptions.length > 2) {
          this.isOptionsOverflow = true;
        } else {
          this.isOptionsOverflow = this.numOfLetters >= 18 && this.numOfLetters !== 0;
        }
      },
      deep: true,
    },
  },
};
</script>

<template>
<div class="dropdown-container">
  <div
  class="ds-combo-box ds-combo-box--multi ds-combo-box--multi-width ds-m-t-xs"
  tabindex="0"
  ref="comboBox"
  @blur="clearInput"
  @click="changeTextStyle" v-click-outside="changeBackTextStyle">
  <resize-observer @notify="this.onResize"></resize-observer>
  <div class="ds-combo-box__placeholder">
    <div v-if="this.selectedOptions.length > 0 && !this.isOptionsOverflow && !this.isComboBoxSizeOverflow">
          <span  v-for="option in selectedOptions" :key="option[idKey]"
                 class="chosen-option-box ">
          {{optionBoxComputed(option[textKey])}}
            <i class="ds-icon ds-icon--close" @click="removeItem(option)"></i>
    </span>
    </div>
    <div v-if="this.isOptionsOverflow || this.isComboBoxSizeOverflow">
      <span v-for="option in optionsWithOverflow" :key="option[idKey]"
        class="chosen-option-box">
        {{optionBoxComputed(option[textKey])}}
        <i class="ds-icon ds-icon--close" @click="removeItem(option)"></i>
      </span>
      <span v-if="this.selectedOptions.length > 1"
            class="options-overflow-box">...+{{selectedOptions.length-optionsWithOverflow.length}}</span>
    </div>
    <span class="editlist__placeholder__text"
          :class=" { 'position-absolute placeholder__text-transform ds-p-x-xxs': selectedOptions.length>0 } "
          ref="placeHolderText" >
       {{ placeholderText }}
    </span>
  </div>
  <div class="ds-combo-box__selected"></div>
  <i class="ds-icon--big ds-icon--arrow-down ds-combo-box__icon ds-combo-box__icon--down" ref="dropDownIcon"></i>
  <div class="ds-dropdown ds-combo-box__dropdown">
    <div class="ds-dropdown__search-bar ds-combox-box__dropdown__search-bar">
      <label class="ds-textField ds-dropdown__search-bar__text-field">
        <input
          class="ds-textField__input ds-dropdown__search-bar__text-field__input"
          type="text"
          v-model="filterText"
        />
      </label>
      <i v-if="!filterText" class="ds-icon--big ds-icon--search ds-dropdown__search-bar__icon"></i>
      <i v-if="filterText" class="ds-icon--clear ds-dropdown__search-bar__icon list__search-bar--clear"
         @click="clearSearchQuery">
      </i>
    </div>
    <div
      class="ds-dropdown__options-wrapper editablelist-dropdown-wrapper ds-m-y-s ds-m-r-s">
      <div
        v-for="availableOption in filteredAllOptions"
        :key="availableOption[idKey]"
        :data-title="availableOption[textKey]"
        :data-value="availableOption[idKey]">
        <label class="ds-checkbox-multiselect ds-checkbox ds-p-l-s ds-p-y-xs">
          <input
            class="ds-checkbox__control"
            type="checkbox"
            v-model="selectedOptions"
            :value="availableOption"/>
          <span class="ds-checkbox__box">
            <i class="ds-icon ds-icon--checked"></i>
          </span>
          <span class="ds-checkbox__label" v-html="highlightSearchTerm(availableOption[textKey])">
          </span>
        </label>
      </div>
    </div>
  </div>
  <!-- <i @click="closeDropdown" class="ds-icon ds-icon--large ds-icon--close floating-icon-right"></i> -->
    <button @click="closeDropdown"
            class="ds-button ds-button--primary floating-icon-right"
            type="button">
      <span class="button-text-centered" v-if="this.selectedOptions.length === 0">Done</span>
      <span class="button-text-centered" v-else>Add</span>
    </button>
  </div>
</div>
</template>

<style lang="less">
@import "../../assets/css/common.less";
.dropdown-container {
  position: relative;
}

.button-text-centered {
  margin-left: auto;
  margin-right: auto;
}

.rotate-icon {
  transform: rotate(180deg);
}

.floating-icon-right {
  position: absolute;
  width: 70px;
  right: -75px;
  line-height: 42px !important;
  vertical-align: middle;
  height: 42px;
  padding: 0;
  z-index: 1;
  .ds-icon::before{
    cursor: pointer !important;
    color: @white-color !important;
  }
}
.ds-combo-box .ds-dropdown .ds-dropdown__inner__option {
  margin: 0;
  padding: 5px;
  white-space: normal;
}
.ds-checkbox .ds-checkbox__control {
  width: 0;
  min-width: 0;
  margin: 0;
}
span.ds-checkbox__label,
span.ds-checkbox__control-preselected-text {
  text-align: left;
}
.ds-combo-box--multi-width {
  max-width: 260px;
  min-width: 191px !important;
}
.ds-checkbox .ds-icon::before {
  color: @color-white !important;
}
.list__search-bar--clear {
  color: @color-gray-4 !important;
  &::before {
      font-size: 20px;
    }
  margin-top: 11px !important;
}
.editablelist-dropdown-wrapper {
  max-height: 10.75rem !important;
}
.placeholder__text-transform {
    transform: translateX(-10%) translateY(-24px) scale(0.8125) !important;
    color: @color-link !important;
}

.position-absolute {
  position: absolute;
}
.editlist__placeholder__text {
  color: @color-gray-3;
  z-index: 4;
  background-color: @color-white;
  padding-left: 5px;
  &:extend(.font-normal);
}

.chosen-option-box {
  &:extend(.font-normal);
  padding: 4px 8px;
  background: #EAEAEA;
  margin: 0 2px;
  cursor: default;
}

.options-overflow-box {
  padding: 4px 8px;
  background: #007DB8;
  color: #FFFFFF;
}
.services-items-list-container {
  .ds-combo-box .ds-dropdown {
  min-width: 0 !important;
}
}

</style>
