<script>

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFForbiddenPage',
  props: {
    isFromPfEditor: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    forbiddenCodes: [],
    isDefaultMessage: true,
    learnMoreLinks: urlLinks.LEARN_MORE_LINKS,
  }),
  mounted() {
    if (this.$route.params.forbiddenCodes) {
      this.forbiddenCodes = this.$route.params.forbiddenCodes;
    }
    this.isDefaultMessage = this.forbiddenCodes.length === 0;
  },
  methods: {
    containsForbiddenErrorCode(code) {
      return this.forbiddenCodes.some((item) => item === code);
    },
  },
};
</script>

<template>
  <div>
    <div class="not-found-header ds-m-b-s"> {{ $t('forbidden.header') }}</div>
    <div v-if="isDefaultMessage" class="not-found-text ds-m-b-s"
         v-html="isFromPfEditor ? $t('forbidden.default.editor.text') : $t('forbidden.default.text')"></div>
    <div v-if="containsForbiddenErrorCode('NOT_OWN_PROFILE')" class="not-found-text ds-m-b-s">
      {{ $t('forbidden.wrong.partner.text') }}
      <a :href="learnMoreLinks.NOT_OWN_PROFILE" rel="noopener noreferrer" target="_blank">
        {{ $t('learn.more') }}
      </a>
    </div>
    <div v-if="containsForbiddenErrorCode('NOT_AUTHORIZED')" class="not-found-text ds-m-b-s">
      {{ $t('forbidden.permission.text') }}
      <a :href="learnMoreLinks.PERMISSION" rel="noopener noreferrer" target="_blank">
        {{ $t('learn.more') }}
      </a>
    </div>
    <div v-if="containsForbiddenErrorCode('DUE_DILIGENCE_FLAG')" class="not-found-text ds-m-b-s">
      {{ $t('forbidden.due.diligence.text') }}
      <a :href="learnMoreLinks.DUE_DILIGENCE" rel="noopener noreferrer" target="_blank">
        {{ $t('learn.more') }}
      </a>
    </div>
    <div v-if="containsForbiddenErrorCode('NO_VALID_PARTNER_TYPE')" class="not-found-text ds-m-b-s">
      {{ $t('forbidden.partner.type.status.text') }}
      <a :href="learnMoreLinks.PARTNER_TYPE" rel="noopener noreferrer" target="_blank">
        {{ $t('learn.more') }}
      </a>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../assets/css/common.less";

.not-found-header {
  &:extend(.font-expanded);
  font-size: 38px;
  line-height: 46px;
  color: @color-black;
  text-align: center;
}

.not-found-text {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
  color: @color-gray-1;
  text-align: center;
}

</style>
