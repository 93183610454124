<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PFPaginationComponent',
  computed: {
    ...mapState('profileSearch', {
      currentPage: (state) => state.pageNumber,
      countPages: (state) => state.countPages,
    }),
  },
  data: () => ({
    pageNumber: 1,
  }),
  methods: {
    preventNonNumericalInput(e) {
      const charCode = e.which ?? e.keyCode;
      const charStr = String.fromCharCode(charCode);

      if (!charStr.match(/^[0-9]+$/)) e.preventDefault();
    },
    ...mapActions('profileSearch', {
      nextPage: 'nextPage',
      prevPage: 'prevPage',
      goToPage: 'goToPage',
    }),
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
    increaseFunction(event) {
      event.target.blur();
      this.nextPage();
      this.scrollToTop();
    },
    decreaseFunction(event) {
      event.target.blur();
      this.prevPage();
      this.scrollToTop();
    },
    changePage(event) {
      if (event.keyCode === 13 && this.pageNumber) {
        const pageNum = parseInt(event.target.value, 10);
        if (pageNum > this.countPages) {
          this.goToPage(parseInt(this.countPages, 10));
          this.pageNumber = parseInt(this.countPages, 10);
        } else if (pageNum <= 0) {
          this.goToPage(parseInt(1, 10));
          this.pageNumber = parseInt(1, 10);
        } else {
          this.goToPage(pageNum);
          this.pageNumber = pageNum;
        }
        this.scrollToTop();
      }
    },
  },
  mounted() {
    this.pageNumber = this.currentPage;
  },
  watch: {
    currentPage(val) {
      this.pageNumber = val;
    },
  },
};
</script>

<template>
  <div>
    <div v-if="countPages < 4" class="ds-pagination ds-pagination--small">
      <div class="ds-pagination__grid">
        <div class="ds-pagination__button-group--left" @click="decreaseFunction">
          <button class="ds-button ds-button--secondary ds-m-r-s" :disabled="currentPage <= 1"
                  :class="currentPage <= 1 ? '' : 'pagination-button-color'">
            Previous
          </button>
        </div>
        <div class="ds-pagination__page-counter">
          <div class="ds-pagination__page-counter__prefix">Page</div>
          <div class="ds-pagination__page-counter__counter">{{ currentPage }}</div>
          <div class="ds-pagination__page-counter__max">of {{ countPages }}</div>
          <div class="ds-pagination__page-counter__suffix">pages</div>
        </div>
        <div class="ds-pagination__button-group--right" @click="increaseFunction">
          <button class="ds-button ds-button--secondary ds-m-l-s" :disabled="currentPage >= countPages"
                  :class="currentPage >= countPages ? '' : 'pagination-button-color'">
            Next
          </button>
        </div>
      </div>
    </div>
    <div v-else class="ds-pagination">
      <div class="ds-pagination__grid">
        <div class="ds-pagination__button-group--left">
          <button class="ds-button ds-button--secondary ds-m-r-s"
            @click="decreaseFunction" :disabled="currentPage <= 1"
            :class="currentPage <= 1 ? '' : 'pagination-button-color'">
            Previous
          </button>
        </div>
        <div class="ds-pagination__page-counter">
          <div class="ds-pagination__page-counter__prefix">Page</div>
          <div class="ds-pagination__page-counter__counter">{{ currentPage }}</div>
          <input
            class="ds-input"
            type="number"
            inputmode="numeric"
            min="1"
            :max="countPages"
            v-model="pageNumber"
            @keypress="preventNonNumericalInput"
            @keyup="changePage"
          />
          <div class="ds-pagination__page-counter__max">of {{ countPages }}</div>
          <div class="ds-pagination__page-counter__suffix">pages</div>
        </div>
        <div class="ds-pagination__button-group--right">
          <button class="ds-button ds-button--secondary ds-m-l-s"
            @click="increaseFunction" :disabled="currentPage >= countPages"
            :class="currentPage >= countPages ? '' : 'pagination-button-color'">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.pagination-button-color {
  color: @color-blue-light !important;
  border-color: @color-blue-light !important;
}

.pagination-button-color:hover {
  color: @color-white !important;
  border-color: @color-link__hover !important;
}

.ds-pagination__page-counter {
  cursor: default;
}
</style>
