<script>
import { mapState } from 'vuex';
import PFDropdownsFilterComponent from '../partnerNavigatorPage/PFDropdownsFilterContainer';

export default {
  name: 'PFHomeFilterComponent',
  props: {
    filterType: { type: String, default: '' },
    type: { type: String, default: '' },
    title: { type: String, default: '' },
    activeOptions: { type: Array, default: (() => []) },
    removeItem: { type: Function, default: () => {} },
    cleanFilterByType: { type: Function, default: () => {} },
    imgSrc: { type: String, default: '' },
    textKey: { type: String, default: '' },
    maxTabShown: { type: Number, default: 2 },
  },
  components: {
    PFDropdownsFilterComponent,
  },
  data: () => ({
    isShownFilters: false,
    truncatedTitle: '',
  }),
  mounted() {
    this.onResize();
  },
  computed: {
    ...mapState('solutionL2Store', {
      solutionDataMap: (state) => state.solutionDataMap,
      reverseL2L1Mapping: (state) => state.reverseL2L1Mapping,
    }),
    selectedOptions() {
      return this.activeOptions.length > this.maxTabShown ? this.activeOptions.slice(0, this.maxTabShown) : this.activeOptions;
    },
    moreTabNum() {
      return this.activeOptions.length > this.maxTabShown ? this.activeOptions.length - this.maxTabShown : 0;
    },
  },
  methods: {
    clickDropDownBar(event) {
      if (event.target.id !== 'cancel-open-dropdown') {
        this.isShownFilters ? this.closeDropdown() : this.openDropDown();
      }
    },
    openDropDown() {
      this.isShownFilters = true;
    },
    closeDropdown() {
      this.isShownFilters = false;
    },
    checkIfCLickedOutside(event) {
      if (event.type !== 'touchstart') {
        this.closeDropdown();
      } else {
        const dropdown = document.getElementById(`PF-Dropdown-home-${this.filterType}`);

        if (!(this.$refs.comboBox.contains(event.target)
          || (dropdown && dropdown.contains(event.target)))) {
          this.closeDropdown();
        }
      }
    },
    changeTextStyle() {
      this.$refs.dropDownIcon.classList.add('rotate-icon');
    },
    changeBackTextStyle() {
      this.closeDropdown();
      this.$refs.dropDownIcon.classList.remove('rotate-icon');
    },
    getSolutionTitle(SolutionId) {
      const L1Title = this.reverseL2L1Mapping.get(SolutionId);
      if (L1Title) {
        return this.solutionDataMap.get(L1Title).L2Data.get(SolutionId).title;
      }
      return this.solutionDataMap.get(SolutionId).title;
    },
    onResize() {
      const mobileScreen = 345;
      const imgWidth = 68;
      const textWidth = this.$refs.comboBox.offsetWidth;
      if (this.filterType === 'designation' && textWidth < mobileScreen) {
        const numToShow = Math.floor((textWidth - imgWidth) / 12);
        const titleToShow = this.$t(this.title);
        this.truncatedTitle = `${titleToShow.substring(0, numToShow)}...`;
      } else {
        this.truncatedTitle = this.$t(this.title);
      }
    },
  },
};
</script>

<template>
  <div class="home-page-filter-container">
    <div
      class="ds-combo-box ds-combo-box--multi home-page-filter"
      :class=" {
      'selected-padding' : selectedOptions.length>0} "
      tabindex="0"
      ref="comboBox"
      @click="clickDropDownBar($event)"
      v-click-outside="checkIfCLickedOutside">
      <resize-observer v-if="filterType === 'designation'" @notify="this.onResize"></resize-observer>
      <div class="ds-combo-box__placeholder">
        <img class="home-page-placeholder-icon ds-m-r-xs" :src="imgSrc" alt=""
          :class="selectedOptions.length>0 ? '' : 'img-margin-top'">
        <span class="editlist__placeholder__text home-filter-placeholder"
              v-if="selectedOptions.length <= 0"
              ref="placeHolderText"
              :id="selectedOptions.length>0 ? 'cancel-open-dropdown' : ''" >
          <!-- <resize-observer @notify="this.onResize"></resize-observer> -->
          <span v-if="filterType !== 'designation'">
            {{$t('home.page.filter.any')}}</span> {{ truncatedTitle }}
        </span>
      </div>
      <div v-if="selectedOptions.length > 0" class="home-page-badge-container">
          <span  v-for="(option, idx) in selectedOptions"
            id="cancel-open-dropdown"
            :key="idx + '-home' + filterType" class="chosen-option-box"
            :class="idx === (maxTabShown - 1) && moreTabNum > 0 ? 'last-selected-badge' : 'normal-selected-badge'">
            <span class="selected-badge-text" id="cancel-open-dropdown" v-if="textKey.length > 0">{{option[textKey]}}</span>
            <span class="selected-badge-text" id="cancel-open-dropdown" v-else>{{getSolutionTitle(option)}}</span>
            <span class="selected-badge-text close-icon-container" id="cancel-open-dropdown">
              <i class="ds-icon ds-icon--close" id="cancel-open-dropdown" @click="removeItem(option)"></i>
              </span>
          </span>
          <div v-if="moreTabNum > 0"
              class="options-overflow-box">...+{{moreTabNum}}</div>

      </div>
      <i v-if="selectedOptions.length > 0"
        @click="cleanFilterByType(filterType)"
        id="cancel-open-dropdown"
        class="ds-icon ds-icon--clear home-page-clear-icon"></i>
      <i class="ds-icon--arrow-down ds-combo-box__icon ds-combo-box__icon--down home-page-drop-icon"
        :class=" {'rotate-icon' :  isShownFilters}"
        @click="isShownFilters ? closeDropdown() : openDropDown()"
       ref="dropDownIcon"
       id="cancel-open-dropdown"></i>
    </div>
    <PFDropdownsFilterComponent
      class="ds-filter-sort__configuration-bar home-page__configuration-bar"
          v-if="isShownFilters"
          :type="type"
          :filterType="filterType"
          :title="$t(`${title}`)"
          :homeMode="true"
          :closeHomeFilter="closeDropdown"
      />
  </div>
</template>

<style scoped lang="less">
.home-page-filter-container {
  max-width: 1680px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.img-margin-top {
  margin-top: 1.5px;
}
.ds-combo-box__placeholder {
  width: fit-content;
}
.home-placeholder__text-transform {
  font-size: 14px !important;
  line-height: 15px !important;
  color: #727272;
  // transform: scale(0.8125) !important;
  top: -10px;
  left: 8px;
}

.ds-icon--close::before {
    font-size: 9.33px;
    color: #727272;
}
.ds-icon--close {
  margin-left: 7.33px;
  cursor: pointer;
}
.ds-icon--close:hover::before,
.home-page-clear-icon:hover::before,
.home-page-drop-icon:hover {
  color: #222222;
}
.close-icon-container {
  padding-bottom: 3.33px;
}
.home-filter-placeholder {
  font-size: 19px;
  line-height: 24px;
  color: #3C3C3C;
}
.home-page__configuration-bar {
  position: absolute !important;
  background-color: #ffffff;
  z-index: 103;
  width: 490px;
  height: fit-content;
  position: relative;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 4px 14.4px rgba(0, 0, 0, 0.13);
}

.home-page-placeholder-icon {
  height: 21px;
}
.home-page-filter {
  background-color: #ffffff;
  align-items: center;
  flex-direction: row;
  justify-content: start;
  max-width: 490px;
  min-height: 44px;
  padding-left: 12px;
  padding-right: 12px;
  border: unset;
}
.home-page-drop-icon {
  font-size: 20px;
  color: #727272;
  top: calc(50% - 10px);
  right: 12px;
}
.home-page-clear-icon {
  color: #969696;
  display: block;
  position: absolute;
  top: calc(50% - 8px);
  right: 42px;
}
.home-page-clear-icon::before {
  font-size: 16px;
  color: #969696;
}
.home-page-badge-container {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 45px - 18px);
  justify-content: flex-start;
  background-color: white;
  padding-right: 8px;
  margin-top: 6px;
  margin-bottom: 2px;
  .chosen-option-box {
    margin-right: 4px;
    margin-bottom: 4px;
    margin-left: 0px !important;
    padding: 0px 8px 0px 8px;
    display: inline-flex;
    align-items: center;
    height: 32px;
  }
}
.selected-badge-text {
    &:extend(.font-normal);
    display: inline-block;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.normal-selected-badge {
  .selected-badge-text {
    max-width: 155px;
  }
}
.last-selected-badge {
  .selected-badge-text {
    max-width: 92px;
  }
}
.options-overflow-box {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
  height: 32px;
  margin-bottom: 4px;
}
.selected-padding {
  padding-left: 12px;
}
</style>
