<script>
import { mapState } from 'vuex';
import { VueRecaptcha } from 'vue-recaptcha';
import PFPartnerLogoComponent from '@/components/common/PFPartnerLogoComponent';
import PFDropdownSingleComponent from '@/components/common/PFDropdownSingleComponent';
import removeNonASCIIChar from '@/plugins/removeSpecialChar';

const urlLinks = require('@/plugins/linkUrl');

const ID_KEY = 'country';
const VALUE_KEY = 'text';
const SITE_KEY = VUE_APP_RECAPTCHA_PUBLIC_KEY;
const MAX_CHAR = 1000;

export default {
  name: 'PFContactEmailComponent',
  props: {
    headerButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    contactModalOpen: false,
    emailTimer: null,
    phoneTimer: null,
    PRIVACY_LINK: urlLinks.PRIVACY_LINK,
    contactEmailCheckBox: false,
    idkey: ID_KEY,
    valueKey: VALUE_KEY,
    errorFields: {
      errorEmail: false,
      emptyEmail: false,
      errorLastName: false,
      errorFirstName: false,
      errorPhone: false,
      errorCompany: false,
      errorComments: false,
      emptyCheckbox: false,
      emptyCountry: false,
      recaptchaError: false,
    },
    siteKey: SITE_KEY || 'test',
    recaptchaResponse: null,
    isButtonDisabled: false,
    focusFields: [],
    maxChar: MAX_CHAR,
    resetCheckbox: false,
  }),
  components: {
    PFPartnerLogoComponent,
    PFDropdownSingleComponent,
    VueRecaptcha,
  },
  mounted() {
    if (this.email) {
      this.checkEmailField();
    }
  },
  methods: {
    updateButtonAccessibility() {
      this.isButtonDisabled = this.errorFieldExist || !this.recaptchaResponse;
    },
    toggleContactModal() {
      this.contactModalOpen = !this.contactModalOpen;
    },
    closeModal() {
      if (this.contactEmailCheckBox) {
        this.resetCheckbox = true;
      }
      this.contactModalOpen = false;
      this.contactEmailCheckBox = false;
      this.$store.dispatch('contactEmail/updateRequestStatus', null);
      this.resetRecaptcha();
      this.resetErrorFields();
    },
    resetErrorFields() {
      Object.keys(this.errorFields)
        .forEach((key) => {
          this.errorFields[key] = false;
        });
    },
    checkEmailField() {
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!this.email || this.email.length === 0) {
        this.errorFields.errorEmail = true;
        this.errorFields.emptyEmail = true;
      } else if (re.test(this.email)) {
        this.errorFields.errorEmail = false;
        this.errorFields.emptyEmail = false;
      } else {
        this.errorFields.errorEmail = true;
        this.errorFields.emptyEmail = false;
      }
    },
    updateEmail() {
      if (this.emailTimer) {
        clearTimeout(this.emailTimer);
      }
      this.emailTimer = setTimeout(() => this.checkEmailField(), 1000);
    },
    checkMandatoryField(fieldName, val) {
      this.errorFields[fieldName] = !val;
    },
    checkPhoneField() {
      const re = /^[+]*[(]{0,1}[+0-9]*[)]{0,1}[-\s./0-9]*$/;
      if (!this.phone || this.phone.length === 0) {
        this.errorFields.errorPhone = false;
      } else {
        this.errorFields.errorPhone = !re.test(this.phone);
      }
    },
    updateCountry(val) {
      this.country = val;
      this.checkMandatoryField('emptyCountry', val);
    },
    updateCountryEmptyStatus(val) {
      this.errorFields.emptyCountry = val;
    },
    checkAllFields() {
      this.checkEmailField();
      this.blurMandatoryField('errorFirstName', this.firstName);
      this.blurMandatoryField('errorLastName', this.lastName);
      this.blurMandatoryField('errorCompany', this.company);
      this.blurMandatoryField('emptyEmail', this.email);
      this.blurMandatoryField('errorComments', this.comments);
      this.checkMandatoryField('emptyCheckbox', this.contactEmailCheckBox);
      this.checkMandatoryField('emptyCountry', this.country);
      this.checkMandatoryField('recaptchaError', this.recaptchaResponse);
    },
    checkErrorExist() {
      return (
        Object.keys(this.errorFields)
          .filter((field) => this.errorFields[field])
          .length !== 0
      );
    },
    checkNoEmptyFields() {
      return (
        this.email
        && this.contactEmailCheckBox
        && this.lastName
        && this.firstName
        && this.country
        && this.comments
        && this.company
      );
    },
    onVerify(response) {
      this.recaptchaResponse = response;
      this.checkMandatoryField('recaptchaError', this.recaptchaResponse);
      this.$store.dispatch('contactEmail/updateCaptchaResponseToken', response);
    },
    resetRecaptcha() {
      if (this.$refs.recaptcha) {
        this.$refs.recaptcha.reset();
      }
      this.recaptchaResponse = null;
      this.checkMandatoryField('recaptchaError', true);
      this.$store.dispatch('contactEmail/updateCaptchaResponseToken', null);
    },
    recaptchaFailed() {
      console.log('recaptcha failed');
    },
    sendRequest() {
      this.checkAllFields();
      this.updateButtonAccessibility();
      if (!this.isButtonDisabled) {
        this.$store.dispatch('contactEmail/sendData');
        this.resetRecaptcha();
        if (this.contactEmailCheckBox) {
          this.resetCheckbox = true;
        }
        this.contactEmailCheckBox = false;
      } else {
        this.$eventBus.$emit('PFAACustomEvent',
          {
            eventName: 'ContactFormError',
            custom2: `Contact form validation error in these fields:${this.getErrorFieldsName()}`,
            custom3: true,
          });
      }
    },
    getErrorFieldsName() {
      return Object.keys(this.errorFields)
        .filter((field) => this.errorFields[field]).toString();
    },
    addFocusField(val) {
      if (!this.focusFields.includes(val)) {
        this.focusFields.push(val);
      }
    },
    removeFocusFields(val) {
      if (this.focusFields.includes(val)) {
        this.focusFields.splice(this.focusFields.indexOf(val), 1);
      }
    },
    blurEmailField() {
      this.removeFocusFields('email');
      this.checkEmailField();
    },
    blurPhoneField() {
      this.removeFocusFields('phone');
    },
    blurMandatoryField(fieldName, val) {
      this.removeFocusFields(fieldName);
      this.checkMandatoryField(fieldName, val);
    },
    sendEmailBackendError() {
      const subject = encodeURIComponent(this.$tc('help.mail.body.recaptcha.error.subject'));
      const profileId = this.partnerProfileData.profileId ? this.partnerProfileData.profileId : 'N/A';
      const userId = this.profileAuthorizations?.userName ? this.profileAuthorizations.userName : 'N/A';
      const pageTitle = this.$route.name;
      const url = window.location.href;
      this.$sendEmail.constructor.sendEmailBackendError(subject, profileId, userId, pageTitle, url);
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      partnerProfileData: (state) => state.profileData,
    }),
    ...mapState('userProfile', {
      profileAuthorizations: (state) => state.user.profileAuthorizations,
    }),
    ...mapState('contactEmail', {
      requestStatus: (state) => state.requestStatus,
    }),
    fullCountry() {
      return [...this.$store.state.fullData.allowedCountries].sort((a, b) => a.text.localeCompare(b.text));
    },
    errorFieldExist() {
      return !this.checkNoEmptyFields() || this.checkErrorExist();
    },
    email: {
      get() {
        return this.$store.state.contactEmail.customerInfo.email;
      },
      set(val) {
        this.$store.dispatch('contactEmail/updateContactEmail', val);
      },
    },
    firstName: {
      get() {
        return this.$store.state.contactEmail.customerInfo.firstName;
      },
      set(val) {
        this.$store.dispatch('contactEmail/updateFirstName', val);
      },
    },
    lastName: {
      get() {
        return this.$store.state.contactEmail.customerInfo.lastName;
      },
      set(val) {
        this.$store.dispatch('contactEmail/updateLastName', val);
      },
    },
    company: {
      get() {
        return this.$store.state.contactEmail.customerInfo.company;
      },
      set(val) {
        this.$store.dispatch('contactEmail/updateCompany', val);
      },
    },
    phone: {
      get() {
        return this.$store.state.contactEmail.customerInfo.phone;
      },
      set(val) {
        this.$store.dispatch('contactEmail/updatePhone', val);
      },
    },
    country: {
      get() {
        return this.$store.state.contactEmail.customerInfo.country;
      },
      set(val) {
        this.$store.dispatch('contactEmail/updateCountry', val);
      },
    },
    comments: {
      get() {
        return this.$store.state.contactEmail.customerInfo.comments;
      },
      set(val) {
        this.$store.dispatch('contactEmail/updateComments', val);
      },
    },
    charLeft() {
      if (this.comments) {
        return this.maxChar - this.comments.length;
      }
      return this.maxChar;
    },
  },
  watch: {
    contactModalOpen(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
        if (this.fullCountry.length === 0) {
          this.$store.dispatch('fullData/fetchAllAllowedCountryData');
        }
      } else {
        document.body.style.overflow = 'scroll';
        document.body.style.paddingRight = '0px';
      }
    },
    contactEmailCheckBox(val) {
      if (this.resetCheckbox) {
        this.resetCheckbox = false;
      } else {
        this.checkMandatoryField('emptyCheckbox', val);
      }
    },
    phone(val) {
      // eslint-disable-next-line no-control-regex
      this.phone = removeNonASCIIChar(val);
    },
    errorFields: {
      deep: true,
      immediate: true,
      handler(val) {
        if (this.checkErrorExist()) {
          this.updateButtonAccessibility();
        } else {
          this.isButtonDisabled = false;
        }
      },
    },
  },
};
</script>

<template>
  <div
   class="no-outline"
   tabindex="0"
   @keyup.esc="closeModal"
  >
    <button
      class="ds-button ds-button--primary contact-email-button"
      type="button"
      @click="toggleContactModal();
      $eventBus.$emit('PFAACustomEvent',
      {eventName: 'ContactFormView'})"
    >
      {{ $t('email.contact') }}
    </button>

    <div
      class="ds-modal contact-email-modal ds-modal--active"
      v-if="contactModalOpen"
    >
    <div class="modal-container">
      <div class="contact-email-dialog">
        <div class="ds-modal__box contact-email-modal__box">
          <div class="ds-modal__box__header" v-show="requestStatus !== 1">
            <PFPartnerLogoComponent
              class="ds-m-none ds-m-t-s"
              :logo="partnerProfileData.logoUrl"
              :title="partnerProfileData.name"
              :isContactMe="true"
            />
            <div class="ds-modal__box__header__heading ds-p-l-m ds-m-t-s">
              <div class="ds-m-t-s">{{ $t('contact.me.modal.title') }}</div>
              <div class="partner-name">{{ partnerProfileData.name }}</div>
            </div>
            <i
              class="ds-icon ds-icon--close modal-close-icon"
              @click="closeModal"
            ></i>
          </div>
          <div class="ds-modal__box__content ds-p-none" v-show="requestStatus">
            <div class="center-content">
              <div class="ds-m-b-l">
                <img
                  v-if="requestStatus === 2"
                  src="@/assets/success-message-icon.svg"
                  alt="triangle with all three sides equal"
                  height="101"
                  width="150"
                />
                <i v-else class="ds-loading-indicator contact-loading"></i>
              </div>
              <div v-if="requestStatus === 2" class="submit-success-title ds-heading--l ds-m-b-m">
                {{ $t('contact.me.send.success') }}
              </div>
              <div v-else class="submit-success-title ds-heading--l ds-m-b-m">
                {{ $t('contact.me.sending') }}
              </div>
              <div v-if="requestStatus === 2" class="submit-success-subtitle ds-m-x-m">
                {{
                  $t('contact.me.send.success.subtitle.one') +
                  partnerProfileData.name +
                  $t('contact.me.send.success.subtitle.two')
                }}
              </div>
              <div v-else class="contact-sending-text ds-m-x-m">
                {{$t('contact.me.sending.subtitle.one')}}
                <br>
                {{ $t('contact.me.sending.subtitle.two')}}
              </div>
            </div>
          </div>
          <div v-show="!requestStatus">
            <div class="ds-modal__box__content ds-p-none">
              <div class="ds-flexRow pf-contact-email-item">
                <div class="ds-flexCol ds-flexCol-sm-6 pf-contact-email-left-items">
                  <div class="contact-email-second-title">
                    {{ $t('contact.me.modal.left.title') }}
                  </div>
                  <label
                    class="ds-m-none ds-textField ds-textField--fixed email-input-field"
                    :class="
                      errorFields.errorFirstName ? 'ds-textField--error' : ''
                    "
                  >
                    <input
                      class="ds-textField__input ds-m-t-m"
                      type="text"
                      maxlength="100"
                      :placeholder="
                        focusFields.includes('errorFirstName')
                          ? ''
                          : $t('contact.me.first.name')
                      "
                      v-model="firstName"
                      @focus="addFocusField('errorFirstName')"
                      @blur="
                        blurMandatoryField(
                          'errorFirstName',
                          $event.target.value
                        )
                      "
                      @input="
                        checkMandatoryField(
                          'errorFirstName',
                          $event.target.value
                        )
                      "
                      id="pf-contact-first-name-text"
                      autocomplete="off"
                    />
                    <span
                      class="ds-textField__placeholder"
                      v-if="firstName || focusFields.includes('errorFirstName')"
                    >
                      {{ $t('contact.me.first.name') }}
                    </span>
                    <span
                      class="ds-textField__message"
                      v-show="errorFields.errorFirstName"
                    >
                      <i class="ds-icon--close-outline ds-m-r-xs"></i>
                      {{ $t('mandatory.field.error.message') }}
                    </span>
                  </label>
                  <label
                    class="ds-m-none ds-textField ds-textField--fixed email-input-field"
                    :class="
                      errorFields.errorLastName ? 'ds-textField--error' : ''
                    "
                  >
                    <input
                      class="ds-textField__input ds-m-t-m"
                      type="text"
                      maxlength="100"
                      :placeholder="
                        focusFields.includes('errorLastName')
                          ? ''
                          : $t('contact.me.last.name')
                      "
                      v-model="lastName"
                      @focus="addFocusField('errorLastName')"
                      @blur="
                        blurMandatoryField('errorLastName', $event.target.value)
                      "
                      @input="
                        checkMandatoryField(
                          'errorLastName',
                          $event.target.value
                        )
                      "
                      id="pf-contact-last-name-text"
                      autocomplete="off"
                    />
                    <span
                      class="ds-textField__placeholder"
                      v-if="lastName || focusFields.includes('errorLastName')"
                    >
                      {{ $t('contact.me.last.name') }}
                    </span>
                    <span
                      class="ds-textField__message"
                      v-show="errorFields.errorLastName"
                    >
                      <i class="ds-icon--close-outline ds-m-r-xs"></i>
                      {{ $t('mandatory.field.error.message') }}
                    </span>
                  </label>
                  <label
                    class="ds-m-none ds-textField ds-textField--fixed email-input-field"
                    :class="errorFields.errorEmail ? 'ds-textField--error' : ''">
                    <input class="ds-textField__input ds-m-t-m"
                           type="text"
                           maxlength="240"
                           :placeholder="focusFields.includes('email') ? '' : $t('label.email')"
                           v-model="email"
                           @input="updateEmail"
                           @focus="addFocusField('email')"
                           @blur="blurEmailField"
                           id="pf-contact-email-text"
                           autocomplete="off"/>
                    <span class="ds-textField__placeholder" v-if="email || focusFields.includes('email')">
                      {{ $t('label.email') }}
                    </span>
                    <span class="ds-textField__message" v-show="errorFields.errorEmail">
                      <i class="ds-icon--close-outline ds-m-r-xs"></i>
                      {{
                        errorFields.emptyEmail ?
                          $t('mandatory.field.error.message') : $t('local.office.email.error.message')
                      }}
                    </span>
                  </label>
                  <label
                    class="ds-m-none ds-textField ds-textField--fixed email-input-field"
                  >
                    <input
                      class="ds-textField__input ds-m-t-m"
                      type="text"
                      maxlength="30"
                      :placeholder="
                        focusFields.includes('phone')
                          ? ''
                          : $t('contact.me.phone.placeholder')
                      "
                      v-model="phone"
                      @focus="addFocusField('phone')"
                      @blur="blurPhoneField"
                      id="pf-contact-phone-text"
                      autocomplete="off"
                    />
                    <span
                      class="ds-textField__placeholder"
                      v-if="phone || focusFields.includes('phone')"
                    >
                      {{ $t('contact.me.phone') }}
                    </span>
                  </label>
                </div>
                <div class="ds-flexCol ds-flexCol-sm-6 pf-contact-email-right-items">
                  <div class="contact-email-second-title">
                    {{ $t('contact.me.modal.right.title') }}
                  </div>
                  <label
                    class="ds-m-none ds-textField ds-textField--fixed email-input-field"
                    :class="
                      errorFields.errorCompany ? 'ds-textField--error' : ''
                    "
                  >
                    <input
                      class="ds-textField__input ds-m-t-m"
                      type="text"
                      maxlength="90"
                      :placeholder="
                        focusFields.includes('errorCompany')
                          ? ''
                          : $t('contact.me.company.name')
                      "
                      v-model="company"
                      @focus="addFocusField('errorCompany')"
                      @blur="
                        blurMandatoryField('errorCompany', $event.target.value)
                      "
                      @input="
                        checkMandatoryField('errorCompany', $event.target.value)
                      "
                      id="pf-contact-company-text"
                      autocomplete="off"
                    />
                    <span
                      class="ds-textField__placeholder"
                      v-if="company || focusFields.includes('errorCompany')"
                    >
                      {{ $t('contact.me.company.name') }}
                    </span>
                    <span
                      class="ds-textField__message"
                      v-show="errorFields.errorCompany"
                    >
                      <i class="ds-icon--close-outline ds-m-r-xs"></i>
                      {{ $t('mandatory.field.error.message') }}
                    </span>
                  </label>
                  <PFDropdownSingleComponent
                    :allOptions="fullCountry"
                    :idKey="idkey"
                    :valueKey="valueKey"
                    :updateSelectedOptionValue="updateCountry"
                    :preselectedOption="country"
                    :errorStatusParent="errorFields.emptyCountry"
                    :updateErrorStatusParent="updateCountryEmptyStatus"
                  />
                </div>
              </div>

              <div class="ds-textarea ds-m-t-l">
                <label
                  class="ds-m-none ds-textField ds-textField--fixed"
                  :class="
                    errorFields.errorComments ? 'ds-textField--error' : ''
                  "
                >
                  <textarea
                    class="ds-textField__input ds-m-t-m"
                    :maxlength="maxChar"
                    :placeholder="
                      focusFields.includes('errorComments')
                        ? ''
                        : $t('contact.me.comments')
                    "
                    v-model="comments"
                    @focus="addFocusField('errorComments')"
                    @blur="blurMandatoryField('errorComments', $event.target.value)"
                    @input="checkMandatoryField('errorComments', $event.target.value)"
                    id="pf-contact-comments-text"
                    autocomplete="off"
                  />
                  <span
                    class="ds-textField__placeholder"
                    v-if="comments || focusFields.includes('errorComments')"
                  >
                    {{ $t('contact.me.comments') }}
                  </span>
                  <span class="error-text-field">
                    <span
                      class="ds-textField__message"
                      v-show="errorFields.errorComments"
                    >
                      <i class="ds-icon--close-outline ds-m-r-xs"></i>
                      {{ $t('mandatory.field.error.message') }}
                    </span>
                    <span class="char-left ds-m-t-xxs">
                      {{ charLeft }} {{ $tc('editor.global.characters.left', charLeft) }}
                    </span>
                  </span>
                </label>
              </div>

              <label class="ds-checkbox ds-m-t-l"
                :for="headerButton ? 'contact-email-checkbox-header' : 'contact-email-checkbox'">
                <input
                  class="ds-checkbox__control"
                  type="checkbox"
                  :id="headerButton ? 'contact-email-checkbox-header' : 'contact-email-checkbox'"
                  v-model="contactEmailCheckBox"
                />
                <span class="ds-checkbox__box">
                  <i class="ds-icon ds-icon--checked"></i>
                </span>
                <span class="ds-checkbox__label">
                  <span :class="errorFields.emptyCheckbox ? 'error-checkbox-text' : ''">
                    {{ $t('contact.me.statement.one') }}
                    {{ partnerProfileData.name }}
                    {{ $t('contact.me.statement.two') }}
                  </span>
                  <a :href="PRIVACY_LINK" target="_blank">{{
                      $t('contact.me.statement.three')
                    }}</a>
                </span>
              </label>
            </div>
            <div class="ds-modal__box__action-bar ds-m-b-s ds-p-none ds-p-x-l">
              <div>
                <div class="ds-m-b-m">
                  <vue-recaptcha
                    ref="recaptcha"
                    @verify="onVerify"
                    @expired="resetRecaptcha"
                    @error="recaptchaFailed"
                    :sitekey="siteKey"
                    :loadRecaptchaScript="false"
                  >
                  </vue-recaptcha>
                  <span
                      class="ds-textField__message"
                      v-show="errorFields.recaptchaError"
                    >
                      <i class="ds-icon--close-outline ds-m-r-xs"></i>
                      {{ $t('mandatory.field.error.message') }}
                  </span>
                </div>
                <div class="ds-textField__message ds-m-b-s" v-show="requestStatus === false">
                  <i class="ds-icon--close-outline email-send-fail-icon ds-m-r-xs"></i>
                  <div>
                    {{ $t('contact.me.send.fail') }}
                    {{ $t('editor.footer.message.contact') }}
                   <a class="email-send-fail-contact" href=""  @click.prevent="sendEmailBackendError()">
                    <u>{{ $t('editor.footer.message.contact.email') }}</u></a>
                   {{ $t('editor.footer.message.contact.b') }}
                  </div>
                </div>
                <button
                  class="ds-modal__box__action-bar__action ds-button ds-button--primary request-button ds-m-x-none"
                  type="button"
                  @click="sendRequest()"
                  :disabled="isButtonDisabled">
                  {{ $t('contact.me.send.request') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";
.center-content {
  text-align: center;
  width: 432px;
  height: 628px;
  margin: auto;
  padding-top: 159px;
}
.error-checkbox-text {
  color: @color-field-error;
}

.submit-success-title {
  &:extend(.font-expanded);
  color: @color-black;
}

.submitting-title {
  &:extend(.font-expanded);
  color: #3c3c3c;
}

.submit-success-subtitle {
  &:extend(.font-normal);
  color: @color-gray-2;
  font-size: 16px;
  line-height: 26px;
}
.contact-sending-text {
  &:extend(.font-normal);
  color: #3c3c3c;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}
.contact-loading {
  font-size: 48px;
  height: 48px;
  width: 48px;
  margin-left: auto;
  margin-right: auto;
}

.contact-email-button {
  &:extend(.font-normal);
  width: 160px;
  max-width: none;
  font-size: 16px;
  text-align: center;
}

.partner-name {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 26px;
  color: @color-gray-3;
}

.contact-email-modal {
  z-index: 201 !important;
}

.contact-email-modal.ds-modal--active {
  overflow-y: scroll;
  overflow-x: hidden;
  display: block !important;
}

.contact-email-dialog {
  position: relative;
  width: 644px;
  margin: 1.75rem auto;
}

.contact-email-modal__box {
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}

.contact-email-second-title {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 26px;
  color: @color-black;
}

.pf-contact-email-item {
  width: 100%;
  margin-right: unset !important;
  margin-left: unset !important;
}

.pf-contact-email-left-items {
  padding-right: 16px !important;
  padding-left: unset !important;
  min-width: 223px;
}

.pf-contact-email-right-items {
  padding-left: 16px !important;
  padding-right: unset !important;
  min-width: 223px;
}

.email-input-field {
  width: 274px;
}

.ds-modal__box__action-bar {
  justify-content: start;
}

#pf-contact-comments-text {
  height: 66px;
  resize: vertical;
}

.ds-checkbox .ds-checkbox__control {
  width: 0;
}

.ds-checkbox__label {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 26px;
  color: @color-black;
}

.ds-textField__input::placeholder,
.ds-textField__placeholder {
  &:extend(.font-normal);
}

.modal-close-icon {
  width: 23px;
  height: 23px;
  float: right;
  margin-right: 33px;
  color: @color-gray-4;
  position: absolute;
  top: 20px;
  right: 0;
  cursor: pointer;
}

.modal-close-icon::before {
  font-size: 23px;
}

.modal-close-icon:hover {
  color: @color-glance-consultants;
}

.char-left {
  &:extend(.font-normal);
  height: 18px;
  font-size: 12px;
  line-height: 1.5;
  text-align: right;
  color: @color-glance-consultants;
  position: absolute;
  right: 0;
}

.ds-textField__message {
  color: @color-field-error;
}
.email-send-fail-contact {
  color: @color-field-error;
}

.email-send-fail-icon {
  margin-top: -25px;
}

.error-text-field {
  display: flex;
  width: 100%;
}

.no-outline {
  outline: 0;
}
</style>
