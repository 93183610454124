<script>
export default {
  name: 'PFSwaComponent',
};
</script>

<template>
  <!-- Adobe Launch Analytics html block -->
<div>
    <div id="teconsent"></div>
</div>
</template>

<style lang="less" scoped>

</style>
