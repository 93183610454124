<script>
/* eslint-disable vue/require-v-for-key */
/* eslint-disable vue/require-v-for-key */
/* eslint-disable vue/no-template-key */
// eslint-disable-next-line import/extensions
import PFSpecializationTblComponent from './PFSpecializationTblComponent.vue';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFERPModalComponent',
  components: {
    PFSpecializationTblComponent,
  },
  data: () => ({
    faqLinkCompetency: urlLinks.LEARN_MORE_SPECIALIZATION,
    faqLinkSolution: urlLinks.HELP_TEXT_LEARN_MORE.SAP_SOLUTION,
  }),
  props: {
    modalHeader: { type: String, default: '' },
    competencyLevel: { type: String, default: '' },
    L2: {
      type: Object,
      default: () => ({}),
    },
    toggleModal: { type: Function, default: () => {} },
    hasCompetency: { type: Boolean, default: () => false },
    isOpenedFromIECompetencyModal: { type: Boolean, default: () => false },
    closeParent: { type: Function, default: () => {} },
  },
  computed: {
    hasSpecialization() {
      return this.L2.specializations?.length > 0;
    },
    getModalHeader() {
      if(this.L2.solutionL2Key === 'PFERPHCPU') {
        return this.$t('GwS.modal.header');
      } else if(this.L2.solutionL2Key === 'PFERPHCPE') {
        return this.$t('RwS.modal.header');
      } else {
        return this.modalHeader;
      }
    },
  },
  methods: {
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
    isOverflown() {
      this.$nextTick(() => {
        if (
          this.$refs.modalProfileCompetencyContent.scrollHeight
          > this.$refs.modalProfileCompetencyContent.clientHeight
        ) {
          this.$refs.modalProfileCompetencyHeader.style.borderBottom = '1px solid #c6c6c6';
          this.$refs.modalProfileCompetencyActionBar.style.borderTop = '1px solid #c6c6c6';
        } else {
          this.$refs.modalProfileCompetencyHeader.style.borderBottom = 'none';
          this.$refs.modalProfileCompetencyActionBar.style.borderTop = 'none';
        }
      });
    },
  },
  mounted() {
    this.isOverflown();
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
  },
  beforeUnmount() {
    if (!this.isOpenedFromIECompetencyModal) {
      document.body.style.paddingRight = '0px';
      document.body.style.overflow = 'scroll';
    }
  },
};
</script>

<template>
  <div class="profile-competency-modal-container">
    <div class="ds-modal ds-modal--active ds-modal--scrollable"
         ref="pfCompetencyModal" tabindex="0" @keyup.esc="toggleModal(false)">
      <div class="modal-container">
      <div class="modal-container-inner-upload" id="profile-competency-modal">
      <div class="ds-modal__box profile-competency-modal" ref="competencyModal">
        <div class="ds-modal__box__header ds-p-b-m" ref="modalProfileCompetencyHeader">
          <span class="ds-modal__box__header__heading competency-modal-header">
            {{ getModalHeader }}</span>
            <i
              class="ds-icon ds-icon--close modal-close-icon modal-icon ds-icon-profile-close"
              @click.prevent="toggleModal(false)"
            ></i>
        </div>
        <div class="ds-modal__box__content ds-m-none ds-m-l-l ds-m-r-s ds-m-t-s ds-m-b-s"
        ref="modalProfileCompetencyContent">

          <div class="profile-competency-text profile-competency-table">
            <table class="ds-table ds-m-none">
              <tbody>
                <tr>
                  <td class="td-border-none top-cell ds-p-none" colspan="2">
                    <div class="profile-competency-text ds-m-b-m"
                    v-if="hasCompetency && competencyLevel?.toUpperCase() !== 'ZERO'">
                      <div class="profile-competency-level-text certer-text ds-m-b-s">{{competencyLevel}}</div>
                      <div class="competency-level-img-container certer-text">
                        <img v-if="competencyLevel.toUpperCase() === 'ESSENTIAL'" alt=""
                          class="competency-level-img competency-level1-img"
                          src="../../assets/Competency-lvl1-large-rest.png"/>
                        <img v-if="competencyLevel.toUpperCase() === 'ADVANCED'" alt=""
                          class="competency-level-img competency-level2-img"
                          src="../../assets/Competency-lvl2-large-rest.png"/>
                        <img v-if="competencyLevel.toUpperCase() === 'EXPERT'" alt=""
                          class="competency-level-img competency-level3-img"
                          src="../../assets/Competency-lvl3-large-rest.png"/>
                      </div>
                    </div>
                    <div>
                    <span class="profile-competency-level-description ds-m-t-m"
                    v-show="hasCompetency && competencyLevel?.toUpperCase() !== 'ZERO'">
                      {{$t("profile.comptency.level.description")}}</span>
                    <span class="profile-competency-level-description" v-show="!hasCompetency">
                      {{ $t("specialization.ERP.not.available.guideText") }}</span>
                    <span class="profile-competency-level-description ds-m-t-m"
                    v-show="hasCompetency && competencyLevel.toUpperCase() === 'ZERO'">
                      {{ $t("zero.level.ERP.guide.text") }}</span>
                      <a class="cursor-pointer"
                      @click.prevent="openInNewTab(faqLinkSolution)">
                      {{ $t("learn.more") }}
                    </a>
                    </div>
                  </td>
                </tr>
                <tr  v-if="hasCompetency && competencyLevel?.toUpperCase() !== 'ZERO'">
                  <td class="td-border-none mid-cell-top" colspan="2">
                    <PFSpecializationTblComponent
                       :specialization="L2.specializationData"
                       :ERPMode="true"
                       :showTableInitial="true"/>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="ds-modal__box__action-bar" ref="modalProfileCompetencyActionBar">
          <button v-if="isOpenedFromIECompetencyModal"
            class="ds-modal__box__action-bar__action ds-button ds-button--secondary"
            type="button"
            @click="toggleModal(false)"
            id="profile-competency-button__back"
          >
            {{ $t("button.back") }}
          </button>
          <div v-else style="visibility:hidden"
            class="ds-modal__box__action-bar__action ds-button ds-button--secondary">
          </div>
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary"
            type="button"
            @click="() => {
              toggleModal(false);
              closeParent();
              }"
            id="profile-competency-button__cancel"
          >
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.specialization-header-text {
  color: @color-grey-0;
  &:extend(.font-bold);
  font-size: 16px;
  line-height: 24px;
}
.inline-word-text {
  white-space: pre;
  display: inline-block;
}

.ds-modal__box__action-bar__action {
  margin-left: 0;
}
.no-split-text {
  display: inline-flex;
  align-content: center;
  white-space: nowrap;
  text-indent: 0px;
}
.cursor-pointer {
  cursor: pointer;
}
.project-text {
  font-size: 14px;
  line-height: 21px;
}
.ds-modal__box__action-bar {
  border-color: #eaeaea !important;
  justify-content: space-between;
}
.top-cell {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-color: #eaeaea !important;
}
.mid-cell-top {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 32px !important;
}
.mid-cell-bot {
  padding-bottom: 20px !important;
  border-color: #eaeaea !important;
}
.specialization-tag {
  text-indent: 0px !important;
  min-width: max-content;
  border: 0.5px solid @color-gray-1;
  border-radius: 10px;
  padding: 0 4% 0 4%;
  margin-left: 8px;
  height: 19px;
  line-height: 13px;
  &:extend(.font-expanded);
  font-size: 11px;
  transform: translateY(-2px);
  align-items: center;
  text-align: center;
  color: @color-gray-1;
  display: inline-flex;
}
.solution-item {
  text-indent: -12px;
  padding-left: 12px;
}
.country-name {
  width: 95% !important;
  vertical-align: middle;
  border-color: #eaeaea !important;
}
.inner-table {
  margin-left: 10%;
}
.bottom-border {
  border-bottom: 1px solid #eaeaea !important;
  padding-bottom: 0.75rem;
}
.country-number {
  min-width:40px;
  border-color: #eaeaea !important;
}
.modal-header {
  padding-bottom: 0 !important;
  border-color: #eaeaea !important;
}
.category-text {
  border-bottom: none !important;
}
.td-border-none {
  border: none !important;
}
.td-min-width {
  min-width: 37px !important;
}
.text-align-right {
  text-align: right !important;
}
.profile-project-header, .profile-project-number {
  color: @color-grey-0;
  &:extend(.font-bold);
  font-size: 16px;
  line-height: 24px;
}
.competency-industry-col-name {
  border-bottom: transparent !important;
}
.col-data {
  vertical-align: middle;
  border-color: #eaeaea !important;
}
.profile-project-number {
  text-align: right;
  width: fit-content !important;
}
.competency-flexRow {
  display: flex;
}
.profile-project-text {
  color: @color-grey-0;
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
}
.competency-level-img {
  height: 30px;
  width: 170px;
}
.certer-text {
  text-align: center;
}
.competency-modal-header {
  color: @color-grey-0;
}
.col-name {
  &:extend(.font-bold);
  color: @color-grey-0;
  width: 30% !important;
}
.profile-competency-level-text {
  &:extend(.font-bold);
  font-size: 21px;
  line-height: 27px;
}
.flex-content-container {
  display: flex;
}
.profile-name-column {
  flex-grow: 5;
}
.profile-addr-column {
  margin-left: 4px;
  flex-grow: 4;
}
.profile-notification-button {
  margin-left: 52px;
  color: @color-link;
  &:extend(.font-expanded);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  .ds-icon::before {
    color: @color-grey-0;
    line-height: 80px;
    font-size: 24px;
  }
  .profile-notification-button-text {
    line-height: 80px;
    font-size: 16px;
  }
}
.profile-notification-more-text:hover,.profile-notification-button:hover {
  color: @color-link__hover;
}
.profile-notification-more-text {
  &:extend(.font-normal);
  color: @color-link;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  white-space: pre-wrap;
}
.profile-competency-text {
  &:extend(.font-normal);
  color: @color-grey-0 !important;
  font-size: 16px;
  line-height: 24px;
}

.profile-specialization-text {
  &:extend(.font-normal);
  color: @color-grey-0;
}
.modal-icon {
  color: @color-gray-2;
}
.modal-icon:hover {
  color: @color-gray-1;
  cursor: pointer;
}
.ds-modal--active {
  z-index: 200;
}
.modal-container-inner-upload {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 250px);
}
.profile-competency-modal {
  // padding-top: 0 !important;
  max-width: 669px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  max-height: 620px;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  border-color: #eaeaea !important;
}
.ds-modal__box__header__heading {
  padding-right: 40px !important;
  &:extend(.font-expanded);
  font-size: 27px;
  line-height: 35px;
}
.ds-icon-profile-close::before {
    font-size: 19px;
    font-weight: 800;
    line-height: 35px;
}
.ds-modal__box__content {
  padding-right: 40px !important;
  margin-right: 10px;
  border-color: #eaeaea !important;
}
.ds-modal__box__content::-webkit-scrollbar-track {
  background: @color-slight-gray;
  border-radius: 20px;
}
.ds-modal__box__content::-webkit-scrollbar-thumb {
  background-color: @color-gray-4;
  border-radius: 20px;
}
.ds-table {
  width: 100%;
}
</style>
