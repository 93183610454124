<script>
import { mapState } from 'vuex';
import PESocialMediasComponent from '@/components/editor/PESocialMediasComponent';

export default {
  name: 'PFSocialMediasBarComponent',
  components: { PESocialMediasComponent },
  props: {
    editMode: { type: Boolean, default: false },
    countryProfileId: { type: Number, default: 0 },
    countryName: { type: String, default: 'global' },
  },
  data: () => ({
    openEdit: false,
    orderOfMedia: [
      'WEBSITE',
      'LINKEDIN',
      'INSTAGRAM',
      'XING',
      'FACEBOOK',
      'TWITTER',
      'WECHAT',
      'YOUTUBE',
    ],
  }),
  methods: {
    hidePopUp() {
      this.openEdit = false;
    },
    getSocialMedias() {
      if (this.countryName === 'global') {
        return this.profileData.profileSocialMedias ? this.profileData.profileSocialMedias
          .filter((media) => media.socialMediaType !== 'WEBSITE') : '';
      }
      return this.profileData.localProfiles.find(
        (lProfile) => this.countryName === lProfile.country,
      ).profileSocialMedias;
    },
    reorderSocialMedia() {
      const reorderedMedia = [];
      const mediaData = this.getSocialMedias();
      if (mediaData) {
        this.orderOfMedia.forEach((socialMediaType) => {
          const media = mediaData.find((item) => item.socialMediaType === socialMediaType);
          if (media) {
            reorderedMedia.push(media);
          }
        });
      }
      return reorderedMedia;
    },
    openwindow(url) {
      let openedUrl = url;
      if (!this.editMode) {
        if (!/^https?:\/\//.test(url)) {
          openedUrl = `http://${url}`;
        }
        window.open(openedUrl, '_blank');
      }
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      profileData: (state) => state.profileData,
    }),
  },
  watch: {
    openEdit(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      } else {
        document.body.style.overflow = 'scroll';
        document.body.style.paddingRight = '0px';
      }
    },
  },
};
</script>

<template>
  <div class="ds-flexGrid social-media">
    <ul
      class="social-media__list"
      :class="[
        editMode ? 'social-media__list-edit' : '',
        getSocialMedias() == null || getSocialMedias().length === 0
          ? 'no-padding'
          : 'center-padding',
      ]"
    >
      <li
        v-for="socialLink in reorderSocialMedia()"
        class="ds-m-none social-media__item"
        v-bind:class="[
          countryName == 'global'
            ? 'ds-m-l-xs social-media_global-item'
            : 'ds-m-l-xxs social-media_local-item',
        ]"
        v-bind:key="socialLink.socialMediaType"
      >
        <a
          class="social-media__link"
          :class="editMode ? 'media-icon-default-cursor' : ''"
          :title="socialLink.socialMediaType"
          href=""
          v-on:click.stop.prevent="openwindow(socialLink.link);
          $eventBus.$emit('PFCustomEvent',
          {eventName: 'NavigateExternal', custom2: 'SocialMedia' , custom3: socialLink.socialMediaType})"
        >
          <span
            :class="[
              (editMode ? 'edit-social-media__icon_' : 'social-media__icon_') +
                socialLink.socialMediaType.toLowerCase(),
              'social-media__icon',
            ]"
          >
          </span>
        </a>
      </li>
      <button
        v-if="
          editMode &&
          (getSocialMedias() == null || getSocialMedias().length === 0)
        "
        @click="openEdit = !openEdit"
        class="ds-button ds-button--primary ds-m-none ds-button--socialmedia-edit"
      >
        {{ $t('editor.social.media.add') }}
      </button>
      <div
        class="ds-m-l-s social-media-edit-button"
        v-else-if="
          editMode &&
          !(getSocialMedias() == null || getSocialMedias().length === 0)
        "
        @click="openEdit = !openEdit"
      >
        {{ $t('editor.social.media.edit') }}
      </div>
    </ul>
    <div
        v-if="editMode && !(getSocialMedias() == null || getSocialMedias().length === 0)"
        class="ds-m-t-s social-media-edit-button social-media-edit-button-small-screen"
        @click="openEdit = !openEdit"
      >
        {{ $t('editor.social.media.edit') }}
    </div>
    <PESocialMediasComponent
      :isShown="openEdit"
      :cancelAction="hidePopUp"
      :countryName="countryName"
    />
  </div>
</template>

<style lang="less">
.social-media__list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
  padding-left: 0px;
  list-style: none;
  line-height: normal;
}
.social-media__list-edit.no-padding {
  padding-left: 0px;
}
.social-media__list-edit.center-padding {
  padding-left: 122px;
}
.social-media__list-edit::after {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: -1; // hide images
  content: url('../../assets/icons/facebook-edit.svg')
    url('../../assets/icons/linkedIn-edit.svg')
    url('../../assets/icons/instagram-edit.svg')
    url('../../assets/icons/twitter-edit.svg')
    url('../../assets/icons/youtube-edit.svg')
    url('../../assets/icons/website-edit.svg')
    url('../../assets/icons/xing-edit.svg')
    url('../../assets/icons/wechat-edit.svg')
    url('../../assets/icons/delete.svg');// preload images
}

.social-media_global-item {
  height: 29px;
  width: 29px;
}

.social-media_local-item {
  height: 24px;
  width: 24px;
}

.social-media__icon {
  width: 100%;
  height: 100%;
  background-size: contain;
  display: block;
}

.ds-button--socialmedia-edit {
  width: auto;
  max-width: none;
}

.social-media-edit-button {
  &:extend(.font-normal);
  font-size: 15px;
  line-height: 21px;
  color: @color-link;
  cursor: pointer;
}
.social-media-edit-button:hover {
  color: @color-link__hover;
}

.social-media__icon_facebook {
  background-image: url('../../assets/icons/facebook-icon.svg');
}

.edit-social-media__icon_facebook {
  background-image: url('../../assets/icons/facebook-edit.svg');
}

.social-media__icon_facebook:hover {
  filter: brightness(0) saturate(100%) invert(21%) sepia(0%) saturate(53%)
    hue-rotate(188deg) brightness(91%) contrast(85%);
}

.social-media__icon_linkedin {
  background-image: url('../../assets/icons/linkedIn-icon.svg');
}

.edit-social-media__icon_linkedin {
  background-image: url('../../assets/icons/linkedIn-edit.svg');
}

.social-media__icon_linkedin:hover {
  filter: brightness(0) saturate(100%) invert(21%) sepia(0%) saturate(53%)
    hue-rotate(188deg) brightness(91%) contrast(85%);
}

.social-media__icon_instagram {
  background-image: url('../../assets/icons/instagram-icon.svg');
}

.edit-social-media__icon_instagram {
  background-image: url('../../assets/icons/instagram-edit.svg');
}

.social-media__icon_instagram:hover {
  filter: brightness(0) saturate(100%) invert(21%) sepia(0%) saturate(53%)
    hue-rotate(188deg) brightness(91%) contrast(85%);
}

.social-media__icon_xing {
  background-image: url('../../assets/icons/xing.svg');
}

.edit-social-media__icon_xing {
  background-image: url('../../assets/icons/xing-edit.svg');
}

.social-media__icon_xing:hover {
  filter: brightness(0) saturate(100%) invert(21%) sepia(0%) saturate(53%)
    hue-rotate(188deg) brightness(91%) contrast(85%);
}

.social-media__icon_twitter {
  background-image: url('../../assets/icons/twitter-icon.svg');
}

.edit-social-media__icon_twitter {
  background-image: url('../../assets/icons/twitter-edit.svg');
}

.social-media__icon_twitter:hover {
  filter: brightness(0) saturate(100%) invert(21%) sepia(0%) saturate(53%)
    hue-rotate(188deg) brightness(91%) contrast(85%);
}

.social-media__icon_youtube {
  background-image: url('../../assets/icons/youtube-icon.svg');
}

.edit-social-media__icon_youtube {
  background-image: url('../../assets/icons/youtube-edit.svg');
}

.social-media__icon_youtube:hover {
  filter: brightness(0) saturate(100%) invert(21%) sepia(0%) saturate(53%)
    hue-rotate(188deg) brightness(91%) contrast(85%);
}

.social-media__icon_website {
  background-image: url('../../assets/icons/website-icon.svg');
}

.edit-social-media__icon_website {
  background-image: url('../../assets/icons/website-edit.svg');
}

.social-media__icon_website:hover {
  filter: brightness(0) saturate(100%) invert(21%) sepia(0%) saturate(53%)
    hue-rotate(188deg) brightness(91%) contrast(85%);
}

.social-media__icon_wechat {
  background-image: url('../../assets/icons/wechat-icon.svg');
}

.edit-social-media__icon_wechat {
  background-image: url('../../assets/icons/wechat-edit.svg');
}

.social-media__icon_wechat:hover {
  filter: brightness(0) saturate(100%) invert(21%) sepia(0%) saturate(53%)
    hue-rotate(188deg) brightness(91%) contrast(85%);
}

.media-icon-default-cursor {
  cursor: default !important;
}
</style>
