<script>
import { mapState } from 'vuex';
import PFSortBarComponent from './PFSortBarComponent';
import responseHandlerUtil from '@/plugins/search/responseHandler';

export default {
  name: 'PFFilterSortBarComponent',
  props: {
    selectedType: { type: [Array, Object], default: () => [] },
    openFiltersBarAction: { type: Function, default: () => true },
    optionSelected: { type: [Array, Object], default: () => [] },
  },
  components: {
    PFSortBarComponent,
  },
  computed: {
    ...mapState('profileSearch', {
      countResults: (state) => state.countResults,
      emitNoResults: (state) => state.emitNoResults,
      lastUsedQuery: (state) => state.lastUsedQuery,
      emitTrackResults: (state) => state.emitTrackResults,
    }),
    searchTerm() {
      return this.$route.query.q;
    },
  },
  methods: {
    addSpaceAfterComma(text) {
      return responseHandlerUtil.addSpaceAfterComma(text);
    },
  },
  watch: {
    emitNoResults(val) {
      this.$eventBus.$emit('PFAACustomEvent',
        { eventName: 'searchNoResults', custom2: this.lastUsedQuery });
    },
    emitTrackResults(val) {
      this.$eventBus.$emit('PFAACustomEvent',
        { eventName: 'pushSearchEvent', custom2: this.countResults, custom3: window.location.search });
    },
  },
};
</script>

<template>
<div class="ds-filter-sort__sort-bar">
  <div class="ds-filter-sort__sort-bar__results-counter">
    <div class="ds-filter-sort__sort-bar__results-counter__counter">
      {{countResults}}
    </div>
    <div class="ds-filter-sort__sort-bar__results-counter__label">
      {{$tc('results.text', countResults)}}
      {{searchTerm ? 'for "' + addSpaceAfterComma(searchTerm) + '"' : '' }}
    </div>
  </div>
  <PFSortBarComponent
    :selectedType="selectedType"
    :actionFunction="openFiltersBarAction"
    :optionSelected="optionSelected" />
</div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.ds-filter-sort__sort-bar {
  max-width: 1244px;
  margin: auto !important;
  .ds-filter-sort__sort-bar__results-counter {
    &:extend(.font-normal);
    width         : fit-content;
    height        : 32px;
    font-size     : 21px;
    line-height   : 32px;
    letter-spacing: normal;
    color         : @color-black;
    cursor: default;
  }
  .ds-filter-sort__sort-bar__results-counter__counter,.ds-filter-sort__sort-bar__results-counter__label {
    &:extend(.font-normal);
    color         : @color-black;
  }
}
</style>
