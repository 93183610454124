class DataOrdering {
  static reorderItems(item, source, target, payload, keyName) {
    if (item[keyName] === payload.target && source.length > 0) {
      return source[0];
    }
    if (item[keyName] === payload.source && target.length > 0) {
      return target[0];
    }
    return item;
  }

  static orderData(resolveItemFiled, payload, data, keyName) {
    const source = data.filter((item) => resolveItemFiled(item) === payload.source);
    const target = data.filter((item) => resolveItemFiled(item) === payload.target);
    const sourceIndex = data.findIndex((item) => item[keyName] === payload.source);
    const targetIndex = data.findIndex((item) => item[keyName] === payload.target);
    if (sourceIndex > targetIndex) {
      data.splice(sourceIndex, 1);
      data.splice(targetIndex, 1);
      data.splice(targetIndex, 0, source[0]);
      data.splice(targetIndex + 1, 0, target[0]);
    } else {
      data.splice(sourceIndex, 1);
      data.splice(targetIndex, 0, source[0]);
    }
    return data;
  }

  static reorderArrayItemsByIdx(items, sourceIdx, targetIdx) {
    const source = { ...items[sourceIdx] };
    const newArray = [];
    items.forEach((element, index) => {
      if (sourceIdx < targetIdx && index !== sourceIdx) {
        newArray.push(element);
      }
      if (index === targetIdx) {
        newArray.push(source);
      }
      if (sourceIdx > targetIdx && index !== sourceIdx) {
        newArray.push(element);
      }
    });
    return newArray;
  }
}
export default DataOrdering;
