class ErrorHandler {
  static handle(err, router) {
    // eslint-disable-next-line no-restricted-globals
    const currentUrl = location.href;
    if (!window.navigator.onLine) {
      router.replace({ name: 'networkErrorPage', query: { q: '' } });
      // eslint-disable-next-line no-restricted-globals
      history.replaceState({}, 'Network Error', currentUrl);
      return;
    }
    switch (err.response.status) {
      case 400:
        router.replace({ name: 'badRequestPage', query: { q: '' } });
        // eslint-disable-next-line no-restricted-globals
        history.replaceState({}, 'Bad Request', currentUrl);
        break;
      case 401:
        router.replace({ name: 'unauthorizedPage', query: { q: '' } });
        // eslint-disable-next-line no-restricted-globals
        history.replaceState({}, 'Not Authorized', currentUrl);
        break;
      case 403:
        router.replace({ name: 'accessRestrictedPage', query: { q: '' }, params: { forbiddenCodes: err.response.data } });
        // eslint-disable-next-line no-restricted-globals
        history.replaceState({}, 'Not Authorized', currentUrl);
        break;
      case 404:
        if (typeof err.response.data === 'string') {
          router.replace({ name: 'systemUnavailablePage', query: { q: '' } });
          // eslint-disable-next-line no-restricted-globals
          history.replaceState({}, 'System Error', currentUrl);
        } else {
          router.replace({ name: 'notFoundPage', query: { q: '' } });
          // eslint-disable-next-line no-restricted-globals
          history.replaceState({}, 'Not Found', currentUrl);
        }
        break;
      case 500:
        router.replace({ name: 'systemErrorPage', query: { q: '' } });
        // eslint-disable-next-line no-restricted-globals
        history.replaceState({}, 'System Error', currentUrl);
        break;
      case 503:
        router.replace({ name: 'systemUnavailablePage', query: { q: '' } });
        // eslint-disable-next-line no-restricted-globals
        history.replaceState({}, 'System Error', currentUrl);
        break;
      default:
        router.replace({ name: 'systemErrorPage', query: { q: '' } });
        // eslint-disable-next-line no-restricted-globals
        history.replaceState({}, 'System Error', currentUrl);
        break;
    }
  }
}
export default ErrorHandler;
