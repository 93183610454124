<script>
export default {
  name: 'PFPrivacyComponent',
};
</script>

<template>
  <div class="privacy-container ds-p-b-xxl">
    <div class="privacy-header-text ds-p-t-xxl">
      {{$t('privacy.header.text')}}
    </div>
    <div class="privacy-second-header-text ds-m-t-s">
      {{$t('privacy.second.header.text')}}
    </div>
    <div class="content-link ds-p-t-xl">
      <div class="ds-m-b-s ds-m-r-xxs">
        <a href="#header-A">{{$t('privacy.header.A')}}</a>
        <i class="ds-icon ds-icon--arrow-right"></i>
      </div>
      <div class="ds-m-b-s ds-m-r-xxs">
        <a href="#header-B">{{$t('privacy.header.B')}}</a>
        <i class="ds-icon ds-icon--arrow-right"></i>
      </div>
      <div class="ds-m-r-xxs">
        <a href="#header-C">{{$t('privacy.header.C')}}</a>
        <i class="ds-icon ds-icon--arrow-right"></i>
      </div>
    </div>
    <div class="content-container">
      <div class="privacy-header-text ds-m-t-xxl ds-m-b-xl" id="header-A">
        {{$t('privacy.header.A')}}
      </div>
      <div class="content-text-A">
        <div>
          <b>{{$t('privacy.section.A.a.question')}}</b>
          {{$t('privacy.section.A.a.answer')}}
          <br><br>
        </div>
        <div>
          <b>{{$t('privacy.section.A.b.question')}}</b>
          {{$t('privacy.section.A.b.answer')}}
          <br><br>
        </div>
        <div>
          <b>{{$t('privacy.section.A.c.question')}}</b>
          {{$t('privacy.section.A.c.answer.a')}}
          <ul class="ds-list">
              <li v-for="index in 3" :key="'A.c.answer.a' + index" class="ds-list__item ds-bullet__item">
                <span class="ds-item__label--normal">{{ $t(`privacy-section-A-c-answer-b[${index - 1}]`) }}</span>
              </li>
          </ul>
          <div> {{$t('privacy.section.A.c.answer.c')}} </div>
          <ul class="ds-list">
              <li v-for="index in 2" :key="'A.c.answer.c' + index" class="ds-list__item ds-bullet__item">
                <span class="ds-item__label--normal">{{ $t(`privacy-section-A-c-answer-d[${index - 1}]`) }}</span>
              </li>
          </ul>
          <div> {{$t('privacy.section.A.c.answer.e')}} </div>
        </div>
        <br>
        <div>
          <b>{{$t('privacy.section.A.d.question')}}</b>
          {{$t('privacy.section.A.d.answer.a')}}
        </div>
        <ul class="ds-list">
            <li v-for="index in 2" :key="'A.d.answer.a' + index" class="ds-list__item ds-bullet__item">
              <span class="ds-item__label--normal" v-html="$t(`privacy-section-A-d-answer-b[${index - 1}]`)"></span>
            </li>
        </ul>
        <div>
          <b>{{$t('privacy.section.A.e.question')}}</b>
          {{$t('privacy.section.A.e.answer.a')}}
        </div>
        <ul class="ds-list">
            <li v-for="index in 4" :key="'A.e.answer.a' + index" class="ds-list__item ds-bullet__item">
              <span class="ds-item__label--normal" v-html="$t(`privacy-section-A-e-answer-b[${index - 1}]`)"></span>
            </li>
        </ul>
        <div>{{$t('privacy.section.A.e.answer.c')}}</div>
        <br>
        <div>
          <b>{{$t('privacy.section.A.f.question')}}</b>
          {{$t('privacy.section.A.f.answer.a')}}
        </div>
        <ul class="ds-list">
            <li v-for="index in 2" :key="'A.f.answer.a' + index" class="ds-list__item ds-bullet__item">
              <span class="ds-item__label--normal" v-html="$t(`privacy-section-A-f-answer-b[${index - 1}]`)"></span>
            </li>
        </ul>
        <div v-html="$t('privacy.section.A.f.answer.c')"></div>
        <br>
        <div>
          <b>{{$t('privacy.section.A.g.question')}}</b>
        </div>
        <br>
        <div v-for="index in 4" :key="'A.g' + index">
          <span v-html="$t(`privacy-section-A-g-answer[${index - 1}]`)"></span>
          <br><br>
        </div>
        <div>
          <b>{{$t('privacy.section.A.h.question')}}</b>
          {{$t('privacy.section.A.h.answer')}}
        </div>
        <br>
        <div>
          <b>{{$t('privacy.section.A.i.question')}}</b>
        </div>
        <br>
        <div v-for="index in 3" :key="'A.i' + index">
          <span v-html="$t(`privacy-section-A-i-answer[${index - 1}]`)"></span>
          <br><br>
        </div>
        <div>
          <b>{{$t('privacy.section.A.j.question')}}</b>
        </div>
        <br>
        <div v-html="$t('privacy.section.A.j.answer')"></div>
      </div>
      <div class="privacy-header-text ds-m-t-xxl ds-m-b-xl" id="header-B">
        {{$t('privacy.header.B')}}
      </div>
      <div class="content-text-B">
        <div>
          <b>{{$t('privacy.section.B.a.question')}}</b>
          <br><br>
        </div>
        <div v-for="index in 3" :key="'B.a' + index">
          <span v-html="$t(`privacy-section-B-a-answer-a[${index - 1}]`)"></span>
          <br><br>
        </div>
        <ul class="ds-list ds-m-none">
          <li v-for="index in 3" :key="'B.a.answer.b' + index" class="ds-list__item ds-bullet__item">
            <span class="ds-item__label--normal" v-html="$t(`privacy-section-B-a-answer-b[${index - 1}]`)"></span>
          </li>
        </ul>
        <br>
        <div>{{$t('privacy.section.B.a.answer.c')}}</div>
        <br>
        <div v-html="$t('privacy.section.B.a.answer.d')"></div>
        <ul class="ds-list">
          <li v-for="index in 3" :key="'B.a.answer.d' + index" class="ds-list__item ds-bullet__item">
            <span class="ds-item__label--normal" v-html="$t(`privacy-section-B-a-answer-e[${index - 1}]`)"></span>
          </li>
        </ul>
      </div>
      <div class="privacy-header-text ds-m-t-xxl ds-m-b-xl" id="header-C">
        {{$t('privacy.header.C')}}
      </div>
      <div class="content-text-C">
        <div v-for="index in 5" :key="'C.a' + index">
          <span v-html="$t(`privacy-section-C-a[${index - 1}]`)"></span>
          <br><br>
        </div>
        <ul class="ds-list ds-m-none">
          <li v-for="index in 4" :key="'C.b' + index" class="ds-list__item ds-bullet__item">
            <span class="ds-item__label--normal" v-html="$t(`privacy-section-C-b[${index - 1}]`)"></span>
          </li>
        </ul>
        <br>
        <div v-for="index in 11" :key="'C.c' + index">
          <span v-html="$t(`privacy-section-C-c[${index - 1}]`)"></span>
          <br><br>
        </div>
        <ul class="ds-list ds-m-none">
          <li class="ds-list__item ds-bullet__item">
            <span class="ds-item__label--normal">{{$t('privacy.section.C.d')}}</span>
          </li>
        </ul>
        <br>
        <div v-for="index in 1" :key="'C.f' + index">
          <span v-html="$t(`privacy-section-C-f[${index - 1}]`)"></span>
          <br><br>
        </div>
      </div>
    </div>

  </div>
</template>

<style lang="less" scoped>
  .privacy-header-text {
    &:extend(.font-expanded);
    font-size: 2.86rem;
    line-height: 1.2;
    text-align: center;
    width: 100%;
    color: @black-color;
  }
  .privacy-container {
    &:extend(.font-normal);
    color: @color-gray-1;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
  }
  .privacy-second-header-text {
    width: 932px;
    max-width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.3rem;
    line-height: 1.5;
  }
  .content-link {
    text-align: center;
    .ds-icon--arrow-right {
      color: @color-link;
    }
    .ds-icon--arrow-right::before {
      font-size: 12px;
    }
  }
  .content-container {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .ds-list {
    color: @color-gray-1;
    .ds-item__label--normal {
      &:extend(.font-normal);
    }
  }
</style>
