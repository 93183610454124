<script>
/* eslint-disable no-undef */
import { mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

const CONTACT_FORM_NAME = 'partnerContactForms';
const DEFAULT_QUERY_TERM_TYPE = 'partner';
const GUIDED_SEARCH_KEY = [
  { urlName: 'products', eventName: 'solution' },
  { urlName: 'location', eventName: 'location' },
  { urlName: 'industry', eventName: 'industry' }];

export default {
  name: 'AAComponent',
  data: () => ({
    profileId: '',
    pageUrl: '',
    referrer: '',
    componentName: '',
    triggerEventAfterLoad: false,
    partnerProfileNameChanged: false,
    title: '',
    currentSearchData: {},
  }),
  mounted() {
    // This global data layer event should be pushed on every page load
    this.pushGlobalDLEvent();
    this.$eventBus.$on('PFAACustomEvent', (data) => {
      this.callAACustomEvent(data);
    });
  },
  methods: {
    async callAACustomEvent(data) {
      switch (data.eventName) {
        case 'ClickModal':
          // data.custom2 is detail field value
          this.pushPageViewEvent(data.custom2);
          window.adobeDataLayer.push({
            event: 'stBeaconReady',
          });
          break;
        case 'ContactFormView':
          this.pushFormViewEvent();
          window.adobeDataLayer.push({
            event: 'stlBeaconReady',
          });
          break;
        case 'ContactFormSubmit':
          this.pushFormSubmitEvent();
          window.adobeDataLayer.push({
            event: 'stlBeaconReady',
          });
          break;
        case 'ContactFormError':
          // data.custom2 is err obj, data.custom3 is error type
          this.pushFormErrorEvent(data.custom2, data.custom3);
          window.adobeDataLayer.push({
            event: 'stlBeaconReady',
          });
          break;
        case 'ClickSearchSuggestionItem':
          this.currentSearchData = { term: data.custom2, location: 'KSD' };
          break;
        case 'ClickSearchSuggestionPartner':
          this.guidedSearch(data.custom5);// param is the url, if null, then no guidedsearch
          const searchLocation = data.custom6 ? 'GS' : 'KSD';
          this.currentSearchData = { term: data.custom4, location: searchLocation, numNA: true };
          this.pushSearchClickEvent(data.custom2, data.custom3, `/partnerNavigator?q=${data.custom2}`, 'KSD');
          break;
        case 'SearchInitiated': // click search button
          this.guidedSearch(data.custom3);// param is the url
          const searchLocation2 = data.custom4 ? 'GS' : 'SRP';
          this.currentSearchData = { term: data.custom2, location: searchLocation2 };
          break;
        case 'searchNoResults':
          this.searchNoResults(data.custom2);
          break;
        case 'clickSearchResult':
          this.pushSearchClickEvent(data.custom2, data.custom3, `/profile/${data.custom4}`, 'SRP');
          break;
        case 'pushSearchEvent':
          this.pushSearchEvent(this.currentSearchData, data.custom2, data.custom3);
          window.adobeDataLayer.push({
            event: 'stlBeaconReady',
          });
          this.currentSearchData = {};
          break;
        default:
          break;
      }
    },
    guidedSearch(url) {
      if (url) {
        const guidedSearch = {
          fields: [],
        };
        const urlParameters = new URLSearchParams(url);
        GUIDED_SEARCH_KEY.forEach((key) => {
          (urlParameters.getAll(key.urlName) || []).forEach((item) => {
            const value = {
              name: key.eventName,
              value: item,
            };
            guidedSearch.fields.push(value);
          });
        });
        if (guidedSearch.fields.length > 0) {
          window.adobeDataLayer.push({
            event: 'guidedSearch',
            guidedSearch,
          });
        }
      }
    },
    pushFormViewEvent() {
      const uniqueId = this.getUid();
      window.adobeDataLayer.push({
        event: 'formView',
        forms: {
          uniqueID: uniqueId,
          name: CONTACT_FORM_NAME,
          referrer: window.location.href,
        },
      });
    },
    pushFormSubmitEvent() {
      const uniqueId = this.getUid();
      const transactionID = this.getTransactionId();
      const CountryCode = this.getFormCountryCode();
      window.adobeDataLayer.push({
        event: 'formSubmit',
        forms: {
          uniqueID: uniqueId,
          name: CONTACT_FORM_NAME,
          transactionID,
          referrer: window.location.href,
          contactRequested: 'Yes',
          country: CountryCode,
        },
      });
    },
    pushFormErrorEvent(error, validationErr) {
      let errMessage = '';
      if (validationErr) {
        errMessage = error;
      } else if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errMessage = `Reponse error with status: ${error.response.status}`;
        errMessage += ` Reponse data:${error.response.data.error}`;
      } else if (error.request) {
        errMessage = `The request was made but no response was received: ${error.request}`;
      } else {
        errMessage = `Something happened in setting up the request that triggered an Error: ${error.message}`;
      }
      window.adobeDataLayer.push({
        event: 'formError',
        forms: {
          name: CONTACT_FORM_NAME,
          errorDescription: errMessage,
          referrer: window.location.href,
        },
      });
    },
    callSwaTrackSearch(path) {
      const regex = /\/partnerNavigator\?/i;
      const searchTerms = {
        query: '',
        products: [],
        location: [],
        industry: [],
        engagement: [],
      };
      path.replace(regex, '')
        .split('&')
        .filter((element) => {
          const filterRegex = /^(products=|location=|industry=|engagement=)/i;
          return filterRegex.test(element);
        })
        .forEach((element) => {
          const [filterType, filterValue] = element.split('=');
          try {
            const decodedFilterValue = decodeURIComponent(filterValue);
            searchTerms[filterType].push(decodedFilterValue);
          } catch (e) {
            console.error(e);
          }
        });
      searchTerms.query = this.query || '';
      // swa.trackCustomEvent('Search', searchTerms.query, searchTerms.products.join(), searchTerms.location.join(), searchTerms.industry.join(), searchTerms.engagement.join());
    },
    pushGlobalDLEvent() {
      // This needs needs to before any data layer values are pushed into the object
      window.adobeDataLayer = window.adobeDataLayer || [];
      window.adobeDataLayer.push({
        event: 'globalDL',
        site: {
          name: 'p-pf', // it requires a site name code for PF
          country: 'glo',
        },
        user: {
          loginStatus: this.userLogInStatus,
        },
      });
    },
    pushPageViewEvent(details) {
      window.adobeDataLayer = window.adobeDataLayer || [];
      const dataEvent = {
        event: 'pageView',
        page: {
          name: this.pageUrl,
          section: this.section,
          language: 'en',
          country: 'glo',
          url: window.location.href,
          referrer: this.referrer,
          title: this.title,
          // 'template': this.componentName,
        },
        user: {
          loginStatus: this.userLogInStatus,
        },
      };
      if (details) {
        dataEvent.page.detail = details;
      }
      window.adobeDataLayer.push(dataEvent);
    },
    getTitle(name) {
      switch (name) {
        case 'home':
          this.title = 'SAP Partner Finder';
          break;
        case 'partnerNavigatorPage':
          this.title = 'Search Results';
          break;
        default:
          this.title = this.partnerProfileName;
      }
    },
    pushSearchEvent(data, num, filters) {
      // use this one when there is an search query
      const queryTermType = this.queryTermTypeState ? this.queryTermTypeState : DEFAULT_QUERY_TERM_TYPE;
      const sortType = this.sorting;
      const term = data.term || this.query;
      // capture GwS RwS filters
      const urlParameters = new URLSearchParams(filters);
      const rwsGws = urlParameters.getAll('designation');
      const rws = rwsGws.includes('rise') ? 'RwS' : '';
      const gws = rwsGws.includes('gws') ? 'GwS' : '';
      // when user click partner suggestion item, UI will directly open the profile, result count not available
      const resultCountNum = data.numNA ? 'NA' : num;
      window.adobeDataLayer = window.adobeDataLayer || [];
      window.adobeDataLayer.push({
        event: 'search',
        search: {
          term: `${sortType}|${gws}|${rws}|${queryTermType}|${term}|${resultCountNum}`,
          location: data.location,
          keywordType: queryTermType,
        },
      });
    },
    pushSearchClickEvent(term, rank, url, location) {
      // use when user clicks search suggetion item or click search result
      window.adobeDataLayer = window.adobeDataLayer || [];
      const dummyLink = document.createElement('a');
      dummyLink.href = url;
      const clickUrl = dummyLink.href;
      window.adobeDataLayer.push({
        event: 'searchClick',
        search: {
          term,
          clickRank: rank + 1,
          clickURL: clickUrl,
          clickLocation: location,
        },
      });
    },
    searchNoResults(term) {
      window.adobeDataLayer.push({
        event: 'searchNoResults',
        search: {
          term,
        },
      });
      window.adobeDataLayer.push({
        event: 'stlBeaconReady',
      });
    },
    getCurrentProfileId() {
      return this.$router.currentRoute.value.params.profileId;
    },
    getUid() {
      let uniqueId;
      if (window.sessionStorage.getItem(`${this.getCurrentProfileId()}FormView`)) {
        uniqueId = window.sessionStorage.getItem(`${this.getCurrentProfileId()}FormView`);
      } else {
        uniqueId = uuidv4();
        window.sessionStorage.setItem(`${this.getCurrentProfileId()}FormView`, uniqueId);
      }
      return uniqueId;
    },
    getTransactionId() {
      let transactionId;
      if (window.sessionStorage.getItem(`${this.getCurrentProfileId()}FormSubmit`)) {
        transactionId = window.sessionStorage.getItem(`${this.getCurrentProfileId()}FormSubmit`);
      } else {
        transactionId = uuidv4();
        window.sessionStorage.setItem(`${this.getCurrentProfileId()}FormSubmit`, transactionId);
      }
      return transactionId;
    },
    getFormCountryCode() {
      return this.$store.state.fullData.allowedCountries
        .filter((item) => item.text === this.currentSelectedCountry)[0].country;
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      partnerProfileData: (state) => state.profileData,
      partnerProfileName: (state) => state.profileData.name,
      loadingProfileData: (state) => state.loadingProfileData,
    }),
    ...mapState('profileSearch', {
      query: (state) => state.query,
      queryTermTypeState: (state) => state.queryTermTypeState,
      sorting: (state) => state.sorting,
    }),
    ...mapState('userProfile', {
      userLogInStatus: (state) => state.loggedIn,
    }),
    ...mapState('contactEmail', {
      requestStatus: (state) => state.requestStatus,
      errorMessage: (state) => state.errorMessage,
      currentSelectedCountry: (state) => state.currentSelectedCountry,
    }),
  },
  watch: {
    loadingProfileData(val) {
      if (!val) {
        this.title = this.partnerProfileName;
        this.pushPageViewEvent();
        window.adobeDataLayer.push({
          event: 'stBeaconReady',
        });
      }
    },
    requestStatus(val) {
      if (val === true) {
        this.$eventBus.$emit('PFAACustomEvent',
          { eventName: 'ContactFormSubmit' });
      } else if (val === false) {
        this.$eventBus.$emit('PFAACustomEvent',
          {
            eventName: 'ContactFormError',
            custom2: this.errorMessage,
            custom3: false,
          });
      }
    },
    $route: {
      immediate: true,
      deep: true,
      handler(to, from) {
        if (from) {
          this.profileId = to.params.profileId || '';
          this.pageUrl = to.path; // full path contains param, path doesn't
          this.componentName = to.name;
          this.referrer = from.name ? window.location.origin + from.fullPath : document.referrer;
          this.section = to.path.split('/')[1];
          if (to.name === 'home') {
            this.section = '/';
          }
          if ((to.name === 'home' && from.name !== 'home') || to.name === 'partnerNavigatorPage'
            || (to.name === 'profileEditorPageEdit' && from.name == 'profileEditorPagePreview')
            || (to.name === 'profileEditorPagePreview' && from.name == 'profileEditorPageEdit')) {
            this.getTitle(to.name);
            this.pushPageViewEvent();
            window.adobeDataLayer.push({
              event: 'stBeaconReady',
            });
          }
        }
      },
    },
  },
};
</script>

<template>
  <div></div>
</template>

<style lang="less" scoped>

</style>
