<script>
/* eslint-disable vue/require-v-for-key */
/* eslint-disable vue/require-v-for-key */
/* eslint-disable vue/no-template-key */
// eslint-disable-next-line import/extensions
import PFSpecializationTblComponent from './PFSpecializationTblComponent.vue';

const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFCompetencyModalComponent',
  components: {
    PFSpecializationTblComponent,
  },
  data: () => ({
    faqLinkCompetency: urlLinks.LEARN_MORE_SPECIALIZATION,
    faqLinkSolution: urlLinks.HELP_TEXT_LEARN_MORE.SAP_SOLUTION,
    tooltipOpen: {},
  }),
  props: {
    modalHeader: { type: String, default: '' },
    L1Key: { type: String, default: '' },
    competencyLevel: { type: String, default: '' },
    L2s: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Object,
      default: () => ({}),
    },
    industries: {
      type: Object,
      default: () => ({}),
    },
    toggleModal: { type: Function, default: () => {} },
    hasCompetency: { type: Boolean, default: () => false },
    specializations: { type: Array, default: () => [] },
    isOpenedFromIECompetencyModal: { type: Boolean, default: () => false },
    closeParent: { type: Function, default: () => {} },
  },
  computed: {
  },
  methods: {
    hasSpecialization() {
      return this.specializations.length > 0;
    },
    isNumOfSpecializationsEqNumSolutions() {
      return this.L2s.every((l2) => l2.hasSpecialization === true);
    },
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
    isOverflown() {
      this.$nextTick(() => {
        if (
          this.$refs.modalProfileCompetencyContent.scrollHeight
          > this.$refs.modalProfileCompetencyContent.clientHeight
        ) {
          this.$refs.modalProfileCompetencyContentTbl.style.paddingRight = '0px';
        } else {
          this.$refs.modalProfileCompetencyContentTbl.style.paddingRight = '8px';
        }
      });
    },
    removeLastWord(str) {
      const strArr = str.trim().split(' ');
      strArr.pop();
      return strArr.join(' ');
    },
    getLastWord(str) {
      const strArr = str.trim().split(' ').pop();
      return this.removeLastWord(str).length > 0 ? ` ${strArr}` : strArr;
    },
    toggleTooltip(id) {
      this.tooltipOpen[id] = false;
    },
  },
  mounted() {
    this.isOverflown();
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
  },
  beforeUnmount() {
    if (!this.isOpenedFromIECompetencyModal) {
      document.body.style.paddingRight = '0px';
      document.body.style.overflow = 'scroll';
    }
  },
};
</script>

<template>
  <div class="profile-competency-modal-container">
    <div class="ds-modal ds-modal--active ds-modal--scrollable"
         ref="pfCompetencyModal" tabindex="0" @keyup.esc="toggleModal(false)">
      <div class="modal-container">
      <div class="modal-container-inner-upload" id="profile-competency-modal">
      <div class="ds-modal__box profile-competency-modal" ref="competencyModal">
        <div class="ds-modal__box__header ds-p-b-m modal-profile-competency-header" ref="modalProfileCompetencyHeader">
          <span class="ds-modal__box__header__heading competency-modal-header">
            {{ modalHeader }}</span>
            <i
              class="ds-icon ds-icon--close modal-close-icon modal-icon ds-icon-profile-close"
              @click.prevent="toggleModal(false)"
            ></i>
        </div>
        <div class="ds-modal__box__content ds-m-none ds-m-l-l ds-m-r-s ds-m-b-s"
        :class="{'ds-m-t-s': hasCompetency}"
        ref="modalProfileCompetencyContent">

          <div class="profile-competency-text profile-competency-table">
            <table class="ds-table ds-m-none" ref="modalProfileCompetencyContentTbl">
              <tbody>
                <tr>
                  <td v-if="hasCompetency && competencyLevel?.toUpperCase() !== 'ZERO'" class="top-cell td-border-none" colspan="2">
                    <div class="profile-competency-text">
                      <div class="profile-competency-level-text certer-text ds-m-b-s">{{competencyLevel}}</div>
                      <div class="competency-level-img-container certer-text">
                        <img v-if="competencyLevel.toUpperCase() === 'ESSENTIAL'" alt=""
                          class="competency-level-img competency-level1-img"
                          src="../../assets/Competency-lvl1-large-rest.png"/>
                        <img v-if="competencyLevel.toUpperCase() === 'ADVANCED'" alt=""
                          class="competency-level-img competency-level2-img"
                          src="../../assets/Competency-lvl2-large-rest.png"/>
                        <img v-if="competencyLevel.toUpperCase() === 'EXPERT'" alt=""
                          class="competency-level-img competency-level3-img"
                          src="../../assets/Competency-lvl3-large-rest.png"/>
                      </div>
                    </div>
                    <div class="profile-competency-level-description">
                      {{$t("profile.comptency.level.description")}}</div>
                  </td>
                </tr>
                <tr v-if="!isNumOfSpecializationsEqNumSolutions()">
                  <td class="td-border-none mid-cell-top" colspan="2">
                    <div v-show="hasCompetency && competencyLevel.toUpperCase() !== 'ZERO'"
                    class="ds-m-b-xs specialization-header-text PFSpecializationTblComponent-border">
                      {{$tc("profile.competency.Solutions", L2s.length)}}</div>
                    <span v-show="hasCompetency  && competencyLevel.toUpperCase() !== 'ZERO'">
                      {{ $t("profile.competency.modal.solution.text") }}</span>
                    <span v-show="!hasCompetency">{{ $t("profile.solution.text.competency.not.available") }}</span>
                    <span v-show="hasCompetency && competencyLevel.toUpperCase() === 'ZERO'">
                      {{ $t("profile.solution.text.level.zero") }}</span>
                    <a class="cursor-pointer"
                      @click.prevent="openInNewTab(faqLinkSolution)">
                      {{ $t("learn.more") }}
                    </a>
                  </td>
                </tr>
                <template v-if="!isNumOfSpecializationsEqNumSolutions()">
                  <tr v-for="(solution, idx) in L2s"
                      :key="solution.solutionL2Name + '-' + idx">
                            <td class="ds-p-none td-border-none">
                              <div class="spe-name-data">
                              <span>&nbsp; &#8226;&nbsp;&nbsp;&nbsp;{{solution.solutionL2Name}}</span>
                              <div v-if="L1Key !== 'PFFIN' && !solution.specializationAvailable"
                                class="tool-tip-container"
                                v-click-outside="() => toggleTooltip(solution.solutionL2Name)">
                                <button @onClick="(e) => e.stopPropagation()"
                                  class="ds-icon ds-icon--info tool-tip-icon ds-m-l-xs ds-m-r-xs ds-p-none"
                                  :class="tooltipOpen[solution.solutionL2Name] ? 'tool-tip-icon-clicked' : ''"
                                  @click="tooltipOpen[solution.solutionL2Name] = !tooltipOpen[solution.solutionL2Name];isOverflown()">
                                  </button>
                                <div class='ds-tooltip-icon'
                                :class="solution.solutionL2Name.length > 30 ? 'toolitp-icon-item-list-ERP'
                                : 'toolitp-icon-item-list'">
                                  <div
                                    :class="[tooltipOpen[solution.solutionL2Name] ? 'display-block' :
                                    'display-none','tooltiptext',
                                    tooltipOpen[solution.solutionL2Name] && solution.solutionL2Name.length > 30 ?
                                    'ds-tooltip-icon__tooltip--ERP':'ds-tooltip-icon__tooltip--service']">
                                    {{ $t('specialization.not.available.helptext') }}
                                    <span @click="openInNewTab(faqLinkCompetency)" class="help-text-link">
                                      <b> {{$t('learn.more')}}</b>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              </div>
                            </td>
                            <td class="ds-p-none td-border-none">
                              <div v-if="solution.hasSpecialization" class="specialization-tag">
                                {{$t("profile.competency.Specializations")}}
                              </div>
                            </td>
                          </tr>
                        </template>
                <tr>
                  <td class="PFSpecializationTblComponent-td td-border-none mid-cell-top"
                  colspan="2" v-if="hasSpecialization()">
                    <div class="ds-m-b-xs specialization-header-text PFSpecializationTblComponent-border">
                      {{$tc('profile.competency.Specializations', specializations.length)}}</div>
                    {{ $t("profile.competency.modal.specialization.text") }}
                    <a class="cursor-pointer"
                      @click.prevent="openInNewTab(faqLinkCompetency)">
                      {{ $t("learn.more") }}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td ref="speTable" class="td-border-none spe-table mid-cell-top" colspan="2">
                    <div v-for="(spe, idx) in specializations" :key="idx+spe.solutionL2Key">
                      <PFSpecializationTblComponent
                       v-on:checkOverflow="isOverflown"
                       :specialization="spe"
                       :showTableInitial="specializations&&specializations.length === 1"
                       :idx="idx"/>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="ds-modal__box__action-bar" ref="modalProfileCompetencyActionBar">
          <button v-if="isOpenedFromIECompetencyModal"
            class="ds-modal__box__action-bar__action ds-button ds-button--secondary"
            type="button"
            @click="toggleModal(false)"
            id="profile-competency-button__back"
          >
            {{ $t("button.back") }}
          </button>
          <div v-else style="visibility:hidden"
            class="ds-modal__box__action-bar__action ds-button ds-button--secondary">
          </div>
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary"
            type="button"
            @click="() => {
              toggleModal(false);
              closeParent();
              }"
            id="profile-competency-button__cancel"
          >
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.spe-name-data {
  display: flex;
  justify-content: left;
  align-items: center;
}
.tool-tip-container {
  text-align: left;
  display: inline-block;
  position: relative;
  button {
    background: none;
    border: none;
  }
}
.modal-profile-competency-header {
  border-color: #eaeaea !important;
}
.PFSpecializationTblComponent-td {
  padding-top: 20px;
}
.PFSpecializationTblComponent-border {
  border-top: 1px solid #eaeaea !important;
  padding-top: 20px;
}
.specialization-header-text {
  color: @color-grey-0;
  &:extend(.font-bold);
  font-size: 16px;
  line-height: 24px;
}
.inline-word-text {
  white-space: pre;
  display: inline-block;
}

.ds-modal__box__action-bar__action {
  margin-left: 0;
}
.no-split-text {
  display: inline-flex;
  align-content: center;
  white-space: nowrap;
  text-indent: 0px;
}
.cursor-pointer {
  cursor: pointer;
}
.profile-competency-level-description {
  margin-top: 20px;
}
.project-text {
  font-size: 14px;
  line-height: 21px;
}
.ds-modal__box__action-bar {
  border-color: #eaeaea !important;
  justify-content: space-between;
}
.top-cell {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 20px !important;
  padding-bottom: 0px !important;
  border-color: #eaeaea !important;
}
.mid-cell-top {
  padding-top: 20px !important;
  padding-left: 0px !important;
}
.spe-table {
  padding-top: 0px !important;
  padding-left: 0px !important;
}
.mid-cell-bot {
  padding-bottom: 20px !important;
  border-color: #eaeaea !important;
}
.specialization-tag {
  text-indent: 0px !important;
  min-width: max-content;
  border: 0.5px solid @color-gray-1;
  border-radius: 10px;
  padding: 0 4.1% 0 4.1%;
  margin-left: 8px;
  height: 19px;
  line-height: 13px;
  &:extend(.font-expanded);
  font-size: 11px;
  transform: translateY(-2px);
  align-items: center;
  text-align: center;
  color: @color-gray-1;
  display: inline-flex;
}
.solution-item {
  text-indent: -12px;
  padding-left: 12px;
}
.country-name {
  width: 95% !important;
  vertical-align: middle;
  border-color: #eaeaea !important;
}
.inner-table {
  margin-left: 10%;
}
.bottom-border {
  border-bottom: 1px solid #eaeaea !important;
  padding-bottom: 0.75rem;
}
.country-number {
  min-width:40px;
  border-color: #eaeaea !important;
}
.modal-header {
  padding-bottom: 0 !important;
  border-color: #eaeaea !important;
}
.category-text {
  border-bottom: none !important;
}
.td-border-none {
  border: none !important;
}
.td-min-width {
  min-width: 37px !important;
}
.text-align-right {
  text-align: right !important;
}
.profile-project-header, .profile-project-number {
  color: @color-grey-0;
  &:extend(.font-bold);
  font-size: 16px;
  line-height: 24px;
}
.competency-industry-col-name {
  border-bottom: transparent !important;
}
.col-data {
  vertical-align: middle;
  border-color: #eaeaea !important;
}
.profile-project-number {
  text-align: right;
  width: fit-content !important;
}
.competency-flexRow {
  display: flex;
}
.profile-project-text {
  color: @color-grey-0;
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
}
.competency-level-img {
  height: 30px;
  width: 170px;
}
.certer-text {
  text-align: center;
}
.competency-modal-header {
  color: @color-grey-0;
}
.col-name {
  &:extend(.font-bold);
  color: @color-grey-0;
  width: 30% !important;
}
.profile-competency-level-text {
  &:extend(.font-bold);
  font-size: 21px;
  line-height: 27px;
}
.flex-content-container {
  display: flex;
}
.profile-name-column {
  flex-grow: 5;
}
.profile-addr-column {
  margin-left: 4px;
  flex-grow: 4;
}
.profile-notification-button {
  margin-left: 52px;
  color: @color-link;
  &:extend(.font-expanded);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  .ds-icon::before {
    color: @color-grey-0;
    line-height: 80px;
    font-size: 24px;
  }
  .profile-notification-button-text {
    line-height: 80px;
    font-size: 16px;
  }
}
.profile-notification-more-text:hover,.profile-notification-button:hover {
  color: @color-link__hover;
}
.profile-notification-more-text {
  &:extend(.font-normal);
  color: @color-link;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  white-space: pre-wrap;
}
.profile-competency-text {
  &:extend(.font-normal);
  color: @color-grey-0 !important;
  font-size: 16px;
  line-height: 24px;
}

.profile-specialization-text {
  &:extend(.font-normal);
  color: @color-grey-0;
}
.modal-icon {
  color: @color-gray-2;
}
.modal-icon:hover {
  color: @color-gray-1;
  cursor: pointer;
}
.ds-modal--active {
  z-index: 200;
}
.modal-container-inner-upload {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 250px);
}
.profile-competency-modal {
  // padding-top: 0 !important;
  max-width: 669px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  max-height: 620px;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  border-color: #eaeaea !important;
}
.ds-modal__box__header__heading {
  padding-right: 40px !important;
  &:extend(.font-expanded);
  font-size: 27px;
  line-height: 35px;
}
.ds-icon-profile-close::before {
    font-size: 19px;
    font-weight: 800;
    line-height: 35px;
}
.ds-modal__box__content {
  padding-right: 40px !important;
  margin-right: 10px;
  border-color: #eaeaea !important;
}
.ds-modal__box__content::-webkit-scrollbar-track {
  background: @color-slight-gray;
  border-radius: 20px;
}
.ds-modal__box__content::-webkit-scrollbar-thumb {
  background-color: @color-gray-4;
  border-radius: 20px;
}
.ds-table {
  width: 100%;
}
</style>
