const GUIDED_REQUEST_TYPE = 'results/count';

class SearchResponseHandler {
  static handleResponse(context, data, router, requestType) {
    if (requestType === GUIDED_REQUEST_TYPE) return false;
    const content = data?.data?.results;
    const resultsCount = data?.data?.count;
    if (resultsCount && resultsCount === 1
      && (context.state.alwaysRedirectToPartner ? true : context.state.searchPartner)) {
      const partnerProfileId = content?.[0]?.profileId;
      context.dispatch('setPathChangedFromUiFlag', true);
      router.replace({ name: 'profilePage', params: { profileId: partnerProfileId } })
        .then(() => {
          context.dispatch('setQueryWithoutSearch', '');
        });
      context.commit('setSearchPartner', false);
      return true;
    }
    return false;
  }

  static addSpaceAfterComma(text) {
    return text ? text.split(',').map((word) => word.trim()).join(', ') : '';
  }
}
export default SearchResponseHandler;
