<script>
import { mapState } from 'vuex';

export default {
  name: 'PESessionExpirationPopupComponent',
  props: {
    toggleShowLeaveDialog: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('userProfile', {
      sessionExpired: (state) => (PROCESS_NODE_ENV !== 'local'
        ? state.sessionExpired : false),
      loggedIn: (state) => state.loggedIn,
    }),
    ...mapState('partnerProfile', {
      changedData: (state) => state.changedData,
    }),
  },
  mounted() {
    if (this.sessionExpired) {
      this.changeOverflowTo('hidden');
      document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
    }
  },
  methods: {
    login() {
      window.location.reload();
    },
    close() {
      this.closeSessionExpiredDialog();
      this.$store.commit('userProfile/setCancelSessionExtendingClicked', true);
      this.changeOverflowTo('scroll');
      document.body.style.paddingRight = '0px';
    },
    closeSessionExpiredDialog() {
      this.$store.commit('userProfile/setSessionExpired', false);
    },
    relocateToHomePage() {
      window.location.href = '/';
      this.$store.commit('userProfile/setCancelSessionExtendingClicked', false);
    },
    changeOverflowTo(newOverflow) {
      document.body.style.overflow = newOverflow;
    },
  },
};
</script>

<template>
    <div class="ds-modal ds-modal--active" v-if="sessionExpired">
      <div class="modal-container">
      <div class="modal-container-inner-session">
      <div class="ds-modal__box leave-dialog-modal">
        <div class="ds-modal__box__header ds-p-y-none">
          <i class="ds-icon--clear ds-p-r-xs" />
          <div class="ds-modal__box__header__heading">
             <div class="ds-upload__block-reminder-upload
                                                dialog-warning-message-red ds-message__content ds-p-none">
                {{ $t('session.expired') }}
              </div>
          </div>
        </div>
        <div class="ds-modal__box__content">
          <div class="dialog-text ds-m-y-s">
            {{  $t("session.expired.text") }}
          </div>
        </div>
        <div class="ds-modal__box__action-bar ds-p-y-none">
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary save-button ds-m-x-none"
            type="button"
            @click="login"
          >
            {{ $t("button.login") }}
          </button>
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--secondary discard-button ds-m-l-m"
            type="button"
            @click="close"
          >
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
      </div>
      </div>
    </div>

</template>

<style lang="less" scoped>
.ds-modal--active {
    z-index: 200;
}
.modal-container-inner-session {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 102px);
}
.leave-dialog-modal {
  width: 480px;
  height: fit-content;
  min-height: 207px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  .dialog-warning-message {
    &:extend(.font-expanded);
    font-weight: normal;
    line-height: 35px;
    ds-icon--clear::before {
      font-size: 24px;
      line-height: 35px;
    }
    &-red {
      color: @color-editor-footer-error;
    }
  }
  .dialog-text {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
    color: @color-black;
    padding-left: 30px;
  }
  .ds-button {
    &:extend(.font-normal);
    font-size: 16px;
    max-width: none;
    width: fit-content !important;
  }
  .cancel-button {
    color: @color-link;
    border: none;
    background-color: transparent !important;
  }
  .cancel-button:hover {
    color: @color-link__hover;
  }
  .ds-icon--clear::before {
    color: @color-red-delete-button;
    font-size: 30px;
    line-height: 30px;
    position: relative;
    left: -15px;
  }
  .ds-modal__box__header__heading {
    font-family: 'BentonSans';
    font-size: 1.31rem;
  }
  .ds-modal__box__content {
    margin-top: -5px;
    margin-bottom: 30px;
  }
  .ds-modal__box__action-bar {
    padding-right: 1rem;
  }
  .ds-message__content {
    font-family: "BentonSansRegular";
    color: @color-black;
    margin-left: -10px;
  }
}

</style>
