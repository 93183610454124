<script>
import PFProfileResourceComponent from '@/components/common/PFProfileResourceComponent';

export default {
  name: 'PFProfileSliderDataResourceComponent',
  components: { PFProfileResourceComponent },
  props: {
    data: { type: Object },
    showAllToggle: { type: Boolean, default: false },
    countryName: { type: String, default: '' },
    countryText: { type: String, default: '' },
  },
  methods: {
    editResource() {
      this.editAction(this.data);
    },
    removeResource() {
      this.removeAction(this.data);
    },
    emitToggleCardHeight() {
      this.$emit('toggleCardHeight');
    },
  },
};
</script>

<template>
  <div v-if="data" class="pf-resource-slide-content">
    <PFProfileResourceComponent v-if="data.firstResource"
                                :countryName="countryName"
                                :countryText="countryText"
                                class="pf-resource-slide-content-item"
                                :class="showAllToggle ? 'resource-height-large' : ''"
                                :data="data.firstResource"
                                v-on:toggleCardHeight="emitToggleCardHeight"
                                :showAllToggle="showAllToggle"
                                :countryProfileId="1"/>
    <PFProfileResourceComponent v-if="data.secondResource"
                                :countryName="countryName"
                                :countryText="countryText"
                                class="pf-resource-slide-content-item"
                                :class="showAllToggle ? 'resource-height-large' : ''"
                                :data="data.secondResource"
                                v-on:toggleCardHeight="emitToggleCardHeight"
                                :showAllToggle="showAllToggle"
                                :countryProfileId="1"/>
    <PFProfileResourceComponent v-if="data.thirdResource"
                                :countryName="countryName"
                                :countryText="countryText"
                                class="pf-resource-slide-content-item"
                                :class="showAllToggle ? 'resource-height-large' : ''"
                                :data="data.thirdResource"
                                v-on:toggleCardHeight="emitToggleCardHeight"
                                :showAllToggle="showAllToggle"
                                :countryProfileId="1"/>
  </div>
</template>

<style lang="less">

.pf-resource-slide-content {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 241px;
  width: 840px;
}

.pf-resource-slide-content-item {
  width: 264px;
  height: 251px;
  margin-left: 6px;
  margin-right: 6px;
}

.pf-resource-slide-content-item:hover {
  border: solid 1px #005178;
  box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
  transition: transform .25s, visibility .25s ease-in;
}

.resource-height-large {
  height: 390px;
}

</style>
