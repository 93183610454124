<script>
import { mapState } from 'vuex';
import PEBrandNameComponent from '@/components/editor/PEBrandNameComponent';

const MAX_LENGTH_NAME = 90;
const MAX_LENGTH_WEBSITE = 64;

export default {
  name: 'PEPartnerTitleComponent',
  data: () => ({
    isValidName: true,
    isValidWebsite: null,
    isTitleFocused: false,
    isWebsiteFocused: false,
    wasChangedWebsite: false,
    nameMaxCharLength: MAX_LENGTH_NAME,
    webSiteMaxCharLength: MAX_LENGTH_WEBSITE,
    partnerNameMandatoryErr: {},
    partnerNameFormatErr: {},
    websiteMandatoryErr: {},
    notValidUrlErr: {},
    timer: null,
    clickDiscard: false,
    dataLoadedAfterDiscard: false,
    partnerLevelModal: false,
    mountedName: 'mountedPartnerName',
    mountedWebsite: 'mountedPartnerWebsite',
  }),
  mounted() {
    this.partnerNameMandatoryErr = {
      id: 'nameErr',
      field: this.$t('editor.header.banner.partner.name'),
      message: this.$t('mandatory.field.error.message'),
    };
    this.partnerNameFormatErr = {
      id: 'nameFormatErr',
      field: this.$t('editor.header.banner.partner.name'),
      message: this.$t('title.field.error.message'),
    };
    this.websiteMandatoryErr = {
      id: 'webSiteUrlErr',
      field: this.$t('editor.header.banner.partner.website'),
      message: this.$t('mandatory.field.error.message'),
    };
    this.notValidUrlErr = {
      id: 'notValidUrlErr',
      field: this.$t('editor.header.banner.partner.website'),
      message: this.$t('editor.header.banner.partner.website.error.message'),
    };
    if (this.mountedComponent.includes(this.mountedName || this.mountedWebsite)) {
      this.isValidName = this.validateName(this.name);
      this.isValidWebsite = !this.webSiteUrl ? 1 : this.validateUrl();
    }
    this.$eventBus.$on('*', (type) => {
      if (['footerSaveClicked', 'footerPublishClicked', 'editorToggleButtonClicked'].includes(type)) {
        this.isValidName = this.validateName(this.name);
        this.isValidWebsite = !this.webSiteUrl ? 1 : this.validateUrl();
      }
    });
    this.$eventBus.$on('footerDiscardClicked', () => {
      this.clickDiscard = true;
    });
    this.$eventBus.$on('nameErr', () => {
      this.isValidName = false;
    });
    this.$eventBus.$on('webSiteUrlErr', () => {
      this.isValidWebsite = 1;
    });
    this.$eventBus.$on('notValidUrlErr', () => {
      this.isValidWebsite = 2;
    });
  },
  components: {
    PEBrandNameComponent,
  },
  computed: {
    ...mapState('partnerProfile', {
      mountedComponent: (state) => state.mountedComponent,
    }),
    name: {
      get() {
        return this.$store.state.partnerProfile.profileData.name;
      },
      set(val) {
        this.$store.dispatch('partnerProfile/updateName', val.trim());
      },
    },
    webSiteUrl: {
      get() {
        return this.$store.state.partnerProfile.profileData.webSiteUrl;
      },
      set(val) {
        this.$store.dispatch('partnerProfile/updateWebsiteUrl', val);
      },
    },
  },
  methods: {
    withoutProtocol(url) {
      return url.replace(/(^\w+:|^)\/\//, '');
    },
    validateUrl() {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?'
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
        + '((\\d{1,3}\\.){3}\\d{1,3}))'
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~&+@]*)*'
        + '(\\?[;&a-z\\d%_.~+=-]*)?'
        + '(\\#[-a-z\\d_]*)?$',
        'i',
      );
      const isFormatValid = pattern.test(this.webSiteUrl);
      return isFormatValid ? null : 2;
    },
    addValidationError(validationError) {
      this.$store.dispatch('partnerProfile/addErrorField', validationError);
    },
    removeValidationError(errorId) {
      this.$store.dispatch('partnerProfile/removeErrorField', errorId);
    },
    handleNameError(val) {
      const errMessage = this.name ? this.partnerNameFormatErr : this.partnerNameMandatoryErr;
      const removeErrMessage = this.name ? this.partnerNameMandatoryErr : this.partnerNameFormatErr;
      if (val) {
        this.removeValidationError(this.partnerNameFormatErr.id);
        this.removeValidationError(this.partnerNameMandatoryErr.id);
      } else {
        this.addValidationError(errMessage);
        this.removeValidationError(removeErrMessage.id);
      }
    },
    handleWebsiteError(val) {
      if (val === 1) {
        this.addValidationError(this.websiteMandatoryErr);
        this.removeValidationError(this.notValidUrlErr.id);
      } else if (val === 2) {
        this.addValidationError(this.notValidUrlErr);
        this.removeValidationError(this.websiteMandatoryErr.id);
      } else {
        this.removeValidationError(this.websiteMandatoryErr.id);
        this.removeValidationError(this.notValidUrlErr.id);
      }
    },
    validateName(val) {
      const re = /^[A-Za-z0-9]/;
      if (val !== '' && re.test(val)) {
        return true;
      }
      return !val ? false : 0;
    },
  },
  watch: {
    name(val) {
      if (this.mountedComponent.includes(this.mountedName)) {
        this.isValidName = this.validateName(val);
      } else {
        this.$store.dispatch(
          'partnerProfile/addMountedComponent',
          this.mountedName,
        );
      }
    },
    webSiteUrl(val) {
      if (this.mountedComponent.includes(this.mountedWebsite)) {
        if (this.clickDiscard) {
          this.clickDiscard = false;
          this.dataLoadedAfterDiscard = true;
        } else if (this.dataLoadedAfterDiscard) {
          this.isValidWebsite = !val ? 1 : this.validateUrl();
          this.dataLoadedAfterDiscard = false;
        } else {
          if (this.timer) {
            clearTimeout(this.timer);
          }
          this.timer = setTimeout(() => {
            this.isValidWebsite = !val ? 1 : this.validateUrl();
          }, 2000);
        }
        this.wasChangedWebsite = true;
      } else {
        this.$store.dispatch(
          'partnerProfile/addMountedComponent',
          this.mountedWebsite,
        );
      }
    },
    isValidName(val) {
      this.handleNameError(val);
    },
    isValidWebsite(val) {
      this.handleWebsiteError(val);
    },
  },
};
</script>

<template>
  <div class="pf-editor-header-title ds-m-x-none">
    <div class="pf-editor-header-content">
      <div class="pf-editor-header-title-container">
        <div v-bind:class="[isValidName ? 'pf-editable-partner_text-container' :
      'pf-editable-partner_name pf-editable-partner_text-container']">
        <label
          class="ds-textField ds-textField--fixed pf-editable-partner_text-label"
          v-bind:class="[
            isValidName || isTitleFocused
              ? 'ds-m-y-m'
              : 'ds-m-none ds-m-t-s ds-textField--error ds-required-textfield',
          ]"
        >
          <input
            id="partner-name__text"
            class="ds-textField__input"
            type="text"
            @focus="isTitleFocused = true"
            @blur="isTitleFocused = false"
            :placeholder="$t('editor.header.banner.partner.name.placeholder')"
            v-model="name"
            :maxlength="nameMaxCharLength"
          />
          <span class="ds-textField__placeholder" v-if="name || isValidName">
            {{ $t('editor.header.banner.partner.name') }}
          </span>
          <div class="ds-textField__message" v-if="!isValidName && !isTitleFocused">
            <i class="ds-icon--close-outline ds-p-r-xs"></i>
            {{ name ? $t('title.field.error.message') : $t('mandatory.field.error.message')}}
          </div>
        </label>
      </div>
      <div class="pf-editable-partner_website">
        <label
          class="ds-textField ds-textField--fixed"
          v-bind:class="[
            !isValidWebsite || isWebsiteFocused ? '' : 'ds-textField--error ds-required-textfield',
          ]"
        >
          <input
            id="partner-name__website"
            class="ds-textField__input"
            type="text"
            @focus="isWebsiteFocused = true"
            @blur="isWebsiteFocused = false"
            :placeholder="$t('editor.header.banner.partner.website.placeholder')"
            v-model="webSiteUrl"
            :maxlength="webSiteMaxCharLength"
          />
          <span
            class="ds-textField__placeholder"
            v-if="webSiteUrl || !isValidWebsite"
          >
            {{ $t('editor.header.banner.partner.website') }}
          </span>
          <div class="ds-textField__message" v-if="isValidWebsite && !isWebsiteFocused">
            <i class="ds-icon--close-outline ds-p-r-xs"></i>
            {{
              isValidWebsite === 2
                ? $t('editor.header.banner.partner.website.error.message')
                : $t('mandatory.field.error.message')
            }}
          </div>
        </label>
      </div>
      </div>
      <PEBrandNameComponent/>
    </div>
  </div>
</template>

<style lang="less">
.pf-editor-header-title {
  width: 100%;
  padding-right: auto;
  .pf-editor-header-title-container {
    width: 100%;
    min-width: 197px;
  }
  .pf-editor-header-content {
    display: flex;
    width: 90%;
    max-width: 647px;
  }

  .pf-editable-partner_name {
    margin-bottom: 9px;

    .ds-textField__message {
      margin-top: 2px;
      font-size: 12px;
    }
  }
  .ds-textField__input {
    max-width: 400px;
  }

  .pf-editable-partner_website {
    .ds-textField__message {
      font-size: 12px;
    }

    .ds-textField__input {
      min-height: 35px;
      height: 35px;
    }
  }

  .partner-website {
    a {
      width: 118px;
      height: 18px;
      font-size: 12px;
      line-height: 1.5;
    }
  }
  .pf-editable-partner_text-label {
    width: 100%;
    max-width: 400px;
  }
  .pf-editable-partner_text-container {
    width: 100%;
    max-width: 600px;
  }
  .partner-level--container {
    width: 48px;
    height: 28px;
    cursor: pointer;
    display: inline-block;
    margin-top: 27px;
    .partner-level--logo {
      width: 48px;
      height: 28px;
    }
  }
}
</style>
