<script>
import urlLinks from '../../plugins/linkUrl';

export default {
  name: 'PFRiseWithSAPModalComponent',
  data: () => ({
    learnMore: urlLinks.HELP_TEXT_LEARN_MORE.RISE_WITH_SAP,
    learnMoreRiseValidated: urlLinks.HELP_TEXT_LEARN_MORE.RISE_WITH_SAP_VALIDATED_PARTNER,
    maxWidth: 694,
    isOverflownFlag: false,
  }),
  props: {
    closePopup: { type: Function, default: () => {} },
    isRiseValidated: { type: Boolean, default: false },
  },
  methods: {
    isOverflown() {
      this.$nextTick(() => {
        if (
          this.$refs.modalScrollContent.scrollHeight
          > this.$refs.modalScrollContent.clientHeight
        ) {
          this.isOverflownFlag = true;
          this.$refs.riseContainer.style.paddingRight = '32px';
          this.$refs.modalScrollContent.style.marginRight = '8px';
          this.$refs.modalScrollContent.style.marginTop = '20px';
          this.$refs.modalScrollContent.style.marginBottom = '20px';
        } else {
          this.isOverflownFlag = false;
          this.$refs.riseContainer.style.paddingRight = '0px';
          this.$refs.modalScrollContent.style.marginRight = '32px';
        }
      });
    },
  },
  computed: {
    maxWidthStyle() {
      return `${this.maxWidth}px`;
    },
  },
};
</script>

<template>
  <div class="ds-modal ds-modal--active ds-modal--scrollable"
        ref="platinumEditModal" tabindex="0" @keyup.esc="closePopup">
    <div class="modal-container">
      <div class="modal-container-inner">
        <div class="ds-modal__box RwS-logo-modal" :class="{'RwS-validated-logo-modal': isRiseValidated}">
          <div class="ds-modal__box__header " :class="{'border-none': !isRiseValidated}">
            <div class="ds-modal__box__header__heading">
              <div class="ds-modal__box__header__heading-text">
                {{ isRiseValidated ? $t('rise.SAP.validated.title') : $t('rise.SAP.title') }}
              </div>
              <i
                class="ds-icon ds-icon--close modal-close-icon"
                @click="closePopup"
              ></i>
            </div>
          </div>
          <div v-if="isRiseValidated" class="ds-modal__box__content RwS-validated-content">
            <div class="solution-container">
              <div class="solution-section-title">
                <img class="RwS-validated-logo" src="@/assets/Rise_with_SAP_ValidatedPartner_logo.svg"/>
              </div>
              <p class="RwS-validated-p">
                {{$t('rise.SAP.validated.text')}}
                <span @click="openLearnMore" class="help-text-link">
                  <a :href="learnMoreRiseValidated" target="_blank" rel="noopener noreferrer">{{$t('learn.more')}}</a>
                </span>
              </p>
            </div>
          </div>
          <div v-else class="ds-modal__box__content" ref="modalScrollContent">
            <div class="solution-container">
              <div class="rise-container" ref="riseContainer">
                <p class="rise-p">
                  {{$t('rise.text')}}
                  <span @click="openLearnMore" class="help-text-link">
                    <a :href="learnMore" target="_blank" rel="noopener noreferrer">{{$t('learn.more')}}</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="ds-modal__box__action-bar border-none">
            <button
              class="ds-modal__box__action-bar__action ds-button ds-button--primary ds-m-x-none"
              type="button"
              @click="closePopup"
              id="RwS-logo-button__done"
            >
              {{ $t("button.close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>

.ds-modal--active {
    z-index: 200;
}
.border-none {
  border: none !important;
}
.modal-container-inner {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 250px);
}
.RwS-logo-modal {
  border-color: @color-grey-5;
  border-width: '1px';
  max-width: 564px;
  position: relative;
  height: fit-content;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  .ds-modal__box__content {
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px;
  }
  .ds-modal__box__action-bar {
    padding-top: 20px;
  }

  .ds-modal__box__header {
    padding-bottom: 20px;
  }
  .ds-modal__box__header__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .ds-modal__box__header__heading-text {
    color: @color-grey-0;
    &:extend(.font-expanded);
    font-size: 27px;
    line-height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .RwS-validated-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    flex-wrap: wrap;

    .solution-section-title {
      margin-bottom: 20px;
      .RwS-validated-logo {
        height: 54px;
      }
      text-align: center;
    }
    .RwS-validated-p {
      &:extend(.font-normal);
      font-size: 16px;
      line-height: 24px;
      color: #222222;
      margin: 0;
    }
  }
  .ds-modal__box__content::-webkit-scrollbar-track {
  background: @color-slight-gray;
  border-radius: 20px;
  }
  .ds-modal__box__content::-webkit-scrollbar-thumb {
    background-color: @color-gray-4;
    border-radius: 20px;
  }
  .modal-close-icon {
      width: 32px;
      height: 32px;
      color: @color-gray-2;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .modal-close-icon::before {
      font-size: 23px;
    }

    .modal-close-icon:hover {
      color: @color-glance-consultants;
    }
}

.solution-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.rise-container {
  max-width: v-bind('maxWidthStyle');
}

.rise-p {
  &:extend(.font-normal);
  margin-top: 12px !important;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin-bottom: 0px;

}

</style>
