<script>
const SHOW_MAX_SOLUTION = 5;
const SHOW_MAX_INDUSTRY = 4;
const QPPS_CATEGORY = 'QPPS_More_Info';

export default {
  name: 'PFQPPSDetailModalComponent',
  data: () => ({
    maxSolution: SHOW_MAX_SOLUTION,
    maxIndustry: SHOW_MAX_INDUSTRY,
    QPPSCategory: QPPS_CATEGORY,
    showAllSolution: false,
    showAllIndustry: false,
  }),
  props: {
    toggleModal: { type: Function, default: () => {} },
    backButtonAction: { type: Function, default: () => {} },
    QPPSDetail: { type: Object, default: () => {} },
    showBackButton: { type: Boolean, default: false },
  },
  computed: {
    VPUC() {
      return this.QPPSDetail.packageType === 'Validated Partner Use Cases';
    },
    moreSolution() {
      const numSolution = this.getPackages(this.QPPSDetail, 'packageProducts').length;
      return numSolution > this.maxSolution ? numSolution - 4 : 0;
    },
    getSolution() {
      if (this.moreSolution > 0 && !this.showAllSolution) {
        return this.getPackagesNoOrder(this.QPPSDetail, 'packageProducts').slice(0, 4);
      }
      return this.getPackagesNoOrder(this.QPPSDetail, 'packageProducts');
    },
    moreIndustry() {
      const numIndustry = this.getPackages(this.QPPSDetail, 'industries').length;
      return numIndustry > this.maxIndustry ? numIndustry - 3 : 0;
    },
    getIndustry() {
      if (this.moreIndustry > 0 && !this.showAllIndustry) {
        return this.getPackages(this.QPPSDetail, 'industries').filter((industry) => industry !== '-').slice(0, 3);
      }
      return this.getPackages(this.QPPSDetail, 'industries').filter((industry) => industry !== '-');
    },
    getModalFeature() {
      const feature = [];
      if (this.QPPSDetail.conversionPackage) {
        feature.push('Conversion to SAP S/4HANA Cloud');
      }
      if (this.QPPSDetail.includesBtpIndustryCloud) {
        feature.push('Industry Cloud');
      }
      if (this.QPPSDetail.intelligentEnterprise) {
        feature.push('Intelligent Enterprise');
      }
      if (this.QPPSDetail.includesSustainability) {
        feature.push('Sustainability Solutions');
      }
      return feature;
    },
  },
  methods: {
    getPackages(countryQPPS, key) {
      return countryQPPS[key]
        .filter((word) => word.length > 0)
        .sort((a, b) => a.localeCompare(b));
    },
    getPackagesNoOrder(countryQPPS, key) {
      return countryQPPS[key]
        .filter((word) => word.length > 0);
    },
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
    isOverflown() {
      this.$nextTick(() => {
        if (
          this.$refs.modalProfileQPPSDetailContent.scrollHeight
          > this.$refs.modalProfileQPPSDetailContent.clientHeight
        ) {
          this.$refs.modalProfileQPPSDetailHeader.style.borderBottom = '1px solid #eaeaea';
          this.$refs.modalProfileQPPSDetailActionBar.style.borderTop = '1px solid #eaeaea';
        } else {
          this.$refs.modalProfileQPPSDetailHeader.style.borderBottom = 'none';
          this.$refs.modalProfileQPPSDetailActionBar.style.borderTop = 'none';
        }
      });
    },
    emitSWAEvent(QPPSCategory, QPPSName) {
      if (!this.editMode) {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$eventBus.$emit('PFCustomEvent',
          {
            eventName: 'Click_QPPS', custom2: QPPSCategory, custom3: QPPSName,
          });
      }
    },
  },
  mounted() {
    this.isOverflown();
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
    window.setTimeout(() => this.$refs.pfQPPSmodal.focus(), 0);
  },
  beforeUnmount() {
    document.body.style.paddingRight = '0px';
    document.body.style.overflow = 'scroll';
  },
};
</script>

<template>
  <div class="profile-QPPS-detail-modal-container">
    <div class="ds-modal ds-modal--active ds-modal--scrollable"
         ref="pfQPPSmodal" tabindex="0" @keyup.esc="toggleModal(false)">
      <div class="modal-container">
      <div class="modal-container-inner-upload" id="profile-QPPS-detail-modal">
      <div class="ds-modal__box profile-QPPS-modal">
        <div class="ds-modal__box__header ds-p-b-m" ref="modalProfileQPPSDetailHeader">
          <div class="ds-modal__box__header__heading QPPS-modal-header" v-html="$sanitize(QPPSDetail.packageName)"></div>
            <i
              class="ds-icon ds-icon--close modal-close-icon modal-icon ds-icon-profile-close"
              @click="toggleModal(false)"
            ></i>
        </div>
        <div class="ds-modal__box__content ds-m-none ds-m-l-l ds-m-r-s ds-m-t-m ds-m-b-m"
        ref="modalProfileQPPSDetailContent">
          <div class="profile-QPPS-text ds-m-b-m">
            <div class="QPPS-img-container certer-text ds-m-t-s">
              <img v-if="VPUC" class="VPUC-img"
                src="../../assets/SAP_Validated_Partner_Use_Case_R.png" alt="">
              <img v-else alt=""
                class="QPPS-img" src="../../assets/SAP_Qualified_PartnerPackageSolution_R.png"/>
            </div>
            <div v-html="$sanitize(QPPSDetail.teaserText)"></div>
          </div>

          <div class="profile-QPPS-text profile-QPPS-table">
            <table class="ds-table ds-m-none">
              <tbody>
                <tr v-if="getSolution.length > 0">
                  <td class="col-name">{{$t('QPPS.modal.package')}}</td>
                  <td class="col-data">
                    <div class="profile-specialization-text qpps-solution-text-row" v-for="(product, idx) in getSolution"
                      :key="product + '-' + idx">
                       &nbsp; &#8226;&nbsp;
                        {{product}}
                    </div>
                    <div v-if="moreSolution > 0 && !showAllSolution" class="QPPS-show-more" @click="showAllSolution = true">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{moreSolution + ' ' + $tc('QPPS.modal.more.solution', moreSolution)}}
                    </div>
                  </td>
                </tr>
                <tr v-if="getIndustry.length > 0">
                  <td class="col-name">{{$t('QPPS.modal.industry')}}</td>
                  <td class="col-data">
                     <div class="profile-specialization-text qpps-industry-text-row" v-for="(product, idx) in getIndustry"
                      :key="product + '-' + idx">
                      &nbsp; &#8226;&nbsp;
                        {{product}}
                    </div>
                    <div v-if="moreIndustry > 0 && !showAllIndustry" class="QPPS-show-more" @click="showAllIndustry = true">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{moreIndustry + ' ' + $tc('QPPS.modal.more.industry', moreIndustry)}}
                    </div>
                  </td>
                </tr>
                <tr v-if="getModalFeature.length > 0">
                  <td class="col-name">{{$t('QPPS.modal.feature')}}</td>
                  <td class="col-data">
                    <div class="profile-specialization-text qpps-feature-text-row" v-for="(product, idx) in getModalFeature"
                      :key="product + '-' + idx">
                       &nbsp; &#8226;&nbsp;
                        {{product}}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="ds-modal__box__action-bar" ref="modalProfileQPPSDetailActionBar">
          <button
            v-if="showBackButton"
            class="ds-modal__box__action-bar__action ds-button ds-button--secondary ds-m-l-m"
            type="button"
            @click="backButtonAction()"
            id="profile-QPPS-detail-button__back"
          >
            {{ $t("button.back") }}
          </button>
          <a v-if="(QPPSDetail.assetsLink?.trim().startsWith('https://') || QPPSDetail.assetsLink?.trim().startsWith('http://')) && $showVPUCFeature" class="learn-more-link"
              id="profile-QPPS-detail-learn-more-link"
              @click.prevent="openInNewTab(QPPSDetail.assetsLink);emitSWAEvent(QPPSCategory,QPPSDetail.packageName)">
              {{ $t("QPPS.modal.more.info") }}
            </a>
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary ds-m-l-m"
            type="button"
            @click="toggleModal(false)"
            id="profile-QPPS-detail-button__cancel"
          >
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
#profile-QPPS-detail-button__back {
  margin-right: auto;
  margin-left: 0 !important;
}
.competency-flexRow {
  display: flex;
}
.learn-more-link {
  &:extend(.font-normal);
  cursor: pointer;
}
.QPPS-show-more {
  width: fit-content;
  &:extend(.font-expanded);
  cursor: pointer;
  color: @color-link;
}
.QPPS-show-more:hover {
  color: @color-link__hover;
}

.profile-project-text {
  color: @color-grey-0;
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
}
.VPUC-img {
  max-width: 177px;
}
.QPPS-img {
  width: 194.62px;
}
.certer-text {
  text-align: center;
}
.QPPS-modal-header {
  text-align: left;
  color: @color-grey-0;
}
.col-name {
  &:extend(.font-normal);
  color: @color-grey-0;
}
.profile-QPPS-count-text {
  &:extend(.font-expanded);
  font-size: 21px;
  line-height: 27px;
  color: @color-grey-0;
}
.flex-content-container {
  display: flex;
}
.profile-name-column {
  flex-grow: 5;
}
.profile-addr-column {
  margin-left: 4px;
  flex-grow: 4;
}
.profile-notification-button {
  margin-left: 52px;
  color: @color-link;
  &:extend(.font-expanded);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  .ds-icon::before {
    color: @color-grey-0;
    line-height: 80px;
    font-size: 24px;
  }
  .profile-notification-button-text {
    line-height: 80px;
    font-size: 16px;
  }
}
.profile-notification-more-text:hover,.profile-notification-button:hover {
  color: @color-link__hover;
}
.profile-notification-more-text {
  &:extend(.font-normal);
  color: @color-link;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  white-space: pre-wrap;
}
.profile-QPPS-text {
  text-align: left;
  &:extend(.font-normal);
  color: @color-grey-0 !important;
  font-size: 16px;
  line-height: 24px;
}

.profile-specialization-text, .QPPS-show-more {
  &:extend(.font-expanded);
  text-indent: -23px;
  font-size: 16px;
  line-height: 24px;
}

.profile-specialization-text {
  color: @color-grey-0;
}

.modal-icon {
  line-height: 200%;
  color: @color-gray-2;
}
.modal-icon:hover {
  color: @color-gray-1;
  cursor: pointer;
}
.ds-modal--active {
  z-index: 200;
}
.modal-container-inner-upload {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 341px);
}
.profile-QPPS-modal {
  max-width: 669px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  max-height: 760px;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}
.ds-modal__box__header__heading {
  padding-right: 40px !important;
  &:extend(.font-expanded);
  font-size: 27px;
  line-height: 35px;
}
.ds-icon-profile-close::before {
    font-size: 19px;
    font-weight: 800;
}
.ds-modal__box__content {
  padding-right: 40px !important;
  margin-right: 10px;
}
.ds-modal__box__content::-webkit-scrollbar-track {
  background: @color-slight-gray;
  border-radius: 20px;
}
.ds-modal__box__content::-webkit-scrollbar-thumb {
  background-color: @color-gray-4;
  border-radius: 20px;
}
.ds-table {
  width: 100%;
  tr:not(:last-child) td {
    border-bottom: 1px solid @color-grey-5;
  }
}
.QPPS-img-container {
  height: fit-content;
  margin-bottom: 28px;
}
</style>
