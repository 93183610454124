<script>
const MIN_AUTOCOMPLETE_LENGTH = 1;

export default {
  name: 'PFDropdownSingleComponent',
  props: {
    allOptions: {
      type: Array,
      default: () => [],
    },
    placeholderText: { type: String, default: '' },
    idKey: { type: String, default: '' },
    valueKey: { type: String, default: '' },
    updateSelectedOptionValue: {
      type: Function,
      default: () => {},
    },
    preselectedOption: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    HQCountry: { type: Boolean, default: false },
    updateHQContrySelection: {
      type: Function,
      default: () => {},
    },
    selectedHQCountry: { type: String, default: '' },
    errorStatusParent: { type: Boolean, default: false },
    updateErrorStatusParent: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    selectedOption: '',
    filterText: '',
    minAutocompleteLength: MIN_AUTOCOMPLETE_LENGTH,
    availableOptions: [],
    dropDownshow: false,
    errorStatus: false,
    openedDropdown: false,
  }),
  watch: {
    allOptions: {
      handler(val) {
        this.availableOptions = this.allOptions;
      },
      deep: true,
    },
    selectedHQCountry(val) {
      this.selectedOption = val;
    },
    selectedOption(val) {
      if (val) {
        this.errorStatus = false;
      }
    },
    errorStatusParent(val) {
      this.openedDropdown = true;
      this.errorStatus = val;
    },
    errorStatus(val) {
      this.updateErrorStatusParent(val);
    },
  },
  mounted() {
    this.availableOptions = this.allOptions;
    this.selectedOption = this.preselectedOption;
    this.errorStatus = this.errorStatusParent;
  },
  methods: {
    updateSelectedOptionObject(val) {
      if (this.HQCountry) {
        this.updateHQContrySelection(val);
      }
      this.updateSelectedOption(val[this.valueKey]);
    },
    updateSelectedOption(val) {
      this.selectedOption = val;
      this.updateSelectedOptionValue(val);
      this.closeDropdown();
    },
    filterOptions() {
      if (this.filterText.length >= this.minAutocompleteLength) {
        this.availableOptions = this.allOptions.filter(
          (option) => option[this.valueKey]
            .toLowerCase()
            .includes(this.filterText.toLowerCase()),
          this,
        );
      } else {
        this.availableOptions = this.allOptions;
      }
    },
    openDropdown() {
      if (!this.disabled) {
        this.dropDownshow = true;
        this.openedDropdown = true;
        this.$nextTick(function () {
          this.$refs.searchBar.focus();
        });
      }
    },
    closeDropdown() {
      this.dropDownshow = false;
      this.$refs.coutryDropdown.blur();
      this.$refs.searchBar.blur();
      if (this.openedDropdown && !this.selectedOption) {
        this.errorStatus = true;
      } else {
        this.errorStatus = false;
      }
      this.reset();
    },
    reset() {
      this.filterText = '';
      this.availableOptions = this.allOptions;
    },
  },
};
</script>

<template>
  <div>
    <div
      class="ds-combo-box ds-combo-box--single"
      :class="[errorStatus ? 'ds-combo-box--error' : '',
      disabled ? 'ds-combo-box--disabled' : '',
      HQCountry ? 'ds-m-t-s' : 'ds-m-t-m']"
      ref="coutryDropdown"
      tabindex="0"
      @focus="openDropdown"
      v-click-outside="closeDropdown"
    >
      <div
        class="ds-combo-box__placeholder"
        v-show="dropDownshow || selectedOption"
        :class="disabled ? 'ds-combo-box__placeholder_postion--disabled'
        : 'ds-combo-box__placeholder_postion--enabled'"
      >
        <span
          class="ds-combo-box__placeholder__text ds-p-none"
          :class="dropDownshow ? 'color-blue' : 'country-placeholder'"
          >{{ $t('contact.me.country') }}</span
        >
      </div>
      <div class="ds-combo-box__selected ds-combo-box__selected--active">
        <div
          class="ds-combo-box__selected__option country-placeholder"
          v-if="!selectedOption && !dropDownshow"
        >
          {{ $t('contact.me.country') }}
        </div>
        <div class="ds-combo-box__selected__option selected-country" v-else>
          {{ selectedOption }}
        </div>
      </div>
      <i
        v-if="!dropDownshow"
        class="ds-icon--big ds-icon--arrow-down ds-combo-box__icon ds-combo-box__icon--down"
      ></i>
      <i
        v-else
        class="ds-icon--big ds-icon--arrow-up ds-combo-box__icon ds-combo-box__icon--arrow-up"
      ></i>
      <div class="ds-dropdown ds-combo-box__dropdown" v-show="dropDownshow">
        <div class="ds-dropdown__search-bar ds-combo-box__dropdown__search-bar">
          <label class="ds-textField ds-dropdown__search-bar__text-field">
            <input
              class="ds-textField__input ds-dropdown__search-bar__text-field__input"
              type="text"
              ref="searchBar"
              v-model="filterText"
              @keyup="filterOptions"
            /> </label
          ><i
            class="ds-icon--big ds-icon--search ds-dropdown__search-bar__icon"
          ></i>
        </div>
        <div
          class="ds-dropdown__inner ds-dropdown__inner--scrollable ds-p-r-xxs"
        >
          <div
            class="ds-dropdown__inner__option"
            @click="updateSelectedOptionObject(item)"
            v-for="item in availableOptions"
            :key="item[idKey]"
            :id="item[idKey]"
          >
            {{ item[valueKey] }}
          </div>
        </div>
      </div>
    </div>
    <div class="ds-textField__message" v-show="errorStatus && !dropDownshow">
      <i class="ds-icon--close-outline ds-m-r-xs"></i>
      {{ $t('mandatory.field.error.message') }}
    </div>
  </div>
</template>

<style lang="less" scoped>
.ds-combo-box--single {
  max-width: 274px;
  min-width: 0px !important;
}

.ds-combo-box__placeholder {
  &:extend(.font-normal);
  display: block;
  transform-origin: left;
  position: absolute;
  top: 1.3125rem;
  left: 0.25rem;
  margin: 0;
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  font-size: 1rem;
  background-color: @white-color;
  .ds-combo-box__placeholder__text {
    &:extend(.font-normal);
    color: @color-gray-1;
  }
}
.ds-combo-box__placeholder_postion--disabled {
  transform: translateY(-0.5rem) translateY(-110%) scale(0.8125) !important;
}
.ds-combo-box__placeholder_postion--enabled {
  transform: translateY(-0.5rem) translateY(-120%) scale(0.8125) !important;
}
.ds-combo-box--single:focus {
  .ds-combo-box__placeholder__text {
    color: @color-link;
  }
}

.color-blue {
  color: @color-link !important;
}
.ds-combo-box__dropdown {
  min-width: 0 !important;
}

.country-placeholder {
  font-size: 16px;
  &:extend(.font-normal);
  color: @color-gray-2 !important;
}
.ds-combo-box--single {
  .selected-country {
    font-size: 16px;
    &:extend(.font-normal);
    color: @color-black;
  }
}
.ds-textField__message {
  color: @color-field-error;
}
.ds-combo-box--disabled {
  .ds-combo-box__placeholder {
    background: linear-gradient(#ffffff 50%, #eaeaea 50%);
    color: @color-gray-3;
  }
  .ds-combo-box__placeholder__text {
    color: @color-gray-3;
  }
  .selected-country {
    color: @color-gray-4;
  }
}
</style>
