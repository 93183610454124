<script>
import { mapState } from 'vuex';

const MAX_TEXTAREA_INPUT_LENGTH = 1500;
const MAX_CHAR_DEFAULT_SHOW = 1000;
export default {
  name: 'PFServiceValuePropComponent',
  props: {
    editMode: { type: Boolean },
  },
  data: () => ({
    isValidProp: true,
    isFocusedProp: false,
    maxChar: MAX_TEXTAREA_INPUT_LENGTH,
    initialDisplayCount: MAX_CHAR_DEFAULT_SHOW,
    showAllToggle: false,
    errorId: 'serviceSummaryErr',
  }),
  methods: {
    handleServicePropError(val) {
      if (val) {
        this.$store.dispatch('partnerProfile/removeErrorField', this.errorId);
      } else {
        this.$store.dispatch('partnerProfile/addErrorField',
          {
            id: this.errorId,
            field: this.$t('field.name.services.description'),
            message: this.$t('mandatory.field.error.message'),
          });
      }
    },
  },
  mounted() {
    if (this.mountedComponent.includes(this.$options.name)) {
      this.isValidProp = this.servicePropSummary && this.servicePropSummary !== '';
    }
    this.$eventBus.$on('*', (type) => {
      if (['footerSaveClicked', 'footerPublishClicked', 'editorToggleButtonClicked'].includes(type)) {
        this.isValidProp = this.servicePropSummary && this.servicePropSummary !== '';
      }
    });
    this.$eventBus.$on('serviceSummaryErr', () => {
      this.isValidProp = false;
    });
  },
  computed: {
    ...mapState('partnerProfile', { mountedComponent: (state) => state.mountedComponent }),
    servicePropSummary: {
      get() {
        return this.$store.state.partnerProfile.profileData.serviceSummary;
      },
      set(newProposition) {
        this.$store.dispatch('partnerProfile/updateServiceValuePropositionSummary', newProposition);
      },
    },
    charLeft() {
      if (this.servicePropSummary) {
        return this.maxChar - this.servicePropSummary.length;
      }
      return this.maxChar;
    },
    getText() {
      if (!this.showAllToggle && this.servicePropSummary && this.servicePropSummary.length > 0) {
        const text = this.servicePropSummary.substring(0, this.initialDisplayCount);
        return this.$sanitize(text);
      }
      if (this.servicePropSummary) {
        return this.$sanitize(this.servicePropSummary);
      }
      return '';
    },
    showMoreText() {
      return (this.servicePropSummary && this.servicePropSummary.length > this.initialDisplayCount);
    },
  },
  watch: {
    servicePropSummary(val) {
      if (this.mountedComponent.includes(this.$options.name)) {
        this.isValidProp = val && val !== '';
      } else {
        this.$store.dispatch('partnerProfile/addMountedComponent', this.$options.name);
      }
    },
    isValidProp(val) {
      this.handleServicePropError(val);
    },
  },
};
</script>

<template>
  <div class="service-prop-container default-cursor">
    <div v-show="editMode">
      <div class="ds-textarea service-prop-textarea ds-m-t-l">
        <label
          class="ds-textField ds-required-textfield ds-m-none"
          :class="isValidProp || isFocusedProp ? '' : 'ds-textField--error'">
          <textarea
            class="ds-textField__input ds-textField__input--textarea service-textarea"
            type="text"
            :spellcheck="true"
            :placeholder="$t('helptext.service.valueProp.edit.placeholder')"
            :maxlength="maxChar"
            @focus="isFocusedProp = true"
            @blur="isFocusedProp = false"
            v-model="servicePropSummary"/>
          <div class="ds-textField__message" v-if="!isValidProp && !isFocusedProp">
            <i class="ds-icon--close-outline ds-p-r-xs"></i>
            {{ $t("mandatory.field.error.message") }}
          </div>
        </label>
        <div class="char-left ds-m-t-xxs">
          {{ charLeft }} {{ $tc("editor.global.characters.left", charLeft) }}
        </div>
      </div>
    </div>
    <div v-show="!editMode" class="ds-m-t-l service-prop-summary">
      <span v-html="getText"></span>
      <span class="fade-overlay" v-show="showMoreText && !showAllToggle"></span>
      &nbsp;
      <span v-show="showMoreText">
        <a
          class="show-more-link"
          href=""
          @click.prevent="showAllToggle = !showAllToggle">
          {{ showAllToggle ? $t('show.less.text') : $t('title.brand.names.more') }}
        </a>
      </span>
    </div>
  </div>
</template>

<style scoped lang="less">
@import "../../assets/css/common.less";

.service-prop-summary {
  white-space: pre-wrap;
  &:extend(.font-normal);
  width: 100%;
  font-size: 16px;
  line-height: 1.5;
  color: @color-black;

  span {
    &:extend(.font-normal);
  }
}

.service-prop-textarea {
  & > label textarea.ds-textField__input--textarea {
    min-height: 168px;
  }
  .ds-textField__message {
    &:extend(.font-normal);
    font-size: 14px;
    .ds-icon--close-outline {
      line-height: 17px;
    }
  }
}

.char-left {
  &:extend(.font-normal);
  height: 18px;
  font-size: 12px;
  line-height: 1.5;
  text-align: right;
  color: @color-glance-consultants;
}

.service-textarea {
  resize: vertical;
}

.fade-overlay::before {
  content: "";
  background-image: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff);
  width: 2rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
</style>
