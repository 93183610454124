<script>

export default {
  name: 'PEValidationComponent',
  props: {
    isShown: {
      type: Boolean,
      default: false,
    },
    errorFields: {
      type: Array,
    },
    errorMessage: {
      type: String,
      default: '',
    },
  },
};
</script>

<template>
  <div v-if="isShown" class="validation-container" :class="errorFields.length > 1 ? 'left-shift-plural' : ''">
    <div class="validation-container-header">
      {{ errorMessage }}
    </div>
    <div class="validation-list">
      <div class="validation-item" v-for="errorField in this.errorFields" :key="errorField.id">
        <div class="icon-container">
          <i class="ds-icon ds-icon--close-outline ds-message__content__icon"/>
        </div>
        <div class="error-container">
          <div class="error-field-text">{{ errorField.field }}</div>
          <div class="error-message-text">{{ errorField.message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.validation-container {
  -webkit-box-shadow: 0 0 .9375rem 0 rgba(0, 0, 0, .2);
  box-shadow: 0 0 .9375rem 0 rgba(0, 0, 0, .2);
  background-color: #fff;
  width: 319px;
  max-height: 285px;
  max-width: 319px;
  white-space: nowrap;
  position: absolute;
  bottom: 100%;
  left: -28%;
  padding: 0 8px 20px 0;

  &.left-shift-plural {
    left: -22%;
  }

  .validation-container-header {
    &:extend(.font-expanded);
    padding: 20px 30px 20px 20px;
    font-size: 21px;
    line-height: 27px;
    letter-spacing: normal;
    color: #000;;
  }

  .validation-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 198px;
  }

  .validation-list::-webkit-scrollbar {
    background-color: @color-gray-4;
    width: 9px;
  }

  .validation-list::-webkit-scrollbar-thumb {
    background-color: #727272;
  }
}

.validation-item {
  padding: 0 8px 15px 20px;
  display: flex;

  .icon-container {
    color: @color-field-error;

    i {
      margin-right: 8px;
      line-height: 20px;
    }
  }

  .ds-icon::before {
    font-size: 18px;
  }

  .error-field-text {
    &:extend(.font-expanded);
    width: 240px;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: normal;
    color: @color-field-error;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
  }

  .error-message-text {
    &:extend(.font-normal);
    width: 240px;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: normal;
    color: @color-field-error;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
