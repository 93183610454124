import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import orderingUtil from '../../plugins/profile/ordering';

function findAddressInLocalProfile(hqAddress, localProfile) {
  return localProfile.addresses
    .find((address) => address.fullAddress === hqAddress.fullAddress
      && address.locationName === hqAddress.locationName
      && address.suite === hqAddress.suite);
}

function findLocalProfileByCountryCode(countryCode, localProfiles) {
  return localProfiles
    .find((profile) => profile.country === countryCode);
}

function findTargetLocalAddress(hqAddress, localProfiles) {
  const targetLocalProfile = findLocalProfileByCountryCode(hqAddress.countryCode, localProfiles);
  let targetAddress = null;
  if (targetLocalProfile) {
    targetAddress = findAddressInLocalProfile(hqAddress, targetLocalProfile);
  }
  return targetAddress;
}

function findTargetLocalAddressIndex(hqAddress, localProfiles) {
  const targetLocalProfile = findLocalProfileByCountryCode(hqAddress.countryCode, localProfiles);
  if (targetLocalProfile) {
    const targetAddress = findAddressInLocalProfile(hqAddress, targetLocalProfile);
    return targetLocalProfile.addresses.indexOf(targetAddress);
  }
  return -1;
}

function prepareLocalAddressToHq(hq, localAddress) {
  const oldHqId = hq ? hq.id : null;
  const newHq = { ...localAddress };
  delete newHq.id;
  delete newHq.mainLocation;
  delete newHq.dropdownName;
  newHq.id = oldHqId;
  return newHq;
}

const LocationAddressAutocompleteStore = {
  namespaced: true,
  state: {
    query: '',
    searchResults: [],
    lastUsedQuery: '',
    sessionToken: null,
    placeDetailsResult: null,
    loading: false,
  },
  /*
     * Actions can be used to make async http calls ot fetch data, manipulate/process data and call
     * mutation functions to affect the state.
     */
  actions: {
    async getAddressAutocompleteResults(context, country) {
      const q = context.state.query;
      if (q !== '' && q.length >= 3) {
        context.commit('setLastUsedQuery', q);
        if (!context.state.sessionToken) {
          const token = uuidv4();
          context.commit('setSessionToken', token);
        }
        let query = `/sap/search/api/search/place/autocomplete?input=${q}`;
        query = query.concat(country ? `&componentRestrictions=${country}` : '');
        query = query.concat(context.state.sessionToken ? `&uuid=${context.state.sessionToken}` : '');

        await axios.get(query)
          .then((results) => context.dispatch('cleanResults', results))
          .catch((err) => {
            throw new Error(`Response error: ${err}`);
          });
      } else {
        await context.dispatch('clearResults', context);
      }
    },
    async findOfficeAddressPlaceDetails(context, placeId) {
      let endPoint = `/sap/search/api/search/place/details?placeId=${placeId}`;
      endPoint = endPoint.concat(context.state.sessionToken ? `&uuid=${context.state.sessionToken}` : '');
      context.commit('setLoading', true);
      await axios.get(endPoint)
        .then((result) => {
          const countryCode = result.data.countryCode;
          const responseCountryName = result.data.country;
          const allCountries = context.rootState.fullData.allowedCountries;
          const mappingCountry = allCountries.find((item) => item.country === countryCode);
          if (mappingCountry && mappingCountry.text !== responseCountryName) {
            result.data.country = mappingCountry.text;
          }
          context.commit('updatePlaceDetailsResult', result.data);
        })
        .catch((err) => { throw new Error(`Response error: ${err}`); });
      context.commit('setLoading', false);
      context.commit('setSessionToken', null);
    },

    cleanResults(context, results) {
      results === {} ? context.commit('setResults', []) : context.commit('setResults', results.data);
    },

    keyPressed(context, { value, country }) {
      if (value === context.state.lastUsedQuery && context.state.lastUsedQuery
        && context.state.lastUsedQuery.length > 3) {
        return;
      }
      context.commit('newValue', value);
      context.dispatch('getAddressAutocompleteResults', country);
    },

    clearResults(context) {
      context.commit('setResults', []);
      context.commit('setLastUsedQuery', '');
      context.commit('updatePlaceDetailsResult', null);
    },

    clearQuery(context) {
      context.commit('newValue', '');
    },

    setQueryValue(context, value) {
      context.commit('newValue', value);
    },
  },

  mutations: {
    newValue(context, value) {
      context.query = value;
    },
    setLoading(context, value) {
      context.loading = value;
    },
    setResults(context, results) {
      context.searchResults = results;
    },
    setLastUsedQuery(context, value) {
      context.lastUsedQuery = value;
    },
    updatePlaceDetailsResult(context, value) {
      context.placeDetailsResult = value;
    },
    setSessionToken(context, value) {
      context.sessionToken = value;
    },
    setOriginalHqData() {
      this.commit('partnerProfile/setOriginalData', { profileData: this.state.partnerProfile.profileData.hqAddress, key: 'hqAddress' }, { root: true });
    },
    compareHqDataChange() {
      this.commit('partnerProfile/compareObjectByKey', { key: 'hqAddress', keyProperty: ['locationName', 'fullAddress', 'email', 'phone', 'suite', 'countryCode'] }, { root: true });
      this.state.partnerProfile.profileChangedData.set('hqAddress', this.state.partnerProfile.profileData.hqAddress);
    },
    setOriginalLocalProfileData(context, country) {
      const targetLocalProfile = this.state.partnerProfile.profileData.localProfiles
        .find((profile) => profile.country === country);
      this.commit('partnerProfile/setOriginalData', {
        profileData: targetLocalProfile.addresses,
        key: `${country}addresses`,
      }, { root: true });
    },
    compareLocalAddressChange(context, country) {
      const targetLocalProfile = this.state.partnerProfile.profileData.localProfiles
        .find((profile) => profile.country === country);
      this.commit('partnerProfile/compareArrayOfObjectByKey', {
        profileData: targetLocalProfile.addresses,
        key: `${country}addresses`,
        keyProperty: ['locationName', 'fullAddress', 'email', 'phone', 'suite', 'mainLocation'],
      }, { root: true });
      this.state.partnerProfile.profileChangedData.set('localProfiles', this.state.partnerProfile.profileData.localProfiles);
      this.state.partnerProfile.changedCountryData.add(country);
    },
    removeHqByFullAddress() {
      const targetAddress = findTargetLocalAddress(this.state.partnerProfile.profileData.hqAddress,
        this.state.partnerProfile.profileData.localProfiles);
      this.commit('locationAddressAutoComplete/setOriginalHqData');
      if (targetAddress) {
        this.commit('locationAddressAutoComplete/setOriginalLocalProfileData', targetAddress.countryCode);
        targetAddress.mainLocation = false;
        this.commit('locationAddressAutoComplete/compareLocalAddressChange', targetAddress.countryCode);
      }
      this.state.partnerProfile.profileData.hqAddress = {};
      this.commit('locationAddressAutoComplete/compareHqDataChange');
    },
    deleteLocalOfficeByHq() {
      const index = findTargetLocalAddressIndex(this.state.partnerProfile.profileData.hqAddress,
        this.state.partnerProfile.profileData.localProfiles);
      if (index >= 0) {
        this.commit('locationAddressAutoComplete/deleteOfficeAddress', {
          placeIdx: index,
          country: this.state.partnerProfile.profileData.hqAddress.countryCode,
        });
      } else {
        this.commit('locationAddressAutoComplete/removeHqByFullAddress');
      }
    },
    setHq(context, location) {
      this.commit('locationAddressAutoComplete/setOriginalHqData');
      this.commit('locationAddressAutoComplete/setOriginalLocalProfileData', location.countryCode);
      const targetAddress = findTargetLocalAddress(location,
        this.state.partnerProfile.profileData.localProfiles);
      targetAddress.mainLocation = true;
      this.commit('locationAddressAutoComplete/removeOldHqTag', location);
      this.state.partnerProfile.profileData.hqAddress = prepareLocalAddressToHq(
        this.state.partnerProfile.profileData.hqAddress,
        location,
      );
      this.commit('locationAddressAutoComplete/compareLocalAddressChange', location.countryCode);
      this.commit('locationAddressAutoComplete/compareHqDataChange');
    },
    removeOldHqTag(context, newHq) {
      const oldHq = findTargetLocalAddress(this.state.partnerProfile.profileData.hqAddress,
        this.state.partnerProfile.profileData.localProfiles);
      if (oldHq && (oldHq.fullAddress !== newHq.fullAddress
        || oldHq.locationName !== newHq.locationName
        || oldHq.suite !== newHq.suite)) {
        this.commit('locationAddressAutoComplete/setOriginalLocalProfileData', oldHq.countryCode);
        oldHq.mainLocation = false;
        this.commit('locationAddressAutoComplete/compareLocalAddressChange', oldHq.countryCode);
      }
    },
    reorderOfficeAddress(context, { sourceIdx, targetIdx, country }) {
      const targetLocalProfile = this.state.partnerProfile.profileData.localProfiles
        .find((profile) => profile.country === country);
      this.commit('locationAddressAutoComplete/setOriginalLocalProfileData', targetLocalProfile.country);
      targetLocalProfile.addresses = orderingUtil
        .reorderArrayItemsByIdx(targetLocalProfile.addresses, sourceIdx, targetIdx);
      this.commit('locationAddressAutoComplete/compareLocalAddressChange', targetLocalProfile.country);
    },
    deleteOfficeAddress(context, { placeIdx, country }) {
      const targetLocalProfile = findLocalProfileByCountryCode(country,
        this.state.partnerProfile.profileData.localProfiles);
      this.commit('locationAddressAutoComplete/setOriginalLocalProfileData', targetLocalProfile.country);
      if (targetLocalProfile.addresses[placeIdx].mainLocation) {
        this.commit('locationAddressAutoComplete/setOriginalHqData');
        this.state.partnerProfile.profileData.hqAddress = {};
        this.commit('locationAddressAutoComplete/compareHqDataChange');
      }
      targetLocalProfile.addresses.splice(placeIdx, 1);
      this.commit('locationAddressAutoComplete/compareLocalAddressChange', targetLocalProfile.country);
    },
    updateOfficeAddress(context, {
      placeIdx, newValue, updateGoogleAutoValue, country,
    }) {
      const targetLocalProfile = this.state.partnerProfile.profileData.localProfiles
        .find((profile) => profile.country === country);

      if (targetLocalProfile) {
        if (targetLocalProfile.addresses == null) {
          targetLocalProfile.addresses = [];
        }
        this.commit('locationAddressAutoComplete/setOriginalLocalProfileData', targetLocalProfile.country);
      }
      let newAddress = { ...newValue };
      if (updateGoogleAutoValue) {
        const newGoogleAddress = {
          fullAddress: context.placeDetailsResult.fullAddress,
          city: context.placeDetailsResult.city,
          regionText: context.placeDetailsResult.region,
          countryCodeText: context.placeDetailsResult.country,
          countryCode: context.placeDetailsResult.countryCode,
          alternativeCountryName: context.placeDetailsResult.alternativeCountryName,
          latitude: context.placeDetailsResult.latitude,
          longitude: context.placeDetailsResult.longitude,
          addressLines: context.placeDetailsResult.addressLines,
          addressName: context.placeDetailsResult.addressName,
        };
        newAddress = { ...newGoogleAddress, ...newValue };
      } else if (placeIdx === null && !targetLocalProfile) {
        const hqAddressData = this.state.partnerProfile.profileData.hqAddress;
        const newGoogleAddress = {
          fullAddress: hqAddressData.fullAddress,
          city: hqAddressData.city,
          regionText: hqAddressData.regionText,
          countryCodeText: hqAddressData.countryCodeText,
          countryCode: hqAddressData.countryCode,
          alternativeCountryName: hqAddressData.alternativeCountryName,
          latitude: hqAddressData.latitude,
          longitude: hqAddressData.longitude,
          addressLines: hqAddressData.addressLines,
          addressName: hqAddressData.addressName,
        };
        newAddress = { ...newGoogleAddress, ...newValue };
      }
      let targetAddress = newAddress;
      if (placeIdx !== null) {
        targetAddress = targetLocalProfile.addresses[placeIdx];
        Object.entries(newAddress).forEach(([key, value]) => {
          targetAddress[key] = value;
        });
      } else if (targetLocalProfile) {
        targetLocalProfile.addresses.push(newAddress);
      }
      if (newAddress.mainLocation) {
        this.commit('locationAddressAutoComplete/setOriginalHqData');
        this.commit('locationAddressAutoComplete/removeOldHqTag', targetAddress);
        this.state.partnerProfile.profileData.hqAddress = prepareLocalAddressToHq(
          this.state.partnerProfile.profileData.hqAddress,
          targetAddress,
        );
        this.commit('locationAddressAutoComplete/compareHqDataChange');
      }
      if (targetLocalProfile) {
        this.commit('locationAddressAutoComplete/compareLocalAddressChange', targetLocalProfile.country);
      }
    },
  },
};

export default LocationAddressAutocompleteStore;
