<script>

export default {
  name: 'PFHeadquartersAddressComponent',
  computed: {
    multipleCountries() {
      return this.$store.state.partnerProfile.profileData.localProfiles
      && this.$store.state.partnerProfile.profileData.localProfiles.length > 1;
    },
    hqAddressData: {
      get() {
        if (this.$store.state.partnerProfile.profileData.hqAddress) {
          return this.$store.state.partnerProfile.profileData.hqAddress;
        }
        return {};
      },
    },
    show: {
      get() {
        return Object.keys(this.hqAddressData)
          .filter((key) => this.hqAddressData[key]).length > 0;
      },
    },
  },
};
</script>

<template>
  <div v-if="multipleCountries && hqAddressData && show" class="ds-flexGrid">
    <div class="ds-p-t-xl">
      <div class="hq-address-container center-content">
        <div v-for="addressLine in hqAddressData.addressLines" :key="addressLine.id" class="hq-address__line">
          <span>{{ addressLine }}</span>
        </div>
        <div class="address__line-1">
            {{ hqAddressData.suite ? hqAddressData.suite : ''}}
        </div>
        <div v-show="hqAddressData.phone" class="hq-address__phone">
          {{ hqAddressData.phone }}
        </div>

        <div v-show="hqAddressData.email" class="hq-address__email">
          <a @click="$eventBus.$emit('PFCustomEvent',
             {eventName: 'NavigateExternal', custom2: 'ContactPartnerEmail'})"
             :href="'mailto:'+hqAddressData.email">{{ hqAddressData.email }}</a>
        </div>

      </div>
    </div>
  </div>

</template>

<style lang="less" scoped>
@import "../../assets/css/common.less";

.hq-address-container {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  font-size: 15px;
  width: fit-content;
  &:extend(.font-normal);
  line-height: 21px;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;

  .hq-address__line {
    font-size: 15px;
    line-height: 21px;
    color: @color-black;
  }

  .hq-address__phone,
  .hq-address__email {
    color: @color-black;
    font-size: 15px;
    line-height: 21px;
  }

  .hq-address--office-name {
    color: @color-black;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    &:extend(.font-expanded);
  }

  a {
    color: @color-link;
    font-size: 15px;
    line-height: 21px;

    &:hover {
      color: @color-link__hover;
    }
  }
}

.center-content {
  margin-left: auto;
  margin-right: auto;
}

</style>
