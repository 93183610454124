<script>
import { mapState } from 'vuex';
import axios from 'axios';
import PFPartnerLogoComponent from '@/components/common/PFPartnerLogoComponent';

export default {
  name: 'PEFileUpdateComponent',
  props: {
    iconUrl: { type: String, default: '' },
    fileName: { type: String, default: '' },
    date: { type: String, default: '' },
    handleFilesUpload: { type: Function, default: () => true },
    justUploadLogo: { type: Boolean, default: false },
  },
  components: {
    PFPartnerLogoComponent,
  },
  data: () => ({
    imgLastModifiedDate: '',
    showLogoReminder: false,
  }),
  computed: {
    ...mapState('userProfile', {
      cancelSessionExtendingClicked: (state) => state.cancelSessionExtendingClicked,
    }),
  },
  methods: {
    getFileName(url) {
      if (url) {
        const fileName = url.substring(url.lastIndexOf('/') + 1);
        const maxShowingLen = 50;
        if (fileName.length <= maxShowingLen) {
          return fileName;
        }
        return `${fileName.substring(0, 30)}...${fileName.substring(fileName.length - 10, fileName.length)}`;
      }
      return '';
    },
    checkSessionExpiration(event) {
      if (this.cancelSessionExtendingClicked) {
        this.$store.commit('userProfile/setSessionExpired', true);
        event.preventDefault();
      }
    },
  },
  mounted() {
    if (this.iconUrl.length > 0) {
      const image = new Image();
      image.src = this.iconUrl;
      image.onload = () => {
        this.showLogoReminder = image.height !== image.width;
      };
      axios.get(this.iconUrl).then((response) => {
        const myDate = new Date(response.headers['last-modified']);
        const options = { dateStyle: 'long', timeStyle: 'short', hour12: false };
        this.imgLastModifiedDate = myDate.toLocaleString('en-US', options);
      });
    }
  },
};
</script>
<template>
  <div class="ds-update-loga-area" >
    <PFPartnerLogoComponent :logo="iconUrl" :isPreview="true" />
    <div class="ds-m-l-l ds-p-none ds-p-t-xs logo-upload-info">
      <div class="ds-upload__block-filename">{{ getFileName(fileName) }}</div>
      <div class="ds-upload__block-date-upload ds-m-b-s">
        <span v-show="imgLastModifiedDate.length > 0">
          {{ justUploadLogo ? $t('partner.logo.uploaded.justnow') : $t('partner.logo.uploaded') + ' ' + imgLastModifiedDate }}
        </span>
      </div>
      <div class="ds-upload__block ds-p-none ds-m-none">
        <input
          class="ds-input"
          type="file"
          ref="file"
          @click="checkSessionExpiration"
          @change="handleFilesUpload($refs.file.files[0])"
        />
        <button type="button" class="ds-update__block__button">
          <div class="ds-update__block__button__text">
            {{ $t('editor.logo.upload.popup.form.body.description.edit.button.text') }}
          </div>
        </button>
      </div>
    </div>
    <div class="line-break-row"></div>
    <div v-show="showLogoReminder"
         class="ds-upload__block-reminder-upload editor-warning-message ds-message__content ds-p-none ds-m-t-s"
    >
      <i class="ds-icon--exclamation-circle ds-p-r-xs"></i>
      {{ $t('editor.logo.upload.popup.reminder') }}
    </div>
  </div>
</template>

<style scoped lang="less">
@import "../../../assets/css/common.less";

.ds-update-loga-area {
  display: flex;
  flex-wrap: wrap;
  .ds-upload__block {
    align-items: initial;
    border: none;
    width: fit-content;
  }
  .ds-upload__block-reminder-upload {
    &:extend(.font-normal);
    flex-basis: 100%;
    font-size: 16px;
  }
  .line-break-row {
    flex-basis: 100%;
    height: 0;
  }
  .ds-upload__block-filename {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
  }
  .ds-upload__block-date-upload {
    &:extend(.font-normal);
    font-size: 12px;
    line-height: 1.5;
    color: #3c3c3c;
  }
  .ds-update__block__button {
    width: 128px;
    height: 42px;
    border: solid 1px @color-link ;
    background-color: transparent;
    max-width: none;
    .ds-update__block__button__text {
      &:extend(.font-normal);
      font-size: 16px;
      line-height: 1.5;
      color: @color-link;
    }
  }
  .logo-upload-info {
    max-width: 400px;
  }
  .editor-warning-message {
    color: @warning-orange;
    ::before {
      font-size: 24px;
    }
  }
}
.ds-upload__block:hover {
  .ds-update__block__button{
    border: solid 1px @color-link__hover;
    background-color: @color-link__hover;
  }
  .ds-update__block__button__text {
    color: @white-color;
  }
}
</style>
