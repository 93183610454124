<script>
const CountryAmount = {
  minumum: 5,
  maximum: 25,
};
export default {
  name: 'PEPlatinumPopupComponent',
  props: {
    closePopup: {
      type: Function,
      default: () => {},
    },
    goldCountries: {
      type: Array,
      default: () => [],
    },
    platinumCountries: {
      type: Array,
      default: () => [],
    },
    showGoldLogo: {
      type: Boolean,
      default: false,
    },
    groupedAccount: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    // Logic for "show more countries" feature. Retained for potential future use again.
    //
    // showGoldCountries() {
    //   if (this.goldCountries.length <= 5 || this.showAllCountires) {
    //     return this.goldCountries;
    //   }
    //   return this.goldCountries.slice(0, 4);
    // },
    companyName() {
      return this.$store.state.partnerProfile.profileData.name;
    },
    isPlatinumCountriesAvailable() {
      return this.platinumCountries?.length > 0;
    },
    platinumCountriesColumnClassOnCount() {
      return this.getColumnClassOnCountriesCount(this.platinumCountries);
    },
    goldCountriesColumnClassOnCount() {
      return this.getColumnClassOnCountriesCount(this.goldCountries);
    },
  },
  methods: {
    getColumnClassOnCountriesCount(countriesList) {
      if (countriesList?.length > CountryAmount.minumum && countriesList?.length <= CountryAmount.maximum) {
        return 'countries-list--column-2';
      }
      if (countriesList?.length > CountryAmount.maximum) {
        return 'countries-list--column-3';
      }
      return 'countries-list--column-1';
    },

    getCompanyName() {
      return { company: this.companyName };
    },
    goldCountryText() {
      return this.goldCountries.length === 1
        ? this.$t('sap.gold.partner.text.b.single', this.getCompanyName())
        : this.$t('sap.gold.partner.text.b', this.getCompanyName());
    },
    platinumModalDescriptionText() {
      return this.platinumCountries.length > 0
        ? this.$t('sap.platinum.partner.country.description.text', this.getCompanyName())
        : this.$t('sap.platinum.partner.description.text', this.getCompanyName());
    },
    platinumCountryText() {
      return this.platinumCountries.length === 1
        ? this.$t('sap.platinum.partner.countries.text.single', this.getCompanyName())
        : this.$t('sap.platinum.partner.countries.text', this.getCompanyName());
    },
  },
  mounted() {
    window.setTimeout(() => this.$refs.platinumEditModal.focus(), 0);
  },
};
</script>

<template>
  <div>
    <div
      class="ds-modal ds-modal--active ds-modal--scrollable"
      ref="platinumEditModal"
      tabindex="0"
      @keyup.esc="closePopup"
    >
      <div class="modal-container">
        <div class="modal-container-inner-platinum">
          <div class="ds-modal__box platinum-logo-modal">
            <div class="ds-modal__box__header border-none">
              <div class="ds-modal__box__header__heading">
                {{ $t("sap.partner.edge") }}
                <i class="ds-icon ds-icon--close modal-close-icon" @click="closePopup"></i>
              </div>
            </div>
            <div class="ds-modal__box__content" :class="showGoldLogo ? 'gold-country-max-height' : ''">
              <div class="dialog-text">
                <div v-if="showGoldLogo">
                  <div class="platinum-popup--container ds-m-b-s">
                    <img class="platinum-popup--logo" src="@/assets/SAP_GoldPartner_grad_R.png" />
                  </div>
                  <div class="platinum-logo--text ds-m-b-s">{{ $t("sap.gold.partner") }}</div>
                  <div class="platinum-text">{{ $t("sap.gold.partner.text") }}</div>
                  <div class="platinum-text ds-m-t-m" v-if="groupedAccount">
                    {{ this.goldCountryText() }}
                  </div>
                  <ul
                    :class="goldCountriesColumnClassOnCount"
                    class="ds-list ds-m-none countries-list"
                    v-if="groupedAccount"
                  >
                    <li
                      class="ds-list__item ds-bullet__item gold-country-list-bullet"
                      v-for="country in goldCountries"
                      :key="country"
                    >
                      <span class="platinum-text">{{ country }}</span>
                    </li>
                  </ul>

                  <!--Logic for "show more countries" feature. Retained for potential future use again.  -->

                  <!-- <div
                    class="more-gold-countries"
                    @click="showAllCountires = true"
                    v-if="!(this.goldCountries.length <= 5 || this.showAllCountires)"
                  >
                    {{ goldCountries.length - 4 }}
                    {{ $t("sap.gold.partner.more.countries") }}
                  </div> -->
                </div>
                <div v-else>
                  <div class="platinum-popup--container ds-m-b-s">
                    <img class="platinum-popup--logo" src="@/assets/SAP_PlatinumPartner_R.png" />
                  </div>
                  <div class="platinum-logo--text ds-m-b-s">{{ $t("sap.platinum.partner") }}</div>
                  <div class="platinum-text">{{ this.platinumModalDescriptionText() }}</div>
                  <p v-if="isPlatinumCountriesAvailable">{{ this.platinumCountryText() }}</p>
                  <ul :class="platinumCountriesColumnClassOnCount" class="countries-list">
                    <li v-for="country in platinumCountries" :key="country">
                      <span class="platinum-text">{{ country }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="ds-modal__box__action-bar border-none">
              <button
                class="ds-modal__box__action-bar__action ds-button ds-button--primary ds-m-x-none"
                type="button"
                @click="closePopup"
                id="platinum-logo-button__done"
              >
                {{ $t("button.close") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.countries-list {
  list-style-type: none;
  column-gap: 20px;
  &--column-1 {
    column-count: 1;
  }
  &--column-2 {
    column-count: 2;
  }
  &--column-3 {
    column-count: 3;
  }
}
.countries-list li::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10"><path d="M0 5 L6 5" stroke="%23007DB8" stroke-width="1" fill="transparent"/></svg>');
  background-repeat: no-repeat;
}
.countries-list li {
  padding-left: 15px;
  text-indent: -15px;
}

.modal-container-inner-platinum {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 250px);
}
.ds-modal--active {
  z-index: 200;
}
.platinum-logo-modal {
  max-width: 564px;
  position: relative;
  height: fit-content;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  .dialog-text {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
    color: @color-grey-0;
  }
  .ds-button {
    &:extend(.font-normal);
    font-size: 16px;
    max-width: none;
    width: fit-content !important;
  }
  .ds-modal__box__header__heading {
    &:extend(.font-expanded);
    font-size: 27px;
    line-height: 35px;
  }
  .modal-close-icon {
    width: 23px;
    height: 23px;
    float: right;
    margin-right: 33px;
    color: @color-gray-4;
    position: absolute;
    top: 20px;
    right: 0;
    cursor: pointer;
  }

  .modal-close-icon::before {
    font-size: 23px;
  }

  .modal-close-icon:hover {
    color: @color-glance-consultants;
  }
  .platinum-popup--container {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
  .platinum-popup--logo {
    width: 85.1px;
    height: 50px;
  }
  .platinum-logo--text {
    &:extend(.font-expanded);
    font-size: 21px;
    line-height: 27px;
    color: @color-grey-0;
    text-align: center;
  }
  .platinum-text {
    &:extend(.font-normal);
    font-size: 16px;
    line-height: 24px;
    color: @color-grey-0;
  }
  .gold-country-list {
    padding-inline-start: 28px;
  }
  // For "show more countries" feature. Retained for potential future use again.
  // .gold-country-max-height {
  //   max-height: 417px;
  // }
  // .more-gold-countries {
  //   padding-left: 28px;
  //   cursor: pointer;
  //   color: @color-link-show-more;
  // }
  // .more-gold-countries:hover {
  //   color: @color-link__hover;
  // }
  .border-none {
    border: none !important;
  }
}
@media (max-width: 600px) {
  .countries-list {
    column-count: 1;
  }
}
</style>
