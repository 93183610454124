<script>
import { mapActions, mapState } from 'vuex';

const MAX_CHAR = 750;

export default {
  name: 'PESocialMediasComponent',
  props: {
    isShown: { type: Boolean, default: false },
    cancelAction: { type: Function, default: () => false },
    countryProfileId: { type: Number, default: 0 },
    countryName: { type: String, default: 'global' },
  },
  data: () => ({
    changedData: {
      WEBSITE: null,
      LINKEDIN: null,
      INSTAGRAM: null,
      XING: null,
      FACEBOOK: null,
      TWITTER: null,
      WECHAT: null,
      YOUTUBE: null,
    },
    hasFilledLink: false,
    maxChar: MAX_CHAR,
  }),
  methods: {
    ...mapActions('partnerProfile', {
      updateSocialMedias: 'updateSocialMedias',
    }),
    clearChanges() {
      this.changedData = {
        WEBSITE: null,
        LINKEDIN: null,
        INSTAGRAM: null,
        XING: null,
        FACEBOOK: null,
        TWITTER: null,
        WECHAT: null,
        YOUTUBE: null,
      };
    },
    validateUrl(url) {
      if ((typeof (url) === 'string' && url.length === 0) || url === null) {
        return true;
      }
      const pattern = new RegExp(
        // eslint-disable-next-line no-useless-escape
        '^(https?:\\/\\/)?'
        + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
        + '((\\d{1,3}\\.){3}\\d{1,3}))'
        + '(\\:\\d+)?(\\/[-a-z\\d%_.~&+@]*)*'
        + '(\\?[;&a-z\\d%_.~+=-]*)?'
        + '(\\#[-a-z\\d_]*)?$',
        'i',
      );
      return pattern.test(url);
    },

    isSocialMediaValid(socialMedia) {
      if (this.changedData[socialMedia] !== null && this.changedData[socialMedia].valid) {
        return true;
      }
      if (this.changedData[socialMedia] === null) {
        const element = this.getSocialMedias()
          ? this.getSocialMedias().find((item) => socialMedia === item.socialMediaType) : null;
        if (element) {
          return this.validateUrl(element.link);
        }
        return true;
      }
      return false;
    },
    areSocialMediasValid() {
      return !(Object.keys(this.changedData).filter((item) => !this.isSocialMediaValid(item)).length !== 0);
    },
    findSocialMediaItem(item) {
      if (this.changedData[item] !== null) {
        return this.changedData[item].value;
      }
      if (this.getSocialMedias() && this.getSocialMedias().length !== 0) {
        const element = this.getSocialMedias().find((sMedia) => sMedia.socialMediaType === item);
        if (element) {
          return element.link;
        }
      }
      return '';
    },
    getSocialMedias() {
      if (this.countryName === 'global') {
        return this.profileData.profileSocialMedias;
      }
      return this.profileData.localProfiles
        .find((lProfile) => this.countryName === lProfile.country).profileSocialMedias;
    },
    checkFilledLinks() {
      const itemsToClear = Object.keys(this.changedData)
        .filter((item) => this.changedData[item] !== null && this.changedData[item].length === 0);
      const itemsToChange = Object.keys(this.changedData)
        .filter((item) => this.changedData[item] !== null && this.changedData[item].length !== 0);
      if (itemsToChange.length !== 0) {
        this.hasFilledLink = true;
      }
      let notEmptyLinks = [];
      if (this.countryName === 'global') {
        if (this.profileData.profileSocialMedias && this.profileData.profileSocialMedias.length !== 0) {
          notEmptyLinks = this.profileData.profileSocialMedias;
        }
      } else {
        const localProfile = this.profileData.localProfiles.find((lProfile) => this.countryName === lProfile.country);
        if (localProfile) {
          notEmptyLinks = localProfile.profileSocialMedias;
        }
      }
      notEmptyLinks = notEmptyLinks || [];
      const itemsAfterRemoving = notEmptyLinks.filter((item) => !itemsToClear.includes(item.socialMediaType));
      this.hasFilledLink = itemsAfterRemoving.length !== 0 || itemsToChange.length !== 0;
    },
    onValueChange(event, socialMediaType) {
      this.changedData[socialMediaType] = { value: event.target.value, valid: this.validateUrl(event.target.value) };
      this.checkFilledLinks();
    },
    saveChanges() {
      if (this.areSocialMediasValid()) {
        if (Object.keys(this.changedData).length !== 0) {
          Object.keys(this.changedData).forEach((item) => {
            if (this.changedData[item]) {
              this.changedData[item] = this.changedData[item].value;
            }
          });
          this.updateSocialMedias({ socialMedias: this.changedData, countryName: this.countryName });
          this.clearChanges();
        }
        this.closeModal();
      }
    },
    closeModal() {
      this.clearChanges();
      this.cancelAction();
    },
    getFirstColumnData() {
      return Object.keys(this.changedData)
        .filter((sMedia) => (this.countryName === 'global' && sMedia !== 'WEBSITE') || this.countryName !== 'global')
        .filter((sMedia, index, array) => array.indexOf(sMedia) + 1
          <= Math.round(array.length / 2));
    },
    getSecondColumnData() {
      return Object.keys(this.changedData)
        .filter((sMedia) => (this.countryName === 'global' && sMedia !== 'WEBSITE') || this.countryName !== 'global')
        .filter((sMedia, index, array) => array.indexOf(sMedia) + 1
          > Math.round(array.length / 2));
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      profileData: (state) => state.profileData,
    }),
    validSocialMedia() {
      return !(Object.keys(this.changedData).filter((item) => !this.isSocialMediaValid(item)).length !== 0);
    },
  },
  watch: {
    isShown() {
      this.checkFilledLinks();
      if (this.isShown) {
        window.setTimeout(() => this.$refs.socialMediaModal.focus(), 0);
      }
    },

  },
};
</script>
<template>
  <div class="ds-modal ds-modal--active social-media-popup" v-if="isShown"
       ref="socialMediaModal" tabindex="0" @keyup.esc="closeModal">
    <div class="modal-container">
    <div class="modal-container-inner-social-media">
    <div id="socialMediaModal" class="ds-modal__box">
      <div class="ds-modal__box__header ds-p-y-none">
        <div v-if="hasFilledLink" class="ds-heading--m da-modal__box_header__heading">
          {{ $t('editor.socialmedia.title.edit.name') }}
        </div>
        <div v-else class="ds-heading--m da-modal__box_header__heading">
          {{ $t('editor.socialmedia.title.add.name') }}
        </div>
      </div>
      <div class="ds-modal__box__content ds-m-b-m">
        <div class="ds-flexRow pf-soc-media-items">
          <div class="ds-flexCol ds-flexCol-xs-6 pf-left-items">
            <label
              v-for="socialMedia in getFirstColumnData()"
              class="ds-m-none ds-textField ds-textField--fixed"
              :key="socialMedia">
              <input
                class="ds-textField__input ds-m-t-m"
                :class="!isSocialMediaValid(socialMedia) ? 'pf-validation-error-textField':''"
                type="text"
                :placeholder="$t('editor.socialmedia.' + socialMedia.toLowerCase() + '.name') + ' (Optional)'"
                :value="findSocialMediaItem(socialMedia)"
                @input="onValueChange($event, socialMedia)"
                :maxlength="maxChar"
              />
              <span
                class="ds-textField__placeholder"
                :class="!isSocialMediaValid(socialMedia) ? 'pf-validation-error-textField-placeholder':''"
                v-if="findSocialMediaItem(socialMedia)">
                {{ $t('editor.socialmedia.' + socialMedia.toLowerCase() + '.name') }}
              </span>
              <div class="ds-textField__message pf-validation-error-message" v-if="!isSocialMediaValid(socialMedia)">
                <i class="ds-icon--close-outline ds-m-r-xs"></i>
                {{ $t('editor.socialmedia.url.error.message') }}
              </div>
            </label>
          </div>
          <div class="ds-flexCol ds-flexCol-xs-6 pf-right-items">
            <label
              v-for="socialMedia in getSecondColumnData()"
              class="ds-m-none ds-textField ds-textField--fixed"
              :key="socialMedia">
              <input
                class="ds-textField__input ds-m-t-m"
                :class="!isSocialMediaValid(socialMedia) ? 'pf-validation-error-textField':''"
                type="text"
                :placeholder="$t('editor.socialmedia.' + socialMedia.toLowerCase() + '.name') + ' (Optional)'"
                :value="findSocialMediaItem(socialMedia)"
                @input="onValueChange($event, socialMedia)"
                :maxlength="maxChar"
              />
              <span
                class="ds-textField__placeholder"
                :class="!isSocialMediaValid(socialMedia) ? 'pf-validation-error-textField-placeholder':''"
                v-if="findSocialMediaItem(socialMedia)">
                {{ $t('editor.socialmedia.' + socialMedia.toLowerCase() + '.name') }}
              </span>
              <div class="ds-textField__message pf-validation-error-message" v-if="!isSocialMediaValid(socialMedia)">
                <i class="ds-icon--close-outline ds-m-r-xs"></i>
                {{ $t('editor.socialmedia.url.error.message') }}
              </div>
            </label>
          </div>
        </div>
      </div>
      <div class="ds-modal__box__action-bar ds-p-y-none">
        <button
          class="ds-button ds-button--primary ds-m-none"
          @click="saveChanges"
          :disabled="!validSocialMedia"
        >Done
        </button>
        <button
          class="ds-button ds-button--secondary ds-m-none ds-m-l-m"
          @click="closeModal"
        >Cancel
        </button>
      </div>
    </div>
    </div>
  </div>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.social-media-popup {
  .pf-validation-error-textField:focus {
    border-color: @color-field-error;
  }

  .pf-validation-error-textField {
    border-color: @color-field-error;
  }

  .pf-validation-error-textField-placeholder, .pf-validation-error-message {
    color: @color-field-error !important;
  }

  .pf-validation-error-message {
    font-size: 14px;
    .ds-icon--close-outline {
      line-height: 17px;
      padding-left: 1px;
    }
  }

  .ds-modal__box__content {
    margin-top: 0 !important;
  }
}

.ds-modal-open-container {
  left: 35% !important;
}

.pf-left-items {
  padding-right: 19px !important;
  padding-left: unset !important;
  max-width: 248px;
  min-width: 204px;
}

.pf-right-items {
  padding-left: 0 !important;
  padding-right: unset !important;
  max-width: 248px;
  min-width: 204px;
}

.ds-modal__box__action-bar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 19px;

  .ds-button {
    width: auto;
  }
}

.pf-soc-media-items {
  width: 100%;
  margin-right: unset !important;
  margin-left: unset !important;
}

#socialMediaModal {
  width: 632px;
  height: auto;
  margin: auto;
  min-height: unset;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}
.modal-container-inner-social-media {
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 170px);
}

.ds-modal__box__header__icon {
  width: 23px;
  height: 23px;
  float: right;
  margin-right: 33px;
  color: @color-gray-4 !important;
  position: absolute;
  top: 20px;
  right: 0;
}

.ds-modal__box__header__icon::before {
  font-size: 23px;
}

.ds-textField__input {
  opacity: 1 !important;
  position: static !important;
}

.social-media__icon_facebook:placeholder-shown {
  background-image: url('../../assets/icons/facebook-icon.svg');
}

.social-media__icon_facebook:not:placeholder-shown {
  background-image: none;
}

.social-media__icon_linkedin {
  background-image: url('../../assets/icons/linkedIn-icon.svg');
}

.social-media__icon_instagram {
  background-image: url('../../assets/icons/instagram-icon.svg');
}

.social-media__icon_twitter {
  background-image: url('../../assets/icons/twitter-icon.svg');
}

.social-media__icon_youtube {
  background-image: url('../../assets/icons/youtube-icon.svg');
}

.social-media__icon_website {
  background-image: url('../../assets/icons/website-icon.svg');
}

.social-media__icon_wechat {
  background-image: url('../../assets/icons/wechat-icon.svg');
}
.social-media-popup {
  z-index: 101;
}
</style>
