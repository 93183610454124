<script>
import { mapState } from 'vuex';
import PFFilterConfigurationBarComponent from '../components/partnerNavigatorPage/PFFilterConfigurationBarComponent';
import PFBadgeBarComponent from '../components/common/PFBadgeBarComponent';
import PFFilterSortBarComponent from '../components/partnerNavigatorPage/PFFilterSortBarComponent';
import PFCardItemContainer from '../components/common/PFCardItemContainer';

const IE_BROWSER_PAGE = 'browserNotSupportedPage';

export default {
  name: 'PFPartnerNavigatorPage',
  components: {
    PFFilterConfigurationBarComponent,
    PFBadgeBarComponent,
    PFFilterSortBarComponent,
    PFCardItemContainer,
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapState('profileSearch', {
      pathChangedFromUiFlag: (state) => state.pathChangedFromUiFlag,
    }),
  },
  beforeCreate() {
    if (/Trident\/|MSIE /i.test(navigator.userAgent)) {
      this.$router
        .replace({
          name: IE_BROWSER_PAGE,
        })
        .catch(() => {
        });
    }
  },
  watch: {
    $route: {
      handler(newValue, oldValue) {
        if (oldValue?.fullPath !== newValue.fullPath && newValue.name != 'home') {
          if (this.pathChangedFromUiFlag) {
            this.$store.dispatch('profileSearch/setPathChangedFromUiFlag', false);
          } else if (!newValue.path.startsWith('/profile/')) {
            this.$store.dispatch('profileSearch/restoreSelection');
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

};
</script>

<template>
  <div class="ds_filter-container">
    <div class="ds-filter-sort">
      <PFFilterConfigurationBarComponent/>
      <div class="ds-filter-tocken-bar">
        <PFFilterSortBarComponent/>
        <div class="ds-filter__tockens-set">
          <PFBadgeBarComponent/>
        </div>
      </div>
    </div>
    <PFCardItemContainer/>
  </div>
</template>

<style lang="less">
.ds_filter-container {
  width: 100%;
  .ds-filter-sort {
    .ds-filter-tocken-bar {
      margin: 49px 96px 27px 96px;
    }
  }
}

.ds-filter__tockens-set {
  max-width: 1280px;
  margin: 0 auto 0 auto;
}
</style>
