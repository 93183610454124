<script>
import { mapActions, mapState } from 'vuex';
import PFActionButtonComponent from '@/components/common/PFActionButtonComponent';
import PFDropdownsFilterComponent from '../partnerNavigatorPage/PFDropdownsFilterContainer';
import PFCloudCompetencyToggleComponent from './PFCloudCompetencyToggleComponent';

const sortOptions = require('@/plugins/search/sortOptions');

export default {
  name: 'PFMobileFilterComponent',
  props: {
  },
  components: {
    PFDropdownsFilterComponent,
    PFActionButtonComponent,
    PFCloudCompetencyToggleComponent,
  },
  data: () => ({
    filterType: [{
      name: 'SORT', filterType: 'sort', type: 'sort', title: 'mobile.filter.sort',
    },
    {
      name: 'LOCATION', filterType: 'location', type: 'locationCheckbox', title: 'search.filter.location',
    },
    {
      name: 'PRODUCTS', filterType: 'products', type: 'checkbox', title: 'search.filter.solution',
    },
    {
      name: 'DESIGNATION', filterType: 'designation', type: 'checkbox', title: 'search.filter.designation',
    },
    {
      name: 'INDUSTRY', filterType: 'industry', type: 'checkbox', title: 'search.filter.industry',
    },
    {
      name: 'ENGAGEMENT', filterType: 'engagement', type: 'checkbox', title: 'search.filter.business.model',
    }],
    showFilterMenu: false,
    showFilterDropDown: false,
    currentFilterType: '',
    closeMenuWithAnimation: false,
    typesSort: sortOptions.SORT_OPTIONS,
  }),
  mounted() {
    this.$eventBus.$on('ClickMobileSortBar', (data) => {
      const sortIdx = 0;
      this.openFilterItems();
      this.openFilterDropDown(sortIdx);
    });
  },
  computed: {
    ...mapState('profileSearch', {
      countResults: (state) => state.countResults,
      selectedOptions: (state) => state.selectedOptions,
      selectedSortOption: (state) => state.selectedSortingOptionText,
      query: (state) => state.query,
    }),
    ...mapState('solutionL2Store', {
      solutionBadge: (state) => state.solutionBadge,
    }),

    countSelectedItems() {
      return this.filterType.reduce((accumulator, currentType) => {
        if (currentType.filterType === 'sort') {
          return accumulator;
        } if (currentType.filterType === 'products') {
          return accumulator + [...this.solutionBadge.keys()].length;
        }
        return accumulator + this.selectedOptions[currentType.filterType].length;
      }, 0);
    },
  },
  methods: {
    ...mapActions('profileSearch', {
      cleanFilters: 'cleanFilters',
    }),
    changeSortType(key, value) {
      this.$store.dispatch('profileSearch/setSelectedSortingOptionText', key);
      this.$store.dispatch('profileSearch/setSorting', value);
      this.$eventBus.$emit('PFAACustomEvent',
        { eventName: 'SearchInitiated', custom2: this.query });
      this.closeFilterDropDownAndShowResult();
    },
    openFilterDropDown(filterTypeIdx) {
      this.currentFilterType = this.filterType[filterTypeIdx];
      this.showFilterDropDown = true;
    },
    closeFilterDropDown() {
      this.showFilterDropDown = false;
      this.currentFilterType = '';
    },
    closeFilterDropDownAndShowResult() {
      this.closeMenuWithAnimation = true;
      this.showFilterDropDown = false;
      this.showFilterMenu = false;
      this.currentFilterType = '';
      this.enableBodyScroll();
    },
    hideFilterItems() {
      this.closeMenuWithAnimation = true;
      this.showFilterMenu = false;
    },
    openFilterItems() {
      this.showFilterMenu = true;
    },
    disableBodyScroll() {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
    },
    enableBodyScroll() {
      document.body.style.paddingRight = '0px';
      document.body.style.overflow = 'scroll';
    },
  },
  watch: {
    showFilterMenu(val) {
      if (val) {
        this.disableBodyScroll();
      } else {
        this.showFilterDropDown ? this.disableBodyScroll() : this.enableBodyScroll();
      }
    },
  },
  beforeUnmount() {
    this.enableBodyScroll();
  },

};
</script>

<template>
  <div class="small-screen-filter-icon-container" @click="openFilterItems">
      <img class="small-screen-filter-icon" src="../../assets/Filter_Icon.png" alt="">
      <div
        v-if="countSelectedItems !== 0"
        class="ds-number-badge-block"
      >
        <i class="ds-number-badge filter-number-badge"
          :class="countSelectedItems >= 10 ? 'ds-number-badge-large' : ''">
          <span>{{ countSelectedItems }}</span>
        </i>
      </div>
  </div>
  <div class="small-screen-filter-menu-container"
    :class="showFilterMenu ? 'selected' : (closeMenuWithAnimation ? 'dismiss' : 'display-none')">
    <div class="small-screen-filter-menu-header">
      <div class="menu-header-text">{{$t('mobile.filter.header')}}</div>
      <div class="menu-header-clear" @click="cleanFilters">{{$t('mobile.filter.clear')}}</div>
    </div>
    <div class="small-screen-filter-menu-body">
      <div v-for="(item,idx) in filterType"
        :key="item.name"
        @click="openFilterDropDown(idx)"
        class="small-screen-filter-menu-item ds-m-t-xs">
        <div class="small-screen-filter-menu-item-text">
          <div>
            {{$t(item.title)}}
            <div v-if="item.type === 'sort'" class="sort-text">{{selectedSortOption}}</div>
          </div>
          <div
            v-if="this.selectedOptions[item.filterType]
            && this.selectedOptions[item.filterType].length !== 0
            && item.filterType !== 'products'"
            class="ds-number-badge-block menu-number-badge-block ds-m-l-xxs"
          >
            <i class="ds-number-badge menu-number-badge"
              :class="this.selectedOptions[item.filterType].length >= 10 ? 'ds-number-badge-large' : ''">
              <span>{{ this.selectedOptions[item.filterType].length }}</span>
            </i>
          </div>
          <div
            v-if="item.filterType === 'products' && [...solutionBadge.keys()].length > 0"
            class="ds-number-badge-block menu-number-badge-block ds-m-l-xxs"
          >
            <i class="ds-number-badge menu-number-badge"
              :class="[...solutionBadge.keys()].length >= 10 ? 'ds-number-badge-large' : ''">
              <span>{{ [...solutionBadge.keys()].length }}</span>
            </i>
          </div>
        </div>
        <i class="ds-icon ds-icon--arrow-right small-screen-filter-menu-item-icon ds-m-r-m"></i>

        </div>

        <PFCloudCompetencyToggleComponent
            :title="$t('search.filter.cloud.competency')"
            class="cloud-competency-toggle"
            />

    </div>
    <div class="small-screen-filter-menu-footer ds-p-x-s">
      <PFActionButtonComponent
          class="small-screen-filter-menu-footer-button ds-m-y-s"
          :action="hideFilterItems"
          :selectedItems="countResults"
          :title="$t('filter.show.button')"
          :endTitle="$tc('results.text', countResults)"
          :mobileMode="true"
      />
    </div>
  </div>
  <div class="small-screen-filter-container" v-if="showFilterDropDown">
    <div class="small-screen-filter-header-back ds-p-t-m" @click="closeFilterDropDown">
      <i class="ds-icon ds-icon--arrow-left"></i>
      <div>{{$t('mobile.filter.back')}}</div>
    </div>
    <div class="small-screen-filter-header">{{$t(currentFilterType.title)}}</div>
    <div class="ds-filter-sort__sort-bar__sort__dropdown ds-p-none display-block"
      v-if="currentFilterType.type === 'sort'">
        <div v-for="(value, key) in typesSort" class="ds-p-r-xxs" :key="key" @click.stop="changeSortType(key, value)">
          <div class="ds-radio__label label-text ds-p-y-xs ds-p-r-s">{{ key }}</div>
        </div>
    </div>
    <PFDropdownsFilterComponent
    v-else
    class="ds-filter-sort__configuration-bar"
    :type="currentFilterType.type"
    :filterType="currentFilterType.filterType"
    :closeMobileFilter="closeFilterDropDownAndShowResult"
    :mobileMode="true"/>
  </div>
</template>

<style lang="less" scoped>
.cloud-competency-toggle{
  margin-top: 8px;
}
.sort-text {
  &:extend(.font-normal);
  font-size: 14px;
  line-height: 18px;
  color: @color-gray-2;
}
.ds-number-badge-large {
    width: 27px !important;
    height: 18px !important;
    left: 10px !important;
}
.display-none {
  display: none;
}
.small-screen-filter-menu-header {
  height: 64px;
  width: 100%;
  box-shadow: inset 0px -1px 0px #BEC4CB;
  display: flex;
  justify-content: space-between;
  .menu-header-text {
    font-size: 20px;
    line-height: 27px;
    color: @color-black;
    &:extend(.font-expanded);
    margin-left: 24px;
    margin-top: 18.5px;
  }
  .menu-header-clear {
    font-size: 16px;
    line-height: 24px;
    color: @color-link;
    &:extend(.font-normal);
    margin-right: 24px;
    margin-top: 20px;
    cursor: pointer;
  }
}
.small-screen-filter-menu-body {
  height: calc(100% - 136px);
}
.small-screen-filter-menu-footer {
  position: relative;
  height: 64px;
  width: 100%;
  box-shadow: 0px 0px 0.9px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.13);
  display: flex;
  justify-content: center;
  .small-screen-filter-menu-footer-button {
    max-width: none;
    width: 100%;
    height: 40px;
    justify-content: center;
  }
}
.small-screen-filter-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  background-color: white;
  left: 0;
  z-index: 200;
}
.small-screen-filter-container .ds-filter-sort__sort-bar__sort__dropdown {
    position: relative;
    width: 100%;
    top: 0;
    box-shadow: none;
    .label-text {
      padding-left: 24px;
    }
}
.small-screen-filter-header-back {
  &:extend(.font-normal);
  color: @color-gray-1;
  height: 64px;
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  padding-left: 24px;
  display: flex;
  cursor: pointer;
  .ds-icon--arrow-left {
    margin-top: 2px;
  }
  .ds-icon--arrow-left::before {
    font-size: 20px !important;
    color: @color-gray-2;
  }
}
.small-screen-filter-header {
  &:extend(.font-expanded);
  font-size: 20px;
  line-height: 27px;
  height: 64px;
  width: 100%;
  padding-top: 18.5px;
  padding-bottom: 18.5px;
  padding-left: 24px;
  color: @black-color;
  cursor: default;
}
.small-screen-filter-menu-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  background-color: white;
  left: 0;
  z-index: 199;
}
.filter-number-badge {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 12px;
  top: -3px;
}
.small-screen-filter-menu-item-text {
  display: flex;
}
.menu-number-badge {
  width: 18px;
  height: 18px;
  margin-top: 3px;

}
.small-screen-filter-icon-container {
    position: relative;
}
.small-screen-filter-menu-item-icon {
  margin-top: 16px;
}
.ds-icon--arrow-right::before {
    font-size: 20px !important;
    color: @color-gray-2;
  }

.small-screen-filter-icon {
    width: 24px;
    height: 24px;
    margin-top: 8px;
    color: @color-gray-5;
    cursor: pointer;
}

.small-screen-filter-menu-item {
  width: 100%;
  height: 52px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.small-screen-filter-menu-item-text {
  &:extend(.font-normal);
  color: @color-grey-0;
  font-size: 16px;
  line-height: 24px;
  margin-left: 24px;
  margin-top: 14px;
}
.selected {
  display: block;
  animation: slide-in 0.5s forwards;
  -webkit-animation: slide-in 0.5s forwards;
}

.dismiss {
  display: block;
  animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards;
}

@keyframes slide-in {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}

</style>
