<script>
const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PETermsOfUseComponent',
  data: () => ({
    guideline: urlLinks.publicationGuideline,
  }),
  methods: {
    sendEmail() {
      const subject = encodeURIComponent(this.$tc('help.mail.subject'));
      const emailBody = encodeURIComponent(this.$tc('help.mail.body'));
      const email = encodeURIComponent(this.$tc('help.mail.contact.email'));
      this.openInNewTab(`mailto:${email}?subject=${subject}&display-name=SAP&body=${emailBody}`);
    },
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
  },
};
</script>

<template>
  <div class="terms-of-use-container ds-p-b-xxl">
    <div class="terms-of-use-forehead">
      <div class="terms-of-use-forehead-text">
        {{ $t('partner.terms.of.use') }}
      </div>
    </div>
    <div class="terms-of-use-header-text ds-p-t-xxl">
      {{ $t('terms.of.use.pe.title') }}
    </div>
    <div class="ds-list">
      <div class="terms-of-use-second-header-text ds-m-t-s">
        {{ $t("terms.of.use.pe.subtitle.a") }}
      </div>
      <div
        class="terms-of-use-second-header-li ds-item__label--normal"
        v-html="$t('terms.of.use.pe.content.a.a')"/>
      <div class="terms-of-use-second-header-text ds-m-t-s">
        {{ $t("terms.of.use.pe.subtitle.b") }}
      </div>
      <div class="terms-of-use-sub-ol">
        <div
          class="term-of-use-sub-li terms-of-use-second-header-li ds-item__label--normal"
          data-char="2"
          v-html="$t('terms.of.use.pe.content.b.a')"/>
        <div
          class="term-of-use-sub-li terms-of-use-second-header-li ds-item__label--normal"
          data-char="2">
          {{ $t("terms.of.use.pe.content.b.b") }}
        </div>
      </div>
      <div class="terms-of-use-second-header-text ds-m-t-s">
        {{ $t("terms.of.use.pe.subtitle.c") }}
      </div>
      <div class="terms-of-use-sub-ol">
        <div
          class="term-of-use-sub-li terms-of-use-second-header-li ds-item__label--normal"
          data-char="3"
          v-html="$t('terms.of.use.pe.content.c.a')"/>
        <div
          class="term-of-use-sub-li terms-of-use-second-header-li ds-item__label--normal"
          data-char="3">
          {{ $t("terms.of.use.pe.content.c.b") }}
          <a
            @click.prevent="sendEmail()">{{ $t("terms.of.use.email") }}
          </a>
          {{ $t("terms.of.use.dot") }}
        </div>
        <div
          class="term-of-use-sub-li terms-of-use-second-header-li ds-item__label--normal"
          data-char="3">
          {{ $t("terms.of.use.pe.content.c.c.a") }}
          <a :href="guideline" target="_blank">{{ $t("terms.of.use.pe.content.c.c.b") }}</a>
          <span v-html="$t('terms.of.use.pe.content.c.c.c')" />
        </div>
        <div
          class="term-of-use-sub-li ds-item__label--normal"
          data-char="3"
        >
          {{ $t("terms.of.use.pe.content.c.d") }}
        </div>
      </div>
    </div>
 <div class="ds-list">
      <div class="terms-of-use-second-header-text ds-m-t-s">
        {{ $t("terms.of.use.pe.subtitle.d") }}
      </div>
      <div
        class="terms-of-use-second-header-li ds-item__label--normal"
        v-html="$t('terms.of.use.pe.content.d')"/>
    </div>
    <div class="ds-list">
      <div class="terms-of-use-second-header-text ds-m-t-s">
        {{ $t("terms.of.use.pe.subtitle.e") }}
      </div>
      <ol class="ds-list terms-of-use-sub-ol">
        <li
          v-for="sidx in 3"
          :key="'subprinciple' + sidx"
          class="term-of-use-sub-li"
          data-char="5"
        >
          <div
            class="terms-of-use-second-header-li ds-item__label--normal"
            v-html="$t(`terms-of-use-pe-content-e[${sidx - 1}]`)"
          />
        </li>
      </ol>
    </div>

    <div class="ds-list">
      <div class="terms-of-use-second-header-text ds-m-t-s">
        {{ $t("terms.of.use.pe.subtitle.f") }}
      </div>
      <ol class="ds-list terms-of-use-sub-ol">
        <li
          v-for="sidx in 5"
          :key="'subprinciple' + sidx"
          class="term-of-use-sub-li"
          data-char="6"
        >
          <div
            class="terms-of-use-second-header-li ds-item__label--normal"
            v-html="$t(`terms-of-use-pe-content-f[${sidx - 1}]`)"
          />
        </li>
      </ol>
    </div>

    <div class="ds-list">
      <div class="terms-of-use-second-header-text ds-m-t-s">
        {{ $t("terms.of.use.pe.subtitle.g") }}
      </div>
      <ol class="ds-list terms-of-use-sub-ol">
        <li
          v-for="sidx in 2"
          :key="'subprinciple' + sidx"
          class="term-of-use-sub-li"
          data-char="7"
        >
          <div
            class="terms-of-use-second-header-li ds-item__label--normal"
            v-html="$t(`terms-of-use-pe-content-g[${sidx - 1}]`)"
          />
        </li>
      </ol>
    </div>
    <div class="ds-list">
      <div class="terms-of-use-second-header-text ds-m-t-s">
        {{ $t("terms.of.use.pe.subtitle.h") }}
      </div>
      <div
        class="terms-of-use-second-header-li ds-item__label--normal"
        v-html="$t('terms.of.use.pe.content.h')"/>
    </div>
    <div class="ds-list">
      <div class="terms-of-use-second-header-text ds-m-t-s">
        {{ $t("terms.of.use.pe.subtitle.i") }}
      </div>
      <ol class="ds-list terms-of-use-sub-ol">
        <li
          v-for="sidx in 2"
          :key="'subprinciple' + sidx"
          class="term-of-use-sub-li"
          data-char="9"
        >
          <div
            class="terms-of-use-second-header-li ds-item__label--normal"
            v-html="$t(`terms-of-use-pe-content-i-a[${sidx - 1}]`)"
          />
        </li>
      </ol>
      <div
        class="ds-item__label--normal term-of-use-sub-li"
        data-char="9"
      >{{ $t("terms.of.use.pe.content.i.b.a") }}
        <a
          href=""
          @click.prevent="sendEmail()">{{ $t("terms.of.use.email") }}
        </a>
        {{ $t("terms.of.use.pe.content.i.b.b") }}
      </div>
      <div
        class="ds-item__label--normal term-of-use-sub-li"
        data-char="9"
      >{{ $t("terms.of.use.pe.content.i.c") }}
      </div>
    </div>
    <div class="ds-list">
      <div class="terms-of-use-second-header-text ds-m-t-s">
        {{ $t("terms.of.use.pe.subtitle.j") }}
      </div>
      <ol class="ds-list terms-of-use-sub-ol">
        <li
          v-for="sidx in 9"
          :key="'subprinciple' + sidx"
          class="term-of-use-sub-li"
          data-char="10"
        >
          <div
            v-if="sidx != 2"
            class="terms-of-use-second-header-li ds-item__label--normal"
            v-html="$t(`terms-of-use-pe-content-j[${sidx - 1}]`)"
          />
          <div v-if="sidx === 2" class="terms-of-use-second-header-li ds-item__label--normal">
            <span v-html="$t(`terms-of-use-pe-content-j[${sidx - 1}]`)"></span>
            <a
              href=""
              @click.prevent="sendEmail()"
            >{{ $t("terms.of.use.email") }}
            </a>
            {{ $t("terms.of.use.dot") }}
          </div>
        </li>
      </ol>
    </div>
  </div>
</template>

<style lang="less" scoped>

  .terms-of-use-forehead{
    height: 146px;
    background-image: radial-gradient(60.12% 60.12% at 74.24% 100%, #727272 0%, #222222 100%);
  }
  .terms-of-use-forehead-text{
    padding-top: 38px;
    padding-left: 90px;
    font-size: 27px;
    font-weight: 400;
    color: @white-color ;
  }
  .terms-of-use-container{
    &:extend(.font-normal);
    color: @color-gray-1;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
  }

  .terms-of-use-header-text{
    padding-top: 72px !important;
    &:extend(.font-expanded);
    font-size: 35px;
    line-height: 1.2;
    text-align: center;
    width: 100%;
    color: @color-gray-1;
  }
    .terms-of-use-second-header-li{
    list-style: none;
    margin-left: auto;
    margin-right: auto;
  }
  .terms-of-use-second-header-text{
    margin-top: 52px !important;
    margin-bottom: 24px !important;
    font-size: 21px;
    line-height: 1.5;
  }
.ds-list {
    color: @color-gray-1;
    text-align: left;
    margin-left: 90px;
    margin-right: 90px;
    padding-left: 0px;
    .ds-item__label--normal {
      &:extend(.font-normal);
      font: 16px;
    }
  }
.ds-item__label--sub-ol {
      &:extend(.font-normal);
      font: 16px;
}
.terms-of-use-sub-ol {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  list-style-type: None;
  counter-reset: item;
}

.term-of-use-sub-li {
  display: table;
  counter-increment: item;
  font-size: 16px;
  margin-bottom: 24px;

}
.term-of-use-sub-li::before {
  content: (attr(data-char))"."counters(item, "");
  display: table-cell;
  margin-right: 10px;
  width: 40px !important;
  &:extend(.font-normal);
}
</style>
