<script>

import { mapActions, mapState } from 'vuex';
import PFProfileResourceComponent from '@/components/common/PFProfileResourceComponent';
import PEProfileResourceComponent from '@/components/editor/PEProfileResourceComponent';
import PFSliderComponent from '@/components/common/PFSliderComponent';
import PFPopUpComponent from '@/components/common/PFPopUpComponent';

export default {
  name: 'PFProfileResourcesComponent',
  components: {
    PFSliderComponent, PFProfileResourceComponent, PEProfileResourceComponent, PFPopUpComponent,
  },
  props: {
    editMode: { type: Boolean, default: false },
    countryProfileId: { type: Number, default: 0 },
    countryName: { type: String, default: 'global' },
    countryText: { type: String, default: '' },
  },
  data: () => ({
    openEdit: false,
    currentResource: null,
    showMore: false,
    isPopupShown: false,
    resourceToRemove: null,
    showAllToggle: false,
  }),
  methods: {
    ...mapActions('partnerProfile', {
      addProfileResource: 'addProfileResource',
      updateProfileResource: 'updateProfileResource',
      removeProfileResource: 'removeProfileResource',
    }),
    changeVisibilityOfModal() {
      this.openEdit = !this.openEdit;
    },
    editResource(resource) {
      this.currentResource = resource;
      this.changeVisibilityOfModal();
    },
    saveResource(changedResource, initialResource) {
      const indexOfResource = this.getResources().indexOf(initialResource);
      if (indexOfResource !== -1) {
        this.updateProfileResource({
          resource: changedResource,
          countryName: this.countryName,
          index: indexOfResource,
        });
      } else {
        this.addProfileResource({
          resource: changedResource,
          countryName: this.countryName,
        });
      }
    },
    removeResource(resource) {
      this.isPopupShown = true;
      this.resourceToRemove = resource;
    },
    submitRemoveResource() {
      this.removeProfileResource({
        countryName: this.countryName,
        index: this.getResources().indexOf(this.resourceToRemove),
      });
      this.isPopupShown = false;
    },
    hideModal() {
      this.openEdit = false;
      this.currentResource = null;
    },
    getResources() {
      if (this.countryName === 'global') {
        if (this.profileData.resources) {
          return this.profileData.resources;
        }
      } else if (this.profileData.localProfiles.find((lProfile) => this.countryName === lProfile.country).resources) {
        return this.profileData.localProfiles.find((lProfile) => this.countryName === lProfile.country).resources;
      }
      return [];
    },
    getSliderData() {
      const resources = this.getResources();
      const result = [{
        firstResource: resources[0],
        secondResource: resources[1],
        thirdResource: resources[2],
      }];

      let x;
      if (resources.length > 3) {
        for (x = 1; x <= resources.length - 3; x++) {
          result.push(
            {
              firstResource: resources[x],
              secondResource: resources[x + 1],
              thirdResource: resources[x + 2],
            },
          );
        }
      }
      return result;
    },
    getSliderDataSmallScreen() {
      const resources = this.getResources();
      const result = [{
        firstResource: resources[0],
        secondResource: resources[1],
      }];

      let x;
      if (resources.length > 2) {
        for (x = 1; x <= resources.length - 2; x++) {
          result.push(
            {
              firstResource: resources[x],
              secondResource: resources[x + 1],
            },
          );
        }
      }
      return result;
    },
    getSliderDataXSScreen() {
      const resources = this.getResources();
      const result = [];

      let x;
      for (x = 0; x <= resources.length - 1; x++) {
        result.push(
          {
            firstResource: resources[x],
          },
        );
      }
      return result;
    },
    hasResources() {
      return this.getResources().length > 0;
    },
    hasLimitOfResources() {
      return this.hasResources() && this.getResources().length >= 10;
    },
    hasMoreItems() {
      return this.getResources() && (this.getResources().length - this.getLineItems() > 0);
    },
    getLineItems() {
      if (this.editMode) {
        return this.countryProfileId === 0 ? 3 : 2;
      }
      return this.countryProfileId === 0 ? 4 : 3;
    },
    getRowCount() {
      return this.countryProfileId === 0 ? 4 : 3;
    },
    hidePopup() {
      this.isPopupShown = false;
    },
    toggleShowMore(event) {
      this.showMore = !this.showMore;
      event.target.blur();
    },
    changeShowAllToggle() {
      this.showAllToggle = !this.showAllToggle;
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      profileData: (state) => state.profileData,
    }),
    moreResourceDisplay() {
      const vm = this;
      return (line) => {
        const resourcePerRow = vm.getRowCount();
        const remainResourceNum = vm.getResources().length - line * resourcePerRow + (vm.hasLimitOfResources() ? 0 : 1);
        return Math.min(resourcePerRow, remainResourceNum);
      };
    },
    globalAdditionalResourcesDisplay() {
      if (this.hasMoreItems() && this.showMore) {
        return this.getResources().length;
      }
      return Math.min(4, this.getResources().length);
    },
  },
  watch: {
    openEdit() {
      if (this.openEdit) {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      } else {
        document.body.style.overflow = 'scroll';
        document.body.style.paddingRight = '0px';
      }
    },
    isPopupShown(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      } else {
        document.body.style.overflow = 'scroll';
        document.body.style.paddingRight = '0px';
      }
    },
  },
};
</script>

<template>
  <div v-if="editMode" class="pf-resource">
    <div class="pf-resource-row ds-flexRow ds-m-none"
         :id="countryProfileId+'-pe-resource-row'"
         :class="countryProfileId===0?'pf-resource-global-row pf-resource-global-row-edit':'pf-resource-local-row'"
    >
      <PFProfileResourceComponent v-if="!hasResources() || !hasLimitOfResources()"
                                  class="pf-resource-item pf-resource-item-edit ds-p-none"
                                  :class="countryProfileId ===0?'pf-resource-global-item ds-flexCol ds-flexCol-xs-3':
                                  'pf-resource-local-item ds-flexCol ds-flexCol-xs-4'"
                                  :country-profile-id="countryProfileId"
                                  :add-new-item="true"
                                  :visibility-action="changeVisibilityOfModal"/>
      <PFProfileResourceComponent v-for="n in getResources().length"
                                  reorderAction="partnerProfile/reorderResourceses"
                                  class="pf-resource-item pf-resource-item-edit ds-p-none"
                                  :class="countryProfileId===0?'pf-resource-global-item ds-flexCol ds-flexCol-xs-3':
                                  'pf-resource-local-item ds-flexCol ds-flexCol-xs-4'"
                                  :is-slider-data="false"
                                  :country-profile-id="countryProfileId"
                                  :country-name="countryName"
                                  :resourceLength="getResources().length"
                                  :key="n - 1"
                                  :edit-mode="editMode"
                                  :edit-action="editResource"
                                  :data="getResources()[n - 1]"
                                  :remove-action="removeResource"/>
    </div>
    <PEProfileResourceComponent :is-shown="openEdit"
                                :save-action="saveResource"
                                :resource="currentResource"
                                :cancel-action="hideModal"/>

    <PFPopUpComponent
      class="pf-resource-confirm-delete-popup"
      :isShown="isPopupShown"
      :text="$t('editor.resource.delete.text')"
      :submitText="'Delete'"
      :cancelText="$t('helptext.partner.editor.discard.agreement.cancelButton')"
      :headerText="$t('editor.resource.delete.header.text')"
      :submitAction="submitRemoveResource"
      :cancelAction="hidePopup"
    />
  </div>
  <div v-else-if="hasResources() && countryProfileId !== 0" class="pf-resources-view ds-m-b-xxl">
    <PFSliderComponent :content="getSliderData()"
                       component-id="PFProfileSliderDataResourceComponent"
                       v-on:toggleCardHeight="changeShowAllToggle"
                       :showAllToggle="showAllToggle"
                       :countryName="countryName"
                       :countryText="countryText"
                       class="LargeScreenSlider"/>
    <PFSliderComponent :content="getSliderDataSmallScreen()"
                       component-id="PFProfileSliderDataResourceComponent"
                       v-on:toggleCardHeight="changeShowAllToggle"
                       :showAllToggle="showAllToggle"
                       :countryName="countryName"
                       :countryText="countryText"
                       class="SmallScreenSlider"/>
    <PFSliderComponent :content="getSliderDataXSScreen()"
                       component-id="PFProfileSliderDataResourceComponent"
                       v-on:toggleCardHeight="changeShowAllToggle"
                       :showAllToggle="showAllToggle"
                       :countryName="countryName"
                       :countryText="countryText"
                       class="XSScreenSlider"/>
  </div>
  <div v-else-if="hasResources() && countryProfileId === 0" class="pf-resource">
    <div class="ds-flexRow ds-m-none pf-resource-row" :class="hasMoreItems() ? 'ds-m-b-xl' : 'ds-m-b-xxl'">
      <PFProfileResourceComponent v-for="n in globalAdditionalResourcesDisplay"
                                  class="pf-resource-item pf-resource-global-item ds-flexCol ds-flexCol-xs-3"
                                  :class="showAllToggle ? 'global-resource-height-large' : ''"
                                  :is-slider-data="false"
                                  v-on:toggleCardHeight="changeShowAllToggle"
                                  :showAllToggle="showAllToggle"
                                  :key="n - 1"
                                  :edit-mode="editMode"
                                  :edit-action="editResource"
                                  :data="getResources()[n - 1]"
                                  :remove-action="removeResource"/>
    </div>
    <button
      v-if="hasMoreItems()"
      class="pf-resource-button-more-resources ds-button ds-button--secondary ds-m-b-xxl"
      @click="toggleShowMore">
      {{ showMore ? 'Less Resources' : 'More Resources' }}
    </button>
  </div>
</template>

<style lang="less">
.pf-resources-view {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pf-resource-button-more-resources {
  max-width: unset;
}

.pf-resource-global-item {
  width: 264px;
  height: 342px;
  margin-left: 16px;
  margin-right: 16px;
}
.global-resource-height-large {
  height: 460px;
  .pf-resource-item-title {
    font-size: 16px !important;
    line-height: 21px !important;
  }
}

.pf-resource-local-item {
  width: 264px;
  height: 251px;
  margin-left: 6px;
  margin-right: 6px;
  .pf-resource-item-description {
    height: 84px;
  }
}

.pf-resource-item:not(:empty):not(.pf-resource-item-add-new):not(.pf-resource-item-edit):hover {
  border: solid 1px #005178;
  box-shadow: 0 40px 40px 0 rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
  transition: transform .25s, visibility .25s ease-in;
}

.pf-resource {
  width: 100%;
  margin-top: 16px;
  text-align: center;
}

.pf-resource-row {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: initial;
}

.pf-resource-confirm-delete-popup {
  text-align: initial;
}

.pf-resource-local-row {
  max-width: 1078px;
  margin-right: auto !important;
  margin-left: auto !important;
  height: fit-content;
  .pf-resource-item-edit {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.pf-resource-item-edit, .pf-resource-item {
    min-width: 264px;
    max-width: 264px !important;
}

.pf-resource-item {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pf-resource-global-row {
  height: fit-content;
}
.pf-resource-global-row-edit {
  height: fit-content;
  .pf-resource-item-description {
    height: 105px;
  }
}
.pf-resource-global-item.pf-resource-item-edit, .pf-resource-global-item.pf-resource-item {
  margin-top: 17px;
  margin-bottom: 17px;
}
.pf-resource-global-row.pf-resource-global-row-edit {
  max-width: 1477px;
}
</style>
