<script>
const NOT_FOUND_VALUE = 'not found';
export default {
  name: 'PFPartnerLogoComponent',
  props: {
    logo: { type: String, default: '' },
    title: { type: String, default: NOT_FOUND_VALUE },
    isPreview: { type: Boolean, default: false },
    isContactMe: { type: Boolean, default: false },
    isSmallScreenMode: { type: Boolean, default: false },
  },
  data: () => ({
    defaultIconText: null,
    urlError: false,
  }),
  methods: {
    imgUrlAlt() {
      this.urlError = true;
    },
    extractDataFromTitle() {
      const myStr = this.title ? this.title : NOT_FOUND_VALUE;
      const matches = myStr.trim().replace(/\s\s+/g, ' ').split(' ');
      return matches.length > 1 ? matches
        .slice(0, 2)
        .map((letter) => letter.charAt(0).toUpperCase())
        .join('') : myStr.charAt(0);
    },
  },
  watch: {
    logo() {
      this.defaultIconText = null;
    },
  },
};
</script>

<template>
  <div class="ds-flexCol-Logo">
    <div :class="[isPreview ? 'ds-profile-logo-prev' : 'ds-profile-logo',
      isContactMe ? '' : 'header-logo-margin',
      isSmallScreenMode ? 'ds-profile-logo-small-screen' : '']">
      <img class="partner-logo-img" v-if="!urlError && logo" :src="logo" @error="imgUrlAlt" />
      <div class="search-partner-icon-text" v-else>{{ extractDataFromTitle() }}</div>
    </div>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.ds-flexCol-Logo {
  max-width: 130px !important;
  height: 100%;
  display: block;
  position:relative
}
.header-logo-margin {
  top: calc(50% - 80px/2);
  display: block;
  position:relative
}
.ds-profile-logo {
  width: 80px;
  height: 80px;
  background-color: @color-white;
  border: 1px solid @color-slight-gray;
  ::before {
    font-size: 21px;
  }

  div {
    &:extend(.font-bold-expanded);
    font-size: 27px;
    font-stretch: normal;
    font-style: normal;
    line-height: 56px;
    letter-spacing: normal;
    text-align: center;
    color: @color-gray-3;
    padding: 12px;
  }
}
.ds-profile-logo-small-screen {
  width: 70px;
  height: 70px;
}
.search-partner-icon-text {
  background-color: @color-slight-gray;
}

.ds-profile-logo-prev {
  width: 116px;
  height: 116px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(194, 194, 194, 0.76);

  div {
    &:extend(.font-bold-expanded);
    font-size: 27px;
    font-stretch: normal;
    font-style: normal;
    line-height: 56px;
    letter-spacing: normal;
    text-align: center;
    color: @color-gray-3;
    padding: 12px;
  }
}
</style>
