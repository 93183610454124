<script>
export default {
  name: 'PEErrorLostComponent',
  props: {
    errorMessages: { type: Array, default: () => [] },
  },
};
</script>
<template>
  <div class="pf-list-message--error">
    <div
      class="ds-message ds-message--error"
      v-for="errormessage in errorMessages"
      :key="errormessage"
    >
      <div class="ds-message__content">
        <i class="ds-icon--big ds-icon--close-outline ds-m-r-xs"></i>
        <span class="file-upload-box-error-text">{{$t(errormessage)}}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.pf-list-message--error {
  width: 100%;
  .ds-message--error {
    border: none;
    background-color: transparent;
    .ds-message__content {
      &:extend(.font-normal);
      .ds-icon--big::before{
        font-size: 24px !important;
      }
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .file-upload-box-error-text {
    text-align: start;
  }
}
</style>
