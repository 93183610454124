<script>
import { mapState } from 'vuex';
import PFPopUpComponent from '@/components/common/PFPopUpComponent';
import PFDropdownSingleComponent from '@/components/common/PFDropdownSingleComponent';
import PEOfficeGoogleAddressComponent
from '@/components/editor/PFGooglePlaceAutoCompleteComponent/PEOfficeGoogleAddressComponent';

const ID_KEY = 'country';
const VALUE_KEY = 'text';

export default {
  name: 'PEOfficeEditComponent',
  components: {
    PEOfficeGoogleAddressComponent,
    PFPopUpComponent,
    PFDropdownSingleComponent,
  },
  props: {
    addressModel: { type: Object },
    addressIdx: { type: Number, default: null },
    closeModal: { type: Function },
    countryCode: { type: String, default: '' },
    countryCodeText: { type: String, default: '' },
    multipleCountries: { type: Boolean, default: false },
    allowedCountries: { type: Array, default: () => [] },
  },
  data: () => ({
    address: {
      locationName: null,
      suite: null,
      addressLines: [],
      alternativeCountryName: null,
      city: null,
      countryCode: null,
      countryCodeText: null,
      email: null,
      fullAddress: null,
      id: null,
      latitude: null,
      longitude: null,
      phone: null,
      regionText: null,
      mainLocation: null,
    },
    hqCheckBox: false,
    isValidOfficeData: false,
    isValidEmail: true,
    emailTimer: null,
    focusFields: [],
    tooltipOpen: false,
    isPopupShown: false,
    hideEditPopup: false,
    idkey: ID_KEY,
    valueKey: VALUE_KEY,
  }),
  mounted() {
    this.address.countryCode = this.countryCode;
    this.address.countryCodeText = this.countryCodeText;
    Object.entries(this.addressModel).forEach(([key, value]) => {
      this.address[key] = value;
    });
    this.hqCheckBox = this.addressModel.mainLocation;
    document.body.style.overflow = 'hidden';
    window.setTimeout(() => this.$refs.officeEditModal.focus(), 0);
  },
  beforeUnmount() {
    document.body.style.overflow = 'scroll';
  },
  methods: {
    saveAddress() {
      let updateGoogleAutoValue = false;
      if (this.address.fullAddress !== this.query) {
        updateGoogleAutoValue = true;
      }
      let placeIdx = this.addressIdx;
      const newValue = {
        locationName: this.address.locationName,
        suite: this.address.suite,
        email: this.address.email,
        phone: this.address.phone,
        mainLocation: this.address.mainLocation,
      };
      if (this.allowedCountries.length > 1) {
        newValue.mainLocation = true;
        const targetLocalProfile = this.$store.state.partnerProfile.profileData.localProfiles
          .find((profile) => profile.country === this.address.countryCode);
        const targetHQAddress = this.$store.state.partnerProfile.profileData.hqAddress;
        placeIdx = null;
        if (this.addressModel.addressLines
          && targetLocalProfile && targetLocalProfile.addresses && targetLocalProfile.addresses.length > 0) {
          targetLocalProfile.addresses.forEach((address, idx) => {
            if (address.fullAddress === targetHQAddress.fullAddress
              && address.locationName === targetHQAddress.locationName
              && address.suite === targetHQAddress.suite) {
              placeIdx = idx;
            }
          });
        }
      }
      if (this.addressModel.mainLocation && !newValue.mainLocation) {
        this.$store.commit('locationAddressAutoComplete/removeHqByFullAddress');
      }
      this.$store.commit('locationAddressAutoComplete/updateOfficeAddress', {
        placeIdx, newValue, updateGoogleAutoValue, country: this.address.countryCode,
      });
      this.closeModal();
    },
    finishEditing(selectedAddress, addressChanged) {
      if (selectedAddress) {
        if (addressChanged) {
          this.$store.dispatch('locationAddressAutoComplete/findOfficeAddressPlaceDetails', selectedAddress.placeId);
        }
      }
    },
    validOfficeData(value) {
      this.isValidOfficeData = value;
    },
    emailValidator() {
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (!this.address.email || this.address.email.length === 0 || re.test(this.address.email)) {
        this.isValidEmail = true;
      } else {
        this.isValidEmail = false;
      }
    },
    updateEmail() {
      if (this.emailTimer) {
        clearTimeout(this.emailTimer);
      }
      this.emailTimer = setTimeout(() => this.emailValidator(), 1000);
    },
    addFocusField(val) {
      if (!this.focusFields.includes(val)) {
        this.focusFields.push(val);
      }
    },
    blurField(val) {
      if (this.focusFields.includes(val)) {
        this.focusFields.splice(this.focusFields.indexOf(val), 1);
      }
    },
    selectCurrentCountry(country) {
      this.address.countryCode = country.country;
      this.address.countryCodeText = country.text;
      if (this.query && ((this.placeDetailsResult && this.placeDetailsResult.countryCode !== country.country)
      || (this.placeDetailsResult == null && this.countryCode !== country.country))) {
        this.resetfields();
        this.$store.dispatch('locationAddressAutoComplete/clearQuery');
        this.$store.dispatch('locationAddressAutoComplete/clearResults');
      }
    },
    resetfields() {
      this.address.suite = null;
    },
    openPopup() {
      this.isPopupShown = true;
      this.hideEditPopup = true;
    },
    submitRemoveOffice() {
      this.$emit('removeOfficeFromChild');
      this.closeModal();
    },
    hidePopup() {
      this.isPopupShown = false;
      this.hideEditPopup = false;
      document.body.style.overflow = 'scroll';
    },
  },
  computed: {
    ...mapState('locationAddressAutoComplete', {
      query: (state) => state.query,
      loading: (state) => state.loading,
      placeDetailsResult: (state) => state.placeDetailsResult,
    }),
    ...mapState('partnerProfile', {
      mainLocation: (state) => state.profileData.hqAddress,
    }),
    enableSave() {
      return this.query && (this.address.fullAddress === this.query || this.isValidOfficeData)
        && this.isValidEmail && !this.loading;
    },
  },
  watch: {
    hqCheckBox(val) {
      this.address.mainLocation = val;
    },
    placeDetailsResult(val) {
      if (val && this.address.countryCode !== val.countryCode) {
        if (this.allowedCountries
          .filter((country) => country[this.idkey] === val.countryCode).length >= 1) {
          this.address.countryCodeText = val.country;
          this.address.countryCode = val.countryCode;
        } else {
          this.isValidOfficeData = false;
        }
      }
    },
  },
};
</script>
<template>
  <div class="address-edit-modal-container">
    <div class="ds-modal ds-modal--active" v-show="!hideEditPopup"
         ref="officeEditModal" tabindex="0" @keyup.esc="closeModal">
      <div class="modal-container">
      <div class="modal-container-inner-HQ">
      <div class="ds-modal__box address-edit-modal">
        <div class="ds-modal__box__header ds-p-y-none">
          <div class="ds-modal__box__header__heading office-edit-header">
            {{
             addressModel.addressLines ? $t("location.section.edit")
             : (allowedCountries.length > 1 ? $t("location.section.add.HQ") : $t("button.add"))
            }}
            {{
              $t("location.section.edit.location")
            }}
          </div>
        </div>
        <div class="ds-modal__box__content ds-m-x-m">
          <div class="ds-m-l-s office-edit-second-header ds-bot">
            {{$t('address.edit.location.address')}}
          </div>
          <PEOfficeGoogleAddressComponent
                                          :initialValue="address.fullAddress"
                                          :countryCode="address.countryCode"
                                          :placeholderMessage="$t('address.edit.address')"
                                          :isValidData="isValidOfficeData"
                                          @officeFinishEditing="finishEditing"
                                          @validOfficeData="validOfficeData"/>
          <div class="ds-flexRow office-edit-flexRow ds-m-none">
            <div class="ds-flexCol ds-flexCol-xs-6 ds-p-none office-left-input ds-p-x-s">
              <label
                class="ds-m-none ds-textField ds-textField--fixed location-suite-input-field"
              >
                <input
                  class="ds-textField__input ds-m-t-s"
                  type="text"
                  :placeholder="focusFields.includes('suite')
                          ? ''
                          :$t('location.section.suite.placeholder')"
                  v-model="address.suite"
                  @focus="addFocusField('suite')"
                  @blur="blurField('suite')"
                  id="address-edit-suite-text"
                />
                <span class="ds-textField__placeholder"
                  v-if="address.suite || focusFields.includes('suite')">
                  {{ $t("location.section.suite.label") }}
                </span>
              </label>
            </div>
            <div class="ds-flexCol ds-flexCol-xs-6 ds-p-none office-right-input ds-p-x-s">
              <PFDropdownSingleComponent
                    :allOptions="allowedCountries"
                    :idKey="idkey"
                    :valueKey="valueKey"
                    :disabled="allowedCountries.length === 0 || addressModel.fullAddress ? true : false"
                    :updateHQContrySelection="selectCurrentCountry"
                    :HQCountry="true"
                    :preselectedOption="countryCodeText"
                    :selectedHQCountry="address.countryCodeText"
              />
            </div>
          </div>
          <div class="ds-m-t-m ds-m-l-s office-edit-second-header">{{$t('address.edit.contact.details')}}</div>
          <div class="ds-flexRow office-edit-flexRow ds-m-none">
            <div class="ds-flexCol ds-flexCol-xs-6 ds-p-none office-left-input ds-p-x-s">
              <label
                class="ds-m-none ds-textField ds-textField--fixed location-phone-input-field"
              >
                <input
                  class="ds-textField__input ds-m-t-s"
                  type="text"
                  :placeholder="focusFields.includes('phone')
                          ? ''
                          :$t('contact.me.phone.placeholder')"
                  v-model="address.phone"
                  id="address-edit-phone-text"
                  @focus="addFocusField('phone')"
                  @blur="blurField('phone')"
                />
                <span class="ds-textField__placeholder"
                  v-if="address.phone || focusFields.includes('phone')">
                  {{ $t("contact.me.phone") }}
                </span>
              </label>
            </div>
            <div  class="ds-flexCol ds-flexCol-xs-6 ds-p-none office-right-input ds-p-x-s">
              <label
                class="ds-m-none ds-textField ds-textField--fixed location-email-input-field"
              >
                <input
                  class="ds-textField__input ds-m-t-s"
                  :class="!isValidEmail ? 'office-address-error-textField' : ''"
                  type="text"
                  @input="updateEmail"
                  @focus="addFocusField('email')"
                  @blur="emailValidator(); blurField('email')"
                  :placeholder="focusFields.includes('email')
                          ? ''
                          :$t('location.section.email.placeholder')"
                  v-model="address.email"
                  id="address-edit-email-text"
                />
                <span class="ds-textField__placeholder"
                  v-if="address.email || focusFields.includes('email')"
                :class="!isValidEmail ? 'address-error-color' : ''">
                  {{ $t("label.email") }}
                </span>
                <span class="ds-textField__message office-error-message" v-show="!isValidEmail">
                  <i class="ds-icon--close-outline ds-m-r-xs"></i>
                  {{$t("local.office.email.error.message")}}
                </span>
              </label>
            </div>
          </div>
          <div class="ds-m-t-l ds-m-l-s hq-check-box-container" v-if="multipleCountries">
            <label class="ds-checkbox"
                for="set-hq-checkbox">
                <input
                  class="ds-checkbox__control"
                  type="checkbox"
                  id="set-hq-checkbox"
                  v-model="hqCheckBox"
                />
                <span class="ds-checkbox__box">
                  <i class="ds-icon ds-icon--checked"></i>
                </span>
                <span class="ds-checkbox__label office-set-hq-checkbox-label">
                  {{ mainLocation.fullAddress && !this.addressModel.mainLocation ?
                    $t('location.section.replace.hq') : $t('location.section.set.hq')}}
                </span>
            </label>
            <div class="hq-help-container">
              <i class="ds-icon ds-icon--info hq-select-info-icon ds-m-l-xxs"
              :class="tooltipOpen ? 'hq-select-info-icon-hover' : ''"
              @click="tooltipOpen = true"
              v-click-outside="()=>{tooltipOpen = false}"></i>
              <div class="ds-tooltip-icon hq-check-tooltip-icon">
                <div :class="[tooltipOpen ? 'display-block' : 'display-none',
                'tooltiptext','ds-tooltip-icon__tooltip--HQ']">
                    <span>{{$t('hq.select.checkbox.helptext')}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ds-modal__box__action-bar ds-p-y-none ds-m-l-s">
          <button v-if="addressModel.addressLines" id="hq_discard_button" type="button"
          class="ds-button ds-button--tertiary hq-delete-button ds-p-none
          ds-p-y-xs ds-p-l-xs ds-p-r-m"
          @click="openPopup"> {{$t('button.delete')}} </button>
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary save-button ds-m-x-none"
            type="button"
            @click="saveAddress"
            :disabled="!enableSave"
            id="address-edit-button__done"
          >
            {{
             addressModel.addressLines ? $t("button.save")
             : $t("button.add")
            }}
          </button>
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--secondary ds-m-none ds-m-l-m"
            type="button"
            @click="closeModal"
            id="address-edit-button__cancel"
          >
            {{ $t("button.cancel") }}
          </button>
        </div>
      </div>
      </div>
      </div>
    </div>
    <PFPopUpComponent
      class="pf-HQ-confirm-delete-popup"
      :isShown="isPopupShown"
      :text="this.addressModel.mainLocation ? $t('main.office.delete.modal.text')
        : $t('local.office.delete.modal.text')"
      :submitText="$t('button.delete')"
      :cancelText="$t('helptext.partner.editor.discard.agreement.cancelButton')"
      :headerText="$t('local.office.delete.header.text')"
      :submitAction="submitRemoveOffice"
      :cancelAction="hidePopup"/>
  </div>
</template>
<style lang="less" scoped>
.address-edit-modal-container{
  .modal-container-inner-HQ {
    position: relative;
    max-width: 632px;
    margin-left: auto;
    margin-right: auto;
    top: calc(50% - 217px);
  }
  .ds-modal--active {
    z-index: 200;
  }
  .address-edit-modal {
    max-width: 632px;
    height: fit-content;
    min-height: 0;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
  }
  .office-edit-header {
    &:extend(.font-expanded);
    line-height: 35px;
    color: @color-black;
  }
  .office-edit-second-header {
    &:extend(.font-expanded);
    line-height: 21px;
    color: @color-black;
  }
  .location-address-line-3, .location-address-line-4 {
    display: flex;
  }
  .office-edit-flexRow {
    width: 100%;
  }
  .office-right-input, .office-left-input {
    min-width: 155px;
  }
  .office-address-error-textField {
    border-color: @color-field-error !important;
  }
  .office-error-message {
    color: @color-field-error;
  }
  .ds-bot {
    margin-bottom: 10px;
  }
  .address-error-color {
    color: @color-field-error !important;
  }
  .office-set-hq-checkbox-label {
    line-height: 24px;
  }
  .ds-select-box, .ds-select-box__wrapper__dropdown  {
    min-width: 0 !important;
  }
  .location-country-input-field {
    cursor: pointer;
  }
  .hq-check-box-container {
    display: flex;
  }
  .hq-select-info-icon {
    line-height: 24px;
    display: inline-block;
    cursor: pointer;
    color: @color-gray-4;
  }
  .hq-select-info-icon::before {
    font-size: 18px;
  }
  .hq-select-info-icon-hover {
    color: @color-gray-2;
  }
  .hq-check-tooltip-icon {
    margin-top: 6px;
    margin-left: 4px;
  }
  .hq-delete-button {
    color: @color-red-delete-button;
    cursor: pointer;
    background-color: transparent;
  }
  .hq-delete-button:hover {
    color: @color-editor-footer-error;
  }
  .open-dropdown-color {
    color: @color-blue-border;
  }
  .close-dropdown-color {
    color: @color-gray-1;
  }
}
</style>
