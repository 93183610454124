<script>
const urlLinks = require('@/plugins/linkUrl');

export default {
  name: 'PFHelpComponent',
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    if (this.$router.currentRoute.value.name === 'profileEditorPageEdit' || this.$router.currentRoute.value.name === 'profileEditorPagePreview') {
      this.faqLink = urlLinks.FOOTER.FAQ_PE;
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  props: {
    editorHeader: { type: Boolean, default: false },
  },
  components: {},
  data: () => ({
    dropdownEnabled: false,
    faqLink: urlLinks.FOOTER.FAQ_PF,
    partnerWithUs: urlLinks.PARTNER_WITH_US,
  }),
  methods: {
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
    showIndicator() {
      this.dropdownEnabled = !this.dropdownEnabled;
    },
    hideIndicator() {
      this.dropdownEnabled = false;
    },
    changeIconColor() {
      this.$refs.helpIcon.classList.remove(this.editorHeader ? 'pe-header-icon-color' : 'pf-header-icon-color');
      this.$refs.helpIcon.classList
        .add(this.editorHeader ? 'pe-header-icon-hover-color' : 'pf-header-icon-hover-color');
    },
    changeIconColorBack() {
      if (!this.dropdownEnabled) {
        this.$refs.helpIcon.classList
          .remove(this.editorHeader ? 'pe-header-icon-hover-color' : 'pf-header-icon-hover-color');
        this.$refs.helpIcon.classList.add(this.editorHeader ? 'pe-header-icon-color' : 'pf-header-icon-color');
      }
    },
    sendEmail() {
      const subject = encodeURIComponent(this.$tc('help.mail.subject'));
      const emailBody = encodeURIComponent(this.$tc('help.mail.body'));
      const email = encodeURIComponent(this.$tc('help.mail.contact.email'));
      this.openInNewTab(`mailto:${email}?subject=${subject}&display-name=SAP&body=${emailBody}`);
    },
    handleScroll() {
      if (document.getElementsByClassName('edit-header')[0]) {
        const headerBottom = document.getElementsByClassName('edit-header')[0].offsetHeight;
        if (!this.editorHeader && window.scrollY > headerBottom) {
          this.hideIndicator();
        } else if (this.editorHeader && window.scrollY < headerBottom) {
          this.hideIndicator();
        }
      }
    },
  },
  watch: {
    dropdownEnabled(val) {
      if (val) {
        this.$refs.rectangleIndicator.style.visibility = 'visible';
        this.changeIconColor();
      } else {
        this.$refs.rectangleIndicator.style.visibility = 'hidden';
        this.changeIconColorBack();
      }
    },
    $route: {
      handler(to) {
        if (to.name === 'profileEditorPageEdit' || to.name === 'profileEditorPagePreview') {
          this.faqLink = urlLinks.FOOTER.FAQ_PE;
        } else {
          this.faqLink = urlLinks.FOOTER.FAQ_PF;
        }
      },
      deep: true,
    },
  },

};
</script>

<template>
  <button class="ds-button ds-select-button help-dropdown-btn ds-m-none ds-m-r-l" @mouseover="changeIconColor"
          @mouseleave="changeIconColorBack" @click="showIndicator" v-click-outside="hideIndicator"
          tabindex="0" @keyup.esc="hideIndicator">
    <i class="ds-icon ds-icon--help" ref="helpIcon"
       :class="editorHeader ? 'pe-header-icon-color' : 'pf-header-icon-color'"/>
    <div class="rectangle helpIndicator" ref="rectangleIndicator"/>
    <div class="ds-select-button__dropdown" ref="helpDropdown" v-show="dropdownEnabled">
      <div class="ds-select-button__dropdown__inner help-dropdown">
        <div class="dropdown-option-get-help ds-m-t-m ds-m-b-s ds-p-x-m default-cursor">
          {{ $t('title.get.help') }}
        </div>
        <div
          class="ds-select-button__dropdown__inner__option ds-p-y-xs ds-p-x-m ds-m-b-xxs"
          @click="openInNewTab(faqLink)"
        >
          {{ $t('title.faq') }}
        </div>
        <div
          class="ds-select-button__dropdown__inner__option ds-p-y-xs ds-p-x-m ds-m-b-xxs"
          @click="openInNewTab(partnerWithUs)"
        >
          {{ $t('title.partner.with.us') }}
        </div>
        <div
          class="ds-select-button__dropdown__inner__option ds-p-y-xs ds-p-x-m ds-m-b-xs"
          @click="sendEmail()"
        >
          {{ $t('title.contact.us') }}
        </div>
      </div>
    </div>
  </button>
</template>

<style lang="less">
@import "../../assets/css/common.less";
.ds-select-button {
  display: block;
}

.ds-select-button__dropdown {
  min-width: 12px;
  z-index: 205;
  display: block;
}
.ds-select-button__dropdown__inner {
  background-color: @color-white;
  position: absolute;
}
.ds-select-button__dropdown__inner__option {
  margin: 0;
  padding: 0;
}
.rectangle {
  width: 28px;
  height: 5px;
  background-color: @light-orange;
  visibility: hidden;
}
.help-dropdown-btn,
.help-dropdown-btn:focus,
.help-dropdown-btn:hover,
.ds-select-button__dropdown {
  background-color: transparent;
}

.helpIndicator {
  position: absolute;
  bottom: 0px;
}

.help-dropdown-btn {
  text-align: center;
  padding: 0;
  border: none;
  .ds-icon--help {
    vertical-align: middle;
    &::before {
      font-size: 28px;
    }
  }
}
.help-dropdown-btn:hover {
  .rectangle {
    visibility: visible !important;
  }
}
.dropdown-option-get-help {
  &:extend(.font-expanded);
  font-size: 21px;
  line-height: 27px;
}
.help-dropdown {
  width: 319px;
  height: fit-content;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.21);
  right: -780%;
  margin-top: -1px;
  .ds-select-button__dropdown__inner__option {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}

.pf-header-icon-hover-color {
  color: @white-color !important;
}
.pf-header-icon-color {
  color: @color-grey-5 !important;
}

.pe-header-icon-hover-color {
  color: @color-gray-1 !important;
}
.pe-header-icon-color {
  color: @color-gray-3 !important;
}
</style>
