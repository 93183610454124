<script>
export default {
  name: 'PFLoadingIndicatorComponent',
  props: {
    active: {
      type: Boolean, default: false,
    },
    fullScreen: {
      type: Boolean, default: false,
    },
    methods: {
      selectOption(option) {
        this.actionFunction(option, this.filterType);
      },
    },
  },
};
</script>

<template>
  <div
    v-if="active"
    :class="[ fullScreen ? 'pf-fullscreen-component':'', 'ds-loading-indicator--container']">
    <i class="ds-loading-indicator ds-m-s"></i>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.ds-loading-indicator--container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 102;
  background: @color-white;

  .ds-loading-indicator {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
  }
}
.pf-fullscreen-component {
  position: fixed;
}
</style>
