<script>
/* eslint-disable no-undef */
import { mapState } from 'vuex';
import PFContentAreaComponent from '@/components/common/PFContentAreaComponent';

export default {
  name: 'PEPagePreviewComponent',
  components: {
    PFContentAreaComponent,
  },
  computed: {
    ...mapState('partnerProfile', {
      partnerProfileData: (state) => state.profileData,
    }),
  },
};
</script>

<template>
  <div>
    <PFContentAreaComponent :partnerProfileData="partnerProfileData" :isPreviewMode="true" />
  </div>
</template>
