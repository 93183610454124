<script setup>
import {
  ref, computed, watch,
} from 'vue';
import { useStore } from 'vuex';
import urlLinks from '@/plugins/linkUrl';

/**
 * Component to toggle competency with additional tooltip information.
 * Allows enabling or disabling the competency option based on the active state.
 *
 * @component
 * @props {String} title - Title for the toggle button.
 * @props {Boolean} modelValue - Boolean value to indicate if the toggle should be checked.
 * @emits update:modelValue - Emits the updated value when the toggle state changes.
 */
const { title } = defineProps({
  title: String,
  modelValue: Boolean,
});

const store = useStore();

const isBadgeConsistentWithUnavailableItems = computed(() => store.getters['solutionL2Store/isBadgeConsistentWithUnavailableItems']);

const isChecked = computed(() => store.state.profileSearch.selectedOptions.isCompetency.length > 0);
const isComptencyDisabled = computed(() => store.state.profileSearch.filterOptions.isCompetency.active.length === 0);
const isComptencyEnabled = computed(() => store.state.profileSearch.filterOptions.isCompetency.active.length > 0);
const tooltipOpen = ref(false);
const faqLinkSolution = ref(urlLinks.HELP_TEXT_LEARN_MORE.SAP_SOLUTION);

const toggleCompetencyButton = () => {
  const currentIsChecked = !isChecked.value;

  const option = { optionType: 'isCompetency', title: 'true' };
  if (currentIsChecked) {
    store.dispatch('profileSearch/addSelectedOption', { optionType: 'isCompetency', value: option });
  } else {
    store.dispatch('profileSearch/uncheckOption', option);
  }
};
const toggleTooltip = () => {
  tooltipOpen.value = false;
};
const openLearnMore = () => {
  const win = window.open(faqLinkSolution.value, '_blank');
  win.focus();
};
// TODO: remove after testing
// watch(isComptencyDisabled, (newValue) => {
//   if (newValue) {
//     isChecked.value = false;
//     store.dispatch('profileSearch/uncheckOption', { optionType: 'isCompetency', title: 'true' });
//   }
// });
// TODO: remove after testing
// watch(solutionBadge, (newValue) => {
//   console.log('solutionBadge', newValue);
//   console.log('solutionDataMap', solutionDataMap.value);
//   console.log('itemsWithSpecializationUnavailable', itemsWithSpecializationUnavailable.value);
//   console.log('isBadgeConsistentWithUnavailableItems', isBadgeConsistentWithUnavailableItems.value);
// }, { deep: true });

watch(isBadgeConsistentWithUnavailableItems, (newValue) => {
  if (newValue === true && isComptencyEnabled.value) {
    setTimeout(() => {
      store.dispatch('profileSearch/uncheckOption', { optionType: 'isCompetency', title: 'true' });
    }, 50);
  }
});

</script>

<template>

<div class="toggle-wrapper">
  <div class="ds-toggle-button">
      <label class="ds-toggle-button-label">
        <input class="ds-toggle-button__input" type="checkbox"
          :checked="isChecked" @change="toggleCompetencyButton" :disabled="!isComptencyEnabled"/>
        <div class="ds-toggle-button__text"
          :class="{'toggle-button-disabled__text' : !isComptencyEnabled}">{{ title }}</div>
        <div class="ds-toggle-button__toggle"></div>
      </label>
    </div>
    <div class="ds-tooltip-icon tool-tip-container" >
    <i class="ds-icon ds-icon--info" @click="tooltipOpen=!tooltipOpen" v-click-outside="toggleTooltip"
    aria-label="Toggle tooltip" :class="{'tool-tip-icon-clicked' : tooltipOpen}"></i>
    <div class='ds-tooltip-icon toolitp-icon-item-list'>
            <div
            class="tooltiptext ds-tooltip-icon__tooltip--service"
              :class="tooltipOpen ? 'tooltiptext--open' : 'display-none'">
              {{ !isComptencyEnabled ? $t('competency.cloud.not.available') : $t('competency.cloud.available') }}
              <span @click="openLearnMore" class="help-text-link">
                <b> {{$t('learn.more')}}</b>
              </span>
            </div>

          </div>
  </div>
</div>
</template>

<style lang="less" scoped>

@media (max-width: 1199px) {
  .toggle-wrapper{
    margin-left: 24px !important;
  }
}

.ds-tooltip-icon__tooltip--service {
  cursor: default;
  font-weight: 390 !important;
  width: 170px !important;
  max-width:170px !important;
    right: 18px;
    top: 43px !important;
  }
  &::after{
rotate: 90deg;
top: -10px !important;
right:10.5% !important}

.help-text-link,
.help-text-link b {
    color: @white-color !important;
}

.tooltiptext--open{
display: flex;
flex-direction: column;
}
.ds-tooltip-icon{
display: inline-block;
position: relative !important ;
height: 18px !important;
width: 18px !important;
color: @color-gray-4 ;
margin: 0 0 0 8px !important;
cursor: pointer;
}&:hover{
  color: @color-gray-2 ;
}
.tool-tip-icon-clicked{
  color: @color-gray-2 !important ;
}

.ds-icon--info{
  margin-top: 1px;
  height: 18px !important;
  width: 18px !important;
}
.ds-icon--info::before {
  width: 18px !important;
  height: 18px !important;
}
.ds-tooltip-icon .toolitp-icon-item-list{
  position: absolute !important;
  bottom: 11px;
}
.ds-toggle-button__toggle{
margin-left: 12px !important;
}
.ds-toggle-button__text{
color:  @color-grey-0 !important;
cursor: pointer;
}
.toggle-button-disabled__text {
  color: @color-gray-3 !important;
  cursor: default;
}
.toggle-wrapper{
display: flex;
align-self: center;
align-items: center;
padding: 14px 0;
margin-left: 32px;
}

</style>
