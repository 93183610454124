import i18n from '@/localization/i18n';

class FileValidator {
  serverPrefix = '/sap/details/api';

  serverSecurePrefix = '/sap/details/apis';

  uploadEndPoint = `${this.serverSecurePrefix}/media/storage/`;

  maxSizeLogo = 1024 * 1024;

  maxSizeThumbnail = 30 * 1024 * 1024;

  maxSizeVideo = 30 * 1024 * 1024;

  minThumbnailWidth = 1280;

  minThumbnailHeight = 720;

  minResolutionLogo = 120;

  bestVideoRatio = 0.5625;

  isBestVideoRatio = true;

  messages = {
    requestErrorMessageKey: 'editor.logo.upload.popup.form.validation.general',
    minResolutionMessage: 'editor.logo.upload.popup.form.validation.small.resolution',
    maxSizeMessage: 'editor.logo.upload.popup.form.validation.big.size',
    imageTypeMessage: 'editor.logo.upload.popup.form.validation.format.not.supported',
  }

  http;

  constructor(axios) {
    this.http = axios;
  }

  validateAndUploadIcons(file, profileId, csrfToken) {
    return this.validateandPushFile(file, profileId, csrfToken, FileValidator.validateIcon);
  }

  validateAndUploadVideo(file, profileId, csrfToken) {
    return this.validateandPushVideoFile(file, profileId, csrfToken);
  }

  validateAndUploadThumbnail(file, profileId, csrfToken) {
    return this.validateandPushFile(file, profileId, csrfToken, FileValidator.validateThumbnail);
  }

  validateandPushFile(file, profileId, csrfToken, validateFunction) {
    const image = new Image();
    const loadImage = () => new Promise((resolve, reject) => {
      image.onload = () => {
        const errorMessages = validateFunction(file, image, this);
        errorMessages.length > 0
          ? reject(errorMessages) : this.uploadAndHanlePromise(file, profileId, csrfToken, resolve, reject, this);
      };
      // eslint-disable-next-line prefer-promise-reject-errors
      image.onerror = () => reject(['MIME type of the image file is not allowed. Please check file and try again.']);
      image.src = URL.createObjectURL(file);
    });
    return loadImage();
  }

  validateandPushVideoFile(file, profileId, csrfToken) {
    const loadImage = () => new Promise((resolve, reject) => {
      const errorMessages = [];
      this.validateBestVideoRatio(file);
      FileValidator.pushIfFalse(FileValidator.isValidVideoFormat(file), this.messages.imageTypeMessage, errorMessages);
      FileValidator.pushIfFalse(this.isValidvideoSize(file), this.messages.maxSizeMessage, errorMessages);
      if (errorMessages.length > 0) {
        reject(errorMessages);
      } else {
        this.uploadAndHanlePromise(file, profileId, csrfToken, resolve, reject, this);
      }
    });
    return loadImage();
  }

  static validateIcon(file, image, that) {
    const errorMessages = [];
    FileValidator.pushIfFalse(that.isValidSize(file), that.messages.maxSizeMessage, errorMessages);
    FileValidator.pushIfFalse(that.isValidLogoResolution(image), that.messages.minResolutionMessage, errorMessages);
    FileValidator.pushIfFalse(FileValidator.isValidImageFormat(file), that.messages.imageTypeMessage, errorMessages);
    return errorMessages;
  }

  static validateThumbnail(file, image, that) {
    const errorMessages = [];
    FileValidator.pushIfFalse(that.isValidThumbnailSize(file), that.messages.maxSizeMessage, errorMessages);
    FileValidator.pushIfFalse(that.isValidThumbnailRes(image), that.messages.minResolutionMessage, errorMessages);
    FileValidator.pushIfFalse(FileValidator.isValidImageFormat(file), that.messages.imageTypeMessage, errorMessages);
    return errorMessages;
  }

  static pushIfFalse(condition, value, storage) {
    if (!condition) {
      storage.push(value);
    }
  }

  // eslint-disable-next-line no-unused-vars
  uploadAndHanlePromise(file, profileId, csrfToken, resolve, reject, that) {
    this.uploadFile(file, profileId, csrfToken)
      .then((response) => resolve(`${this.serverPrefix}/${response.data}`))
      .catch((err) => reject(err.response));
  }

  uploadFile(file, profileId, csrfToken) {
    const endPoint = this.uploadEndPoint + profileId;
    const headerContent = { headers: { 'Content-Type': 'multipart/form-data', 'x-csrf-token': csrfToken } };
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post(endPoint, formData, headerContent);
  }

  isValidSize(file) {
    return file.size < this.maxSizeLogo;
  }

  isValidThumbnailRes(image) {
    return !(image.width < this.minThumbnailWidth || image.height < this.minThumbnailHeight);
  }

  isValidLogoResolution(image) {
    return !(image.height < this.minResolutionLogo || image.height < this.minResolutionLogo);
  }

  static isValidImageFormat(file) {
    return /\.(jpg|png|bmp|jpeg)$/i.test(file.name);
  }

  static isValidVideoFormat(file) {
    return /\.(mp4|mov)$/i.test(file.name);
  }

  isValidThumbnailSize(file) {
    return file.size < this.maxSizeThumbnail;
  }

  isValidvideoSize(file) {
    return file.size < this.maxSizeVideo;
  }

  validateBestVideoRatio(file) {
    const url = URL.createObjectURL(file);
    let ratio = this.bestVideoRatio;
    const that = this;
    const $video = document.createElement('video');
    $video.src = url;
    $video.addEventListener('loadedmetadata', function () {
      ratio = this.videoHeight / this.videoWidth;
      that.isBestVideoRatio = ratio === that.bestVideoRatio;
    });
  }

  static handleResponseError(err) {
    if (!window.navigator.onLine) {
      return [i18n.tc('editor.footer.message.network.error')];
    } if (err.status === 429) {
      return [err.data.message];
    } if (err.status === 415) {
      return [err.data.message + i18n.tc('editor.footer.message.profile.fix.file')];
    } if (err.status === 503 || err.status === 404) {
      return [i18n.tc('editor.footer.message.profile.come.later')];
    } if (err.status) {
      return [i18n.tc('editor.footer.message.contact.admin') + i18n.tc('editor.footer.message.contact')
      + i18n.tc('editor.footer.message.contact.email') + i18n.tc('editor.footer.message.contact.b')];
    }
    return err;
  }
}

export default FileValidator;
