<script>
import { mapState } from 'vuex';

const sortOptions = require('@/plugins/search/sortOptions');

export default {
  name: 'PFSortBarComponent',
  props: {
    optionSelected: { type: [Array, Object], default: () => [] },
    actionFunction: { type: Function, default: () => true },
  },
  data: () => ({
    typesSort: sortOptions.SORT_OPTIONS,
    showDropdown: false,
    windowSize: window.innerWidth,
  }),
  methods: {
    changeSortType(key, value) {
      this.closeDropDown();
      this.$store.dispatch('profileSearch/setSelectedSortingOptionText', key);
      this.$store.dispatch('profileSearch/setSorting', value);
      this.$eventBus.$emit('PFAACustomEvent',
        { eventName: 'SearchInitiated', custom2: this.query });
    },
    showToggle() {
      if (!this.isMobile) {
        this.showDropdown = true;
      } else {
        this.$eventBus.$emit('ClickMobileSortBar');
      }
    },
    closeDropDown() {
      this.showDropdown = false;
    },
    changeSize(e) {
      this.windowSize = window.innerWidth;
    },
  },
  created() {
    window.addEventListener('resize', this.changeSize);
  },
  destroyed() {
    window.removeEventListener('resize', this.changeSize);
  },
  computed: {
    ...mapState('profileSearch', {
      selectedOption: (state) => state.selectedSortingOptionText,
      query: (state) => state.query,
    }),
    isMobile() {
      return this.windowSize < 580;
    },
    sortedOptionText() {
      return this.isMobile ? 'Alphabetical' : this.selectedOption;
    },
    iconClass() {
      return this.isMobile ? 'display-none' : (this.showDropdown ? 'ds-icon--arrow-up' : 'ds-icon--arrow-down');
    },
  },
};
</script>

<template>
  <div>
    <div class="ds-filter-sort__sort-bar__sort" @click="showToggle" v-click-outside="closeDropDown">
      <div class="ds-filter-sort__sort-bar__sort__label">Sort: </div>
      <div class="ds-filter-sort__sort-bar__sort__chosen-option">{{ selectedOption }}</div>
      <i id="icon" class="ds-icon ds-m-l-xs sort-dropdown-icon"
         :class="iconClass"></i>
      <div class="ds-filter-sort__sort-bar__sort__dropdown ds-p-none"
        :class="showDropdown ? 'display-block' : 'display-none'">
        <div v-for="(value, key) in typesSort" class="ds-p-r-xxs" :key="key" @click.stop="changeSortType(key, value)">
          <div class="ds-radio__label label-text ds-p-y-xs ds-p-x-s">{{ key }}</div>
        </div>
      </div>
    </div>
    <!-- <div class="ds-filter-sort__sort-bar__mobile-filter-toggle">
      <div class="ds-filter-sort__sort-bar__mobile-filter-toggle__label">
        Filters
      </div>
      <i class="ds-number-badge">{{ optionSelected.length }}</i>
      <i class="ds-filter-sort__sort-bar__mobile-filter-toggle__icon" @click="actionFunction"></i>
    </div> -->
  </div>
</template>

<style lang="less">
@media (min-width: 48em) {
  .ds-filter-sort__sort-bar {
    margin: 0;
  }
}

.ds-filter-sort__sort-bar__sort__label,.ds-filter-sort__sort-bar__sort__chosen-option {
  color: inherit !important;
  &:extend(.font-normal);
  margin-top: 3px;
  font-size: 16px !important;
  line-height: 24px !important;
}
.sort-dropdown-icon {
  margin-top: 5px;
}
.ds-filter-sort__sort-bar__sort {
  cursor: pointer;
}
.ds-filter-sort__sort-bar__sort__dropdown {
  .ds-radio__label {
    width: 100%;
    cursor: pointer;
  }
  .label-text {
    margin-top: 2px;
    margin-bottom: 2px;
  }
  .label-text:hover {
    background-color: @color-slight-gray;
  }
  .ds-radio__input {
    cursor: pointer;
  }
}
</style>
