<script>
const urlLinks = require('@/plugins/linkUrl');

const QPPS_CATEGORY = 'At_A_Glance_Detail_VPUC';
const QPPS_LEARN_MORE = 'QPPS_Learn_More';

export default {
  name: 'PFQPPSModalComponent',
  data: () => ({
    faqLink: urlLinks.LEARN_MORE_QPPS,
    VPUCLearnMore: urlLinks.LEARN_MORE_VPUC,
    showQPPSDetailModal: false,
    selectedQPPSDetail: {},
    QPPSCategory: QPPS_CATEGORY,
    QPPSLearnMore: QPPS_LEARN_MORE,
    QPPSContainerWidth: 200,
    QPPSImgWidth: '194.62px',
    QPPSImgHeight: 'auto',
    VPUCImgHeight: '46px',
    VPUCImgWidth: '177px',
    QPPSCountFontSize: '38px',
    QPPSCountLineHeight: '46px',
  }),
  props: {
    toggleModal: { type: Function, default: () => {} },
    QPPSCount: { type: Number, default: 0 },
    VPUCCount: { type: Number, default: 0 },
    openDetailsModal: { type: Function, default: () => {} },
    getQPPS: { type: Array, default: () => [] },
    getVPUC: { type: Array, default: () => [] },
  },
  computed: {
    QPPSContainerWidthStyle() {
      return `${this.QPPSContainerWidth}px`;
    },
    CountContainerWidthCurr() {
      return this.$refs?.countContainer?.clientWidth;
    },
  },
  methods: {
    openInNewTab(url) {
      const win = window.open(url, '_blank');
      win.focus();
    },
    isOverflown() {
      this.$nextTick(() => {
        if (
          this.$refs.modalProfileQPPSContent.scrollHeight
          > this.$refs.modalProfileQPPSContent.clientHeight
        ) {
          this.$refs.modalProfileQPPSHeader.style.borderBottom = '1px solid #eaeaea';
          this.$refs.modalProfileQPPSActionBar.style.borderTop = '1px solid #eaeaea';
        } else {
          this.$refs.modalProfileQPPSHeader.style.borderBottom = 'none';
          this.$refs.modalProfileQPPSActionBar.style.borderTop = 'none';
        }
      });
    },
    emitSWAEvent(QPPSCategory, QPPSName, QPPSCountry) {
      if (!this.editMode) {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$eventBus.$emit('PFAACustomEvent',
          {
            eventName: 'ClickModal', custom2: QPPSCategory, custom3: QPPSName, custom4: QPPSCountry,
          });
      }
    },
  },
  mounted() {
    if (this.$refs.countContainer.clientWidth < 2 * this.QPPSContainerWidth) {
      this.QPPSImgWidth = '135px';
      this.QPPSImgHeight = '32px';
      this.VPUCImgHeight = '32px';
      this.VPUCImgWidth = '123px';
      this.QPPSCountFontSize = '28px';
      this.QPPSCountLineHeight = '34px';
    }
    this.isOverflown();
    document.body.style.overflow = 'hidden';
    document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
    window.setTimeout(() => this.$refs.pfQPPSmodal.focus(), 0);
  },
  beforeUnmount() {
    document.body.style.paddingRight = '0px';
    document.body.style.overflow = 'scroll';
  },
};
</script>

<template>
  <div class="profile-QPPS-modal-container">
    <div class="ds-modal ds-modal--active ds-modal--scrollable"
         ref="pfQPPSmodal" tabindex="0" @keyup.esc="toggleModal(false)">
      <div class="modal-container">
      <div class="modal-container-inner-upload" id="profile-QPPS-modal">
      <div class="ds-modal__box profile-QPPS-modal">
        <div class="ds-modal__box__header ds-p-b-m" ref="modalProfileQPPSHeader">
          <span class="ds-modal__box__header__heading QPPS-modal-header">
            {{ this.$showVPUCFeature ?
              $tc('accelerate.pacakge.header', QPPSCount+VPUCCount) :  $tc('QPPS.modal.title', QPPSCount+VPUCCount)}}</span>
            <i
              class="ds-icon ds-icon--close modal-close-icon modal-icon ds-icon-profile-close"
              @click="toggleModal(false)"
            ></i>
        </div>
        <div class="ds-modal__box__content ds-m-none ds-m-l-l ds-m-r-s ds-m-t-m ds-m-b-m"
        ref="modalProfileQPPSContent">
          <div class="profile-QPPS-text margin-bottom-fixed">
            <div ref="countContainer" class="count-container">
              <div class="QPPS-count-container" v-if="QPPSCount > 0">
                <div class="profile-QPPS-count-text certer-text">{{QPPSCount}}</div>
                <div class="QPPS-img-container certer-text">
                  <img alt=""
                    class="QPPS-img" src="../../assets/SAP_Qualified_PartnerPackageSolution_R.png"
                    />
                </div>
              </div>
              <div class="VPUC-count-container" v-if="VPUCCount > 0">
                <div class="profile-QPPS-count-text certer-text">{{VPUCCount}}</div>
                <div class="QPPS-img-container certer-text">
                  <img alt=""
                    class="VPUC-img" src="../../assets/SAP_Validated_Partner_Use_Case_R.png"
                    />
                </div>
              </div>
            </div>
          </div>

          <div class="profile-QPPS-text profile-QPPS-table"  v-if="QPPSCount > 0">
            <div class="package-table-header"
            v-if="this.$showVPUCFeature">
            {{$tc('QPPS.modal.title', getQPPS.length)}}</div>
            <div class="profile-QPPS-text margin-bottom-fixed2">
              {{ $t("profile.QPPS.modal.text") }}
              <a class="learn-more-link"
                @click.prevent="openInNewTab(faqLink)">
                {{ $t("learn.more") }}
              </a>
            </div>

            <table class="ds-table ds-m-none">
              <tbody>
                <tr v-for="(country, idx) in getQPPS" :key="country.countryCode + '-' + idx">
                  <td v-if="country.QPPS.length > 0" class="col-name">{{country.countryName}}</td>
                  <td v-if="country.QPPS.length > 0" class="col-data">
                     <div class="profile-specialization-text" v-for="(product, idx) in country.QPPS"
                      :key="product.country + '-' + 'product' + '-' + idx"
                      @click="openDetailsModal(product);emitSWAEvent(QPPSCategory, product.packageName, product.country)">
                       &nbsp; &#8226;&nbsp;
                       <span v-html="$sanitize(product.packageName)"></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="VPUCCount > 0" class="profile-VPUC-text profile-VPUC-table ds-m-t-l">
            <div class="package-table-header">{{$tc('VPUC.table.header', getVPUC.length)}}</div>
            <div class="profile-QPPS-text margin-bottom-fixed2">
              {{ $t("VPUC.modal.description") }}
              <a class="learn-more-link"
                @click.prevent="openInNewTab(VPUCLearnMore)">
                {{ $t("learn.more") }}
              </a>

            </div>
            <table class="ds-table ds-m-none">
              <tbody>
                <tr v-for="(country, idx) in getVPUC" :key="country.countryCode + '-' + idx">
                  <td v-if="country.VPUC.length > 0" class="col-name">{{country.countryName}}</td>
                  <td v-if="country.VPUC.length > 0" class="col-data">
                     <div class="profile-specialization-text" v-for="(product, idx) in country.VPUC"
                      :key="product.country + '-' + 'product' + '-' + idx"
                      @click="openDetailsModal(product);emitSWAEvent(QPPSCategory, product.packageName, product.country)">
                       &nbsp; &#8226;&nbsp;
                       <span v-html="$sanitize(product.packageName)"></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="ds-modal__box__action-bar" ref="modalProfileQPPSActionBar">
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary ds-m-l-m"
            type="button"
            @click="toggleModal(false)"
            id="profile-competency-button__cancel"
          >
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
      </div>
    </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.count-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.package-table-header {
  &:extend(.font-bold);
  font-size: 16px;
  line-height: 24px;
}
.profile-project-header, .profile-project-number {
  color: @color-grey-0;
  &:extend(.font-bold);
  font-size: 16px;
  line-height: 24px;
}
.col-name {
  width: 25%;
  min-width: 130px;
}
.profile-project-number {
  text-align: right;
  width: fit-content !important;
}
.competency-flexRow {
  display: flex;
}
.learn-more-link {
  &:extend(.font-expanded);
  cursor: pointer;
}
.profile-project-text {
  color: @color-grey-0;
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 24px;
}
.VPUC-count-container, .QPPS-count-container  {
  width: v-bind('QPPSContainerWidthStyle');
}

.QPPS-count-container {
  margin-right: 20px;
}

.QPPS-img {
  width: v-bind('QPPSImgWidth');
  height: v-bind('QPPSImgHeight');
}
.VPUC-img {
  width: v-bind('VPUCImgWidth');
  height: v-bind('VPUCImgHeight');
}
.certer-text {
  text-align: center;
}
.QPPS-modal-header {
  color: @color-grey-0;
}
.col-name {
  &:extend(.font-normal);
  color: @color-grey-0;
}
.profile-QPPS-count-text {
  margin-top: 12px;
  margin-bottom: 6px;
  &:extend(.font-normal);
  font-size: v-bind('QPPSCountFontSize');
  line-height: v-bind('QPPSCountLineHeight');
  color: @color-consultants-count;
}
.flex-content-container {
  display: flex;
}
.profile-name-column {
  flex-grow: 5;
}
.profile-addr-column {
  margin-left: 4px;
  flex-grow: 4;
}
.profile-notification-button {
  margin-left: 52px;
  color: @color-link;
  &:extend(.font-expanded);
  cursor: pointer;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  .ds-icon::before {
    color: @color-grey-0;
    line-height: 80px;
    font-size: 24px;
  }
  .profile-notification-button-text {
    line-height: 80px;
    font-size: 16px;
  }
}
.profile-notification-more-text:hover,.profile-notification-button:hover {
  color: @color-link__hover;
}
.profile-notification-more-text {
  &:extend(.font-normal);
  color: @color-link;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  white-space: pre-wrap;
}

.margin-bottom-fixed {
  margin-bottom: 32px;
}

.margin-bottom-fixed2 {
  margin-bottom: 20px;
}
.profile-QPPS-text {
  &:extend(.font-normal);
  color: @color-grey-0 !important;
  font-size: 16px;
  line-height: 24px;
}

.profile-specialization-text {
  &:extend(.font-expanded);
  text-indent: -23px;
  font-size: 16px;
  line-height: 24px;
  color: @color-grey-0;
  cursor: pointer;
}
.profile-specialization-text:hover {
  color: @color-link;
}
.modal-icon {
  line-height: 200%;
  color: @color-gray-2;
}
.modal-icon:hover {
  color: @color-gray-1;
  cursor: pointer;
}
.ds-modal--active {
  z-index: 200;
}
.modal-container-inner-upload {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 341px);
}
.profile-QPPS-modal {
  max-width: 669px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  max-height: 760px;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}
.ds-modal__box__header__heading {
  padding-right: 40px !important;
  &:extend(.font-expanded);
  font-size: 27px;
  line-height: 35px;
}
.ds-icon-profile-close::before {
    font-size: 19px;
    font-weight: 800;
}
.ds-modal__box__content {
  padding-right: 40px !important;
  margin-right: 10px;
}
.ds-modal__box__content::-webkit-scrollbar-track {
  background: @color-slight-gray;
  border-radius: 20px;
}
.ds-modal__box__content::-webkit-scrollbar-thumb {
  background-color: @color-gray-4;
  border-radius: 20px;
}
.ds-table {
  width: 100%;
  tr:not(:last-child) td {
    border-bottom: 1px solid @color-grey-5;
  }
}

.QPPS-img-container {
  height: 47px;
}
</style>
