<script>
import { mapState } from 'vuex';

const PROFILE_INITIAL_PROGRESS = [
  {
    name: ['logoUrl'],
    complete: false,
    behavior: 'EMIT',
    info: 'STEP1_LOGO',
  },
  {
    name: ['videoImageUrl'],
    complete: false,
    behavior: 'SCROLL',
    info: 'pe-video-upload-container',
  },
  {
    name: ['resources'],
    complete: false,
    behavior: 'SCROLL',
    info: 'global-resource-section',
  },
  {
    name: ['tags'],
    complete: false,
    behavior: 'SCROLL',
    info: 'global-keyword-section',
  },
];
const INIT_PROGRESS = 60;

export default {
  name: 'PEProfileProgressComponent',
  data: () => ({
    profileProgress: PROFILE_INITIAL_PROGRESS,
    currentStep: 0,
    progressCompleted: 'INITIAL_STATE',
    progressPercentage: INIT_PROGRESS,
    showProgressModal: false,
    showCompletedProgressBar: 'INITIAL_STATE',
    openCompleteModalOnce: false,
    tooltipOpen: false,
  }),
  methods: {
    toggleTooltip() {
      this.tooltipOpen = false;
    },
    updateProfileProgress() {
      this.profileProgress.forEach((step) => {
        const initialValue = false;
        step.complete = step.name.reduce(
          (accumulator, currentValue) => accumulator || this.checkEmptyField(this.profileData[currentValue]),
          initialValue,
        );
      });
      this.updateCurrentStep();
      this.updateProgressPercentage();
      this.updateProgressCompleted();
    },
    checkEmptyField(field) {
      return (Array.isArray(field) && field.length > 0)
        || (typeof field === 'string' && field.length > 0);
    },
    updateCurrentStep() {
      this.currentStep = this.profileProgress.findIndex((step) => step.complete === false);
    },
    updateProgressPercentage() {
      const initialValue = INIT_PROGRESS;
      this.progressPercentage = this.profileProgress.reduce(
        (accumulator, currentValue) => accumulator + (currentValue.complete ? 10 : 0),
        initialValue,
      );
    },
    openComponent(step) {
      this.toggleModal(false);
      if (step.behavior === 'EMIT') {
        this.$eventBus.$emit(step.info);
      } else {
        this.scrollTo(step.info);
      }
    },
    scrollTo(positionId) {
      const sectionEle = document.getElementById(
        `${positionId}`,
      );
      const partnerDetailHeaderOffsetHeight = document.getElementById(
        'partner-detail-header',
      ).offsetHeight;
      const editHeaderContainerEl = document.getElementsByClassName(
        'edit-header-container',
      )[0];
      const { top } = sectionEle.getBoundingClientRect();
      let result = window.scrollY === 0
        ? top
            - (editHeaderContainerEl ? editHeaderContainerEl.offsetHeight : 0)
        : top + window.scrollY;
      result -= partnerDetailHeaderOffsetHeight;
      if (editHeaderContainerEl) {
        result -= editHeaderContainerEl.offsetHeight;
      }
      window.scrollTo({ left: 0, top: result, behavior: 'smooth' });
    },
    updateProgressCompleted() {
      const totalStep = this.profileProgress.length - 1;
      if ((this.currentStep === totalStep && this.profileProgress[totalStep].complete) || this.currentStep === -1) {
        this.progressCompleted = true;
      } else {
        this.progressCompleted = false;
      }
    },
    toggleModal(val) {
      this.showProgressModal = val;
    },
  },
  computed: {
    ...mapState('partnerProfile', {
      profileData: (state) => state.profileData,
      errorFields: (state) => state.errorFields,
      publishDate: (state) => state.profileData.publishDate,
      contactFormsCount: (state) => state.profileData.contactFormsCount,
    }),
    profilePublished() {
      if (this.publishDate) {
        return true;
      }
      return false;
    },
  },
  watch: {
    profileData: {
      handler() {
        this.updateProfileProgress();
      },
      deep: true,
    },
    showProgressModal() {
      const elem = document.getElementById('editor-footer-bar');
      if (this.showProgressModal === true) {
        elem.style.zIndex = 101;
        document.body.style.overflow = 'hidden';
        window.setTimeout(() => this.$refs.pfProgressModal.focus(), 0);
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      } else {
        elem.style.zIndex = 100;
        document.body.style.overflow = 'scroll';
        document.body.style.paddingRight = '0px';
      }
    },
    progressCompleted(newVal, oldVal) {
      if (oldVal !== 'INITIAL_STATE') {
        this.showCompletedProgressBar = newVal;
        if (!this.openCompleteModalOnce && newVal === true) {
          window.setTimeout(() => {
            this.toggleModal(true);
            this.openCompleteModalOnce = true;
          }, 500);
        }
      }
    },
  },
};
</script>

<template>
<div class="ds-m-l-xl progress-bar-container">
  <div class="progress-bar-incomplete"
  v-if="profilePublished && !progressCompleted && errorFields && errorFields.length === 0">
    <div class="progress-bar">
      <div class="progress-bar-text cursor-pointer ds-m-r-s" @click="toggleModal(true)">{{$t('progress.bar.label')}}</div>
      <progress id="file" :value="progressPercentage" max="100"></progress>
      <div class="progress-bar-val ds-m-l-s">{{progressPercentage}}% </div>
    </div>
    <div class="progress-step cursor-pointer" @click="openComponent(profileProgress[currentStep])">
        {{$t('progress.bar.next.step')}}
        {{ $t(`progress-bar-next-step-text[${currentStep}]`) }}
    </div>
  </div>
  <div v-else-if="profilePublished
    && showCompletedProgressBar === true
    && progressCompleted  === true
    && errorFields && errorFields.length === 0"
    class="progress-bar-completed">
    <div class="profile-complete-title">
      {{$t('profile.complete.title')}}
      <i class="ds-icon ds-icon--checked ds-m-l-xxs"></i>
    </div>
    <div class="profile-complete-title-text">
      {{$t('profile.complete.text')}}
    </div>
  </div>
  <div v-else-if="profilePublished
    && showCompletedProgressBar === 'INITIAL_STATE'
    && progressCompleted  === true
    && contactFormsCount && contactFormsCount > 0
    "
    class="progress-bar-contact-num">
    <div class="profile-complete-title profile-complete-analytic">
      <div class="profile-complete-analytic-title">{{$t('progress.bar.complete.analytic')}}</div>
      <div class="tool-tip-container" v-click-outside="toggleTooltip">
              <i class="ds-icon ds-icon--info tool-tip-icon ds-m-l-xxs"
                :class="tooltipOpen ? 'tool-tip-icon-clicked' : ''" @click="tooltipOpen=!tooltipOpen"></i>
            <div class='ds-tooltip-icon toolitp-icon-profile-progress'>
                <div
                  :class="[tooltipOpen ? 'display-block' :
                  'display-none','tooltiptext','ds-tooltip-icon__tooltip--service']">
                  {{$t('progress.bar.complete.help')}}
                </div>
              </div>
      </div>
    </div>
    <div class="profile-contact-text ds-m-l-m">
      <div class="contact-num">{{contactFormsCount}}</div>
      <div class="contact-text">{{$t('progress.bar.complete.contact')}}</div>
    </div>
  </div>
  <div v-else class="progress-bar progress-bar-placeholder">
      &nbsp;
  </div>
</div>
<div class="ds-modal profile-progress-modal--active"
    :class="showProgressModal ? 'ds-modal--active' : ''"
         ref="pfProgressModal" tabindex="0" @keyup.esc="toggleModal(false)">
      <div class="modal-container">
      <div class="modal-container-inner-upload" id="profile-progress-modal">
      <div class="ds-modal__box profile-progress-modal">
        <div class="ds-modal__box__header ds-p-b-m" ref="modalProfileProgressHeader">
          <span class="ds-modal__box__header__heading progress-modal-header">
            {{ progressCompleted ? $t('progress.modal.completed.title') : $t('progress.modal.title') }}</span>
            <i
              class="ds-icon ds-icon--close modal-close-icon modal-icon ds-icon-profile-close"
              @click="toggleModal(false)"
            ></i>
        </div>
        <div class="ds-modal__box__content ds-m-none ds-m-l-l ds-m-r-s ds-m-y-none ds-m-t-xs"
        ref="modalProfileProgressContent">
          <div v-if="progressCompleted" class="profile-completed">
            <div class="profile-complete-img-container ds-m-b-m">
                  <img
                    src="@/assets/profile-complete-screen.svg"
                    alt="triangle with all three sides equal"
                    height="160"
                    width="160"
                  />
            </div>
            <div class="profile-complete-text ds-m-b-xs">{{$t('progress.modal.completed.text')}}</div>
          </div>
          <div v-else class="profile-in-progress">
            <div class="profile-progress-guidance-text ds-m-b-m">
              {{$t('progress.modal.text')}}</div>
            <div class="next-step-title ds-m-b-xs">{{$tc('progress.modal.next', progressPercentage == 90 ? 1 : 2)}}</div>
            <div class="next-step-text-container" v-for="(step,idx) in profileProgress"
              :key="step.info + idx">
               <div v-if="!step.complete" @click="openComponent(step)" class="next-step-text ds-m-b-xs"> &nbsp; &#8226; &nbsp;
                    {{ $t(`progress-bar-next-step-text[${idx}]`) }}</div>
            </div>

            <div class="complete-step-title ds-m-t-l ds-m-b-xs">{{$t('progress.modal.completed')}}</div>
            <div v-if="errorFields && errorFields.length === 0" class="ds-m-b-xs complete-step-text-container">
                &nbsp;
              <img
                    src="@/assets/bullet-checked.png"
                    alt="triangle with all three sides equal"
                    height="32"
                    width="32"
              />
              <div class="complete-step-text">
                {{$t('progress.modal.mantatory.filled')}}
              </div>
            </div>
            <div v-for="(step,idx) in profileProgress"
              :key="step.info + idx">
               <div v-if="step.complete" class="ds-m-b-xs complete-step-text-container">
                  &nbsp;
                 <img
                    src="@/assets/bullet-checked.png"
                    alt="triangle with all three sides equal"
                    height="32"
                    width="32"
                  />
                 <div class="complete-step-text">
                    {{ $t(`progress-bar-next-step-text[${idx}]`) }}</div></div>
            </div>
          </div>

        </div>
        <div class="ds-modal__box__action-bar ds-p-t-m" ref="modalProfileProgressActionBar">
          <button
            class="ds-modal__box__action-bar__action ds-button ds-button--primary ds-m-l-m"
            type="button"
            @click="toggleModal(false)"
            id="profile-progress-button__cancel"
          >
            {{ $t("button.close") }}
          </button>
        </div>
      </div>
      </div>
    </div>
    </div>
</template>

<style scoped lang="less">
.profile-complete-analytic, .progress-bar-contact-num {
  display: flex;
  height: 100%;
  cursor: default;
}
.profile-complete-analytic {
  margin-top: 9px;
}
.tool-tip-container {
  height: 21px;
}
.tool-tip-icon::before {
  font-size: 16px;
  line-height: 21px;
}
.toolitp-icon-profile-progress {
  left: 20px;
  bottom: 0;
}
.profile-complete-analytic-title, .tool-tip-container {
  position: relative;
}
.contact-num {
  &:extend(.font-bold);
  width: 100%;
  text-align: center;
  color: @color-grey-0;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.contact-text {
  &:extend(.font-expanded);
  color: @color-gray-2;
  width: 100%;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
.profile-progress-modal--active {
  z-index: 201 !important;
}
.profile-complete-title {
  &:extend(.font-bold);
  color: @color-grey-0;
  font-size: 14px;
  line-height: 21px;
  cursor: default;
}
.profile-complete-title-text {
  &:extend(.font-expanded);
  color: @color-grey-0;
  font-size: 14px;
  line-height: 21px;
  cursor: default;
}
.ds-icon--checked::before {
  color: @color-link;
}
.progress-bar-container {
  width: 365px;
  margin-top: 11px;
}
.progress-bar {
  display: flex;
  align-items: center;
  .progress-bar-text {
    &:extend(.font-bold);
    color: @color-link;
    font-size: 14px;
    line-height: 21px;
  }
  .progress-bar-text:hover {
    color: @color-link__hover;
  }
}
.progress-bar-val, .progress-step {
    &:extend(.font-expanded);
    color: @color-grey-0;
    font-size: 14px;
    line-height: 21px;
    cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.progress-step:hover {
    color: @color-link__hover;
}
progress {
    width: 150px;
    height: 10px;
    border: none;
    background: @color-gray-4;
    border-radius: 0.25rem;
}
progress::-webkit-progress-bar {
    border: none;
    background: @color-gray-4;
    border-radius: 0.25rem;
}
progress::-webkit-progress-value {
    border: none;
    background: @color-link;
    border-radius: 0.25rem;
}
progress::-moz-progress-bar {
    border: none;
    background: @color-link;
    border-radius: 0.25rem;
}
.modal-container-inner-upload {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 250px);
}
.profile-progress-modal {
  max-width: 564px;
  height: fit-content;
  margin-left: auto;
  margin-right: auto;
  min-height: 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}
.ds-modal__box__header__heading {
  &:extend(.font-expanded);
  font-size: 21px;
  line-height: 27px;
  color: @color-grey-0;
}
.ds-icon-profile-close::before {
    font-size: 19px;
    font-weight: 800;
    color: @color-gray-2;
    cursor: pointer;
}
.ds-icon-profile-close:hover::before {
  color: @color-grey-0;
}
.profile-complete-img-container {
  width: 160px;
  margin-left: auto;
  margin-right: auto;
}
.profile-complete-text, .profile-progress-guidance-text {
    &:extend(.font-normal);
    color: @color-grey-0;
    font-size: 16px;
    line-height: 24px;
    cursor: default;
}
.next-step-title, .complete-step-title {
    letter-spacing: 1px;
    &:extend(.font-bold);
    color: @color-gray-3;
    font-size: 12px;
    line-height: 18px;
    cursor: default;
}
.next-step-text {
    &:extend(.font-normal);
    color: @color-link;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;;
}
.next-step-text:hover {
  color: @color-link__hover;
}
.complete-step-text-container {
  display: flex;
}
.complete-step-text {
  &:extend(.font-normal);
    color: @color-grey-0;
    font-size: 16px;
    line-height: 24px;
    cursor: default;
    margin-left: 10px;
    display: inline;
    height: fit-content;
    margin-top: 4px;
}
</style>
