<script>
import { mapState } from 'vuex';
import MobileDrag from '@/plugins/mobileDrag';
import PFDropdownMultiComponent from '@/components/common/PFDropdownMultiComponent';

export default {
  name: 'PEEditableListComponent',
  components: {
    PFDropdownMultiComponent,
  },
  props: {
    idKey: {
      type: String,
    },
    textKey: {
      type: String,
    },
    listHeading: {
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    allItems: {
      type: Array,
      default: () => [],
    },
    selectAction: {
      type: String,
      default: '',
    },
    reorderAction: {
      type: String,
      default: '',
    },
    removeAction: {
      type: String,
      default: '',
    },
    helpText: {
      type: String,
      default: '',
    },
    tooltipText: {
      type: String,
      default: '',
    },
    localProfileId: {
      type: Number,
      default: null,
    },
    locationSection: {
      type: Boolean,
      default: false,
    },
    countryName: {
      type: String,
      default: 'global',
    },
    mandatoryCheck: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    addMode: false,
    emptyList: false,
    tooltipOpen: false,
    isDragging: false,
    draggedYCoordinate: 0,
    originalY: '',
    originalWidth: '',
    activeEvent: '',
    preEle: null,
    cloneEle: null,
  }),
  methods: {
    removeItem(industryId) {
      const payload = this.countryName !== 'global'
        ? { industryId, countryName: this.countryName }
        : industryId;
      this.$store.dispatch(this.removeAction, payload);
    },
    onDrop(event) {
      if (this.isDragging) {
        event.preventDefault();
        const draggedElementId = event.dataTransfer.getData('text');
        const targetElementId = this.getTargetElementId(event);
        if (targetElementId !== draggedElementId) {
          const payload = this.buildReorderPayload(
            draggedElementId,
            targetElementId,
          );
          this.$store.dispatch(this.reorderAction, payload);
        }
        this.isDragging = false;
      }
      this.removeBorder(event);
    },
    getTargetElementId(event) {
      const parentId = event.target.classList.contains('ds-icon--clear')
        ? event.target.parentElement.parentElement.id
        : event.target.parentElement.id;
      return event.target.classList.contains('ds-list__item')
        ? event.target.id
        : parentId;
    },
    buildReorderPayload(draggedElementId, targetElementId) {
      return this.countryName !== 'global'
        ? {
          source: draggedElementId,
          target: targetElementId,
          id: this.countryName,
        }
        : {
          source: draggedElementId,
          target: targetElementId,
        };
    },
    onDragStart(event) {
      this.isDragging = true;
      this.draggedYCoordinate = this.getYCoordinate(event.currentTarget);
      event.dataTransfer.setData('text', event.target.id);
    },
    onDragOver(event) {
      event.preventDefault();
      this.draggedYCoordinate > this.getYCoordinate(event.currentTarget)
        ? event.currentTarget.classList.add('reorder-border-top')
        : event.currentTarget.classList.add('reorder-border-bottom');
    },
    onDragLeave(event) {
      this.removeBorder(event);
    },
    onDragEnd(event) {
      event.currentTarget.classList.remove('reorder-border-top');
    },
    handleTouchStart(e) {
      if (e.target.id === 'mobile-trigger-touch') {
        e.preventDefault();
        this.originalWidth = `${e.currentTarget.clientWidth}px`;
        this.originalY = MobileDrag.getOffset(e.currentTarget).top;
        this.activeEvent = 'start';
      }
    },
    handleTouchMove(e) {
      if (e.target.id === 'mobile-trigger-touch') {
        e.preventDefault();
        const listArea = document.getElementById(`editable-list-${this.listHeading}`);
        if (!this.cloneEle) {
          this.cloneEle = MobileDrag.cloneDragItem(e.currentTarget, this.originalWidth);
          listArea.appendChild(this.cloneEle);
        }
        const dragIconSize = 10.5;
        const touchLocation = e.targetTouches[0];
        const pageX = `${touchLocation.pageX - MobileDrag.getOffset(listArea).left - dragIconSize}px`;
        const pageY = `${touchLocation.pageY - MobileDrag.getOffset(listArea).top - dragIconSize}px`;
        this.cloneEle.style.left = pageX;
        this.cloneEle.style.top = pageY;
        this.activeEvent = 'move';
        const validIds = [];

        this.items.forEach((item) => {
          if (item[this.idKey] !== `${e.currentTarget.id}-clone`) {
            validIds.push(item ? item[this.idKey] : 0);
          }
        });
        const currentTargetEle = MobileDrag
          .filterOutEle(this.cloneEle, validIds, e.currentTarget.clientWidth);
        if (currentTargetEle && (!this.preEle || currentTargetEle.id !== this.preEle.id)) {
          MobileDrag.removeElementBorder(this.originalY, this.preEle);
          MobileDrag.addElementBorder(this.originalY, currentTargetEle);
          this.preEle = currentTargetEle;
        }
      }
    },
    handleTouchEnd(e) {
      if (e.target.id === 'mobile-trigger-touch') {
        e.preventDefault();
        if (this.activeEvent === 'move' && this.preEle && this.cloneEle) {
          const draggedElementId = e.currentTarget.id;
          const targetElementId = this.preEle.id;
          if (targetElementId !== draggedElementId) {
            const payload = this.buildReorderPayload(
              draggedElementId,
              targetElementId,
            );
            this.$store.dispatch(this.reorderAction, payload);
          }
        }
        if (this.preEle) {
          MobileDrag.removeElementBorder(this.originalY, this.preEle);
        }
        if (this.cloneEle) {
          document.getElementById(`editable-list-${this.listHeading}`).removeChild(this.cloneEle);
        }
        this.cloneEle = null;
        this.preEle = null;
        this.activeEvent = '';
      }
    },
    removeBorder(event) {
      this.draggedYCoordinate > this.getYCoordinate(event.currentTarget)
        ? event.currentTarget.classList.remove('reorder-border-top')
        : event.currentTarget.classList.remove('reorder-border-bottom');
    },
    toggleAdd() {
      this.addMode = !this.addMode;
    },
    emptyListCheck() {
      this.emptyList = !this.items || (this.items && this.items.length === 0);
    },
    generateValidationError() {
      return {
        id: this.generateErrorId(),
        field: this.listHeading,
        message: `${this.$t('mandatory.field.error.message')}.`,
      };
    },
    generateErrorId() {
      return `${this.countryName}${this.listHeading.replace(/ +/g, '')}MandatoryErr`;
    },
    handleEditableListError(val) {
      if (this.mandatoryCheck) {
        val
          ? this.$store.dispatch('partnerProfile/addErrorField', this.generateValidationError())
          : this.$store.dispatch('partnerProfile/removeErrorField', this.generateErrorId());
      }
    },
    getYCoordinate(el) {
      const rect = el.getBoundingClientRect();
      return rect.top + window.scrollY;
    },
    toggleTooltip() {
      this.tooltipOpen = false;
    },
  },
  watch: {
    items: {
      handler(val) {
        if ((this.mandatoryCheck && val && val.length > 0)
          || this.mountedComponent.includes(this.listHeading + this.localProfileId)) {
          this.emptyListCheck();
        }
        if (!this.mountedComponent.includes(this.listHeading + this.localProfileId)) {
          this.$store.dispatch(
            'partnerProfile/addMountedComponent',
            this.listHeading + this.localProfileId,
          );
        }
      },
      deep: true,
    },
    emptyList(val) {
      this.handleEditableListError(val);
    },
  },
  mounted() {
    if (this.mandatoryCheck && this.mountedComponent.includes(this.listHeading + this.localProfileId)) {
      this.emptyListCheck();
    }
    this.$eventBus.$on('*', (type) => {
      if (['footerSaveClicked', 'footerPublishClicked', 'editorToggleButtonClicked'].includes(type) && this.mandatoryCheck) {
        this.emptyListCheck();
      }
    });
    this.$eventBus.$on('globalSupportedLanguagesMandatoryErr', () => {
      if (this.listHeading === 'Supported Languages' && this.countryName === 'global') {
        this.emptyList = true;
      }
    });
  },
  computed: {
    addButtonText() {
      return this.listHeading.split(' ').slice(-1)[0];
    },
    ...mapState('partnerProfile', {
      mountedComponent: (state) => state.mountedComponent,
    }),
  },
};
</script>

<template>
  <div class="services-items-list-container">
    <div class="list-heading-container">
      <h3
        :class="[
          locationSection
            ? 'ds-m-b-xs location-header-size'
            : 'ds-m-b-s service-header-size',
          mandatoryCheck && emptyList ? 'field-error__message' : '',
        ]"
        class="ds-heading--s item-list--heading ds-m-t-s default-cursor"
      >
        <i v-if="mandatoryCheck && emptyList" class="ds-icon--close-outline ds-m-r-xs"></i>
        <div class="editable-list-heading">
          {{ listHeading }}
          <div  v-if="tooltipText.length > 0" class="tool-tip-container" v-click-outside="toggleTooltip">
              <i class="ds-icon ds-icon--info tool-tip-icon ds-m-r-xs"
                :class="tooltipOpen ? 'tool-tip-icon-clicked' : ''" @click="tooltipOpen=!tooltipOpen"></i>
            <div class='ds-tooltip-icon toolitp-icon-item-list'>
                <div
                  :class="[tooltipOpen ? 'display-block' :
                  'display-none','tooltiptext','ds-tooltip-icon__tooltip--service']" v-html="tooltipText">
                </div>
              </div>
            </div>
        </div>
      </h3>
      <div class="list-error-message">
        <div
          v-if="mandatoryCheck && emptyList"
          :class="locationSection ? 'location-list-item-size' : ''"
        >
          {{ $t("mandatory.field.error.message") }}
        </div>
        <div
          v-if="!this.items || (this.items && this.items.length === 0)"
          :class="[
            mandatoryCheck && emptyList ? 'color-error' : 'color-grey',
            locationSection ? 'location-list-item-size' : '',
            'ds-m-t-s',
          ]"
          v-html="helpText"
        >
        </div>
      </div>
    </div>
    <ul
      class="ds-list items-list ds-m-y-none"
      :id="'editable-list-'+listHeading"
      v-show="items"
      :class="
        locationSection
          ? 'location-item-size item-list-line-height-local'
          : 'service-item-size item-list-line-height-global'
      "
    >
      <li
        :draggable="true"
        @drop="onDrop"
        @dragover="onDragOver"
        @dragstart="onDragStart"
        @dragleave="onDragLeave"
        @dragend="onDragEnd"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
        class="ds-list__item ds-p-b-xxs ds-p-t-xxs"
        :id="item ? item[idKey] : 0"
        v-for="item in items"
        :key="item ? item[idKey] : 0"
      >
      <i class="ds-icon ds-icon--burger ds-p-r-xs" id="mobile-trigger-touch"></i>
        <span class="ds-p-l-none">
          {{ item ? item[textKey] : "" }}
        <i
          @click="removeItem(item[idKey])"
          class="ds-icon ds-icon--clear"
        ></i>
        </span>
      </li>
      <!-- <li v-show="items && items.length === 0">&mdash;</li> -->
    </ul>

    <a
      v-show="!this.addMode && this.items && this.items.length > 0"
      class="ds-m-y-s add-item-link ds-p-t-xxs"
      :class="locationSection ? 'location-item-size' : 'service-item-size'"
      @click.prevent="toggleAdd"
      >+ Add {{ addButtonText }}</a
    >

    <button
      v-show="
        !this.addMode &&
        (!this.items || (this.items && this.items.length === 0))
      "
      class="ds-button ds-button--primary ds-m-t-m list-add-button"
      :class="locationSection ? 'location-list-item-size' : ''"
      type="button"
      @click.prevent="toggleAdd"
    >
      Add {{ addButtonText }}
    </button>

    <PFDropdownMultiComponent
      :idKey="idKey"
      :textKey="textKey"
      :preSelectedOptions="items"
      :allOptions="allItems"
      :placeholderText="'Add ' + addButtonText"
      :selectAction="selectAction"
      :removeAction="removeAction"
      v-on:closeDropdown="toggleAdd"
      v-show="addMode"
      :countryName="countryName"
    />
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.services-items-list-container {
  .tool-tip-container {
    display: inline-block;
    position: relative;
    .tool-tip-icon::before {
      font-size: 18px !important;
      line-height: 27px !important;
      cursor: pointer;
    }
  }
  .tool-tip-icon:hover::before {
    color: @color-gray-3 !important;
  }
  & .field-error__message {
    &:extend(.font-expanded);
    margin-top: 2px;
    // font-size: 14px;
    color: @color-editor-footer-error !important;
    display: block;
  }

  h3.item-list--heading {
    margin-bottom: 0;
    color: @color-gray-1;
  }
  .editable-list-heading {
    display: inline-block;
    position: relative;
    line-height: 27px;
    width: fit-content;
  }
  .location-list-item-size {
    font-size: 14px;
    line-height: 21px;
  }
  .service-list-item-size {
    font-size: 16px;
    line-height: 24px;
  }

  .list-error-message {
    font-size: 16px;
    line-height: 24px;
    color: @color-editor-footer-error;
    &:extend(.font-normal);
    .color-error {
      color: @color-editor-footer-error;
    }
    .color-grey {
      color: @color-gray-2;
    }
  }

  .list-add-button {
    width: auto;
    max-width: none;
  }

  .ds-tooltip-icon {
    position: absolute !important;
    z-index: 3;

    a {
      color: #ffffff;
      font-weight: bold;
    }
  }

  .items-list {
    position: relative;
    list-style: none;
    padding-left: 0;
    li {
      display: flex;
      span {
        text-align: left;
        &:extend(.font-normal);
      }
    }
  }

  .ds-icon {
    &::before {
      color: @color-icon-before;
      font-size: 0.85rem;
      cursor: grab;
    }

    &:hover::before {
      color: @color-icon__hover-before;
    }
  }

  .ds-icon.ds-icon--clear {
    &::before {
      font-size: 0.65rem;
    }
    &:hover {
      cursor: pointer;
    }

    &:hover::before {
      cursor: pointer;
    }
  }

  .ds-icon.ds-icon--burger {
    &:hover::before {
      cursor: pointer;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .add-item-link {
    &:extend(.font-normal);
    color: @color-link;

    &:hover {
      color: @color-link__hover;
      cursor: pointer;
    }
  }

  .reorder-border-top {
    box-shadow: 0px 2px 0px #007DB8 inset;
  }

  .reorder-border-bottom {
    box-shadow: 0px -2px 0px #007DB8 inset;
  }
}
</style>
