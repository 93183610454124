<script>
import PFConsultantPopupComponent from '@/components/common/PFConsultantPopupComponent';

export default {
  name: 'PFServiceCountsComponent',
  props: {
    viewMode: { type: Boolean, default: true },
    title: { type: String, default: '' },
    helpText: { type: String, default: '' },
    showNumber: { type: Number, default: 0 },
    locationSection: { type: Boolean, default: false },
    consultantMode: { type: Boolean, default: true },
    country: { type: String, default: '' },
    headerText: { type: String, default: '' },
    closeText: { type: String, default: '' },
    isConsultants: { type: Boolean, default: true },
    helpTextLink: { type: String, default: '' },
  },
  components: {
    PFConsultantPopupComponent,
  },
  data: () => ({
    activeColor: '',
    tooltipOpen: false,
    isPopupShown: false,
    hoverTooltip: false,
  }),
  methods: {
    changeColor() {
      if (this.consultantMode && this.showNumber > 0) {
        this.activeColor = '#007db8';
      } else if (!this.viewMode && !this.consultantMode) {
        this.activeColor = '#969696';
      } else if (!this.viewMode && this.showNumber <= 0) {
        this.activeColor = '#969696';
      }
      this.tooltipOpen = true;
    },
    changeColorBack() {
      this.activeColor = '';
      this.tooltipOpen = false;
    },
    showHelpOrModal() {
      if (this.consultantMode && this.showNumber > 0 && !this.hoverTooltip) {
        this.isPopupShown = true;
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${this.$store.state.partnerProfile.scrollBarWidth}px`;
      }
    },
    hidePopup() {
      this.isPopupShown = false;
      document.body.style.overflow = 'scroll';
      document.body.style.paddingRight = '0px';
    },
    openLearnMore() {
      const win = window.open(this.helpTextLink, '_blank');
      win.focus();
    },
    toggleHoverTooltip(val) {
      this.hoverTooltip = val;
    },
  },
};
</script>

<template>
  <div>
    <div class='ds-p-none service-count-container'
      :class="consultantMode && showNumber > 0 ? 'link-cursor' : 'default-cursor'"
      :id="viewMode ? 'view-mode' : 'edit-mode'"
      @mouseover="changeColor"
      @mouseleave="changeColorBack"
      @click="showHelpOrModal">
      <div class="tooltip-service-count ds-tooltip-icon">
        <div
          :class="[tooltipOpen ? 'display-block' : 'display-none','tooltiptext','ds-tooltip-icon__tooltip--service']">
          <span v-html="helpText"></span>
          <span v-if="consultantMode && viewMode"
            @mouseover="toggleHoverTooltip(true)"
            @mouseleave="toggleHoverTooltip(false)"
            @click="openLearnMore" class="help-text-link">
            <b>{{$t('learn.more')}}</b>
          </span>
        </div>
      </div>
      <div class="pf-consultant ds-p-none ds-m-none"
        :class="locationSection ? 'location-count' : 'service-count'"
        :style="{color: activeColor}"
        v-html="title">
      </div>
      <div class="pf-consultant-number ds-p-none ds-m-t-xxs"
        :class="locationSection ? 'location-count-number ds-m-b-m' : 'service-count-number'"
        :style="{color: activeColor}">
        {{showNumber === 0 ? "—" : showNumber}}</div>
    </div>
    <PFConsultantPopupComponent
      v-if="consultantMode"
      :closeAction="hidePopup"
      :isShown="isPopupShown"
      :country="country"
      :header-text="headerText"
      :close-text="closeText"
      :is-consultants="isConsultants"
    />
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

  .service-count-container {
    position: relative;
    width: fit-content;
    margin: auto;
  }

  .pf-consultant {
    &:extend(.font-expanded);
    text-align: center;
    letter-spacing: normal;
  }

  .pf-consultant-number {
    &:extend(.font-normal);
    text-align: center;
    margin: auto;
  }

  .service-count {
    font-size: 21px;
    line-height: 1.19;
  }

  .service-count-number {
    font-size: 38px;
    line-height: 1.21;
    margin-bottom: 40px;
  }

  .location-count {
    font-size: 16px;
    line-height: 21px;
  }

  .location-count-number {
    font-size: 26px;
    line-height: 24px;
  }

  #view-mode .pf-consultant {
    color: @color-black;
  }

  #view-mode .pf-consultant-number {
    color: @color-consultants-count;
  }

  #edit-mode {
    color: @color-icon-before;
  }

  .tooltip-service-count {
    position: absolute !important;
    bottom:70%;
    left:100%;
  }

  .help-text-link {
    cursor: pointer;
  }
</style>
