<script>
import { mapState } from 'vuex';
import MobileDrag from '@/plugins/mobileDrag';

import PEOfficeEditComponent from '@/components/editor/popup/PEOfficeEditComponent';
import PFPopUpComponent from '@/components/common/PFPopUpComponent';

const MAX_LOCATION_NAME = 100;
export default {
  name: 'PEAddressesComponent',
  components: {
    PEOfficeEditComponent,
    PFPopUpComponent,
  },
  props: {
    partnerProfileId: {
      type: String,
    },
    localProfileId: {
      type: Number,
    },
    addresses: {
      type: Array,
      default: (() => []),
    },
    countryName: {
      type: String,
    },
    countryCodeText: {
      type: String,
    },
    multipleCountries: {
      type: Boolean,
    },
  },
  data: () => ({
    invalidEmail: [],
    invalidPhone: [],
    emailTimer: null,
    phoneTimer: null,
    maxLocationName: MAX_LOCATION_NAME,
    startDragging: false,
    locationToolTipOpen: [],
    addressEditModalOpen: false,
    currentAddress: null,
    currentAddressIdx: null,
    isPopupShown: false,
    isCurrentMainLocation: false,
    originalWidth: '',
    activeEvent: '',
    preEle: null,
    cloneEle: null,
  }),
  computed: {
    ...mapState('partnerProfile', {
      loading: (state) => state.loading,
    }),
  },
  methods: {
    openAddressEditModal(address, idx) {
      this.currentAddress = address || {};
      this.currentAddressIdx = idx === null ? null : idx;
      this.addressEditModalOpen = true;
    },
    closeAddressEditModal() {
      this.currentAddress = null;
      this.currentAddressIdx = null;
      this.addressEditModalOpen = false;
      this.reset();
    },
    showPopup(idx, isMainLocation) {
      this.currentAddressIdx = idx === null ? null : idx;
      this.isCurrentMainLocation = isMainLocation;
      this.isPopupShown = true;
      document.body.style.overflow = 'hidden';
    },
    hidePopup() {
      this.isPopupShown = false;
      this.currentAddressIdx = null;
      document.body.style.overflow = 'scroll';
    },
    submitRemoveOffice() {
      this.deleteLocalOffice(this.currentAddressIdx);
      this.hidePopup();
    },
    reset() {
      this.$store.dispatch('locationAddressAutoComplete/clearQuery');
      this.$store.dispatch('locationAddressAutoComplete/clearResults');
    },
    deleteLocalOffice(idx) {
      this.$store.commit('locationAddressAutoComplete/deleteOfficeAddress', {
        placeIdx: idx,
        country: this.countryName,
      });
    },
    selectItem(event) {
      this.$store.dispatch('partnerProfile/toggleSelectAddress', event.target.value);
    },
    onDrop(event) {
      event.preventDefault();
      const draggedElementId = event.dataTransfer.getData('text');
      let targetElementId = null;
      if (event.target.className !== 'address-container') {
        const parentEl = this.findParentWithClass('address-container', event.target);
        if (parentEl) {
          targetElementId = parentEl.id;
        } else {
          targetElementId = event.target.id;
        }
      }
      if (targetElementId && targetElementId !== draggedElementId) {
        this.$store.commit('locationAddressAutoComplete/reorderOfficeAddress', {
          sourceIdx: this.extractItemIdxbyId(draggedElementId),
          targetIdx: this.extractItemIdxbyId(targetElementId),
          country: this.countryName,
        });
      }
    },
    onDragStart(event) {
      event.dataTransfer.setData('text', event.target.id);
    },
    onDragOver(event) {
      event.preventDefault();
    },
    extractItemIdxbyId(id) {
      const idArray = id.split('-');
      return parseInt(idArray[idArray.length - 1], 10);
    },
    findParentWithClass(className, el) {
      if (el.className.indexOf(className, 0) > 0) {
        return el;
      }
      return this.findParentWithClass(className, el.parentElement);
    },
    removeHq() {
      this.$store.commit('locationAddressAutoComplete/removeHqByFullAddress');
    },
    handleTouchStart(e) {
      if (e.target.id === 'office-drag-drop-icon') {
        e.preventDefault();
        this.originalWidth = `${e.currentTarget.clientWidth}px`;
        this.activeEvent = 'start';
      }
    },
    handleTouchMove(e) {
      if (e.target.id === 'office-drag-drop-icon') {
        e.preventDefault();
        const listArea = document.getElementById(`${this.countryName}-edit-addresses-container`);
        if (!this.cloneEle) {
          this.cloneEle = MobileDrag.cloneDragItem(e.currentTarget, this.originalWidth);
          listArea.appendChild(this.cloneEle);
        }
        const touchLocation = e.targetTouches[0];
        const resourceDragIconWid = 12;
        const resourceItemPadding = 12;

        const pageX = `${touchLocation.pageX - MobileDrag.getOffset(listArea).left - resourceDragIconWid - resourceItemPadding}px`;
        const pageY = `${touchLocation.pageY - MobileDrag.getOffset(listArea).top - resourceDragIconWid}px`;

        this.cloneEle.style.left = pageX;
        this.cloneEle.style.top = pageY;
        this.activeEvent = 'move';
        const validIds = [];
        let n = 1;
        while (n <= this.addresses.length) {
          const key = `${this.countryName}-office-address-${n - 1}`;
          if (key !== `${e.currentTarget.id}-clone`) {
            validIds.push(key);
          }
          n++;
        }
        const currentTargetEle = MobileDrag
          .filterOutLargeEle(touchLocation.pageX, touchLocation.pageY, validIds);
        if (currentTargetEle && (!this.preEle || currentTargetEle.id !== this.preEle.id)) {
          this.preEle = currentTargetEle;
        }
      }
    },
    handleTouchEnd(e) {
      if (e.target.id === 'office-drag-drop-icon') {
        e.preventDefault();
        if (this.activeEvent === 'move' && this.preEle && this.cloneEle) {
          const draggedElementId = e.currentTarget.id;
          const targetElementId = this.preEle.id;
          if (targetElementId !== draggedElementId) {
            this.$store.commit('locationAddressAutoComplete/reorderOfficeAddress', {
              sourceIdx: this.extractItemIdxbyId(draggedElementId),
              targetIdx: this.extractItemIdxbyId(targetElementId),
              country: this.countryName,
            });
          }
        }
        if (this.cloneEle) {
          document.getElementById(`${this.countryName}-edit-addresses-container`).removeChild(this.cloneEle);
        }
        this.cloneEle = null;
        this.preEle = null;
        this.activeEvent = '';
      }
    },
  },
};
</script>

<template>
  <div class="ds-flexCol-12">
    <div class="ds-m-none ds-m-b-l ds-flexRow office-location-add-container center-items">
      <button
        class="ds-button ds-button--primary office-location-add-button"
        type="button"
        @click="openAddressEditModal(null, null)"
        id="partner-contact-email-button__cancel"
      >
        {{ $t("location.section.add") }}
      </button>
    </div>
    <ul v-if="addresses && addresses.length > 0" class="edit-addresses-container ds-m-none ds-flexRow"
      :id="countryName+'-edit-addresses-container'"
      :class="addresses && addresses.length < 3 ? 'center-items' : ''">
      <li
        :draggable="startDragging"
        @drop="onDrop"
        @dragover="onDragOver"
        @dragstart="onDragStart"
        @touchstart="handleTouchStart"
        @touchmove="handleTouchMove"
        @touchend="handleTouchEnd"
        v-for="n in addresses.length"
        :id="countryName+'-office-address-'+(n - 1)"
        :key="countryName+'-office-address-'+(n - 1)"
        :class="['selected-item',
        'ds-flexCol ds-flexCol-sm-6 ds-flexCol-md-4', 'address-container', 'ds-p-r-m', 'ds-m-b-l']">
        <label class="ds-checkbox pf-disable-select"
          :for="'checkbox-'+partnerProfileId+'-'+countryName+'-'+(n - 1)">
          <div class="ds-checkbox__box">
            <i
            @mouseover="startDragging=true"
            @mouseleave="startDragging=false"
            id="office-drag-drop-icon" class="ds-icon ds-icon--move office-move-icon office-icon-style"></i>
            <i class="ds-icon ds-icon--edit office-edit-icon office-icon-style"
            @click="openAddressEditModal(addresses[n - 1], n - 1)"></i>
            <div class="office-delete-icon"
            @click="showPopup(n - 1, addresses[n - 1].mainLocation)"></div>
          </div>
          <span class="ds-checkbox__label">
            <div class="location-name-label">
              <div class="address__line-1" v-for="(addressLine, idx) in addresses[n - 1].addressLines"
                :key="countryName + '-' +'local-addressLine-' + idx">
                <span>{{ addressLine }}</span>
              </div>
              <div class="address__line-2">
                {{ addresses[n - 1].suite ? addresses[n - 1].suite : ''}}
              </div>
              <div class="address__phone">
                {{ addresses[n - 1].phone }}
              </div>
              <div class="address__email">
                {{ addresses[n - 1].email }}
              </div>
            </div>
        </span>
        </label>
        <div v-show="addresses[n - 1].mainLocation" class="address-text-margin main-location-tag-container">
                <i class="ds-icon ds-icon--checked set-as-main-location-icon ds-m-r-xxs"></i>
                <span class="main-location-tag">{{ $t('location.address.hq.title')}}</span>
                <span class="main-location-remove-tag" @click="removeHq">
                  {{$t('location.address.hq.remove')}}</span>
        </div>
      </li>
    </ul>
  <PEOfficeEditComponent v-if="addressEditModalOpen"
      :countryCode="countryName"
      :countryCodeText="countryCodeText"
      :addressModel="currentAddress"
      :addressIdx="currentAddressIdx"
      :closeModal="closeAddressEditModal"
      :multipleCountries="multipleCountries"
      @removeOfficeFromChild="submitRemoveOffice"
      />
  <PFPopUpComponent
      class="pf-HQ-confirm-delete-popup"
      :isShown="isPopupShown"
      :text="isCurrentMainLocation ? $t('main.office.delete.modal.text') : $t('local.office.delete.modal.text')"
      :submitText="$t('button.delete')"
      :cancelText="$t('helptext.partner.editor.discard.agreement.cancelButton')"
      :headerText="$t('local.office.delete.header.text')"
      :submitAction="submitRemoveOffice"
      :cancelAction="hidePopup"/>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.edit-addresses-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding-left: 0;
  max-width: 778px;
  margin-left: auto !important;
  margin-right: auto !important;
  .ds-checkbox__box {
    border: none !important;
  }
  .office-location-add {
    margin-left: 50%;
  }

  .address__line-1,
  .address__line-2,
  .address__line-3,
  .address__phone,
  .address__email {
    &:extend(.font-normal);
    font-size: 14px;
    line-height: 21px;
  }

  li {
    padding-left: 0;
  }

  .selected-item {
    .address__office-name,
    .address__location-name,
    .address__line-1,
    .address__line-2,
    .address__line-3,
    .address__phone,
    .address__email {
      color: @color-black;
    }
  }

  .address__office-name {
    &:extend(.font-expanded);
    font-size: 16px;
    line-height: 24px;
  }
  .address__location-name {
    &:extend(.font-expanded);
    font-size: 16px;
    line-height: 24px;
  }

  .drag-grab-cursor {
    cursor: grab;
    position: absolute;
    top: 30px;
  }

  .address-error-email {
    color: @color-field-error !important;
  }

  .ds-checkbox__label {
    width: 100%;
  }

  .local-address-label {
    &:extend(.font-normal);
  }
  .location-name-label {
    cursor: default;
  }

  .local-address-error-message {
    color: @color-field-error !important;
    left: 0;
    position: relative !important;
    font-size: 14px;
    .ds-icon--close-outline {
      line-height: 17px;
    }
  }

  .local-address-error-textField {
    border-color: @color-field-error;
  }
  .address-text-margin {
    margin-left: 32px;
  }
  .ds-checkbox__control {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .office-move-icon {
    position: absolute;
    top: 0;
    left: 0;
  }
  .office-edit-icon {
    position: absolute;
    top: 44px;
    left: 0;
  }
  .office-delete-icon {
    position: absolute;
    height: 18px;
    width: 18px;
    top: 88px;
    left: 0;
    background-image: url('../../assets/icons/delete.svg');
    filter: brightness(0) saturate(100%) invert(86%)
    sepia(1%) saturate(0%) hue-rotate(144deg) brightness(96%) contrast(84%);
  }
  .office-delete-icon:hover {
  filter: brightness(0) saturate(100%)
  invert(25%) sepia(17%) saturate(0%) hue-rotate(201deg) brightness(92%) contrast(100%);
}
  .ds-checkbox .office-icon-style::before {
    color: @color-gray-4 !important;
    font-size: 24px;
  }
  .ds-checkbox .office-icon-style:hover::before{
    color: @color-gray-1 !important;
    font-size: 24px;
  }
  .address-container {
    min-width: 180px !important;
    min-height: 112px !important;
  }
  .location__name {
    position: relative;
  }
  .location-tooltip-button {
      position: absolute;
      top: calc(50% - 8px);
      right: 4px;
      border: none;
      background-color: transparent;
      cursor: pointer;
  }
  .ds-checkbox .location-tooltip-button.ds-icon::before {
      color: @color-gray-2 !important;
  }
  .ds-location-name-tooltip {
    left: 90%;
  }
  .location-name-input-field {
    padding-right: 30px;
  }
  .set-as-main-location-icon::before {
    font-size: 12px;
    color: @color-green-0;
  }
  .main-location-tag {
    &:extend(.font-normal);
    font-size: 14px;
    line-height: 21px;
    color: @color-green-0;
  }
  .main-location-remove-tag {
    &:extend(.font-normal);
    cursor: pointer;
    font-size: 14px;
    line-height: 21px;
    color: @color-link;
  }
  .main-location-remove-tag:hover {
    color: @color-link__hover;
  }
  .office-location-add-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    .office-location-add-button {
      width: auto;
    }
  }
}
</style>
