<script>
export default {
  name: 'PFGlanceCountsComponent',
  props: {
    viewMode: { type: Boolean, default: true },
    title: { type: String, default: '' },
    helpText: { type: String, default: '' },
    showNumber: { type: Number, default: 0 },
    consultantMode: { type: Boolean, default: false },
    helpTextLink: { type: String, default: '' },
  },
  data: () => ({
    mock_implementation_data: 10,
    activeColor: '',
    tooltipOpen: false,
  }),
  methods: {
    changeColor() {
      if (!this.viewMode) {
        this.activeColor = '#969696';
      }
      this.tooltipOpen = true;
    },
    changeColorBack() {
      this.activeColor = '';
      this.tooltipOpen = false;
    },
    openLearnMore() {
      const win = window.open(this.helpTextLink, '_blank');
      win.focus();
    },
  },
};
</script>

<template>
  <div class="glance-section default-cursor"
  :id="viewMode ? 'glance-view-mode' : 'glance-edit-mode'"
  @mouseover="changeColor"
  @mouseleave="changeColorBack">
    <div
      class="pf-consultant-glance ds-p-none ds-m-none"
      :style="{color: activeColor}"
      v-html="title">
    </div>
    <div class="pf-consultant-number-glance ds-p-none" :style="{color: activeColor}">
      {{showNumber === 0 ? "—" : showNumber}}
    </div>
    <div v-show="helpText.length > 0" class="ds-tooltip-icon">
      <div :class="[tooltipOpen ? 'display-block' : 'display-none','tooltiptext','ds-tooltip-icon__tooltip--glance']">
           <span v-html="helpText"></span>
           <span v-if="consultantMode && viewMode" @click="openLearnMore" class="help-text-link">
            <b>{{$t('learn.more')}}</b>
          </span>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import "../../assets/css/common.less";

.pf-consultant-glance {
  &:extend(.font-normal);
  font-size: 16px;
  line-height: 21px;
}

.pf-consultant-number-glance {
  &:extend(.font-normal);
  font-size: 27px;
  line-height: 30px;
}

#glance-view-mode .pf-consultant-glance {
  color: @color-glance-consultants;
}

#glance-view-mode .pf-consultant-number-glance {
  color: @color-black;
}

#glance-view-mode:hover .tooltiptext {
  display: block;
}

#glance-edit-mode {
  color: @color-icon-before;
}

.link-cursor {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}
</style>
